import React, { useEffect, useState } from 'react'
import RiskTC from './RiskTables/RiskTypeAndCategoryTable.jsx'
import RiskStatusTable from './RiskTables/RiskStatusTable.jsx'
import ProbabilityTable from './RiskTables/ProbabilityTable.jsx'
import ProbabilityImpactsTable from './RiskTables/ProbabilityImpactsTable.jsx'
import ImpactDimensionsTable from './RiskTables/ImpactDimensionsTable.jsx'
import FrequencyOfReviewTable from './RiskTables/FrequencyOfReviewTable.jsx'
import ActionPriorityTable from './RiskTables/ActionPriorityTable.jsx'
import ActionEffectivenessTable from './RiskTables/ActionEffectivenessTable.jsx'
import ImpactDescriptionTable from './RiskTables/ImpactDescriptionTable.jsx'
import RiskMatrixTable from './RiskTables/RiskMatrixTable.jsx'

export default function RiskSetup(props) {
  const tabs = [
    { id: 1, label: 'Categories & Types', bgColor: 'rgba(249, 168, 37)' },
    { id: 2, label: 'Status & Frequency', bgColor: 'rgba(106, 27, 154)' },
    {
      id: 3,
      label: 'Priorities and Effectiveness',
      bgColor: 'rgba(0, 172, 193)',
    },
    {
      id: 4,
      label: 'Probabilities & Impact Setup',
      bgColor: 'rgba(57, 73, 171)',
    },
    {
      id: 5,
      label: 'Impact Description Setup',
      bgColor: 'rgba(0, 191, 165)',
    },
    {
      id: 6,
      label: 'Organisational Risk Matrix',
      bgColor: 'rgba(126, 87, 194)',
    },
  ]

  const [activeTab, setActiveTab] = React.useState(1)

  const [riskCategory, setRiskCategoryType] = useState({})

  const getRiskCategory = riskCat => {
    setRiskCategoryType(riskCat)
    console.log('riskCat --->', riskCat)
  }
  const renderTab = () => {
    switch (activeTab) {
      case 1:
        return (
          <>
            <RiskTC
              title={'Risk Category'}
              apiEndpoint={'riskCategories'}
              headings={['Name', 'Description']}
              orgId={props.match.params.id}
              getRiskCategory={getRiskCategory}
            />
            <RiskTC
              title={'Risk Type'}
              apiEndpoint={'riskTypes'}
              headings={['Name', 'Risk Category', 'Description']}
              orgId={props.match.params.id}
              riskCategory={riskCategory}
            />
          </>
        )
        break

      case 2:
        return (
          <>
            <RiskStatusTable orgId={props.match.params.id} />
            <FrequencyOfReviewTable orgId={props.match.params.id} />
          </>
        )
        break

      case 3:
        return (
          <>
            <ActionPriorityTable orgId={props.match.params.id} />
            <ActionEffectivenessTable orgId={props.match.params.id} />
          </>
        )
        break

      case 4:
        return (
          <>
            <ProbabilityTable orgId={props.match.params.id} />

            <ProbabilityImpactsTable orgId={props.match.params.id} />
            <ImpactDimensionsTable orgId={props.match.params.id} />
          </>
        )
        break

      case 5:
        return (
          <>
            <ImpactDescriptionTable orgId={props.match.params.id} />
          </>
        )
        break

      case 6:
        return (
          <>
            <RiskMatrixTable orgId={props.match.params.id} />
          </>
        )

      default:
        break
    }
  }

  return (
    <div className="risk-setup-container table-main">
      <div className="risk-tabs-container">
        {tabs.map(item => (
          <div
            key={item.id}
            style={{
              background: item.id === activeTab ? item.bgColor : '#c5cae9',
            }}
            className={`risk-tab ${item.id === activeTab ? 'active-tab' : ''}`}
            onClick={() => setActiveTab(item.id)}
          >
            {item.label}
          </div>
        ))}
      </div>

      <hr />

      {renderTab()}
    </div>
  )
}
