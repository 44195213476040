import React, { Component, useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import Auth from '../../lib/Auth.js'
import axios from 'axios'
import '../../style/container/risk-tables.scss'
import MyTasksModal from '../presentational/UserObjectivesModals/MyTasksModal.jsx'
import ButtonsHeader from '../common/EditDeleteTH.jsx'
import ButtonActions from '../common/EditDeleteButtons.jsx'
import moment from 'moment'
export default class MyTasksTable extends Component {
  constructor(props) {
    super(props)
    this.submitTaskForm = this.submitTaskForm.bind(this)
    // this.fetchTasks = this.fetchTasks.bind(this);
    this.state = {
      tableData: [],
      users: [],
      goals: [],
      showEdit: false,
      frequency: [],
      showAdd: false,
      loading: true,
      orgId: props.orgId,
      selectedItem: null,
      showHeadings: props.showHeadings,
      objectiveType: props.objectiveType,
      editModal: props.editModal,
      objEditModal: props.objEditModal,
      showActions: false,
      page: 0,
      rowsPerPage: 10,
      totalPages: 0,
      priorities: [],
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      orgId: props.orgId,
      showHeadings: props.showHeadings,
      objEditModal: props.objEditModal,
      showActions: props.showActions,
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  getPageRows = () => {
    var chunks = [],
      i = 0,
      n = this.state.tableData.length
    while (i < n) {
      chunks.push(this.state.tableData.slice(i, (i += this.state.rowsPerPage)))
    }

    return chunks[this.state.page] || this.state.tableData
  }

  getOrgId = () => {
    let token = Auth.getToken()
    if (!token) {
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64)).Org
  }

  getTotalPages = () => {
    var chunks = [],
      i = 0,
      n = this.state.tableData.length
    while (i < n) {
      chunks.push(this.state.tableData.slice(i, (i += this.state.rowsPerPage)))
    }
    return chunks.length
  }

  fetchData() {
    axios
      .get(BASE_URL + `/organisations/${this.getOrgId()}/tasks`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          let filterData = res?.data?.filter(
            e => e.assignee === Auth.getLoggedUserId()
          )
          let reduceData = filterData?.reduce((final, data, key) => {
            return [...final, { ...data, itemCount: key + 1 }]
          }, [])
          console.log('TB COUNT -->', reduceData)
          this.setState(
            {
              tableData: reduceData,
              loading: false,
            },
            () => this.setState({ totalPages: this.getTotalPages() })
          )
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.getOrgId()}/priorities`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        this.setState({ priorities: res.data })
      })
  }

  submitTaskForm(e, data) {
    data.dueDt = moment(data.dueDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.startDt = moment(data.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.reminder = moment(data.reminder, 'DD-MM-YY').format('YYYY-MM-DD')

    axios
      .post(
        BASE_URL + `/organisations/${this.getOrgId()}/tasks`,
        {
          ...data,
          // assignee: this.state.users.filter(
          //     (item) => (item.emailAddress = Auth.getUserId())
          // )[0]?.id,
          assignee: Auth.getLoggedUserId(),
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then(res => {
        if (res.status === 200) {
          this.setState({ showAdd: false })
          this.fetchData()
        } else {
          return Swal.fire('Failed', JSON.stringify(res.message), 'error')
        }
      })
      .catch(res => {
        return Swal.fire('Failed', JSON.stringify(res.message), 'error')
      })
  }

  deleteItem = (e, item) => {
    this.setState({ loading: true }, () => {
      axios
        .delete(
          BASE_URL + `/organisations/${this.getOrgId()}/tasks/${item.id}`,
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }

  handleEdit(e, item) {
    if (
      this.state.objEditModal == false ||
      this.state.objEditModal == undefined
    ) {
      this.setState({ selectedItem: item }, () =>
        this.setState({ showEdit: true })
      )
    } else {
      this.props.editItem(item)
    }
  }

  renderTableData() {
    return this.getPageRows().map((item, index) => {
      return (
        <tr className="table-data" key={index}>
          <td style={{ width: '5%' }}>{item.itemCount}</td>
          <td style={{ width: '35%' }}>{item.name}</td>
          <td style={{ width: '10%' }}>{item.actionType.name}</td>
          <td style={{ width: '10%' }}>{item.actionStatus.name}</td>
          <td style={{ width: '8%' }}>
            {moment(
              `${item.startDate.day}/${item.startDate.month}/
                    ${item.startDate.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td style={{ width: '8%' }}>
            {moment(
              `${item.dueDate.day}/${item.dueDate.month}/
                    ${item.dueDate.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td style={{ width: '6%' }}>{item.completed}%</td>
          <td style={{ width: '10%' }}>{item.priority.name}</td>

          {Auth.getRole() !== 'View' && (
            <ButtonActions
              editAction={e => this.handleEdit(e, item)}
              deleteAction={e => this.deleteItem(e, item)}
              dataTarget=""
            />
          )}
        </tr>
      )
    })
  }

  render() {
    return (
      <div className="table-container table-main">
        {this.state.showHeadings && (
          <>
            <h4>My Tasks</h4>
            {Auth.getRole() !== 'View' && (
              <p>
                Add New{' '}
                <i
                  className="fa fa-plus"
                  onClick={() => this.setState({ showAdd: true })}
                />
              </p>
            )}
          </>
        )}
        <table style={{ width: '100%' }} className="strategic-objectives-table">
          <thead>
            <tr className="bg-table-header">
              <th style={{ width: '5%' }}>No.</th>
              <th style={{ width: '20%' }}>Task</th>
              <th style={{ width: '8%' }}>Type</th>
              <th style={{ width: '8%' }}>Status</th>
              <th style={{ width: '8%' }}>Start Date</th>
              <th style={{ width: '8%' }}>Due Date</th>
              <th>Completed</th>
              <th>Priority</th>
              <ButtonsHeader />
            </tr>
          </thead>
          <tbody>
            {this.state.loading ? (
              <tr className="text-center">
                <td colSpan={5}>Loading...</td>{' '}
              </tr>
            ) : (
              this.renderTableData()
            )}
          </tbody>
        </table>
        <div className="text-right mt-3">
          <span style={{ fontSize: '14px' }}>
            Showing page {this.state.page + 1} of {this.state.totalPages}
          </span>
          <button
            className="previous-page btn btn-primary mx-2"
            disabled={this.state.page == 0}
            onClick={() =>
              this.setState(prevState => ({
                page: prevState.page - 1,
              }))
            }
          >
            Prev
          </button>
          <button
            disabled={this.state.page === this.state.totalPages - 1}
            className="next-page btn btn-primary"
            onClick={() =>
              this.setState(prevState => ({
                page: prevState.page + 1,
              }))
            }
          >
            Next
          </button>
        </div>
      </div>
    )
    /*if (this.state.tableData.length === 0) {
      return (
        <>
          <div className="px-5 my-5 text-center">
            <h1>You do not have any Task setup</h1>
            <button
              onClick={() => this.setState({ showAdd: true })}
              className="addRiskButton"
            >
              Add your first task
            </button>
          </div>
          <MyTasksModal
            show={this.state.showAdd}
            handleClose={() => {
              this.setState({ showAdd: false })
            }}
            assignee={this.state.users[0]}
            priorities={this.state.priorities}
            handleSubmit={this.submitTaskForm}
          />
        </>
      )
    } else {
      return (
        <div className="table-container table-main">
          {this.state.showHeadings && (
            <>
              <h4>My Tasks</h4>
              <p>
                Add New{' '}
                <i
                  className="fa fa-plus"
                  onClick={() => this.setState({ showAdd: true })}
                />
              </p>
            </>
          )}
          <table
            style={{ width: '100%' }}
            className="strategic-objectives-table"
          >
            <thead>
              <tr className="bg-table-header">
                <th style={{ width: '5%' }}>No.</th>
                <th style={{ width: '20%' }}>Task</th>
                <th style={{ width: '8%' }}>Type</th>
                <th style={{ width: '8%' }}>Status</th>
                <th style={{ width: '8%' }}>Start Date</th>
                <th style={{ width: '8%' }}>Due Date</th>
                <th>Completed</th>
                <th>Priority</th>
                <ButtonsHeader />
              </tr>
            </thead>
            <tbody>
              {this.state.loading ? (
                <tr className="text-center">
                  <td colSpan={5}>Loading...</td>{' '}
                </tr>
              ) : (
                this.renderTableData()
              )}
            </tbody>
          </table>
          <div className="text-right mt-3">
            <span style={{ fontSize: '14px' }}>
              Showing page {this.state.page + 1} of {this.state.totalPages}
            </span>
            <button
              className="previous-page btn btn-primary mx-2"
              disabled={this.state.page == 0}
              onClick={() =>
                this.setState(prevState => ({
                  page: prevState.page - 1,
                }))
              }
            >
              Prev
            </button>
            <button
              disabled={this.state.page === this.state.totalPages - 1}
              className="next-page btn btn-primary"
              onClick={() =>
                this.setState(prevState => ({
                  page: prevState.page + 1,
                }))
              }
            >
              Next
            </button>
          </div>
        </div>
      )
    }*/
  }
}
