import React from 'react'
import { Tabs, Tab, Table } from 'react-bootstrap'

const UserSetup = ({
    handleChange,
    organisationUsers,
    organisationJobRoles,
    jobRolesDepartments,
    jobRolesTeams,
    addUser,
    viewUser,
    updateUser,
    deleteUser,
    userData,
    organisationId,
    postOrganisationUsers,
    roles,
    userPermissions,
}) => (
    <div className="reporting-structure layout">
        <div className="body-container">
            <Tabs defaultActiveKey="Users" id="uncontrolled-tab-example">
                <Tab eventKey="Users" title="Users">
                    <div className="tab-title">
                        <h2>Users</h2>

                        {/* Add Users modal*/}

                        <button
                            type="button"
                            className="icon add"
                            data-toggle="modal"
                            data-target="#addModal"
                        >
                            <img src="../../assets/add.svg" alt="add" />
                        </button>

                        <div
                            className="modal fade region"
                            id="addModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="addModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <form onSubmit={addUser}>
                                        <div className="modal-header">
                                            <h2
                                                className="modal-title"
                                                id="addModalLabel"
                                            >
                                                Add User
                                            </h2>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </button>
                                        </div>

                                        <div className="modal-body">
                                            <div className="form-row">
                                                <div className="form-cell">
                                                    <label>
                                                        First Name
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="firstName"
                                                        className="input-text"
                                                    />
                                                </div>
                                                <div className="form-cell">
                                                    <label>
                                                        Last Name
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="lastName"
                                                        className="input-text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell">
                                                    <label>
                                                        Email Address
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="emailAddress"
                                                        className="input-text"
                                                    />
                                                </div>
                                                <div className="form-cell">
                                                    <label>
                                                        Job Role
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        defaultValue={'default'}
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="jobRole"
                                                    >
                                                        <option
                                                            value="default"
                                                            disabled="disabled"
                                                        ></option>
                                                        {organisationJobRoles.map(
                                                            (orgJobRole, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={
                                                                        orgJobRole.id
                                                                    }
                                                                >
                                                                    {' '}
                                                                    {
                                                                        orgJobRole.title
                                                                    }{' '}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell">
                                                    <label>Department</label>
                                                    <select
                                                        className="form-select"
                                                        defaultValue={'default'}
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="department"
                                                    >
                                                        <option
                                                            value="default"
                                                            disabled="disabled"
                                                        ></option>
                                                        <option
                                                            value={
                                                                jobRolesDepartments.id
                                                            }
                                                        >
                                                            {' '}
                                                            {
                                                                jobRolesDepartments.name
                                                            }{' '}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="form-cell">
                                                    <label>Team</label>
                                                    <select
                                                        className="form-select"
                                                        defaultValue={'default'}
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="team"
                                                    >
                                                        <option
                                                            value="default"
                                                            disabled="disabled"
                                                        ></option>
                                                        <option
                                                            value={
                                                                jobRolesTeams.id
                                                            }
                                                        >
                                                            {' '}
                                                            {
                                                                jobRolesTeams.name
                                                            }{' '}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell">
                                                    <label>
                                                        Status
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        defaultValue={'default'}
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="status"
                                                    >
                                                        <option
                                                            value="default"
                                                            disabled="disabled"
                                                        ></option>
                                                        <option
                                                            value={'ACTIVE'}
                                                        >
                                                            {' '}
                                                            ACTIVE{' '}
                                                        </option>
                                                        <option
                                                            value={'PENDING'}
                                                        >
                                                            {' '}
                                                            PENDING{' '}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="form-cell"></div>
                                            </div>
                                        </div>

                                        <div className="modal-footer">
                                            <button
                                                type="submit"
                                                className="btn btn-primary add"
                                                aria-label="Close"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Users table*/}

                    <Table size="sm">
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email Address</th>
                                <th>Job Role</th>
                                <th>Department</th>
                                <th>Team</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {organisationUsers.map((orgUser, index) => {
                                if (orgUser.organisation.id == organisationId) {
                                    return (
                                        <tr key={orgUser.id}>
                                            <td>{orgUser.firstName} </td>
                                            <td>{orgUser.lastName}</td>
                                            <td>{orgUser.emailAddress}</td>
                                            <td>{orgUser.department}</td>
                                            {!orgUser.team ? (
                                                <td>{orgUser.team}</td>
                                            ) : (
                                                <td></td>
                                            )}
                                            {!orgUser.jobrole ? (
                                                <td>{orgUser.jobRole}</td>
                                            ) : (
                                                <td></td>
                                            )}
                                            <td>{orgUser.status.name}</td>
                                            <td className="table-button-container">
                                                <button
                                                    onClick={e =>
                                                        viewUser(e, orgUser.id)
                                                    }
                                                    data-toggle="modal"
                                                    data-target="#viewUserModal"
                                                    type="button"
                                                    className="btn"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <img
                                                        src="../../assets/Edit_Icon.svg"
                                                        alt="edit"
                                                    />
                                                </button>
                                                <button
                                                    onClick={e =>
                                                        deleteUser(
                                                            e,
                                                            orgUser.id
                                                        )
                                                    }
                                                    type="button"
                                                    className="btn"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <img
                                                        src="../../assets/Delete_Icon.svg"
                                                        alt="Delete"
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>

                        {/* Edit Users modal*/}

                        <div
                            className="modal fade region"
                            id="viewUserModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="userModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <form
                                        onSubmit={e =>
                                            updateUser(
                                                e,
                                                postOrganisationUsers.id
                                            )
                                        }
                                    >
                                        <div className="modal-header">
                                            <h2
                                                className="modal-title"
                                                id="viewModalLabel"
                                            >
                                                Edit User
                                            </h2>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </button>
                                        </div>

                                        <div className="modal-body">
                                            <div className="form-row">
                                                <div className="form-cell">
                                                    <label>
                                                        First Name
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="firstName"
                                                        className="input-text"
                                                        value={
                                                            postOrganisationUsers.firstName
                                                        }
                                                    />
                                                </div>
                                                <div className="form-cell">
                                                    <label>
                                                        Last Name
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="lastName"
                                                        className="input-text"
                                                        value={
                                                            postOrganisationUsers.lastName
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell">
                                                    <label>
                                                        Email Address
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="emailAddress"
                                                        className="input-text"
                                                        value={
                                                            postOrganisationUsers.emailAddress
                                                        }
                                                    />
                                                </div>
                                                <div className="form-cell">
                                                    <label>Job Role</label>
                                                    <select
                                                        className="form-select"
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="jobRole"
                                                        value={
                                                            postOrganisationUsers.jobRole
                                                        }
                                                    >
                                                        <option
                                                            value="default"
                                                            disabled="disabled"
                                                        ></option>
                                                        {organisationJobRoles.map(
                                                            (orgJobRole, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={
                                                                        orgJobRole.id
                                                                    }
                                                                >
                                                                    {' '}
                                                                    {
                                                                        orgJobRole.title
                                                                    }{' '}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-cell">
                                                    <label>Department</label>
                                                    <select
                                                        className="form-select"
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="department"
                                                        value={
                                                            postOrganisationUsers.department !=
                                                            null
                                                                ? postOrganisationUsers
                                                                      .department
                                                                      .id
                                                                : ''
                                                        }
                                                    >
                                                        <option
                                                            value="default"
                                                            disabled="disabled"
                                                        ></option>
                                                        <option
                                                            value={
                                                                jobRolesDepartments.id
                                                            }
                                                        >
                                                            {' '}
                                                            {
                                                                jobRolesDepartments.name
                                                            }{' '}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="form-cell">
                                                    <label>Team</label>
                                                    <select
                                                        className="form-select"
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="team"
                                                        value={
                                                            postOrganisationUsers.team
                                                        }
                                                    >
                                                        <option
                                                            value="default"
                                                            disabled="disabled"
                                                        ></option>
                                                        {jobRolesTeams.map(
                                                            (
                                                                jobRolesTeam,
                                                                i
                                                            ) => (
                                                                <option
                                                                    key={i}
                                                                    value={
                                                                        jobRolesTeam.id
                                                                    }
                                                                >
                                                                    {' '}
                                                                    {
                                                                        jobRolesTeam.name
                                                                    }{' '}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell">
                                                    <label>
                                                        Status
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        value={
                                                            postOrganisationUsers.status
                                                        }
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationUsers'
                                                            )
                                                        }
                                                        name="status"
                                                    >
                                                        <option
                                                            value="default"
                                                            disabled="disabled"
                                                        ></option>
                                                        <option
                                                            value={'ACTIVE'}
                                                        >
                                                            {' '}
                                                            ACTIVE{' '}
                                                        </option>
                                                        <option
                                                            value={'PENDING'}
                                                        >
                                                            {' '}
                                                            PENDING{' '}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal-footer">
                                            <button
                                                type="submit"
                                                className="btn btn-primary add"
                                                aria-label="Close"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Table>
                </Tab>

                <Tab eventKey="User Permissions" title="User Permissions">
                    <div className="tab-title">
                        <h2>User Permissions</h2>
                        {/*<button type="button" className="icon add" data-toggle="modal" data-target="#teamModal">
            <img src="../../assets/add.svg" alt="add"/>
          </button>
          <div className="modal fade region" id="teamModal" tabIndex="-1" role="dialog" aria-labelledby="teamModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">

              <form onSubmit={addTeam}>
                <div className="modal-header">
                  <h2 className="modal-title" id="teamModalLabel">Add Team</h2>
                  <button type="button" className="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="form-column-modal">
                    <label>Name<span className="form-mandatory">*</span></label>
                    <input onChange={(e) => handleChange(e, "postOrganisationTeams")} name="name" className="input-text" />
                  </div>
                  <div className="form-column-modal">
                    <label>Department<span className="form-mandatory">*</span></label>
                    <select className="form-select" defaultValue={"default"} onChange={(e) => handleChange(e, "postOrganisationTeams")} name="department">
                      <option value="default" disabled="disabled"></option>
                      {organisationDepartments.map((orgDepartment, i)=><option key={i} value={orgDepartment.id}>  {orgDepartment.name} </option> )}
                    </select>
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary add" aria-label="Close">Save</button>
                </div>
              </form>
              </div>
            </div>
          </div>*/}
                    </div>
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Departments</td>
                                <td className="table-button-container">
                                    <button
                                        onClick={e => viewTeam(e, orgTeam.id)}
                                        data-toggle="modal"
                                        data-target="#viewTeamModal"
                                        type="button"
                                        className="btn"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <img
                                            src="../../assets/Edit_Icon.svg"
                                            alt="edit"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={e => deleteTeam(e, orgTeam.id)}
                                        className="btn"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <img
                                            src="../../assets/Delete_Icon.svg"
                                            alt="Delete"
                                        />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Teams</td>
                                <td className="table-button-container">
                                    <button
                                        onClick={e => viewTeam(e, orgTeam.id)}
                                        data-toggle="modal"
                                        data-target="#viewTeamModal"
                                        type="button"
                                        className="btn"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <img
                                            src="../../assets/Edit_Icon.svg"
                                            alt="edit"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={e => deleteTeam(e, orgTeam.id)}
                                        className="btn"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <img
                                            src="../../assets/Delete_Icon.svg"
                                            alt="Delete"
                                        />
                                    </button>
                                </td>
                            </tr>
                        </tbody>

                        {/*<div className="modal fade region" id="viewTeamModal" tabIndex="-1" role="dialog" aria-labelledby="teamModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
              <form onSubmit={(e) => updateTeam(e, organisationData.id)}>
                <div className="modal-header">
                  <h2 className="modal-title" id="teamModalLabel">Edit Team</h2>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="form-column-modal">
                    <label>Name<span className="form-mandatory">*</span></label>
                    <input onChange={(e) => handleChange(e, "postOrganisationTeams")} name="name" className="input-text" value={organisationData.name} />
                  </div>
                  <div className="form-column-modal">
                    <label>Department<span className="form-mandatory">*</span></label>
                    <select className="form-select" defaultValue={"default"} onChange={(e) => handleChange(e, "postOrganisationTeams")} name="department">
                      <option value="default" disabled="disabled"></option>
                      {organisationDepartments.map((orgDepartment, i)=><option key={i} value={organisationData.department.id}>  </option> )}
                    </select>
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary add" aria-label="Close">Save</button>
                </div>
                </form>

              </div>
            </div>
          </div>*/}
                    </Table>
                </Tab>

                {/*System roles*/}

                <Tab eventKey="System Roles" title="System Roles">
                    <div className="tab-title">
                        <h2>System Roles</h2>
                        {/*<button type="button" className="icon add" data-toggle="modal" data-target="#jobRoleModal">
            <img src="../../assets/add.svg" alt="add"/>
          </button>
          {/*<div className="modal fade region" id="jobRoleModal" tabIndex="-1" role="dialog" aria-labelledby="jobRoleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">

              <form onSubmit={addJobRole}>
                <div className="modal-header">
                  <h2 className="modal-title" id="jobRoleModalLabel">Add Job Role</h2>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="form-column-modal">
                    <label>Name<span className="form-mandatory">*</span></label>
                    <input onChange={(e) => handleChange(e, "postOrganisationJobRoles")} name="name" className="input-text" />
                  </div>
                  <div className="form-column-modal">
                    <label>Department<span className="form-mandatory">*</span></label>
                    <select className="form-select" defaultValue={"default"} onChange={(e) => handleChange(e, "postOrganisationJobRoles")} name="department">
                      <option value="default" disabled="disabled"></option>
                      {organisationDepartments.map((orgDepartment, i)=><option key={i} value={orgDepartment.id}>  {orgDepartment.name} </option> )}
                    </select>
                  </div>
                  <div className="form-column-modal">
                    <label>Team<span className="form-mandatory">*</span></label>
                    <select className="form-select" defaultValue={"default"} onChange={(e) => handleChange(e, "postOrganisationJobRoles")} name="team">
                      <option value="default" disabled="disabled"></option>
                      {organisationTeams.map((orgTeam, i)=><option key={i} value={orgTeam.id}>  {orgTeam.name} </option> )}
                    </select>
                  </div>
                  <div className="form-column-modal">
                    <label>Mandatory<span className="form-mandatory">*</span></label>
                    <select className="form-select" defaultValue={"default"} onChange={(e) => handleChange(e, "postOrganisationJobRoles")} name="mandatory">
                      <option value="default" disabled="disabled"></option>
                      <option value={true}> Yes </option>
                      <option value={false}> No </option>
                    </select>
                  </div>
                  <div className="form-column-modal">
                    <label>Reporting To</label>
                    <select className="form-select" defaultValue={"default"} onChange={(e) => handleChange(e, "postOrganisationJobRoles")} name="parentJobRole">
                      <option value="default" disabled="disabled"></option>
                      {organisationJobRoles.map((orgJobRole, i)=><option key={i} value={orgJobRole.id}>  {orgJobRole.name} </option> )}
                    </select>
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary add" data-dismiss="modal" aria-label="Close">Save</button>
                </div>
              </form>
              </div>
            </div>
          </div>*/}
                    </div>

                    {/*System roles table*/}

                    <Table size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        {roles.map((role, index) => {
                            return (
                                <tbody>
                                    <tr key={index}>
                                        <td>{role.name}</td>
                                        <td className="table-button-container">
                                            <button
                                                onClick={e =>
                                                    viewRole(e, role.id)
                                                }
                                                data-toggle="modal"
                                                data-target="#viewjobRModal"
                                                type="button"
                                                className="btn"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <img
                                                    src="../../assets/Edit_Icon.svg"
                                                    alt="edit"
                                                />
                                            </button>
                                            {/*<button type="button" onClick={(e) => deleteJobRole(e, orgJobRole.id)} className="btn" data-dismiss="modal" aria-label="Close">
                      <img src="../../assets/Delete_Icon.svg" alt="Delete" />
                    </button>*/}
                                        </td>
                                    </tr>
                                </tbody>
                            )
                        })}
                    </Table>
                </Tab>
            </Tabs>
        </div>
    </div>
)

export default UserSetup
