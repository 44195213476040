class Auth {
  static setToken(token) {
    localStorage.setItem('token', token)
  }

  static getToken() {
    return localStorage.getItem('token')
  }

  static getPayload() {
    const token = this.getToken()
    if (!token) {
      return false
    }
    const parts = token.split('.')
    if (parts.length < 3) {
      return false
    }
    try {
      return JSON.parse(atob(parts[1]))
    } catch {
      return false
    }
  }

  static isAuthenticated() {
    const payload = this.getPayload()
    if (!payload) return false
    const now = Math.round(Date.now() / 1000)
    return now < payload.exp
  }

  static removeToken() {
    localStorage.removeItem('token')
  }

  static getRole() {
    const token = this.getToken()
    if (!token) {
      return 'not found'
    }
    const parts = token.split('.')
    if (parts.length < 3) {
      return false
    }
    try {
      return JSON.parse(atob(parts[1])).Role
    } catch (err) {
      return err
    }
  }

  static getOrg() {
    const token = this.getToken()
    if (!token) {
      return 'not found'
    }
    const parts = token.split('.')
    if (parts.length < 3) {
      return false
    }
    try {
      return JSON.parse(atob(parts[1])).Org
    } catch (err) {
      return err
    }
  }

  static getUserId() {
    const token = this.getToken()
    if (!token) {
      return 'not found'
    }
    const parts = token.split('.')
    if (parts.length < 3) {
      return false
    }
    try {
      return JSON.parse(atob(parts[1])).Email
    } catch (err) {
      return err
    }
  }
  static getLoggedUserId() {
    const token = this.getToken()
    if (!token) {
      return 'not found'
    }
    const parts = token.split('.')
    if (parts.length < 3) {
      return false
    }
    try {
     
      return JSON.parse(atob(parts[1])).user
    } catch (err) {
      return err
    }
  }
}

export default Auth
