import React from 'react'
import { Popconfirm } from 'antd'

import Auth from '../../lib/Auth.js'

export default function EditDeleteButtons({
  editAction,
  deleteAction,
  dataTarget,
  itemId,
}) {
  const [role, setRole] = React.useState(Auth.getRole())

  React.useEffect(() => {
    return () => {}
  }, [role])

  if (role === 'Admin' || role === 'Edit')
    return (
      <td className="button-actions-outline">
        <button
          onClick={editAction}
          data-toggle="modal"
          data-target={dataTarget}
          type="button"
          className="btn px-1"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="../../assets/Edit_Icon.svg" alt="edit" />
        </button>
        <Popconfirm
          title="Are you sure to delete this task?"
          onConfirm={deleteAction}
         
          okText="Yes"
          cancelText="No"
        >
          <button
            type="button"
            className="btn px-1"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src="../../assets/Delete_Icon.svg" alt="Delete" />
          </button>
        </Popconfirm>
      </td>
    )
  else return ''
}
