import React from 'react'
import Auth from '../../lib/Auth.js'

export default function EditDeleteButtons({ editAction, deleteAction , dataTarget, itemId}) {
    const [role, setRole] = React.useState(Auth.getRole())

    React.useEffect(() => {
        return () => {}
    }, [role])

    if (role === 'Admin' || role === 'Edit')
        return (
            <th className="" style={{width:'10%'}}></th>
            )

        else return ""
}
