import React, { useState, useEffect, useContext } from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Radio,
  Typography,
  DatePicker,
  Modal,
  notification,
} from 'antd'
import html2PDF from 'jspdf-html2canvas'
import { DownloadOutlined } from '@ant-design/icons'
import axios from 'axios'
import { isEmpty } from 'lodash'
import * as XLSX from 'xlsx'

// import reportingBG from '../../../assets/report.jpg'
import '../../../style/container/objectives.scss'

import Auth from '../../../lib/Auth.js'

import userContext from '../../../context/userContext'
import ReportingSummary from './ReportingSummary.jsx'
import RiskOrganisationalReport from './Risks/RiskReport.Organisational.jsx'
import RiskIndividualReport from './Risks/RiskReport.Individual.jsx'
import RiskTreatmentIndividualReport from './Risks/RiskTreatmentReport.Individual.jsx'
import RiskTreatmentOrganisationalReport from './Risks/RiskTreatmentReport.Organisational.jsx'

import '../../../style/presentational/risk-reports.scss'
import ObjectivesKpiModal from './ObjectiveKpiReport/ObjectiveKpiModal.jsx'
import RiskRegisterIndividualReport from './Risks/RiskRegisterReport.Individual.jsx'
import RiskRegisterOrganisationalReport from './Risks/RiskRegisterReport.Organisational.jsx'

const { Title } = Typography
const { RangePicker } = DatePicker
const getOrgId = () => {
  let token = Auth.getToken()
  if (!token) {
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64)).Org
}

export const ReportsPage = (props) => {
  const { sessionDetails } = useContext(userContext)
  const [displayComment, setDisplayComment] = useState('')
  const [userDepartments, setUserDepartments] = useState([])
  const [childDepartments, setChildDepartments] = useState([])
  const [viewDepartments, setViewDepartments] = useState(false)
  const [visible, setVisible] = useState(false)
  const [reportModal, setReportModal] = useState(false)
  const [departmentsMap, setDepartmentsMap] = useState({})
  const [userCriteria, setCriteria] = useState()
  const [selectedReportType, setSelectedReportType] = useState()

  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem('tasks') ? 3 : 1
  )
  const [tasks, setTasks] = useState([])
  const [selectedDept, setDept] = useState(null)
  const [summaryDashBoard, setSummaryDashBoard] = useState({})

  const [summaryReportModal, setSummaryReport] = useState(false)
  const [deptReportModal, setDeptReportModal] = useState(false)
  const [orgReportModal, setOrgReportModal] = useState(false)
  const [riskReportData, setRiskReportData] = useState(null)
  const [riskTreatmentReportData, setRiskTreatmentsReportData] = useState(null)

  const [objectivesKpiModal, setObjectiveKpiModal] = useState(false)
  const [riskRegisterReportData, setRiskRegisterReportData] = useState(null)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [objecKpiRepordData, setObjectiveKpiData] = useState(null)
  const [frequencyOfReview, setFrequencyOfReview] = useState(null)
  const [ristTypeChart, setRiskTypeChart] = useState([
    {
      key: '',
      name: '',
      value: '',
    },
  ])
  const [usersData, setUsersData] = useState(null)

  const orgId = getOrgId()

  useEffect(() => {
    getDepartments()
    getUsers()

    axios
      .get(BASE_URL + `/organisations/${orgId}/tasks`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => setTasks(res.data))
    if (localStorage.getItem('tasks')) {
      localStorage.removeItem('tasks')
    }
  }, [])

  useEffect(() => {
    console.log('MY DATA ', !isEmpty(summaryDashBoard), summaryDashBoard)
    if (!isEmpty(summaryDashBoard)) {
      setReportModal(true)
      riskAnalyticMappoing()
    } else {
      setReportModal(false)
    }
  }, [summaryDashBoard])

  const getUsers = () => {
    axios
      .get(BASE_URL + `/organisations/${orgId}/users`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        setUsersData(res.data)
      })
  }

  const onFinish = (values) => {
    resetReportData()
    setButtonLoading(true)
    console.log('Success:', values)
    /* criteria: 'INDIVIDUAL'
    dateFilter: (2)[(Moment, Moment)]
    reportType */
    let req = {
      user: sessionDetails?.user?.id,
      department: values.departments
        ? values.departments
        : sessionDetails?.user?.department?.id,
      organisation: orgId,
      reportsCriteria: values.criteria,
      reportType: values.reportType,
      startDate: '',
      endDate: '',
    }
    //
    setSelectedReportType(values.reportType)
    if (values.reportType === 'DASHBOARD_SUMMARY') {
      if (values.criteria === 'INDIVIDUAL') {
        genearteReport(req)
      } else {
        genearteReport(req)
      }
    } else if (values.reportType === 'RISK') {
      switch (values.criteria) {
        case 'INDIVIDUAL': {
          generateIndividualRiskReport()
          break
        }
        case 'ORGANISATIONAL': {
          genearteReport(req)
          break
        }
        case 'DEPARTMENTAL': {
          generateDepartmentalRiskReport(req)
          break
        }
        default:
          break
      }
    } else if (values.reportType === 'RISK_TREATMENT') {
      const customReq = {
        user: sessionDetails?.user?.id,
        department: values.departments
          ? values.departments
          : sessionDetails?.user?.department?.id,
        organisation: orgId,
        reportsCriteria: values.criteria,
        reportType: 'RISK_TREATMENT',
        startDate: '',
        endDate: '',
      }
      genearteReport(customReq)
    } else if (values.reportType.includes('RISK_REGISTER')) {
      const customReq = {
        user: sessionDetails?.user?.id,
        department: values.departments
          ? values.departments
          : sessionDetails?.user?.department?.id,
        organisation: orgId,
        reportsCriteria: values.criteria,
        reportType: 'RISK_REGISTER',
        startDate: '',
        endDate: '',
        detailed: values.reportType === 'RISK_REGISTER_DETAILED' ? true : false,
      }
      genearteReport(customReq)
    } else if (values.reportType === 'OBJECTIVE_AND_KPI') {
      genearteReport(req)
      // setReportModal(true)
      // setButtonLoading(false)
    }
  }

  const fetchDashboardData = (req) => {
    axios
      .get(BASE_URL + `/organisations/${orgId}/dashboard/${req.user}`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        let data = res?.data
        console.log('RES DASH --->', data)
        setSummaryDashBoard(data)
        setSummaryReport(true)

        setButtonLoading(false)
      })
      .catch((e) => {
        notification.warning({
          message: 'Unsuccessful',
          description: 'This report is not available.',
        })
        setButtonLoading(false)
        console.log(e)
      })
  }

  const riskAnalyticMappoing = () => {
    try {
      let riskAnalytic = summaryDashBoard?.riskAnalyticMessages

      if (!isEmpty(riskAnalytic)) {
        let riskAnalyticKeys =
          !isEmpty(riskAnalytic) && Object.keys(riskAnalytic)

        let riskAnalyticChart = riskAnalyticKeys?.reduce((final, item) => {
          let innerData =
            !isEmpty(riskAnalytic[item]) &&
            Object.keys(riskAnalytic[item]).reduce((final1, item1) => {
              return !isEmpty(riskAnalytic[item])
                ? [
                  ...final1,
                  {
                    key: item,
                    name: item1,
                    value: riskAnalytic[item][item1],
                  },
                ]
                : final1
            }, [])
          return !isEmpty(innerData) ? [...final, ...innerData] : final
        }, [])
        setRiskTypeChart(riskAnalyticChart)
        // return riskAnalyticChart
      } else {
        setRiskTypeChart([
          {
            key: '',
            name: '',
            value: '',
          },
        ])
      }
    } catch (e) {
      console.log(e)
      // return []
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const getDepartments = () => {
    axios
      .get(BASE_URL + `/organisations/${orgId}/departments`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        setUserDepartments(res.data)
        const obj = res.data.reduce((acc, item) => {
          acc[item.id] = item.name
          return acc
        }, {})
        console.log(obj, 'deparmtnes map')
        setDepartmentsMap(obj)
      })
  }

  const generateIndividualRiskReport = () => {
    try {
      axios
        .get(
          BASE_URL +
          `/organisations/${orgId}/risks/?owner=${sessionDetails?.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then((res) => {
          const data = {}
          data.criteria = 'INDIVIDUAL'
          data.data = res.data
          setRiskReportData(data)
          setReportModal(true)
          setButtonLoading(false)
        })
        .catch((e) => {
          notification.warning({
            message: 'Unsuccessful',
            description: 'This report is not available.',
          })
          setButtonLoading(false)
          console.log(e)
        })
    } catch (e) {
      setButtonLoading(false)
    }
  }

  const generateDepartmentalRiskReport = (req) => {
    axios
      .get(
        BASE_URL +
        `/organisations/${orgId}/risks/?department=${req.department}`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length === 0)
          throw new Error('There are no risks under this department')
        else {
          const data = {}
          data.criteria = 'DEPARTMENTAL'
          data.data = res.data
          setRiskReportData(data)
          setReportModal(true)
        }
      })
      .catch((e) => {
        console.log(e)
        notification.warning({
          message: 'Unsuccessful',
          description: e?.message || 'This report is not available.',
        })
        console.log(e)
      })
      .finally(() => {
        setButtonLoading(false)
      })
  }

  const genearteReport = (req) => {
    const filters = ['RISK_TREATMENT', 'RISK_REGISTER']
    try {
      axios
        .post(
          BASE_URL + `/organisations/reports`,
          {
            ...req,
            reportType: filters.includes(req.reportType)
              ? 'RISK'
              : req.reportType,
            reportsCriteria: filters.includes(req.reportType)
              ? 'ORGANISATIONAL'
              : req.reportsCriteria,
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
              'content-type': 'application/json',
            },
          }
        )
        .then((res) => {
          if (req.reportType === 'DASHBOARD_SUMMARY') {
            let data = res?.data?.data
            console.log('RES DASH --->', data)
            setSummaryDashBoard(data)
            setSummaryReport(true)
          } else if (req.reportType === 'RISK') {
            handleRisksReport(res.data, req.reportsCriteria)
          } else if (req.reportType === 'RISK_TREATMENT') {
            handleRiskTreatmentReport(
              res.data,
              req.reportsCriteria,
              req.department
            )
          } else if (req.reportType === 'OBJECTIVE_AND_KPI') {
            handleObjectiveKpiReport(res.data)
          } else if (req.reportType === 'RISK_REGISTER') {
            handleRiskRegisterReport(
              res.data,
              req.reportsCriteria,
              req.department,
              req.detailed
            )
          }
          setButtonLoading(false)
        })
        .catch((e) => {
          notification.warning({
            message: 'Unsuccessful',
            description: e?.message || 'This report is not available.',
          })
          setButtonLoading(false)
          console.log(e)
        })
    } catch (e) {
      setButtonLoading(false)
    }
  }

  const handleObjectiveKpiReport = (data) => {
    console.log('RES DATA --->', data?.data)
    setReportModal(true)

    setObjectiveKpiModal(true)
    setObjectiveKpiData(data?.data)
    getFrequency()
  }

  const handleRisksReport = (data, criteria) => {
    const risks = data.data.risks
    console.log(criteria)
    console.log(risks, 'rirsk')
    if (criteria === 'ORGANISATIONAL') {
      const data = {}
      data.criteria = 'ORGANISATIONAL'
      data.data = risks.reduce((acc, item) => {
        console.log(acc[item.risk.department])
        if (acc[item.risk.department]) {
          acc[item.risk.department].push(item)
        } else {
          acc[item.risk.department] = [item]
        }
        return acc
      }, {})
      setRiskReportData(data)
    }

    setReportModal(true)
  }

  const handleCriteria = (e) => {
    console.log(e.target.value)
    let criteriaValue = e.target.value
    setCriteria(criteriaValue)
    if (criteriaValue === 'INDIVIDUAL') {
      setViewDepartments(false)
    } else if (criteriaValue === 'DEPARTMENTAL') {
      filterDepartments(sessionDetails?.user?.department?.id)
      setViewDepartments(true)
    } else if (criteriaValue === 'ORGANISATIONAL') {
      setViewDepartments(false)
    }
  }

  const filterDepartments = (dptId) => {
    let filterDept = userDepartments?.find((data) => {
      return data.id === dptId
    })
    if (filterDept) {
      setChildDepartments(filterDept?.childDepartments)
    }
  }

  const handleDepartment = (e) => {
    let departmentId = e.target.value
    let filterDept = childDepartments.find((item) => item.id == departmentId)
    setDept(filterDept)
  }

  const downloadPDF = async () => {
    console.log(document.getElementById('downloadPdf'))
    let pdfPage = document.getElementById('downloadPdf')
    setTimeout(() => {
      html2PDF(pdfPage, {
        jsPDF: {
          format: 'a4',
        },
        imageType: 'image/jpeg',
        output: `${selectedReportType}.pdf`,
        margin: {
          top: 5,
          right: 5,
          bottom: 5,
          left: 5,
        },
        useCors: true,
      })
    }, 1000)

    /*pdf()*/
  }


  const handleExcelGenerationIndividualReport = (type = null) => {
    const isDepartmental = type === 'departmental'
    const workbook = XLSX.utils.book_new()
    if (objecKpiRepordData) {
      const tables = document.getElementsByClassName("multi-report-table");
      const worksheet = XLSX.utils.json_to_sheet([])
      if (isDepartmental) XLSX.utils.sheet_add_aoa(worksheet, [[`Department : ${tables[0].dataset.departmentname}`]], { origin: -1 })

      for (let i = 0; i < tables.length; i++) {
        XLSX.utils.sheet_add_aoa(worksheet, [[tables[i].dataset.title]], { origin: -1 })
        XLSX.utils.sheet_add_dom(worksheet, tables[i], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, 'report');
      XLSX.writeFile(workbook, "sheetjs.xlsx");
      return;
    }
    else if (selectedReportType === "RISK_REGISTER_DETAILED") {
      const tables = document.querySelectorAll('.double-table-report table');
      console.log(tables)
      const worksheet = XLSX.utils.json_to_sheet([])

      for (let i = 0; i < tables.length; i += 2) {
        const departmentName = tables[i].dataset.departmentname;
        const ownerName = tables[i].dataset.ownername
        const reviewDate = tables[i].dataset.reviewdate
        //RISK DESCRIPTION TABLE
        XLSX.utils.sheet_add_aoa(worksheet, [[`Department : ${departmentName}`, "", `Owner: ${ownerName}`, "", `Review date: ${reviewDate}`]], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })
        XLSX.utils.sheet_add_dom(worksheet, tables[i], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })

        //TREATMENTS TABLE
        XLSX.utils.sheet_add_aoa(worksheet, [['Treatments:']], { origin: -1 })
        XLSX.utils.sheet_add_dom(worksheet, tables[i + 1], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['----------------------------------------------']], { origin: -1 })


      }
      XLSX.utils.book_append_sheet(workbook, worksheet, 'report');
      XLSX.writeFile(workbook, "sheetjs.xlsx");
    }
    else {
      const table = document.getElementById("report-table")
      const worksheet = XLSX.utils.json_to_sheet([])
      if (isDepartmental) XLSX.utils.sheet_add_aoa(worksheet, [[`Department : ${table.dataset.departmentname}`]], { origin: -1 })
      XLSX.utils.sheet_add_dom(worksheet, table, { origin: -1 })
      XLSX.utils.book_append_sheet(workbook, worksheet, 'report');
      XLSX.writeFile(workbook, "sheetjs.xlsx");
    }
  }
  const handleExcelGenerationOrganisationalReport = () => {
    const workbook = XLSX.utils.book_new()
    if (objecKpiRepordData) {
      const tables = document.getElementsByClassName("multi-report-table");
      const worksheet = XLSX.utils.json_to_sheet([])

      for (let i = 0; i < tables.length; i++) {
        XLSX.utils.sheet_add_aoa(worksheet, [[tables[i].dataset.title]], { origin: -1 })
        XLSX.utils.sheet_add_dom(worksheet, tables[i], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })

      }

      XLSX.utils.book_append_sheet(workbook, worksheet, 'report');
      XLSX.writeFile(workbook, "sheetjs.xlsx");
      return;
    }

    else if (selectedReportType === "RISK_REGISTER_DETAILED") {
      const tables = document.querySelectorAll('.double-table-report table');
      console.log(tables)
      const worksheet = XLSX.utils.json_to_sheet([])

      for (let i = 0; i < tables.length; i += 2) {
        const departmentName = tables[i].dataset.departmentname;
        const ownerName = tables[i].dataset.ownername
        const reviewDate = tables[i].dataset.reviewdate
        //RISK DESCRIPTION TABLE
        XLSX.utils.sheet_add_aoa(worksheet, [[`Department : ${departmentName}`, "", `Owner: ${ownerName}`, "", `Review date: ${reviewDate}`]], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })
        XLSX.utils.sheet_add_dom(worksheet, tables[i], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })

        //TREATMENTS TABLE
        XLSX.utils.sheet_add_aoa(worksheet, [['Treatments:']], { origin: -1 })
        XLSX.utils.sheet_add_dom(worksheet, tables[i + 1], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['----------------------------------------------']], { origin: -1 })


      }
      XLSX.utils.book_append_sheet(workbook, worksheet, 'report');
      XLSX.writeFile(workbook, "sheetjs.xlsx");
    }

    else {
      const tables = document.getElementsByClassName("multi-report-table");
      const worksheet = XLSX.utils.json_to_sheet([])
      for (let i = 0; i < tables.length; i++) {
        XLSX.utils.sheet_add_aoa(worksheet, [[`Department: ${tables[i].dataset.departmentname}`]], { origin: -1 })
        XLSX.utils.sheet_add_dom(worksheet, tables[i], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 })
      }
      XLSX.utils.book_append_sheet(workbook, worksheet, 'report');
      XLSX.writeFile(workbook, "sheetjs.xlsx");
      return;
    }
  }

  const downloadExcel = async () => {
    console.log('generating excel', userCriteria)
    if (userCriteria === 'INDIVIDUAL') {
      handleExcelGenerationIndividualReport()
    }
    if (userCriteria === 'DEPARTMENTAL') {
      handleExcelGenerationIndividualReport('departmental')
    }
    if (userCriteria === 'ORGANISATIONAL') {
      handleExcelGenerationOrganisationalReport()
    }
  }

  const resetReportData = () => {
    setSummaryDashBoard({})
    setRiskReportData(null)
    setRiskTreatmentsReportData(null)
    setObjectiveKpiModal(false)
    setRiskRegisterReportData(null)
    setObjectiveKpiData(null)
  }

  const renderRiskReport = () => {
    if (riskReportData.criteria === 'ORGANISATIONAL') {
      return (
        <RiskOrganisationalReport
          data={riskReportData}
          departmentsMap={departmentsMap}
        />
      )
    }
    if (riskReportData.criteria === 'INDIVIDUAL') {
      return (
        <RiskIndividualReport
          data={riskReportData}
          departmentsMap={departmentsMap}
        />
      )
    }
    if (riskReportData.criteria === 'DEPARTMENTAL') {
      return (
        <RiskIndividualReport
          data={riskReportData}
          departmentsMap={departmentsMap}
        />
      )
    }
  }

  const renderRiskTreatmentReport = () => {
    if (riskTreatmentReportData.criteria === 'ORGANISATIONAL') {
      return (
        <RiskTreatmentOrganisationalReport data={riskTreatmentReportData} />
      )
    }
    if (riskTreatmentReportData.criteria === 'INDIVIDUAL') {
      return <RiskTreatmentIndividualReport data={riskTreatmentReportData} />
    }
    if (riskTreatmentReportData.criteria === 'DEPARTMENTAL') {
      return <RiskTreatmentIndividualReport data={riskTreatmentReportData} />
    }
  }

  const renderRiskRegisterReport = () => {
    console.log('RENDER RISK REGISTER')
    if (riskRegisterReportData.criteria === 'ORGANISATIONAL') {
      return <RiskRegisterOrganisationalReport data={riskRegisterReportData} />
    }
    if (riskRegisterReportData.criteria === 'INDIVIDUAL') {
      return <RiskRegisterIndividualReport data={riskRegisterReportData} />
    }
    if (riskRegisterReportData.criteria === 'DEPARTMENTAL') {
      return <RiskRegisterIndividualReport data={riskRegisterReportData} />
    }
  }

  const handleRiskTreatmentReport = async (
    { data: { risks } },
    criteria,
    department = undefined
  ) => {
    console.log(risks, criteria)

    switch (criteria) {
      case 'INDIVIDUAL': {
        const report = {}
        report.criteria = 'INDIVIDUAL'
        const myRisks = risks.filter(
          ({ risk }) => risk.owner === sessionDetails?.user?.id
        )
        report.data = myRisks.reduce((acc, item) => {
          const risk = item.risk
          const treatments = item.riskTreatments
          risk.treatments = treatments
          acc[risk.id] = risk
          return acc
        }, {})

        setRiskTreatmentsReportData(report)
        setReportModal(true)
        break
      }
      case 'DEPARTMENTAL': {
        const report = {}
        report.criteria = 'DEPARTMENTAL'
        const myRisks = risks.filter(
          ({ risk }) => +risk.department === +department
        )
        console.log(myRisks, department)
        if (myRisks.length == 0) {
          notification.warning({
            message: 'No risk treatments under this department',
            description: 'This report is not available.',
          })
          setButtonLoading(false)
          return
        } else {
          report.data = myRisks.reduce((acc, item) => {
            const risk = item.risk
            const treatments = item.riskTreatments
            risk.treatments = treatments
            acc[risk.id] = risk
            return acc
          }, {})

          setRiskTreatmentsReportData(report)
          setReportModal(true)
        }

        break
      }

      case 'ORGANISATIONAL': {
        console.log('running this')
        const report = {}
        report.criteria = 'ORGANISATIONAL'
        const myRisks = risks.reduce((acc, item) => {
          if (acc[item.risk.department]) {
            acc[item.risk.department].push(item)
          } else {
            acc[item.risk.department] = [item]
          }
          return acc
        }, {})
        console.log(myRisks, department)

        const finalObj = {}

        for (const obj in myRisks) {
          finalObj[`${obj}`] = {}
          myRisks[`${obj}`].forEach((item) => {
            if (item.riskTreatments.length !== 0)
              finalObj[obj][item.risk.ref] = {
                ...item.risk,
                treatments: item.riskTreatments,
              }
          })
        }
        console.log(finalObj)
        report.data = finalObj
        setRiskTreatmentsReportData(report)
        setReportModal(true)
        break
      }
    }
  }

  const handleRiskRegisterReport = async (
    { data: { risks } },
    criteria,
    department = undefined,
    detailed = false
  ) => {
    console.log(risks, criteria)

    switch (criteria) {
      case 'INDIVIDUAL': {
        const report = {}
        report.criteria = 'INDIVIDUAL'
        const myRisks = risks.filter(
          ({ risk }) => risk.owner === sessionDetails?.user?.id
        )
        report.data = myRisks.reduce((acc, item) => {
          const risk = item.risk
          const treatments = item.riskTreatments
          risk.treatments = treatments
          risk.scoreObject = {
            gross: {
              score: 0,
              colour: '#fff'
            },
            net: {
              score: 0,
              colour: '#fff'
            },
            target: {
              score: 0,
              colour: '#fff'
            },
          }
          // console.log('--------------------------------RISK---------------------', item)
          item.riskAssessments.forEach((assessment) => {

            const score = (assessment?.probabilityImpact?.riskImpact?.score || 0) * (assessment?.probabilityImpact?.riskProbability?.multiplier || 0);
            // console.log('!!SCORE!!', (assessment?.probabilityImpact?.riskImpact?.score || 0) * (assessment?.probabilityImpact?.riskProbability?.multiplier || 0), score)
            if (
              risk.scoreObject[
              assessment.riskAssessmentType.name.toLowerCase()
              ].score < score
            ) {
              risk.scoreObject[
                assessment.riskAssessmentType.name.toLowerCase()
              ].score = score
              risk.scoreObject[
                assessment.riskAssessmentType.name.toLowerCase()
              ].colour = `#${assessment.probabilityImpact.colour}`
            }
          })
          acc[risk.id] = risk
          return acc
        }, {})

        report.detailed = detailed

        setRiskRegisterReportData(report)
        setReportModal(true)
        break
      }
      case 'DEPARTMENTAL': {
        const report = {}
        report.criteria = 'DEPARTMENTAL'
        const myRisks = risks.filter(
          ({ risk }) => +risk.department === +department
        )
        console.log(myRisks, department)
        if (myRisks.length == 0) {
          notification.warning({
            message: 'No risks under this department',
            description: 'This report is not available.',
          })
          setButtonLoading(false)
          return
        } else {
          report.data = myRisks.reduce((acc, item) => {
            const risk = item.risk
            const treatments = item.riskTreatments
            risk.treatments = treatments
            acc[risk.id] = risk
            risk.scoreObject = {
              gross: {
                score: 0,
                colour: '#fff'
              },
              net: {
                score: 0,
                colour: '#fff'
              },
              target: {
                score: 0,
                colour: '#fff'
              },
            }
            item.riskAssessments.forEach((assessment) => {
              const score = (assessment?.probabilityImpact?.riskImpact?.score || 0) * (assessment?.probabilityImpact?.riskProbability?.multiplier || 0);
              // console.log('!!SCORE!!', (assessment?.probabilityImpact?.riskImpact?.score || 0) * (assessment?.probabilityImpact?.riskProbability?.multiplier || 0), score)

              if (
                risk.scoreObject[
                assessment.riskAssessmentType.name.toLowerCase()
                ].score < score
              ) {
                risk.scoreObject[
                  assessment.riskAssessmentType.name.toLowerCase()
                ].score = score
                risk.scoreObject[
                  assessment.riskAssessmentType.name.toLowerCase()
                ].colour = `#${assessment.probabilityImpact.colour}`
              }
            })
            return acc
          }, {})
          report.detailed = detailed
          setRiskRegisterReportData(report)
          setReportModal(true)
        }
        break
      }

      case 'ORGANISATIONAL': {
        console.log('running this')
        const report = {}
        report.criteria = 'ORGANISATIONAL'
        const myRisks = risks.reduce((acc, item) => {
          item.risk.scoreObject = {
            gross: {
              score: 0,
              colour: '#fff'
            },
            net: {
              score: 0,
              colour: '#fff'
            },
            target: {
              score: 0,
              colour: '#fff'
            },
          }
          item.riskAssessments.forEach((assessment) => {
            const score = (assessment?.probabilityImpact?.riskImpact?.score || 0) * (assessment?.probabilityImpact?.riskProbability?.multiplier || 0);
            if (
              item.risk.scoreObject[
              assessment.riskAssessmentType.name.toLowerCase()
              ].score < score
            ) {
              item.risk.scoreObject[
                assessment.riskAssessmentType.name.toLowerCase()
              ].score = score
              item.risk.scoreObject[
                assessment.riskAssessmentType.name.toLowerCase()
              ].colour = `#${assessment.probabilityImpact.colour}`
            }
          })
          if (acc[item.risk.department]) {
            acc[item.risk.department].push(item)
          } else {
            acc[item.risk.department] = [item]
          }
          return acc
        }, {})
        console.log(myRisks, department)

        const finalObj = {}

        for (const obj in myRisks) {
          finalObj[`${obj}`] = {}
          myRisks[`${obj}`].forEach((item) => {
            if (item.riskTreatments.length !== 0)
              finalObj[obj][item.risk.ref] = {
                ...item.risk,
                treatments: item.riskTreatments,
              }
          })
        }
        console.log(finalObj)
        report.data = finalObj
        report.detailed = detailed
        setRiskRegisterReportData(report)
        setReportModal(true)
        break
      }
    }
  }
  const getFrequency = () => {
    axios
      .get(BASE_URL + `/organisations/${orgId}/frequencyOfReviews`, {
        params: {
          organisation: orgId,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setFrequencyOfReview(res.data)
        }
      })
  }

  const handleModalClose = () => {
    setSummaryReport(false)
    setObjectiveKpiModal(false)
    setReportModal(false)
  }

  const handleReportType = (reportTypeValue) => {
    setSelectedReportType(reportTypeValue)
    if (reportTypeValue === 'DASHBOARD_SUMMARY') {
      setDisplayComment(
        `This report gives the descriptive snapshot of individual, departmental or organizational details in the system which displays number of objectives, KPIs, Risks, Tasks, Treatments etc. `
      )
    } else if (reportTypeValue === 'RISK') {
      setDisplayComment(
        `This report gives a “snapshot” of an overview of set of costs the organization, department and individual is aiming to managed for the year at the time the report is run`
      )
    } else if (reportTypeValue === 'RISK_TREATMENT') {
      setDisplayComment(
        `This report gives a “snapshot” of an overview of set of costs of treatments the organization, department and individual is aiming to managed for the year at the time the report is run`
      )
    } else if (reportTypeValue === 'OBJECTIVE_AND_KPI') {
      setDisplayComment(
        `This reports give a “snapshot” of an overview of set of objectives the organisation aiming to deliver for the year at the time the report is run.`
      )
    } else if (reportTypeValue === 'RISK_REGISTER_SUMMARY') {
      setDisplayComment(
        `This report gives a “snapshot” of a summarized overview of set of risks the organization, department and individual is aiming to managed for the year at the time the report is run`
      )
    } else if (reportTypeValue === 'RISK_REGISTER_DETAILED') {
      setDisplayComment(
        `This report gives a “snapshot” of a detailed overview of set of risks the organization, department and individual is aiming to managed for the year at the time the report is run`
      )
    }
  }

  return (
    <div className="risk-setup-container">
      <Modal
        title="Reporting"
        centered
        visible={reportModal}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        width={1000}
        className="report-modal"
      >
        <div className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={downloadPDF}
            icon={<DownloadOutlined />}
            size="default"
          >
            PDF
          </Button>

          {selectedReportType !== 'DASHBOARD_SUMMARY' &&
            <Button
              type="primary"
              className="ml-2"
              onClick={downloadExcel}
              icon={<DownloadOutlined />}
              size="default"
            >
              Excel
            </Button>}
        </div>
        <div id="downloadPdf">
          {!isEmpty(summaryDashBoard) && summaryReportModal && (
            <ReportingSummary
              userCriteria={userCriteria}
              ristTypeChart={ristTypeChart}
              displayComment={userCriteria}
              summaryDashBoard={summaryDashBoard}
              sessionDetails={sessionDetails}
            />
          )}

          {riskReportData && renderRiskReport()}

          {riskTreatmentReportData && renderRiskTreatmentReport()}
          {riskRegisterReportData && renderRiskRegisterReport()}
          {objectivesKpiModal && (
            <ObjectivesKpiModal
              userCriteria={userCriteria}
              selectedDept={selectedDept}
              reportData={objecKpiRepordData}
              frequencyOfReview={frequencyOfReview}
              userDepartments={userDepartments}
              sessionDetails={sessionDetails}
              usersData={usersData}
            />
          )}
          <h1></h1>
        </div>
      </Modal>

      <div className="reports">
        <Row gutter={16}>
          <Col span={12}>
            <Title level={2}> Generate Report</Title>
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="reportType"
                label="Report Type"
                rules={[{ required: true }]}
              >
                <Select onChange={handleReportType}>
                  <Select.Option value="DASHBOARD_SUMMARY">
                    Summary Dashboard
                  </Select.Option>
                  {/* <Select.Option value="RISK">Risk Registers</Select.Option> */}
                  <Select.Option value="RISK">Financial Risk</Select.Option>
                  <Select.Option value="RISK_TREATMENT">
                    Risk Treatments
                  </Select.Option>
                  <Select.Option value="OBJECTIVE_AND_KPI">
                    Objectives & KPIs Reporting
                  </Select.Option>

                  {/* <Select.Option value="OBJECTIVE">Objective</Select.Option>
                  <Select.Option value="RISK">Risk Registers</Select.Option>
                  <Select.Option value="RISK_TREATMENT">
                    Treatment Reports
                  </Select.Option>
                  <Select.Option value="KPIs">KPIs</Select.Option> */}
                  <Select.Option value="RISK_REGISTER_SUMMARY">
                    Risk Register (summarised)
                  </Select.Option>
                  <Select.Option value="RISK_REGISTER_DETAILED">
                    Risk Register (detailed)
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Generation Criteria"
                name="criteria"
                rules={[{ required: true }]}
              >
                <Radio.Group onChange={handleCriteria}>
                  <Radio.Button value="INDIVIDUAL">
                    Individual Reports
                  </Radio.Button>
                  <Radio.Button value="DEPARTMENTAL">
                    Departmental Reports
                  </Radio.Button>
                  <Radio.Button value="ORGANISATIONAL">
                    Organizational Reports
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              {viewDepartments && (
                <Form.Item
                  label="Departments"
                  name="departments"
                  rules={[{ required: true }]}
                  onChange={handleDepartment}
                >
                  <Radio.Group>
                    {childDepartments &&
                      childDepartments?.map((data, key) => {
                        return (
                          <Radio.Button key={key} value={data.id}>
                            {data.name}
                          </Radio.Button>
                        )
                      })}
                  </Radio.Group>
                </Form.Item>
              )}

              {/* <Form.Item name="dateFilter" label="Filter Date">
                <RangePicker />
              </Form.Item> */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            {/* <span className="reportingBG">
              <img src={reportingBG} alt="reporting"/>
            </span> */}
            <span className="mt-5"></span>
            <p className="mt-5">Description</p>
            <Input.TextArea value={displayComment} disabled rows={7} />
            {/* {viewDepartments && (
              <div className="mt-4">
                {childDepartments && <p>Departments</p>}
                {childDepartments &&
                  childDepartments?.map((data, key) => {
                    return (
                      <span
                        key={key}
                        className="mr-2 mb-2 cursor-pointer"
                        onClick={() => handleDepartment(data)}
                      >
                        <Tag
                          color={
                            selectedDept?.id === data?.id
                              ? 'processing'
                              : 'default'
                          }
                        >
                          {data.name}
                        </Tag>
                      </span>
                    )
                  })}
              </div>
            )} */}
          </Col>
        </Row>
      </div>
      {/*  <div className="risk-tabs-container">
                {tabs.map((item) => (
                    <div
                        key={item.id}
                        style={{
                            background:
                                item.id === selectedTab
                                    ? item.bgColor
                                    : '#c5cae9',
                        }}
                        onClick={() => setSelectedTab(item.id)}
                        className={`tab-label ${
                            item.id === selectedTab ? 'selected-tab' : ''
                        }`}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
            <hr />
            {renderTab()}*/}
    </div>
  )
}
