import React, { useEffect } from 'react'
import GoalCategoriesTable from './OrganisationStrategyTables/GoalCategoriesTable.jsx'
import StrategicGoalsTable from './OrganisationStrategyTables/StrategicGoalsTable.jsx'
import StrategicObjectivesTable from './OrganisationStrategyTables/StrategicObjectivesTable.jsx'
import StrategicIndicatorsTable from './OrganisationStrategyTables/StrategicIndicatorsTable.jsx'
import DocumentsTable from './OrganisationStrategyTables/DocumentsTable.jsx'
import { Result } from 'antd'

export default function OrganisationStrategySetup(props) {
  const tabs = [
    { id: 1, label: 'Goal Categories', bgColor: 'rgba(249, 168, 37)' },
    { id: 2, label: 'Strategic Goals', bgColor: 'rgba(106, 27, 154)' },
    {
      id: 3,
      label: 'Strategic Objectives',
      bgColor: 'rgba(0, 172, 193)',
    },
    {
      id: 4,
      label: 'Strategic Indicators',
      bgColor: 'rgba(57, 73, 171)',
    },
    {
      id: 5,
      label: 'Upload Documents',
      bgColor: 'rgba(0, 191, 165)',
    },
  ]

  const [activeTab, setActiveTab] = React.useState(1)

  const renderTab = () => {
    switch (activeTab) {
      case 1:
        return (
          <>
            <GoalCategoriesTable {...props} orgId={props.match.params.id} />
          </>
        )
        break

      case 2:
        return (
          <>
            <StrategicGoalsTable {...props} orgId={props.match.params.id} />
          </>
        )
        break

      case 3:
        return (
          <>
            <StrategicObjectivesTable
              {...props}
              orgId={props.match.params.id}
              tableHeading={'Strategic Goal'}
              showHeadings={true}
              objectiveType="STRATEGIC"
            />
          </>
        )
        break

      case 4:
        return (
          <>
            <StrategicIndicatorsTable
              {...props}
              orgStratigic={true}
              tableHeading={'Strategic Objective'}
              orgId={props.match.params.id}
              showHeadings={true}
              indicatorType="STRATEGIC"
            />
          </>
        )
        break

      case 5:
        return (
          <>
            <DocumentsTable {...props} orgId={props.match.params.id} />
            {/*<Result status="500" title="Under Development" subTitle="" />*/}
          </>
        )
        break

      default:
        break
    }
  }

  return (
    <div className="risk-setup-container">
      <div className="risk-tabs-container">
        {tabs.map(item => (
          <div
            key={item.id}
            style={{
              background: item.id === activeTab ? item.bgColor : '#c5cae9',
            }}
            className={`risk-tab ${item.id === activeTab ? 'active-tab' : ''}`}
            onClick={() => setActiveTab(item.id)}
          >
            {item.label}
          </div>
        ))}
      </div>

      <hr />

      {renderTab()}
    </div>
  )
}
