import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
export const InfoToolTip = (props) => {
    return (
        <>
            {props.title}&nbsp;
            <OverlayTrigger
                placement="right"
                delay={{ show: 100, hide: 300 }}
                className={'conrad-tooltip-overlay'}
                overlay={
                    <Tooltip id={'conrad-tooltip-' + props.title} {...props}>
                        {props.text}
                    </Tooltip>
                }
            >
                <i style={props.style} className={'fa fa-question-circle conrad-tooltip ' + props.title}
                ></i>
            </OverlayTrigger>
        </>
    )
}
