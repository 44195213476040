import React, { Component, useState, useEffect } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Modal, Button } from 'react-bootstrap'
import ButtonActions from '../../../common/EditDeleteButtons.jsx'
import ButtonsHeader from '../../../common/EditDeleteTH.jsx'
import { InfoToolTip } from '../../../common/InfoTooltip.jsx'
import Auth from '../../../../lib/Auth.js'


export default class RiskStatusTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      showEdit: false,
      showAdd: false,
      loading: true,
      orgId: props.orgId,
      selectedItem: null,
      page: 0,
      rowsPerPage: 5,
      totalPages: 0,
    }
  }

  componentWillReceiveProps(props) {
    this.setState({ orgId: props.orgId })
  }

  componentDidMount() {
    this.fetchData()
  }

  getPageRows = () => {
    var chunks = [],
      i = 0,
      n = this.state.tableData.length
    while (i < n) {
      chunks.push(this.state.tableData.slice(i, (i += this.state.rowsPerPage)))
    }

    return chunks[this.state.page] || this.state.tableData
  }

  getTotalPages = () => {
    var chunks = [],
      i = 0,
      n = this.state.tableData.length
    while (i < n) {
      chunks.push(this.state.tableData.slice(i, (i += this.state.rowsPerPage)))
    }
    return chunks.length
  }

  fetchData() {
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/riskStatuses`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({ tableData: res.data, loading: false }, () =>
            this.setState({ totalPages: this.getTotalPages() })
          )
        }
      })
  }

  handleEdit = item => {
    this.setState({ selectedItem: item }, () =>
      this.setState({ showEdit: true })
    )
  }

  deleteItem = item => {
    this.setState({ loading: true }, () => {
      axios
        .delete(
          BASE_URL +
            `/organisations/${this.state.orgId}/riskStatuses/${item.id}`,
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }

  renderTableData() {
    return this.getPageRows().map((item, index) => {
      return (
        <tr className="table-data" key={index}>
          <td>{item.name}</td>
          <td>{item.description}</td>

          {item.organisation != null ? (
            <ButtonActions
              editAction={() => this.handleEdit(item)}
              deleteAction={() => this.deleteItem(item)}
              dataTarget=""
            />
          ) : (
            <td></td>
          )}
        </tr>
      )
    })
  }

  addItem = values => {
    const data = values
    this.setState({ showAdd: false, loading: true }, () => {
      axios
        .post(
          BASE_URL + `/organisations/${this.state.orgId}/riskStatuses`,
          {
            ...values,
            organisation: this.state.orgId,
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }

  editItem = values => {
    const data = values
    this.setState({ showEdit: false, loading: true }, () => {
      axios
        .put(
          BASE_URL +
            `/organisations/${this.state.orgId}/riskStatuses/${data.id}`,
          {
            ...values,
            organisation: this.state.orgId,
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }
  render() {
    return (
      <div className="table-container px-5 my-5">
        <h4>
          <InfoToolTip
            title={'Risk Status'}
            text={' Risk Status Info text'}
          ></InfoToolTip>
        </h4>
        <p>
          Add New{' '}
          <i
            className="fa fa-plus"
            onClick={() => this.setState({ showAdd: true })}
          />
        </p>

        <table style={{ width: '100%' }}>
          <thead>
            <tr className="bg-table-header">
              <th style={{ width: '15%' }}>Name</th>
              <th>Description</th>
              <ButtonsHeader />
            </tr>
          </thead>
          <tbody>
            {this.state.loading ? (
              <tr className="text-center">
                <td colSpan={5}>Loading...</td>{' '}
              </tr>
            ) : (
              this.renderTableData()
            )}
          </tbody>
        </table>
        <div className="text-right mt-3">
          <span style={{ fontSize: '14px' }}>
            Showing page {this.state.page + 1} of {this.state.totalPages}
          </span>
          <button
            className="previous-page btn btn-primary mx-2"
            disabled={this.state.page == 0}
            onClick={() =>
              this.setState(prevState => ({ page: prevState.page - 1 }))
            }
          >
            Prev
          </button>
          <button
            disabled={this.state.page === this.state.totalPages - 1}
            className="next-page btn btn-primary"
            onClick={() =>
              this.setState(prevState => ({ page: prevState.page + 1 }))
            }
          >
            Next
          </button>
        </div>

        {this.state.showAdd && (
          <AddTableItemModal
            showModal={this.state.showAdd}
            addField={this.addItem}
            dismissModal={() => this.setState({ showAdd: false })}
          />
        )}

        {this.state.showEdit && (
          <EditTableItemModal
            showModal={this.state.showEdit}
            editField={this.editItem}
            dismissModal={() => this.setState({ showEdit: false })}
            item={this.state.selectedItem}
          />
        )}
      </div>
    )
  }
}

const AddTableItemModal = props => {
  const [show, setModal] = useState(props.showModal)
  const submitRef = React.useRef()

  const handleClose = () => {
    props.dismissModal()
  }

  const handleSave = () => {
    submitRef.current.click()
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
    }),
    onSubmit: values => {
      props.addField(values)
    },
  })
  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add New</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-center text-red pr-5">
                {formik.errors.name}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              type="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="text-center text-red pr-5">
                {formik.errors.description}
              </div>
            ) : null}
          </div>

          <button type="submit" ref={submitRef} className="d-none">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const EditTableItemModal = props => {
  const [show, setModal] = useState(props.showModal)
  const submitRef = React.useRef()
  const item = props.item

  const handleClose = () => {
    props.dismissModal()
  }

  const handleSave = () => {
    submitRef.current.click()
  }

  const formik = useFormik({
    initialValues: {
      name: item.name,
      description: item.description,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
    }),
    onSubmit: values => {
      props.editField({ ...values, id: item.id })
    },
  })
  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-center text-red pr-5">
                {formik.errors.name}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              type="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="text-center text-red pr-5">
                {formik.errors.description}
              </div>
            ) : null}
          </div>

          <button type="submit" ref={submitRef} className="d-none">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
