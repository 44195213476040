import LoginContainer from './LoginContainer.jsx'
import DashboardContainer from './DashboardContainer.jsx'
import Auth from '../../lib/Auth.js'
import React, { Component } from 'react'

class SplashScreen extends Component {
  constructor(props) {
    super(props);
    this.state ={ isLoggedIn: false };
  }

    render() {
      if (Auth.isAuthenticated()) {
        return <DashboardContainer {...this.props} />
      }
      else {
        return <LoginContainer {...this.props}/>
      }
  }
}
export default SplashScreen
