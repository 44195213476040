import React, { Component } from 'react'
import axios from 'axios'
import PaymentSuccessForm from '../presentational/PaymentSuccessForm.jsx'

class PaymentSuccessContainer extends Component {
  constructor() {
    super()

    this.state = {
      paymentDetails: {},
    }
  }

  componentDidMount() {
    axios
      .get(BASE_URL + '/payments/' + this.props.match.params.checkoutSessionId)
      .then(res => {
        this.setState({ paymentDetails: res.data })
      })
      .catch(err => this.setState({ errors: err.response }))
  }

  render() {
    if (!this.state.paymentDetails || !this.state.paymentDetails.dateCompleted)
      return null
    const { paymentDetails } = this.state

    return (
      <div>
        <PaymentSuccessForm paymentDetails={paymentDetails} />
      </div>
    )
  }
}

export default PaymentSuccessContainer
