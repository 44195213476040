import React, { useState } from 'react'
import { Modal, Form, Container, Row, Col, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import '../../../style/container/objectives.scss'

export default function MyDeptKPIModal({
  indicators,
  frequency,
  users,
  show,
  submitForm,
  handleClose,
}) {
  const [formData, setData] = useState({
    year: '',
    objectives: [81],
    lead: '',
    startDt: '',
    endDt: '',
    weight: '',
    reviewFrequency: '',
    parent: '',
    description: '',
  })

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const handleChange = e => {
    let data = formData
    data[e.target.name] = e.target.value
    setData(data)
  }

  const handleDateChange = (e, name) => {
    let finalDate = moment(e).format('DD/MM/YY')

    if (name == 'startDate') {
      setStartDate(finalDate)
      setData({ ...formData, startDt: finalDate })
    } else {
      setEndDate(finalDate)
      setData({ ...formData, endDt: finalDate })
    }
  }

  const onIndicatorChange = e => {
    const indicator = indicators.filter(item => item.id == e.target.value)[0]
    let objectives = indicator.objectives.map(item => item.id)
    if (objectives.length == 0) {
      objectives = [81]
    }
    setData({ ...formData, parent: e.target.value, objectives })
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-main objectives-resize"
      >
        <Modal.Header>Add Department KPI</Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Year:</Form.Label>
                    <input
                      type="number"
                      name="year"
                      onChange={e => handleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Indicator (Strategic/Parent):</Form.Label>
                    <Form.Control as="select" onChange={onIndicatorChange}>
                      <option value="-"></option>
                      {indicators.map(item => (
                        <option value={item.id}>{item.description}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Department Indicator:</Form.Label>
                    <input
                      as="text"
                      defaultValue=""
                      onChange={e => handleChange(e)}
                      name="description"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Lead:</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue=""
                      name="lead"
                      onChange={e => handleChange(e)}
                    >
                      <option disabled value="">
                        -
                      </option>
                      {users.map(user => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Start Date:</Form.Label>
                    <DatePicker
                      format="dd/mm/yy"
                      name="startDate"
                      onChange={e => handleDateChange(e, 'startDate')}
                      value={startDate}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>End Date:</Form.Label>
                    <DatePicker
                      format="dd/mm/yyy"
                      name="endDate"
                      onChange={e => handleDateChange(e, 'endDate')}
                      value={endDate}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Review Frequency:</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue=""
                      name="reviewFrequency"
                      onChange={e => handleChange(e)}
                    >
                      <option value="">-</option>
                      {frequency.map(freq => (
                        <option key={freq.id} value={freq.id}>
                          {freq.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={e => submitForm(e, formData)}>Save</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
