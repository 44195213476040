import React, { Component } from 'react'
import axios from 'axios'
import PaymentCancelForm from '../presentational/PaymentCancelForm.jsx'

class PaymentCancelContainer extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <PaymentCancelForm/>
        )
    }
}

export default PaymentCancelContainer