import React, { Component } from 'react'

import { RadialChart, Hint } from 'react-vis'
import axios from 'axios'
import Auth from '../../../lib/Auth.js'

export default class PieChart extends Component {
  state = {
    value: false,
    orgId: 0,
    userId: 0,
    userEmail: 0,
    orgRisks: [],
    usersList: [],
    riskTypes: [],
    noOfRiskTypes: 0,
    chartData: [],
    thetaCount: [],
    testTheta: [5, 6, 7, 8, 9],
    pieData: [{ theta: 0 }],
  }

  componentWillMount() {
    let token = Auth.getToken()
    if (!token) {
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const parts = token.split('.')

    this.setState({
      orgId: JSON.parse(window.atob(base64)).Org,
      userEmail: JSON.parse(atob(parts[1])).Email,
    })
    // this.fetchData()
  }

  componentDidMount() {
    // this.whoAmI()
    this.fetchRisks()

    this.renderPieChartData()
  }

  fetchRisks() {
    //get risks
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/risks`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        const activeRisks = res.data.filter(item=>item.completed && !item.deleted)
        this.setState(
          {
            orgRisks: activeRisks,
          },
          () => this.fetchUsers()
        )
      })

    // this.renderPieChartData()
  }

  fetchUsers = () => {
    //getusers
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/users`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState(
            {
              usersList: res.data,
            },
            () => this.fetchRiskTypes()
          )
        }
      })
  }

  fetchRiskTypes = () => {
    //get risk types
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/riskTypes`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          let dataObject = []
          res.data.forEach(element => {
            dataObject.push({ theta: 0 })
          })

          this.setState(
            {
              riskTypes: res.data,
              noOfRiskTypes: res.data.length,
              dataObject,
            },
            () => this.updateData()
          )
        }
      })
  }

  updateData = () => {
    const myId =
      this?.state?.usersList &&
      this?.state?.usersList.filter(
        user => user.emailAddress == this.state.userEmail
      )[0].id
    const myRisks = this.state.orgRisks.filter(item => item.owner == myId)
    let data = {}
    myRisks.forEach(item => {
      this.state.riskTypes.forEach(riskType => {
        if (riskType.name.toLowerCase() == item.riskType.name.toLowerCase()) {
          if (data[riskType.name] == undefined) {
            data[riskType.name] = 1
          } else {
            data[riskType.name]++
          }
        }
      })
    })

    let pieData = []
    for (let key in data) {
      pieData.push({ theta: data[key], label: key })
    }

    this.setState({ pieData })
  }
  //get risks...done
  //get risks for my user id...done
  //get the risk types....done
  //get the total risk types count....done
  //get number of times a particular risk type exists for my user id....done
  //return those total count in the theta

  //Setting user id
  whoAmI() {
    const emailCheck = this.state.userEmail
    this.state.usersList.map(item => {
      if (item.emailAddress == emailCheck) {
        this.setState({
          userId: item.id,
        })
      } else {
        this.setState({
          userId: 999,
        })
      }
    })
  }

  //Condition check and store counter value
  renderPieChartData() {
    let count = 0
    this.state.riskTypes.forEach(item => {
      this.state.orgRisks.forEach(elem => {
        if (
          elem.owner == this.state.userId &&
          elem.riskType.name == item.name
        ) {
          count = count + 1
        }
      })
    })
    this.setState(prevProps => ({
      thetaCount: [...prevProps.thetaCount, count],
    }))
  }

  render() {
    const { value } = this.state

    return (
      <>
        <p className="risk-types-pie-chart"> Risk Types (% and number)</p>

        <RadialChart
          className={'donut-chart-example'}
          innerRadius={100}
          radius={140}
          getAngle={d => d.theta}
          data={this.state.pieData}
          onValueMouseOver={v => {
            let object = { ...v }
            object.count = object.theta
            object.label = object.label.toUpperCase()
            delete object.theta
            delete object.angle
            delete object.angle0
            delete object.color
            delete object.radius
            delete object.radius0
            delete object.x
            delete object.y
            this.setState({ value: object })
          }}
          onSeriesMouseOut={v => this.setState({ value: false })}
          width={300}
          height={300}
          padAngle={0.04}
        >
          {value !== false && <Hint value={value} />}
        </RadialChart>
      </>
    )
  }
}
