import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap'
import '../../../style/container/risk-setup.scss'
import Sidebar from '../../common/Sidebar.jsx'
import AuditReport from '../../presentational/AuditReport/AuditReport.jsx'

export default class AuditReportContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: `/audit-report`,
    }
  }

  handleClick(value) {
    window.location.href = `${value}`
    this.setState({ activeItem: value })
  }

  render() {
    console.log('AUDIT REPORT ')
    return (
      <div>
        <Sidebar {...this.props} />
        <AuditReport {...this.props} />
      </div>
    )
  }
}
