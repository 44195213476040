import { object } from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Modal, Form, Container, Row, Col, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import '../../../style/container/objectives.scss'

export default function EditMyDeptObjModal({
  frequency,
  users,
  objectives,
  show,
  handleClose,
  submitForm,
  item,
}) {
  const [formData, setData] = useState({
    year: item.year,
    goals: item.goals.map(item => item.id),
    description: item.description,
    lead: item.lead,
    startDt: moment(
      `${item.startDt.day}-${item.startDt.month}-${item.startDt.year}`,
      'DD-MM-YYYY'
    ).format('DD/MM/YY'),
    targetEndDt: moment(
      `${item.targetEndDt.day}-${item.targetEndDt.month}-${item.targetEndDt.year}`,
      'DD-MM-YYYY'
    ).format('DD/MM/YY'),
    weight: item.weight,
    reviewFrequency:
      item.frequencyOfReview == null ? 0 : item.frequencyOfReview,
    parent: item.parentObjective.id,
    id: item.id,
    ref: item.ref,
  })

  const [startDate, setStartDate] = useState(
    moment(
      `${item.startDt.day}-${item.startDt.month}-${item.startDt.year}`,
      'DD-MM-YYYY'
    ).format('DD/MM/YY')
  )
  const [endDate, setEndDate] = useState(
    moment(
      `${item.targetEndDt.day}-${item.targetEndDt.month}-${item.targetEndDt.year}`,
      'DD-MM-YYYY'
    ).format('DD/MM/YY')
  )

  const handleChange = e => {
    let data = { ...formData }
    data[e.target.name] = e.target.value
    setData(data)
  }

  const handleDateChange = (e, name) => {
    let finalDate = moment(e).format('DD/MM/YY')
    if (name == 'startDate') {
      setStartDate(finalDate)
      setData({ ...formData, startDt: finalDate })
    } else {
      setEndDate(finalDate)
      setData({ ...formData, targetEndDt: finalDate })
    }
  }

  const handleObjectiveChange = e => {
    const objective = objectives.filter(item => item.id == e.target.value)[0]
    setData({
      ...formData,
      goals: objective.goals.map(item => item.id),
      parent: objective.id,
    })
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-main objectives-resize"
      >
        <Modal.Header className="modal-header mt-0">
          Edit Department Objective
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Year:<span className="text-danger"> *</span></Form.Label>
                    <input
                      type="number"
                      name="year"
                      onChange={e => handleChange(e)}
                      value={formData.year}
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Objectives (Strategic/Parent):<span className="text-danger"> *</span></Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => handleObjectiveChange(e)}
                      value={formData.parent}
                    >
                      <option value="-"></option>
                      {objectives.map(item => (
                        <option value={item.id}>{item.description}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Department Objective [SMART]:<span className="text-danger"> *</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter objective"
                      name="description"
                      onChange={e => handleChange(e)}
                      value={formData.description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Lead:<span className="text-danger"> *</span></Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue=""
                      name="lead"
                      onChange={e => handleChange(e)}
                      value={formData.lead}
                    >
                      <option disabled value="">
                        -
                      </option>
                      {users.map(user => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Start Date:<span className="text-danger"> *</span></Form.Label>
                    <DatePicker
                      format="dd/mm/yy"
                      name="startDate"
                      onChange={e => handleDateChange(e, 'startDate')}
                      value={startDate}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>End Date:<span className="text-danger"> *</span></Form.Label>
                    <DatePicker
                      format="dd/mm/yy"
                      name="endDate"
                      onChange={e => handleDateChange(e, 'endDate')}
                      value={endDate}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Weight:</Form.Label>
                    <Form.Control
                      type="number"
                      name="weight"
                      placeholder="Enter weight"
                      onChange={e => handleChange(e)}
                      value={formData.weight}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Review Frequency:<span className="text-danger"> *</span></Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue=""
                      name="reviewFrequency"
                      onChange={e => handleChange(e)}
                      value={formData.reviewFrequency}
                    >
                      <option disabled value={0}>
                        -
                      </option>
                      {frequency.map(freq => (
                        <option key={freq.id} value={freq.id}>
                          {freq.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={e => submitForm(e, formData)}>Save</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
