import React, { Component } from 'react'
import axios from 'axios'
import ReactDOM from 'react-dom'
import ReportingStructure from '../../presentational/admin/ReportingStructure.jsx'
import SideMenu, { Item } from 'react-sidemenu'
import Sidebar from '../../common/Sidebar.jsx'
import Swal from 'sweetalert2'
import Auth from '../../../lib/Auth.js'

class ReportingStructureContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: `/admin/reporting-structure/${this.props.match.params.id}`,
      organisationData: {},
      organisationDepartmentData: {},
      organisationTeamData: {},
      organisationJobRoleData: {},
      organisationSystemRoleData: {},
      teams: [],
      postOrganisationDepartments: {
        name: '',
        organisation: this.props.match.params.id,
        head: 0,
        parent: 0,
      },
      postOrganisationTeams: {
        name: '',
        parent: '',
        lead: '',
      },
      postOrganisationJobRoles: {
        id: 0,
        title: '',
        organisation: this.props.match.params.id,
        maxNo: 0,
        department: 0,
        team: 0,
        parentJobRole: 0,
        mandatory: false,
      },
      postOrganisationSystemRoles: {
        id: 0,
        role: '',
        description: '',
      },
      organisationDepartments: [],
      organisationTeams: [],
      organisationJobRoles: [],
      systemRoles: [],
      sysRoles: [],
      organisationSystemRoles: [
        {
          id: 1,
          role: 'Static data 1',
          description: 'Lorem Ipsum Text',
        },
        {
          id: 2,
          role: 'Static data 2',
          description: 'Lorem Ipsum Text',
        },
      ],
      name: '',
      orgChart: {},
      departmentPage: 0,
      totalDepartmentPages: 0,
      rowsPerPage: 10,
      jobRolesPage: 0,
      totalJobRolePages: 0,
      loading: true,
    }

    this.handleChange = this.handleChange.bind(this)
    this.addDepartment = this.addDepartment.bind(this)
    this.addTeam = this.addTeam.bind(this)
    this.addJobRole = this.addJobRole.bind(this)
    this.addSystemRole = this.addSystemRole.bind(this)
    this.updateDepartment = this.updateDepartment.bind(this)
    this.updateTeam = this.updateTeam.bind(this)
    this.updateJobRole = this.updateJobRole.bind(this)
    this.updateSystemRole = this.updateSystemRole.bind(this)
    this.viewDepartment = this.viewDepartment.bind(this)
    this.viewTeam = this.viewTeam.bind(this)
    this.viewJobRole = this.viewJobRole.bind(this)
    this.viewSystemRole = this.viewSystemRole.bind(this)
    this.deleteDepartment = this.deleteDepartment.bind(this)
    this.deleteTeam = this.deleteTeam.bind(this)
    this.deleteJobRole = this.deleteJobRole.bind(this)
    this.deleteSystemRole = this.deleteSystemRole.bind(this)
  }

  handleClick(value) {
    window.location.href = `${value}`
    this.setState({ activeItem: value })
  }

  handleChange({ target: { name, value } }, messageType) {
    this.setState({
      ...this.state,
      [messageType]: { ...this.state[messageType], [name]: value },
    })
    if ((name = 'department')) {
      axios
        .get(BASE_URL + `/organisations/${this.props.match.params.id}/teams`, {
          params: {
            department: value,
            teamLead: 0,
            parentTeam: 0,
          },
        })
        .then(res => {
          this.setState({ teams: res.data })
        })
    }
  }

  componentDidMount() {
    axios
      .all([
        axios.get(BASE_URL + `/organisations/${this.props.match.params.id}`),
        axios.get(
          BASE_URL + `/organisations/${this.props.match.params.id}/jobRoles`
        ),
      ])
      .then(
        axios.spread((organisation, organisationJobRoles) => {
          this.setState({ organisationData: organisation.data }),
            this.setState(
              {
                organisationJobRoles: organisationJobRoles.data,
              },
              () =>
                this.setState({
                  totalJobRolePages: this.getTotalPages('organisationJobRoles'),
                })
            )
        })
      )
      .catch(err => this.setState({ errors: err.response }))
    axios
      .get(BASE_URL + `/orgchart/${this.props.match.params.id}`)
      .then(res => {
        this.setState({ orgChart: res.data })
      })

    axios.get(BASE_URL + `/roles`).then(res => {
      this.setState({ systemRoles: res.data })
    })
    axios
      .get(BASE_URL + `/metrics/${this.props.match.params.id}/users`)
      .then(res => {
        this.setState({ sysRoles: res.data })
      })

    const token = Auth.getToken()
    axios.get(BASE_URL + `/metrics/${this.props.match.params.id}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    this.getUserDepartment()
  }

  getJobRoles = () => {
    axios
      .all([
        axios.get(BASE_URL + `/organisations/${this.props.match.params.id}`),
        axios.get(
          BASE_URL + `/organisations/${this.props.match.params.id}/jobRoles`
        ),
      ])
      .then(
        axios.spread((organisation, organisationJobRoles) => {
          this.setState({ organisationData: organisation.data }),
            this.setState(
              {
                organisationJobRoles: organisationJobRoles.data,
              },
              () =>
                this.setState({
                  totalJobRolePages: this.getTotalPages('organisationJobRoles'),
                })
            )
        })
      )
      .catch(err => this.setState({ errors: err.response }))
  }

  getUserDepartment = () => {
    let departments = []

    axios
      .get(
        BASE_URL + `/organisations/${this.props.match.params.id}/departments`,
        {
          params: {
            parentDepartment: 0,
            departmentHead: 0,
          },
        }
      )
      .then(res => {
        departments = res.data
      })
      .then(() => {
        if (departments) {
          let depIds = []
          departments.forEach(item => {
            if (depIds.includes(item.id)) {
            } else {
              depIds.push(item.id)
            }
          })

          let reduceDepartment = departments.reduce((final, data) => {
            let childData =
              data.childDepartments &&
              data.childDepartments.reduce((childString, dataString) => {
                return [...childString, dataString.name]
              }, [])

            data = {
              ...data,
              childName: childData ? childData.join(' | ') : '',
            }
            return [...final, data]
          }, [])
          // childDepartments
          console.log('reduceDepartment -->', departments, reduceDepartment)
          this.setState(
            {
              organisationDepartments: reduceDepartment,
              loading: false,
            },
            () => {
              this.setState({
                totalDepartmentPages: this.getTotalPages(
                  'organisationDepartments'
                ),
              })
            }
          )
        }

        // const urls = depIds.map(item =>
        //     axios.get(
        //         BASE_URL +
        //             `/organisations/${this.props.match.params.id}/departments/${item}/children`
        //     )
        // )
        // axios.all([...urls]).then(responses => {
        //     let departmentsWithChildren = []
        //     responses.forEach(response => {
        //         if (response?.data?.length !== 0) {
        //             departmentsWithChildren = [
        //                 ...departmentsWithChildren,
        //                 ...response.data,
        //             ]
        //         }
        //     })
        //
        //     departments.forEach(department => {
        //         let dep = department
        //         dep.childName = ''
        //         departmentsWithChildren.forEach(child => {
        //             if (child.parentDepartment.id == department.id) {
        //                 let name = [
        //                     ...dep.childName.split(', '),
        //                     child.name,
        //                 ].join(', ')
        //                 dep.childName = name.replace(',', '|')
        //             }
        //         })
        //         if (dep.childName == undefined) {
        //             dep.childName = ''
        //         }
        //     })
        // })
      })
  }

  getPageRows = (type, typePage) => {
    var chunks = [],
      i = 0,
      n = this.state[type].length
    while (i < n) {
      chunks.push(this.state[type].slice(i, (i += this.state.rowsPerPage)))
    }

    return chunks[typePage] || []
  }

  getTotalPages = type => {
    var chunks = [],
      i = 0,
      n = this.state[type].length
    while (i < n) {
      chunks.push(this.state[type].slice(i, (i += this.state.rowsPerPage)))
    }
    return chunks.length
  }

  // FUNCTIONS TO BE REFACTORED BY PASSING ARGS

  addDepartment(e) {
    e.preventDefault()
    axios
      .post(
        BASE_URL + `/organisations/${this.props.match.params.id}/departments`,
        this.state.postOrganisationDepartments
      )
      .then(() => location.reload())
      .catch(err => {
        this.setState({ errors: err.response.data })
      })
  }

  addTeam(e) {
    e.preventDefault()
    axios
      .post(
        BASE_URL + `/organisations/${this.props.match.params.id}/teams`,
        this.state.postOrganisationTeams
      )
      .then(() => location.reload())
      .catch(err => {
        this.setState({ errors: err.response.data })
      })
  }

  addJobRole(e) {
    e.preventDefault()
    axios
      .post(
        BASE_URL + `/organisations/${this.props.match.params.id}/jobRoles`,
        this.state.postOrganisationJobRoles
      )
      .then(() => {
        this.getJobRoles()
        location.reload()
      })
      .catch(err => {
        this.setState({ errors: err.response.data })
      })
  }

  updateDepartment(e, departmentId) {
    e.preventDefault()
    axios
      .put(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/departments/${departmentId}`,
        this.state.postOrganisationDepartments
      )
      .then(() =>
        this.props.history.push(
          `/admin/reporting-structure/${this.props.match.params.id}`
        )
      )
      .then(() => location.reload())
  }

  updateTeam(e, teamId) {
    e.preventDefault()
    axios
      .put(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/teams/${teamId}`,
        this.state.postOrganisationTeams
      )
      .then(() =>
        this.props.history.push(
          `/admin/reporting-structure/${this.props.match.params.id}`
        )
      )
      .then(() => location.reload())
  }

  updateJobRole(e, jobRolesId) {
    e.preventDefault()
    axios
      .put(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/jobRoles/${jobRolesId}`,
        this.state.postOrganisationJobRoles
      )
      .then(() =>
        this.props.history.push(
          `/admin/reporting-structure/${this.props.match.params.id}`
        )
      )
      .then(() => location.reload())
  }

  deleteDepartment(e, departmentId) {
    e.preventDefault()
    axios
      .delete(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/departments/${departmentId}`
      )
      .then(res => {
        if (res.status === 200) {
          this.props.history.push(
            `/admin/reporting-structure/${this.props.match.params.id}`
          )
        } else {
          return Swal.fire(
            'Failed',
            'Cannot delete department with users or job roles',
            'info'
          )
        }
      })
      .then(() => location.reload())
      .catch(err => {
        return Swal.fire(
          'Failed',
          'Cannot delete department with users or job roles',
          'info'
        )
      })
  }

  deleteTeam(e, teamId) {
    e.preventDefault()
    axios
      .delete(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/teams/${teamId}`
      )
      .then(() =>
        this.props.history.push(
          `/admin/reporting-structure/${this.props.match.params.id}`
        )
      )
      .then(() => location.reload())
  }

  deleteJobRole(e, jobRoleId) {
    e.preventDefault()
    axios
      .delete(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/jobRoles/${jobRoleId}`
      )
      .then(res => {
        if (res.status == 200) {
          this.props.history.push(
            `/admin/reporting-structure/${this.props.match.params.id}`
          )
        } else {
          return Swal.fire(
            'Failed',
            'Cannot delete department with users or job roles',
            'info'
          )
        }
      })
      .then(() => location.reload())
      .catch(err => {
        return Swal.fire(
          'Failed',
          'Unable to delete job role, due to user being assigned, or it being team lead or department head',
          'info'
        )
      })
  }

  viewDepartment(e, departmentId) {
    e.preventDefault()
    axios
      .get(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/departments/${departmentId}`
      )
      .then(res => {
        this.setState({ organisationDepartmentData: res.data })
      })
      .then(res => {
        const {
          state: { organisationDepartmentData, postOrganisationDepartments },
        } = this
        this.setState({
          postOrganisationDepartments: {
            id: organisationDepartmentData.id,
            name: organisationDepartmentData.name,
            organisation: organisationDepartmentData.organisation.id,
            parent: organisationDepartmentData?.parentDepartment?.id,
          },
        })
      })
  }

  viewTeam(e, teamId) {
    e.preventDefault()
    axios
      .get(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/teams/${teamId}`
      )
      .then(res => {
        this.setState({ organisationTeamData: res.data })
      })
      .then(res => {
        const {
          state: { organisationTeamData, postOrganisationTeams },
        } = this
        this.setState({
          postOrganisationTeams: {
            id: organisationTeamData.id,
            name: organisationTeamData.name,
            department: organisationTeamData.department.id,
            parent: organisationTeamData.parentTeam.id,
          },
        })
      })
      .then(res => {
        axios
          .get(
            BASE_URL + `/organisations/${this.props.match.params.id}/teams`,
            {
              params: {
                department: this.state.postOrganisationTeams.department,
                teamLead: 0,
                parentTeam: 0,
              },
            }
          )
          .then(res => {
            this.setState({ teams: res.data })
          })
      })
  }

  viewJobRole(e, jobRoleId) {
    e.preventDefault()
    axios
      .get(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/jobRoles/${jobRoleId}`
      )
      .then(res => {
        this.setState({ organisationJobRoleData: res.data })
      })
      .then(res => {
        const {
          state: { organisationJobRoleData, postOrganisationJobRoles },
        } = this
        this.setState({
          postOrganisationJobRoles: {
            id: organisationJobRoleData.id,
            title: organisationJobRoleData.jobTitle,
            organisation: organisationJobRoleData.organisation.id,
            maxNo: 0,
            department: organisationJobRoleData.department?.id,
            team: 0,
            parentJobRole: organisationJobRoleData.parentJobRole?.id,
            mandatory: organisationJobRoleData.isMandatory,
          },
        })
      })
  }

  addSystemRole() {}

  updateSystemRole() {}

  viewSystemRole() {}

  deleteSystemRole() {}

  render() {
    const {
      organisationDepartments,
      postOrganisationDepartments,
      postOrganisationTeams,
      postOrganisationJobRoles,
      addDepartment,
      updateDepartment,
      organisationDepartmentData,
      updateTeam,
      updateJobRole,
      deleteDepartment,
      deleteTeam,
      deleteJobRole,
      viewDepartment,
      viewTeam,
      viewJobRole,
      organisationData,
      organisationTeams,
      organisationJobRoles,
      organisationJobRoleData,
      orgChart,
      teams,
    } = this.state
    return (
      <div>
        <Sidebar {...this.props} />
        <ReportingStructure
          handleChange={this.handleChange}
          organisationDepartments={this.state.organisationDepartments}
          organisationTeams={organisationTeams}
          organisationJobRoles={organisationJobRoles}
          organisationJobRoleData={organisationJobRoleData}
          postOrganisationDepartments={postOrganisationDepartments}
          postOrganisationTeams={postOrganisationTeams}
          postOrganisationJobRoles={postOrganisationJobRoles}
          organisationData={organisationData}
          addDepartment={this.addDepartment}
          addTeam={this.addTeam}
          teams={teams}
          addJobRole={this.addJobRole}
          updateDepartment={this.updateDepartment}
          updateTeam={this.updateTeam}
          updateJobRole={this.updateJobRole}
          viewDepartment={this.viewDepartment}
          viewTeam={this.viewTeam}
          viewJobRole={this.viewJobRole}
          deleteDepartment={this.deleteDepartment}
          deleteTeam={this.deleteTeam}
          deleteJobRole={this.deleteJobRole}
          organisationDepartmentData={organisationDepartmentData}
          orgChart={orgChart}
          organisationSystemRoles={this.state.organisationSystemRoles}
          organisationSystemRoleData={this.state.organisationSystemRoleData}
          postOrganisationSystemRoles={this.state.postOrganisationSystemRoles}
          addSystemRole={this.addSystemRole}
          updateSystemRole={this.updateSystemRole}
          deleteSystemRole={this.deleteSystemRole}
          viewSystemRole={this.viewSystemRole}
          systemRoles={this.state.systemRoles}
          sysRoles={this.state.sysRoles}
          departmentPage={this.state.departmentPage}
          totalDepartmentPages={this.state.totalDepartmentPages}
          setDepartmentPage={pageNumber =>
            this.setState({ departmentPage: pageNumber })
          }
          jobRolesPage={this.state.jobRolesPage}
          totalJobRolePages={this.state.totalJobRolePages}
          setJobRolePage={pageNumber =>
            this.setState({ jobRolesPage: pageNumber })
          }
          getTotalPages={this.getTotalPages}
          getPageRows={this.getPageRows}
          {...this.props}
        />
      </div>
    )
  }
}

export default ReportingStructureContainer
