import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
const FormInput = ({ title, handleChange, handleSubmit }) => {
  const [termsModal, setTermsModal] = useState(false)

  const handleOk = () => {
    this.setState({
      setTermsModal: true,
    })
  }

  const handleCancel = () => {
    this.setState({
      setTermsModal: false,
    })
  }
  const handleOpen = () => {
    this.setState({
      setTermsModal: false,
    })
  }
  return (
    <React.Fragment>
      {/*<Modal
        title="Basic Modal"
        visible={termsModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>*/}
      <div className="form-input">
        <div className="form-input-container left">
          <div className="form-input-header">
            <img
              src="assets/ConradRP_Logo_Strapline.png"
              alt="ConradRP Logo"
              height="170"
            />
            <h1>{title}</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input-small-container">
              <input
                onChange={handleChange}
                name="firstName"
                className="input-small"
                placeholder="First name"
              ></input>
              <input
                onChange={handleChange}
                name="lastName"
                className="input-small"
                placeholder="Last name"
              ></input>
            </div>
            <input
              onChange={handleChange}
              name="jobTitleFk"
              className="input-text"
              placeholder="Job Title"
            />
            <input
              onChange={handleChange}
              name="emailAddress"
              className="input-text"
              placeholder="Email Address"
            />

            {/*<input className="input-text" placeholder="Phone" />*/}

            <input
              onChange={handleChange}
              name="organisationFk"
              className="input-text"
              placeholder="Name of Organisation"
            />
            <input
              onChange={handleChange}
              name="departmentFk"
              className="input-text"
              placeholder="Department"
            />
            <input
              onChange={handleChange}
              name="teamFk"
              className="input-text"
              placeholder="Team"
            />

            {/*<input className="input-text" placeholder="Address Line 1" />*/}
            {/*<input className="input-text" placeholder="Address Line 2" />*/}
            {/*<input className="input-text" placeholder="Post Code" />*/}
            {/*<input className="input-text" placeholder="City" />*/}
            {/*<input className="input-text" placeholder="County" />*/}

            <div className="checkbox-container">
              <input className="checkbox" type="checkbox" />
              <div className="checkbox-terms-and-conditions">
                I agree to Conrad RP{' '}
                <span className="terms-and-conditions" onClick={handleOpen}>
                  Terms and Conditions
                </span>{' '}
                and{' '}
                <span className="terms-and-conditions" onClick={handleOpen}>
                  Privacy Policy
                </span>
              </div>
            </div>

            <div className="button-container">
              <button className="btn primary">
                <a href="/licences">Submit</a>
              </button>
            </div>
          </form>
        </div>
        <div className="form-input-container right">
		        <div className="text-box-container mt-5">
				        <h1 className="mt-5">Welcome to ConradRP</h1>
				        <div className="text-box" style={{ fontSize: '14px' }}>
						        <p>
								        Connecting people, performance outcomes, and risk exposures!
						        </p>
						        <p>
								        Conrad RP is born out of a passion to support the growing use of
								        risk management skill as a decision making and performance
								        improvement tool. The main aim of this solution is to give none-risk
								        professionals, experts, or users an extremely user friendly,
								        engaging but effective tool to be able to set objectives, align
								        objectives with organizational strategies, work collaboratively with
								        colleagues to identify, describe, assess, evaluate, and manage all
								        threats and opportunities to the achievement of these goals,
								        deliverables or set objectives.
						        </p>
						        <p>
								        The solution is simple enough to set up, its looks and feels great
								        to use with the capacity to collate large data from different
								        sources for multiple uses and analysis. Conrad RP is also designed
								        to be an intelligent source of managing staff tasks, key performance
								        indicators, and risk information.
						        </p>
						        <p className="" style={{ textAlign: 'left' }}>
								        It enables a structured and systematic approach to managing
								        organizational Risk, Performance. Our desire is to inspire you to
								        deliver your goals and success at whatever you set out to do by
								        handling risk better.
						        </p>
						        <p>Enjoy...</p>
						        <p className="m-0">
								        <span>Joachim Adebayo Adenusi</span>
								        (Oga Risk)
						        </p>
						        <p>Co-Founder Conrad RP ERP</p>
				        </div>
				        {/* <button className="btn learn-more">Learn more</button> */}
		        </div>
          <div className="large-triangle-container">
            <div className="large-triangle"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
FormInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
}

export default FormInput
