import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom'
import userContext from './context/userContext'
import axios from 'axios'

import NavBar from './components/common/NavBar.jsx'
import PrivateRoute from './lib/PrivateRoute.jsx'

import AccountCreationContainer from './components/container/AccountCreationContainer.jsx'
import RequestDemoContainer from './components/container/RequestDemoContainer.jsx'
import DashboardContainer from './components/container/DashboardContainer.jsx'

import VerifyEmail from './components/presentational/VerifyEmail.jsx'
import ForgotPasswordContainer from './components/container/ForgotPasswordContainer.jsx'
import ResetPasswordContainer from './components/container/ResetPasswordContainer.jsx'
import Auth from './lib/Auth.js'
import OrgChartContainer from './components/container/OrgChartContainer.jsx'

import SplashScreen from './components/container/SplashScreen.jsx'
import OrganisationSetupContainer from './components/container/admin/OrganisationSetupContainer.jsx'
import ReportingStructureContainer from './components/container/admin/ReportingStructureContainer.jsx'
import UserSetupContainer from './components/container/admin/UserSetupContainer.jsx'
import LicenceContainer from './components/container/LicenceContainer.jsx'
import PaymentSuccessContainer from './components/container/PaymentSuccessContainer.jsx'
import PaymentCancelContainer from './components/container/PaymentCancelContainer.jsx'
import OrganisationStrategySetupContainer from './components/container/admin/OrganisationStrategySetupContainer.jsx'
import RisksContainer from './components/container/RisksContainer.jsx'
import RiskSetupContainer from './components/container/admin/RiskSetupContainer.jsx'
import UserObjectivesContainer from './components/container/UserObjectivesContainer.jsx'
import UserObjContainer from './components/container/UserObjContainer.jsx'
import OrganisationContainer from './components/container/OrganisationContainer.jsx'
import './style/site.scss'
import 'bootstrap'

import '@babel/polyfill'
import Projects from './components/container/Projects.jsx'
import Reports from './components/container/Reports.jsx'
import AuditReport from './components/presentational/AuditReport/AuditReport.jsx'
import AuditReportContainer from './components/container/admin/AuditReportContainer.jsx'

class App extends React.Component {
  state = {
    sessionDetails: {
      name: '',
      department: '',
      profileImage: {},
    },
  }

  setSession = (sessionData) => {
    const { sessionDetails } = this.state

    this.setState({
      sessionDetails: { ...sessionDetails, ...sessionData },
    })
  }

  render() {
    const { sessionDetails } = this.state
    return (
      <div>
        <BrowserRouter>
          <main>
            <userContext.Provider
              value={{
                setSession: this.setSession,
                sessionDetails: sessionDetails,
              }}
            >
              <NavBar />
              <Switch>
                <PrivateRoute
                  authed={Auth.isAuthenticated()}
                  path="/admin/org-strategy/:id"
                  component={OrganisationStrategySetupContainer}
                />
                <PrivateRoute
                  authed={Auth.isAuthenticated()}
                  path="/admin/user-setup/:id"
                  component={UserSetupContainer}
                />
                <PrivateRoute
                  authed={Auth.isAuthenticated()}
                  path="/admin/reporting-structure/:id"
                  component={ReportingStructureContainer}
                />
                <PrivateRoute
                  authed={Auth.isAuthenticated()}
                  path="/admin/account-maintenance/:id"
                  component={OrganisationSetupContainer}
                />
                <PrivateRoute
                  authed={Auth.isAuthenticated()}
                  path="/admin/risk-setup/:id"
                  component={RiskSetupContainer}
                />

                <PrivateRoute
                  authed={Auth.isAuthenticated()}
                  path="/admin/audit-report"
                  component={AuditReportContainer}
                />

                <Route path="/thank-you" component={VerifyEmail} />
                <Route path="/register" component={AccountCreationContainer} />
                <Route path="/licence/:orgId" component={LicenceContainer} />
                <Route
                  path="/paymentSuccess/:checkoutSessionId"
                  component={PaymentSuccessContainer}
                />
                <Route
                  path="/paymentCancel"
                  component={PaymentCancelContainer}
                />
                <Route
                  path="/forgot-password"
                  component={ForgotPasswordContainer}
                />
                <Route
                  path="/reset-password/:token"
                  component={ResetPasswordContainer}
                />
                <Route path="/orgchart" component={OrgChartContainer} />
                <Route exact path="/" component={SplashScreen} />
                <Route path="/risks/" component={RisksContainer} />
                <Route path="/objectives" component={UserObjectivesContainer} />
                <Route path="/myobjectives" component={UserObjContainer} />
                <Route path="/organisation" component={OrganisationContainer} />

                <Route path="/reports" component={Reports} />

                <Route path="/programme-and-projects" component={Projects} />
              </Switch>
            </userContext.Provider>
          </main>
        </BrowserRouter>
      </div>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
