import React, { useContext } from 'react'
import { uncertaintyType } from '../../../../common/uncertaintyType'
import userContext from '../../../../context/userContext'
import * as moment from 'moment'
export default function RiskReportTable({ risks }) {

    const { sessionDetails } = useContext(userContext)
    const currency = sessionDetails?.user?.organisation?.currencyCode


    const departmentName = Object.values(risks)[0]?.departmentName

    return (
        <>
            {departmentName && <p><b>Department</b>: {departmentName}</p>}
            <div className="mb-3">
                {/* <p className="mb-1">Department {risks[0].risk.department}</p> */}
                <table className="risk-register organisational report multi-report-table" id="report-table" data-departmentname={departmentName}>
                    <thead>
                        <tr>
                            <th>Risk Description</th>
                            <th>Risk Objectives</th>
                            <th>Risk Category</th>

                            <th>Risk Classification</th>
                            <th>Risk Owner</th>

                            <th>Gross Risk Score</th>
                            <th>Net Risk Score</th>
                            <th>Target Risk Score</th>
                            <th>Review Date</th>
                            <th>No. of Treatments</th>
                            <th>Days Overdue</th>
                            {/* <th>Comments</th> */}

                        </tr>

                    </thead>
                    <tbody>

                        {Object.values(risks).map((risk) => {
                            const dueDate = moment(`${risk.reviewDate?.day}/${risk.reviewDate?.month}/${risk.reviewDate?.year}`, 'DD/MM/YYYY')
                            return (
                                <tr>
                                    <td>Ref:&nbsp;{risk.ref}<br />
                                        {/* {risk.eventCauses} */}
                                        {/* / */}
                                        {risk.eventDescription}/
                                        {/* {risk.eventConsequences} */}
                                    </td>
                                    <td >{risk?.objectives && risk?.objectives.length !== 0 && (risk.objectives.length == 1 ? <p>{risk.objectives[0].description}</p> : <p>{risk.objectives[0].description} <strong><em> + {risk.objectives.length - 1} more</em></strong></p>)}</td>
                                    <td>{risk.riskCategory?.name}</td>
                                    <td>
                                        {uncertaintyType(risk.impactType.name)}
                                        {/* / */}
                                        {/* {risk.riskCategory?.name}/
                                        {risk.riskType.name} */}
                                    </td>
                                    <td>{risk.ownerName}</td>

                                    <td style={{ backgroundColor: risk?.scoreObject?.gross?.colour }}>
                                        {risk?.scoreObject?.gross?.score}

                                    </td>
                                    <td style={{ backgroundColor: risk?.scoreObject?.net?.colour }}>
                                        {risk?.scoreObject?.net?.score}

                                    </td>
                                    <td style={{ backgroundColor: risk?.scoreObject?.target?.colour }}>
                                        {risk?.scoreObject?.target?.score}
                                    </td>

                                    <td>{`${risk.reviewDate?.day}/${risk.reviewDate?.month}/${risk.reviewDate?.year}`}</td>

                                    <td>{risk.treatments.length}</td>

                                    <td>{moment().diff(dueDate, 'days').toString()}</td>



                                    {/* <td>{risk.comments}</td> */}



                                </tr>)
                        })}

                    </tbody>
                </table>

            </div>
        </>
    )
}
