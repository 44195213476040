import React, { useState, useEffect } from 'react'
import { Table, Button } from 'react-bootstrap'
import axios from 'axios'
import Swal from 'sweetalert2'
import Auth from '../../lib/Auth'
import * as moment from 'moment'
import { Popconfirm } from 'antd'

const EditTreatmentPlanTable = ({
  orgID,
  riskID,
  treatmentTableData,
  editTreatmentHandler,
  updateTreatments,
  actionOwners,
}) => {
  const [treatmentData, setTreatmentData] = useState([])
  const [users, setUsers] = useState([])

  const deleteRiskTreatment = (id) => {
    axios
      .delete(BASE_URL + `/organisations/${orgID}/riskTreatments/${id}`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        Swal.fire('', 'Treatment deleted', 'success').then(() =>
          updateTreatments()
        )
      })
  }

  const editRiskTreatment = (treatmentItem) => {
    editTreatmentHandler(treatmentItem)
  }

  const renderTreatmentTableData = () => {
    return treatmentTableData.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.ref}</td>
          <td>{item.description}</td>
          <td>{item.estimatedCost}</td>
          <td>
            {item.dueDate
              ? moment(item.dueDate, 'YYYY-MM-DD').format('DD/MM/YY')
              : '-'}
          </td>
          <td>{item.actionPriority?.name}</td>
          <td>{item?.riskStatus?.name}</td>
          <td>{item.completed}%</td>
          <td>
            {/* {
                            users.filter((user) => user?.id === item?.owner)[0]
                                ?.name
                        } */}
            {actionOwners?.filter((user) => user.id == item.owner)[0]?.name}
          </td>
          <td>
            <span className="buttons-container">
              <Button onClick={() => editRiskTreatment(item)}>
                <i className="fa fa-pencil"></i>
              </Button>
              <Popconfirm
                Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => deleteRiskTreatment(item.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button>
                  <i className="fa fa-trash"></i>
                </Button>
              </Popconfirm>
            </span>
          </td>
        </tr>
      )
    })
  }
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th style={{ width: '8%' }}>Ref</th>
            <th style={{ width: '20%' }}>Description</th>
            <th style={{ width: '10%' }}>Estimated Cost</th>
            <th style={{ width: '8%' }}>Due Date</th>
            <th style={{ width: '10%' }}>Action Priority</th>
            <th style={{ width: '10%' }}>Action Status</th>
            <th style={{ width: '9%' }}>% Completed</th>
            <th style={{ width: '15%' }}>Owner</th>
            <th style={{ width: '11%' }}></th>
          </tr>
        </thead>
        <tbody>{renderTreatmentTableData()}</tbody>
      </Table>
    </div>
  )
}

export default EditTreatmentPlanTable
