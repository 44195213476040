import React, { useState } from 'react'

import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeries,
  LineSeries,
} from 'react-vis'
import Axios from 'axios'
import Auth from '../../../lib/Auth.js'
import userContext from '../../../context/userContext'

export default class LineChart extends React.Component {
  static contextType = userContext
  constructor(props) {
    super(props)
    this.state = {
      myId: props.myId,
      myObjectives: 0,
      depObjectives: 0,
      allObjectives: 0,
    }
    this.orgId = Auth.getOrg()
  }

  componentWillReceiveProps(props) {
    this.setState({ myId: props.myId }, () => this.getData())
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    const { sessionDetails } = this.context

    let pDept = sessionDetails?.org?.parentDepartment?.department?.id
    console.log('PDEPT --->>', pDept)
    Axios.get(BASE_URL + `/objectives`, {
      params: {
        organisation: this.orgId,
        objectiveType: 'STRATEGIC',
      },
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    }).then(res => {
      let allObjectives = res.data.length

      this.setState({
        allObjectives,
      })
    })
    Axios.get(BASE_URL + `/objectives`, {
      params: {
        organisation: this.orgId,
        objectiveType: 'PERSONAL',
        lead: this.state.myId.id,
      },
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    }).then(res => {
      let myObjectives = res.data.filter(
        item => item.lead === this.state.myId.id
      ).length
      this.setState({
        myObjectives,
      })
    })
    Axios.get(BASE_URL + `/objectives`, {
      params: {
        organisation: this.orgId,
        objectiveType: 'DEPARTMENT',
        department: pDept,
      },
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    }).then(res => {
      let depObjectives = res.data.length
      this.setState({
        depObjectives,
      })
    })
  }

  render() {
    return (
      <>
        <p className="objectives-chart">
          Number of Objectives [Personal, Dept & Org]
        </p>
        <XYPlot xType="ordinal" width={500} height={300} xDistance={100}>
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis />
          <YAxis />
          <VerticalBarSeries
            data={[
              { x: 'Personal', y: this.state.myObjectives },
              { x: 'Departmental', y: this.state.depObjectives },
              {
                x: 'Organisational',
                y: this.state.allObjectives,
              },
            ]}
            barWidth={0.4}
          />
        </XYPlot>
      </>
    )
  }
}
