import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { notification, Modal } from 'antd'

import AddTableItemModal from './AddTableItemModal.jsx'
import EditTableItemModal from './EditTableItemModal.jsx'
import ButtonActions from '../../../common/EditDeleteButtons.jsx'
import ButtonsHeader from '../../../common/EditDeleteTH.jsx'
import { InfoToolTip } from '../../../common/InfoTooltip.jsx'
import Auth from '../../../../lib/Auth.js'

export default function RiskTableDoubleColumn(props) {
  const { getRiskCategory, riskCategory } = props
  const [headings, setHeadings] = useState([...props.headings])
  const [endpoint, setEndpoint] = useState(props.apiEndpoint)
  const [title, setTitle] = useState(props.title)
  const [data, setData] = useState([])
  const [showModal, setModal] = useState(false)
  const [editModal, setEdit] = useState(false)
  const [selectedItem, setItem] = useState({})
  const [tabGroup, setTab] = useState(0)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRows] = useState(5)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [endpoint, JSON.stringify(headings), title])

  useEffect(() => {
    setHeadings(props.headings)
    setEndpoint(props.apiEndpoint)
    setTitle(props.title)
  }, [JSON.stringify(props)])

  const fetchData = () => {
    axios
      .get(BASE_URL + `/organisations/${props.orgId}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          const data = res.data
          if (endpoint === 'riskCategories') {
            getRiskCategory(data)
          }
          setData(data)
          setLoading(false)
        }
      })
  }
  /* const riskCategoryType = () => {
    axios
      .get(BASE_URL + `/organisations/${props.orgId}/riskCategories`)
      .then(res => {
        if (res.status === 200) {
          const data = res.data
          setData(data)
          setLoading(false)
        }
      })
  }*/

  const deleteItem = (e, id) => {
    setLoading(true)
    axios
      .delete(BASE_URL + `/organisations/${props.orgId}/${endpoint}/${id}`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          fetchData()
        } else {
          setLoading(false)
          notification.warning({
            message: 'Unsuccessfully',
            description: '',
          })
        }
      })
      .catch(e => {
        console.log('e.response --->', e.response)
        if (
          e.response &&
          e.response?.data &&
          e.response?.data?.statusCode == '400'
        ) {
          Modal.confirm({
            title: 'Confirm',
            content:
              'The risk type is linked with one or more risks. Are you sure you want to delete it ?',
            okText: 'Delete',
            onOk: () => confirmDelete(id),

            cancelText: 'Cancel',
          })
        }
        setLoading(false)
        /* notification.warning({
          message: 'Unsuccessfully',
          description: '',
        })*/
      })
  }

  const confirmDelete = id => {
    setLoading(true)
    axios
      .delete(
        BASE_URL + `/organisations/${props.orgId}/${endpoint}/${id}?force=true`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then(res => {
        if (res.status === 200) {
          fetchData()
        } else {
          setLoading(false)
          notification.warning({
            message: 'Unsuccessfully',
            description: '',
          })
        }
      })
      .catch(e => {
        setLoading(false)
      })
  }

  const addItem = item => {
    setLoading(true)
    let category_item = { ...item }
    console.log('ITEM SAVE -->', item)
    category_item.code = ''
    axios
      .post(
        BASE_URL + `/organisations/${props.orgId}/${endpoint}`,
        {
          ...category_item,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then(res => {
        if (res.status === 200) {
          fetchData()
          setModal(false)
        } else {
          setLoading(false)
        }
      })
      .catch(e => {
        setLoading(false)
        notification.warning({
          message: 'Unsuccessfully',
          description: '',
        })
      })
  }

  const handleEdit = (e, field) => {
    setItem(field)
    setEdit(true)
  }

  const editItem = item => {
    setEdit(false)
    setLoading(true)
    axios
      .put(
        BASE_URL + `/organisations/${props.orgId}/${endpoint}/${item.id}`,
        {
          code: item.code,
          name: item.name,
          description: item.description,
          riskCategory: item.riskCategory,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then(res => {
        if (res.status === 200) {
          fetchData()
        } else {
          setLoading(false)
          notification.warning({
            message: 'Unsuccessfully',
            description: '',
          })
        }
      })
      .catch(e => {
        setLoading(false)
        notification.warning({
          message: 'Unsuccessfully',
          description: '',
        })
      })
  }

  const renderTableHeading = () => {
    // return headings.map((item, index) => {
    return (
      <tr className="bg-table-header">
        <th style={{ width: '15%' }}>Name</th>
        {endpoint === 'riskTypes' && <th>Risk Category</th>}
        <th>Description</th>

        <ButtonsHeader />
      </tr>
    )
    // })
  }

  const getPageRows = () => {
    var chunks = [],
      i = 0,
      n = data.length
    while (i < n) {
      chunks.push(data.slice(i, (i += rowsPerPage)))
    }
    console.log('NEW ROW --->', chunks?.[page], data)
    return chunks[page] || data
  }

  const getTotalPages = () => {
    var chunks = [],
      i = 0,
      n = data.length
    while (i < n) {
      chunks.push(data.slice(i, (i += rowsPerPage)))
    }
    return chunks.length
  }

  useEffect(() => {
    setTotalPages(getTotalPages())
  }, [data])

  const renderTableData = () => {
    return getPageRows().map((item, index) => {
      let data = []
      /*for (const [key, value] of Object.entries(item)) {
        console.log('NEW @ ROW --->', value, key, item)
        if (headings.includes(key.charAt(0).toUpperCase() + key.slice(1)))
          data.push(<td key={key}>{value}</td>)
      }*/

      return (
        <tr className="table-data" key={index}>
          {/*{data}*/}
          <td>{item.name}</td>
          {endpoint === 'riskTypes' && <td>{item.riskCategory}</td>}

          <td>{item.description}</td>

          <ButtonActions
            itemId={item.id}
            editAction={e => handleEdit(e, item)}
            deleteAction={e => deleteItem(e, item.id)}
            dataTarget=""
          />

          {/* <span className="buttons-container">
                            <button onClick={() => handleEdit(item)}>
                                <i className="fa fa-pencil"></i>
                            </button>
                            <button onClick={() => deleteItem(item.id)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </span>
                    </td> */}
        </tr>
      )
    })
  }

  return (
    <div className="table-container px-5 my-5">
      <h4>
        <InfoToolTip title={title} text={title + ' Info Text'}></InfoToolTip>
      </h4>
      <p>
        Add New <i className="fa fa-plus" onClick={() => setModal(true)} />
      </p>

      <table style={{ width: '100%' }}>
        <thead>{renderTableHeading()}</thead>
        <tbody>
          {loading ? (
            <tr className="text-center">
              <td colSpan={5}>Loading...</td>{' '}
            </tr>
          ) : (
            renderTableData()
          )}
        </tbody>
      </table>

      <div className="text-right mt-3">
        <span style={{ fontSize: '14px' }}>
          Showing page {page + 1} of {totalPages}
        </span>
        <button
          className="previous-page btn btn-primary mx-2"
          disabled={page == 0}
          onClick={() => setPage(page => page - 1)}
        >
          Prev
        </button>
        <button
          disabled={page === totalPages - 1}
          className="next-page btn btn-primary"
          onClick={() => setPage(page => page + 1)}
        >
          Next
        </button>
      </div>

      {showModal && (
        <AddTableItemModal
          showModal={showModal}
          addField={addItem}
          dismissModal={() => setModal(false)}
          tabGroup={tabGroup}
          heading={headings}
          riskCategory={riskCategory}
        />
      )}

      {editModal && (
        <EditTableItemModal
          showModal={editModal}
          editField={editItem}
          dismissModal={() => setEdit(false)}
          tabGroup={tabGroup}
          heading={headings}
          item={selectedItem}
          riskCategory={riskCategory}
        />
      )}
    </div>
  )
}
