import React from 'react'
import { Modal } from 'antd'

function TermsConditionModal(props) {
  const { visible, handleOk, handleCancel } = props
  return (
    <React.Fragment>
      <Modal
        title="Conrad RP - Terms of Use"
        style={{ top: 20 }}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <div className="termsCondition">
          <p className="font-weight-bold">Conrad RP - Terms of Use</p>
          <p>Introduction</p>
          <p>
            Thank you for using Conrad RP. Conrad RP is a service provided by
            company’s name…, later referred to as "Conrad RP", "company", "we",
            "our" and similar.
          </p>
          <p>
            These Terms of Use ("TOU") contain the terms and conditions under
            which Conrad RP and its affiliates provide their services to you and
            describe how the services may be accessed and used.
          </p>
          <p>
            If you will be using the Services on behalf of an organization, you
            agree to these Terms on behalf of that organization and you
            represent that you have the authority to do so. In such case, "you"
            and "your" will refer to that organization.
          </p>
          <p>
            These Terms are prepared and written in English. To the extent that
            any translated version conflicts with the English version, the
            English version controls, except where prohibited by applicable law.
            The original Terms written in English can be found here: LINK TO
            ENGLISH
          </p>
          <p className="font-weight-bold">Terms</p>
          <p>Fees and Payments</p>
          <p>Fees for Services</p>
          <p>
            You agree to pay to Conrad RP any fees for each service you purchase
            or use, in accordance with the pricing and payment terms presented
            to you for that service. Where applicable, you will be billed using
            the billing method you select through the payment process in your
            account. Subscriptions
          </p>
          <p>
            Some of our Services are billed on a subscription basis (we call
            these "Subscriptions"). This means that you will be billed in
            advance on a recurring, periodic basis (each period is called a
            "billing cycle"). Billing cycles are typically monthly or annual,
            depending on what subscription plan you select when purchasing a
            Subscription. Your Subscription will automatically renew at the end
            of each billing cycle unless you cancel auto-renewal within your
            account, or by contacting our customer support team. You may cancel
            auto-renewal on your Subscription at any time, in which case your
            Subscription will continue until the end of that billing cycle
            before terminating. You may cancel auto-renewal on your Subscription
            immediately after the Subscription starts if you do not want it to
            renew.
          </p>
          <p>Taxes</p>
          <p>
            Unless otherwise stated, you are responsible for any taxes or duties
            associated with the sale of the Services, including any related
            penalties or interest (collectively "Taxes"). You will pay Conrad RP
            for the Services without any reduction for Taxes. If Conrad RP is
            obliged to collect or pay Taxes, the Taxes will be invoiced to you.
          </p>
          <p>Price Changes</p>
          <p>
            Conrad RP may change the fees charged for the Services at any time,
            provided that, for Services billed on a subscription basis, the
            change will become effective only at the end of the then-current
            billing cycle of your Subscription. Conrad RP will provide you with
            reasonable prior written notice of any change in fees to give you an
            opportunity to cancel your Subscription before the change becomes
            effective. Right of Withdrawal.
          </p>
          <p>
            If you are a consumer, starting from the date your paid Subscription
            first starts, you have 14 days to exercise your right of withdrawal
            without cause, provided that you have not benefited from or started
            to use the Services before the end of that 14-day period. The
            consumer must notify our customer support team by email within the
            specified period. It is up to the consumer to prove that their
            notification was sent and received within deadline.
          </p>
          <p>
            You are considered to have benefited from or started to use the
            Services if, starting from the moment of your paid upgrade, at least
            one entry has made into the system or if you have used your newly
            acquired privileges to process or export any information on the
            system. Any external costs that Conrad RP had to bear during the
            initial payment (e.g., credit card fees) will be deducted from the
            refund amount. Additionally, the cost of reimbursement as well as a
            proportionate handling fee may be charged to the consumer.
          </p>
          <p>Privacy</p>
          <p>
            When using our Services, you may submit content such as your
            personal data, personal data of others, as well as many different
            types of content. We understand that by using our Services, you are
            trusting us to treat your content appropriately. The protection and
            confidentiality of your data has always been very important to us.
            We have and will never sell your data to third parties.
          </p>
          <p>
            Please review our full Privacy Policy to learn more about how we
            protect your data.
          </p>
          <p>Confidentiality</p>
          <p>
            We will treat your Content as confidential information and only use
            and disclose it in accordance with these Terms. However, we may
            disclose your Content when required by law or legal process, but
            only after Conrad RP, if permitted by law, uses commercially
            reasonable efforts to notify you to give you the opportunity to
            challenge the requirement to disclose.
          </p>
          <p>Security</p>
          <p>
            We will store and process your Content in a manner consistent with
            industry security standards. Company has implemented appropriate
            technical, organizational, and administrative systems, policies, and
            procedures designed to help ensure the security, integrity, and
            confidentiality of your Content and to mitigate the risk of
            unauthorized access to or use of your Content. At reasonable
            intervals, Conrad RP tests and evaluates the effectiveness of these
            technical and organizational measures for ensuring the security of
            the processing.
          </p>
          <p>Your Content</p>
          <p>You Retain Ownership of Your Content</p>
          <p>
            You retain ownership of all of your intellectual property rights in
            your Content. Conrad RP does not claim ownership over any of your
            Content. These Terms do not grant us any licenses or rights to your
            Content except for the limited rights needed for us to provide the
            Services, and as otherwise described in these Terms.
          </p>
          <p>Reference Customers</p>
          <p>
            We may identify you (by name and logo) as a customer on our website
            and on other
          </p>
          <p>promotional materials.</p>
          <p>User Content</p>
          <p>
            The Services display content provided by others that is not owned by
            Conrad RP. Such content is the sole responsibility of the entity
            that makes it available. Correspondingly, you are responsible for
            your own Content and you must ensure that you have all the rights
            and permissions needed to use that Content in connection with the
            Services. Conrad RP is not responsible for any actions you take with
            respect to your Content, including sharing it publicly. Please do
            not use content from the Services unless you have first obtained the
            permission of its owner or are otherwise authorized by law to do so.
          </p>
          <p>Content Review</p>
          <p>
            You acknowledge that, in order to ensure compliance with legal
            obligations, Conrad RP may be required to review certain content
            submitted to the Services to determine whether it is illegal or
            whether it violates these Terms (such as when unlawful content is
            reported to us). We may also modify, prevent access to, delete, or
            refuse to display content that we believe violates the law or these
            Terms. However, Conrad RP otherwise has no obligation to monitor or
            review any content submitted to the Services.
          </p>
          <p>Account Management</p>
          <p>Keep Your Password Secure</p>
          <p>
            You are responsible for safeguarding your password and any other
            credentials used to access your personal Conrad RP account. You are
            responsible for any activity occurring in your account, whether you
            authorized that activity or not. If you become aware of any
            unauthorized access to your account, you should notify us
            immediately. Accounts may not be shared and may only be used by one
            individual per
          </p>
          account.
          <p>Keep Your Details Accurate</p>
          <p>
            Conrad RP occasionally sends notices to the email address registered
            with your account. You must keep your email address and, where
            applicable, your contact details and payment details associated with
            your account current and accurate. Accounts are controlled by the
            entity whose email address is registered with the account.
          </p>
          <p>Remember to Backup</p>
          <p>
            {' '}
            You are responsible for maintaining, protecting, and making backups
            of your Content. To the extent permitted by applicable law, Conrad
            RP will not be liable for any failure to store, for loss of or
            corruption of your Content.
          </p>
          <p>User Requirements</p>
          <p>Legal Status</p>
          <p>
            If you are an individual, you may only use the Services if you have
            the power to form a contract with Conrad RP. If you do not have the
            power to form a contract, you may not use the Services. If you are
            not an individual, you warrant that you are validly formed and
            existing under the laws of your jurisdiction of formation, that you
            have full power and authority to enter into these Terms, and that
            you have duly authorized your agent to bind you to these Terms.
          </p>
          <p>Legal Compliance</p>
          <p>
            You represent and warrant that you will comply with all laws and
            regulations applicable to your use of the Services.
          </p>
          <p>Your Responsibilities</p>
          <p>
            You are responsible for your conduct, Content, and communications
            with others while using the Services. You must comply with the
            following requirements when using the Services:{' '}
          </p>
          <ol type="a">
            <li>
              {' '}
              You may not purchase, use, or access the Services for the purpose
              of building a competitive product or service or for any other
              competitive purposes.
            </li>
            <li>
              You may not misuse our Services by interfering with their normal
              operation or attempting to access them using a method other than
              through the interfaces and instructions that we provide.
            </li>
            <li>
              You may not circumvent or attempt to circumvent any limitations
              that Conrad RP imposes on your account (such as by opening up a
              new account that we have closed for a Terms violation).
            </li>
            <li>
              Unless authorized by Conrad RP in writing, you may not probe,
              scan, or test the vulnerability of any Conrad RP system or
              network.
            </li>
            <li>
              Unless authorized by Conrad RP in writing, you may not use any
              manual or automated system or software to extract or scrape data
              from the websites or other interfaces through which we make our
              Services available.
            </li>
            <li>
              Unless permitted by applicable law, you may not deny others access
              to, or reverse engineer, the Services, or attempt to do so.
            </li>
            <li>
              You may not transmit any viruses, malware, or other types of
              malicious software, or links to such software, through the
              Services.
            </li>
            <li>
              You may not engage in abusive or excessive usage of the Services,
              which is usage significantly in excess of average usage patterns
              that adversely affects the speed, responsiveness, stability,
              availability, or functionality of the Services for other users.
              Conrad RP will endeavour to notify you of any abusive or excessive
              usage to provide you with an opportunity to reduce such usage to a
              level acceptable to Conrad RP.
            </li>
            <li>
              You may not use the Services to infringe the intellectual property
              rights of others, or to commit an unlawful activity.
            </li>
            <li>
              Unless authorized by Conrad RP in writing, you may not resell or
              lease the Services.
            </li>
            <li>
              You may not register accounts by "bots" or other automated
              methods.
            </li>
          </ol>
          <p className="font-weight-bold">
            Suspension and Termination of Services
          </p>
          <p>By You</p>
          <p>
            You can terminate your account at any time from within your Conrad
            RP account. Such termination will result in the deactivation or
            disablement of your account and access to it, and the deletion of
            content you collected through use of the Services. Terminations are
            confirmed immediately, and you will not be charged again for any
            running subscription unless you purchase a new one. If you terminate
            a Subscription in the middle of a billing cycle, you will not
            receive a refund for any period of time you did not use in that
            billing cycle unless you are terminating these Terms for any of the
            following reasons: (a) we have materially breached these Terms and
            failed to cure that breach within 30 days after you have so notified
            us in writing; or (b) a refund is required by law.
          </p>
          <p>By Conrad RP</p>
          <p>
            Conrad RP may limit, suspend, or terminate the Services to you: (a)
            if you fail to comply with these Terms, (b) if you use the Services
            in a way that causes legal liability to us or disrupts others’ use
            of the Services; (c) if we are investigating suspected misconduct by
            you; or (d) if we receive an illegitimate chargeback request from a
            payment provider for Services we have already delivered to you.
            Also, if we limit, suspend, or terminate the Services you receive,
            we will endeavour to give you advance notice and an opportunity to
            export a copy of your Content from that Service. However, there may
            be time sensitive situations where Conrad RP may decide that we need
            to take immediate action without notice. Conrad RP will use
            commercially reasonable efforts to narrow the scope and duration of
            any limitation or suspension under this Section as is needed to
            resolve the issue that prompted such action. Conrad RP has no
            obligation to retain your Content upon termination of the applicable
            Service.
          </p>
          <p>Further Measures</p>
          <p>
            If Conrad RP stops providing the Services to you because you
            repeatedly or egregiously breach these Terms, Conrad RP may take
            measures to prevent the further use of the Services by you,
            including blocking your IP address.
          </p>
          <p>Changes and Updates</p>
          <p>Changes to Terms</p>
          <p>
            Conrad RP may change these Terms at any time for a variety of
            reasons, such as to reflect changes in applicable law or updates to
            Services, and to account for new Services or functionality. The most
            current version will always be posted on the Conrad RP website. If
            an amendment is material, as determined in Conrad RP’s sole
            discretion, Conrad RP will notify you by email. Notice of amendments
            may also be posted to Conrad RP’s blog or upon your login to your
            account. Changes will be effective no sooner than the day they are
            publicly posted. For certain changes to become effective, applicable
            law may require Conrad RP to obtain your consent to such changes, or
            to provide you with sufficient advance notice of them. If you do not
            want to agree to any changes made to the terms for a Service, you
            should stop using that Service, because by continuing to use the
            Services you indicate your agreement to be bound by the updated
            terms.
          </p>
          <p>Changes to Services</p>
          <p>
            Conrad RP constantly changes and improves the Services. Conrad RP
            may add, alter, or remove functionality from a Service at any time
            without prior notice. Conrad RP may also limit, suspend, or
            discontinue a Service at its discretion. If Conrad RP discontinues a
            Service, we will give you reasonable advance notice to provide you
            with an opportunity to export a copy of your Content from that
            Service. Conrad RP may remove content from the Services at any time
            in our sole discretion, although we will endeavour to notify you
            before we do that if it materially impacts you and if practicable
            under the circumstances.
          </p>
          <p>Downgrades</p>
          <p>
            Downgrading your account plan may cause the loss of content,
            features, functionality, or capacity of your account.
          </p>
          <p>Disclaimers and Limitations of Liability</p>
          <p>Disclaimers</p>
          <p>
            {' '}
            While it is in Conrad RP’s interest to provide you with a great
            experience when using the Services, there are certain things we do
            not promise about them. We try to keep our online Services up, but
            they may be unavailable from time to time for various reasons.
            EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS AND TO THE EXTENT
            PERMITTED BY APPLICABLE LAW, THE SERVICES ARE PROVIDED "AS IS" AND
            CONRAD RP DOES NOT MAKE WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, OR
            STATUTORY, INCLUDING THOSE OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT OR ANY REPRESENTATIONS
            REGARDING AVAILABILITY, RELIABILITY, OR ACCURACY OF THE SERVICES.
          </p>
          <p>Exclusion of Certain Liability</p>
          <p>
            TO THE EXTENT PERMITTED BY APPLICABLE LAW, CONRAD RP, ITS
            AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS, AND LICENSORS
            WILL NOT BE LIABLE FOR (A) ANY INDIRECT, CONSEQUENTIAL, SPECIAL,
            INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES WHATSOEVER, OR (B) LOSS
            OF USE, DATA, BUSINESS, REVENUES, OR PROFITS (IN EACH CASE WHETHER
            DIRECT OR INDIRECT), ARISING OUT OF OR IN CONNECTION WITH THE
            SERVICES AND THESE TERMS, AND WHETHER BASED ON CONTRACT, TORT,
            STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, EVEN IF CONRAD RP HAS
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF A REMEDY
            FAILS OF ITS ESSENTIAL PURPOSE.
          </p>
          <p>Limitation of Liability</p>
          <p>
            TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE AGGREGATE LIABILITY
            OF EACH OF CONRAD RP, ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
            SUPPLIERS, AND LICENSORS ARISING OUT OF OR IN CONNECTION WITH THE
            SERVICES AND THESE TERMS WILL NOT EXCEED THE AMOUNTS PAID BY YOU TO
            CONRAD RP FOR USE OF THE SERVICES AT ISSUE DURING THE 12 MONTHS
            PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY.
          </p>
          <p>Consumers</p>
          <p>
            We acknowledge that the laws of certain jurisdictions provide legal
            rights to consumers that may not be overridden by contract or waived
            by those consumers. If you are such a consumer, nothing in these
            Terms limits any of those consumer rights.
          </p>
          <p>Businesses</p>
          <p>
            If you are a business, you will indemnify and hold harmless Conrad
            RP and its affiliates, officers, agents, and employees from all
            liabilities, damages, and costs (including settlement costs and
            reasonable attorneys’ fees) arising out of a third-party claim
            regarding or in connection with your or your end users’ use of the
            Services or breach of these Terms, to the extent that such
            liabilities, damages and costs were caused by you or your end users.
          </p>
          <p>
            <p> Contracting Entity</p>
          </p>
          Who you are contracting with?
          <p>
            <p>
              Unless otherwise specified in relation to a particular service,
              the Services are provided by, and you are contracting with,
              company, location….
            </p>
          </p>
          For any Service provided by company, location…, the following
          provisions will apply to any terms governing that Service:
          <ul>
            <li>
              Contracting Entity. References to "Conrad RP", "we", "us", and
              "our" are references to company, location…. located
              atxxxxxxxxxxxxxxxxx.
            </li>
            <li>
              Governing Law. Those terms are governed by the laws of (country of
              incorporation).
            </li>
            <li>
              Jurisdiction. Except if prohibited by applicable law, each party
              submits to the exclusive jurisdiction of the courts located in
              (country of incorporation).
            </li>
          </ul>
          <p>Other Terms</p>
          <p>Entire Agreement</p>
          <p>
            These Terms (including the Additional Terms) constitute the entire
            agreement between you and Conrad RP, and they supersede any other
            prior or contemporaneous agreements, terms and conditions, written
            or oral concerning its subject matter. Any terms and conditions
            appearing on a purchase order or similar document issued by you do
            not apply to the Services, do not override or form a part of these
            Terms, and are void.
          </p>
          <p>Interpretation</p>
          <p>
            The use of the terms "includes", "including", "such as", and similar
            terms, will be deemed not to limit what else might be included.
          </p>
          <p>No Waiver</p>
          <p>
            A party’s failure or delay to enforce a provision under these Terms
            is not a waiver of its right to do so later.
          </p>
          <p>Precedence</p>
          <p>
            To the extent any conflict exists, the Additional Terms prevail over
            this TOU with respect to the Services to which the Additional Terms
            apply.
          </p>
          <p>Severability</p>
          <p>
            If any provision of these Terms is determined to be unenforceable by
            a court of competent jurisdiction, that provision will be severed,
            and the remainder of terms will remain in full effect.
          </p>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default TermsConditionModal
