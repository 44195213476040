import { Route, Redirect } from 'react-router-dom'
import React, { cloneElement, Component } from 'react'

const PrivateRoute = ({ component: Component, authed, ...rest }) =>
    <Route
        {...rest}
        render={(props) => authed === true
              ?  <Component {...props} />
       : <Redirect to={{pathname: '/', state: {from: props.location}}} />}
   />

export default PrivateRoute
