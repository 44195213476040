import React, { Component } from 'react'
import { Modal, Form, Container, Row, Col, Button } from 'react-bootstrap'
import { notification, Upload, Popconfirm, Button as BTN } from 'antd'
import {
  DownloadOutlined,
  UploadOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

import '../../style/container/objectives.scss'
import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
import RangeSlider from 'react-bootstrap-range-slider'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import moment from 'moment'
import Auth from '../../lib/Auth.js'
import userContext from '../../context/userContext'
import {
  deleteFile,
  getDocumentByType,
  uploadFile,
} from '../../common/uploadFile'
class EditActionPlanModal extends Component {
  static contextType = userContext
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      step: 1,
      actionDes: '',
      actionOw: 0,
      actionTy: 1,
      actionSta: 0,
      actionPri: 0,
      actionFoc: '',
      estCost: 0,
      actCost: 0,
      time: '',
      others: '',
      bcpDcr: '',
      frequencyOfReview: '',
      duedate: '',
      effectiveness: '',
      focus: 0,
      notes: '',
      showFirst: true,
      showNext: false,
      completed: 0,
      riskId: [],
      orgID: props.orgID,
      treatmentItem: props.treatmentItem,
      userID: '',
      docUpload: [],
      getTreatDoc: [],
    }
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentWillReceiveProps(props) {
    let date = moment(props.treatmentItem?.dueDate, 'YYYY-MM-DD').format(
      'DD/MM/YY'
    )

    let treatmentItem = { ...props.treatmentItem }
    treatmentItem.dueDate = date
    treatmentItem.actionStatus =
      treatmentItem.actionStatus == null ? {} : treatmentItem.actionStatus
    treatmentItem.actionPriority =
      treatmentItem.actionPriority == null ? {} : treatmentItem.actionPriority
    treatmentItem.actionType =
      treatmentItem.actionType == null ? {} : treatmentItem.actionType

    treatmentItem.effectiveness =
      treatmentItem.effectiveness == null ? {} : treatmentItem.effectiveness

    treatmentItem.frequencyOfReview =
      treatmentItem.frequencyOfReview == null
        ? {}
        : treatmentItem.frequencyOfReview

    this.setState(
      {
        orgID: props.orgID,
        id: treatmentItem.id,
        treatmentItem: treatmentItem,
        userID: Auth.getLoggedUserId(),
      },
      () => {
        this.getFileList()
      }
    )
  }
  renderActionOwners() {
    return this.props.actionOwners.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }
  inputChange = (input) => (e) => {
    let treatmentItem = { ...this.state.treatmentItem }
    if (input == 'actionType') {
      treatmentItem.actionType.id = e.target.value
    } else if (input == 'actionPri') {
      treatmentItem.actionPriority.id = e.target.value
    } else if (input == 'riskStatus') {
      treatmentItem.riskStatus = e.target.value
    } else if (input == 'effectiveness') {
      treatmentItem.effectiveness.id = e.target.value
    } else if (input == 'frequencyOfReview') {
      treatmentItem.frequencyOfReview.id = e.target.value
    } else {
      treatmentItem[input] = e.target.value
    }

    this.setState({
      treatmentItem,
    })
    parseInt(e.target.value, 10)
  }

  nextClickHandler = () => {
    this.setState({
      showNext: true,
    })
  }

  backClickhandler = () => {
    this.setState({
      showNext: false,
    })
  }

  // componentWillReceiveProps() {
  //     this.setState({
  //         actionDes: this.props.treatmentItem.description,
  //     })
  // }

  saveClickd = (id) => {
    const { sessionDetails } = userContext
    this.setState({
      showNext: false,
    })
    this.props.closeEditModal()

    const riskStatusId = this.state.treatmentItem?.riskStatus?.id

    let riskStatus
    if (riskStatusId && riskStatusId !== 0) {
      riskStatus = this.state.treatmentItem?.riskStatus?.id
    } else if (
      typeof this.state.treatmentItem?.riskStatus === 'string' &&
      this.state.treatmentItem?.riskStatus
    ) {
      riskStatus = this.state.treatmentItem?.riskStatus
    } else {
      riskStatus = this.props.actionStatus?.[0].id
    }


    const frequencyOfReview =
      this.state.treatmentItem?.frequencyOfReview.id ??
      this.props.frequencyOfReview?.[0].id

    axios
      .put(
        BASE_URL +
          `/organisations/${this.state.orgID}/riskTreatments/${this.state.id}`,
        {
          ref: this.state.treatmentItem?.ref,
          description: this.state.treatmentItem.description,
          cost: this.state.treatmentItem.estimatedCost,
          actualCost: this.state.treatmentItem.actualCost,
          dueDate: moment(this.state.treatmentItem.dueDate, 'DD/MM/YY').format(
            'YYYY-MM-DD'
          ),
          actionPriority: this.state.treatmentItem?.actionPriority.id,
          riskStatus: riskStatus,
          actionType: this.state.treatmentItem?.actionType.id,
          completed: this.state.treatmentItem?.completed,
          owner: this.state.treatmentItem?.owner,
          risk: this.state.treatmentItem?.riskId,
          time: this.state.treatmentItem?.time,
          notes: this.state.treatmentItem?.notes,
          comments: 'na',
          others: this.state.treatmentItem?.others,
          bcpDcr: this.state.treatmentItem?.bcpDcr,
          frequencyOfReview: frequencyOfReview,
          effectiveness: this.state.treatmentItem?.effectiveness.id,
          focus: this.state.treatmentItem?.focus,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        this.props.updateRiskTreatments()
        let uploadReq = {
          description: '',
          user: this.props.sessionDetails?.user?.id,
          department: this.props.sessionDetails?.user?.department?.id,
          organisation: this.state.orgID,
          fileType: 'RISK_TREATMENT',
          taskId: 0,
          treatmentId: this.state.id,
          validFileType: true,
        }
        uploadFile(this.state.docUpload, this.state.orgID, uploadReq)
        this.setState({
          docUpload: [],
        })
        if (res.status == 200) {
          Swal.fire({
            title: 'Data posted successfully',
            text: 'Your form data is now present in the table',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
          })
        }
      })
      .catch((err) => {
        return Swal.fire(
          '',
          'Could not post this treatment. Please try again!',
          'error'
        )
      })
  }

  getReviewFrequency() {
    return this.props.frequencyOfReview.map((item, index) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getActionPriorities() {
    return this.props.actionPriorities.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getActionEffectiveness() {
    return this.props.actionEffectiveness.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getActionStatus() {
    return this.props.actionStatus.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  handleDocUpload = ({ fileList }) => {
    console.log('FILE LIST', fileList)

    if (fileList.length > 0) {
      this.setState({
        docUpload: fileList,
      })

      // updateProfile(fileData)
    }
    // uploadFileList({ fileList })
  }

  handleDateChange = (e) => {
    let calculatedDate = moment(e).format('DD/MM/YY')

    let treatmentItem = { ...this.state.treatmentItem }

    treatmentItem.dueDate = calculatedDate

    this.setState({
      duedate: calculatedDate,
      treatmentItem,
    })
  }
  deleteUploadFile = async (docId) => {
    try {
      let orgId = this.state.orgID
      const data = await deleteFile(orgId, docId)
      console.log('DEL DATA --->', data)
      this.getFileList()
    } catch (e) {
      console.log(e)
    }
  }

  getFileList = async () => {
    // debugger
    const { sessionDetails } = userContext
    const docData = await getDocumentByType(this.state.orgID, {
      user: this.state.userID,
      department: this.props.sessionDetails?.user?.department?.id,
      organisation: this.state.orgID,
      fileType: 'RISK_TREATMENT',
      treatmentId: this.state.id,
    })
    console.log('DOCC', docData)
    this.setState({
      getTreatDoc: docData,
    })
  }

  fetchData = () => {}

  render() {
    const {
      actionDes,
      actionOw,
      actionTy,
      actionSta,
      actionPri,
      actionFoc,
      focus,
      estCost,
      actCost,
      time,
      others,
      bcpDcr,
      revFreq,
      duedate,
      actionEf,
      notes,
      completed,
      getTreatDoc,
    } = this.state
    const values = {
      actionDes,
      actionOw,
      actionTy,
      actionSta,
      actionPri,
      actionFoc,
      estCost,
      actCost,
      time,
      others,
      bcpDcr,
      revFreq,
      duedate,
      actionEf,
      notes,
      completed,
      focus,
    }

    console.log({ treatmentItem: this.state.treatmentItem })

    return (
      <div>
        <div>
          <Modal
            show={this.props.showEditModal}
            onHide={this.props.closeEditModal}
            className="modal-main action-plan-modal"
          >
            <Modal.Header className="modal-header">
              Edit Action Plan
            </Modal.Header>
            <Modal.Body className="modal-body">
              <Form>
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Description of Action Plan [SMART]
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="actionDes"
                            onChange={this.inputChange('description').bind(
                              this
                            )}
                            value={this.state.treatmentItem?.description}
                            style={{
                              width: '100%',
                              height: '150px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Owner <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionOw"
                            onChange={this.inputChange('owner').bind(this)}
                            value={this.state.treatmentItem?.owner}
                          >
                            {this.renderActionOwners()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Type: <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionTy"
                            onChange={this.inputChange('actionType').bind(this)}
                            value={this.state.treatmentItem?.actionType?.id}
                          >
                            <option value="1">Proactive</option>
                            <option value="2">Reactive</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Status:{' '}
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionSta"
                            onChange={this.inputChange('riskStatus').bind(this)}
                            value={
                              this.state.treatmentItem?.riskStatus == null
                                ? this.props.actionStatus[0]?.id
                                : this.state.treatmentItem?.riskStatus.id
                            }
                          >
                            {this.getActionStatus()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Priority:{' '}
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionPri"
                            onChange={this.inputChange('actionPri').bind(this)}
                            value={
                              this.state.treatmentItem?.actionPriority == null
                                ? this.props.actionPriorities[0]?.id
                                : this.state.treatmentItem?.actionPriority.id
                            }
                            style={{
                              width: '100%',
                            }}
                          >
                            {this.getActionPriorities()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Focus:{' '}
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="focus"
                            onChange={this.inputChange('focus').bind(this)}
                            value={this.state.treatmentItem?.focus}
                            style={{
                              width: '100%',
                            }}
                          >
                            <option value="1">Cause</option>
                            <option value="2">Impact</option>
                            <option value="3">Impact and Cause</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Estimated Cost:</Form.Label>
                          <Form.Control
                            type="text"
                            name="estCost"
                            onChange={this.inputChange('estimatedCost').bind(
                              this
                            )}
                            value={this.state.treatmentItem?.estimatedCost}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Actual Cost:</Form.Label>
                          <Form.Control
                            type="text"
                            name="actCost"
                            onChange={this.inputChange('actualCost').bind(this)}
                            value={this.state.treatmentItem?.actualCost}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Time:</Form.Label>
                          <Form.Control
                            type="text"
                            name="time"
                            onChange={this.inputChange('time').bind(this)}
                            value={this.state.treatmentItem?.time}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Others</Form.Label>
                          <Form.Control
                            type="text"
                            name="others"
                            onChange={this.inputChange('others').bind(this)}
                            value={this.state.treatmentItem?.others}
                            style={{
                              height: '35px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label
                            style={{
                              fontSize: '10px',
                            }}
                          >
                            Is BCP/DRC Required?
                          </Form.Label>
                          <select
                            className="form-control"
                            name="bcpDcr"
                            onChange={this.inputChange('bcpDcr').bind(this)}
                            value={this.state.treatmentItem?.bcpDcr}
                            style={{
                              height: '30px',
                            }}
                          >
                            <option value="BCP">BCP</option>
                            <option value="DRC">DRC</option>
                            <option value="BCP + DRC">BCP + DRC</option>
                            <option value="none">None</option>
                          </select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Review Frequency:{' '}
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="frequencyOfReview"
                            onChange={this.inputChange(
                              'frequencyOfReview'
                            ).bind(this)}
                            value={
                              this.state.treatmentItem?.frequencyOfReview?.id
                            }
                            style={{
                              height: '30px',
                            }}
                          >
                            {this.getReviewFrequency()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Due Date <span className="text-danger"> *</span>
                          </Form.Label>
                          <DatePicker
                            value={this.state.treatmentItem?.dueDate}
                            name="testDate"
                            onChange={(e) => this.handleDateChange(e)}
                            dateFormat="dd/mm/yy"
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        Completed: <span className="text-danger"> *</span>
                        <Form.Group as={Row}>
                          <Col xs="8">
                            <RangeSlider
                              value={this.state.treatmentItem?.completed}
                              onChange={(e) => {
                                let treatmentItem = {
                                  ...this.state.treatmentItem,
                                }
                                treatmentItem.completed = e.target.value
                                this.setState({
                                  treatmentItem,
                                })
                              }}
                            />
                          </Col>
                          <Col xs="3">
                            <Form.Control
                              value={this.state.treatmentItem?.completed}
                              onChange={this.inputChange('completed').bind(
                                this
                              )}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Action Effectiveness:</Form.Label>
                          <Form.Control
                            as="select"
                            name="effectiveness"
                            onChange={this.inputChange('effectiveness').bind(
                              this
                            )}
                            value={this.state.treatmentItem?.effectiveness?.id}
                          >
                            {this.getActionEffectiveness()}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Upload Evidence:</Form.Label>
                          <br />
                          <Upload
                            multiple={true}
                            customRequest={(e) => {
                              e.onSuccess((val) => {
                                console.log('FILE VAL --->', val)
                              })
                              e.onError((err) => {
                                console.log('FILE err --->', err)
                              })
                            }}
                            onChange={this.handleDocUpload}
                          >
                            <BTN icon={<UploadOutlined />}>Click to Upload</BTN>
                          </Upload>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Notes</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="notes"
                            onChange={this.inputChange('notes').bind(this)}
                            value={this.state.treatmentItem?.notes}
                            style={{
                              height: '120px',
                              width: '100%',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Label>File List </Form.Label>
                        <div
                          style={{
                            width: '100%',
                            height: '150px',
                            overflow: 'auto',
                          }}
                        >
                          {getTreatDoc?.map((data, key) => {
                            return (
                              <div
                                key={key}
                                className="d-flex justify-content-between border mb-1 p-1 bg-white"
                              >
                                <p className="m-0">{data.fileName}</p>
                                <span>
                                  <a
                                    className="cursor-pointer"
                                    href={data.fileUrl}
                                    download
                                    target="_blank"
                                  >
                                    <DownloadOutlined />
                                  </a>
                                  <span className="cursor-pointer">
                                    <Popconfirm
                                      title="Are you sure to delete this File?"
                                      onConfirm={() => {
                                        this.deleteUploadFile(data.id)
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined />
                                    </Popconfirm>
                                  </span>
                                </span>
                              </div>
                            )
                          })}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
              <div>
                <Button onClick={this.props.closeEditModal}>Cancel</Button>
                {/*<Button onClick={this.nextClickHandler.bind(this)}>Next</Button>*/}
                <Button type={'submit'} onClick={this.saveClickd.bind(this)}>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            show={this.state.showNext}
            className="modal-main action-plan-modal"
          >
            <Modal.Header>Edit Action Plan - Cost of Treatment</Modal.Header>
            <Modal.Body>
              <Form>
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Estimated Cost:</Form.Label>
                          <Form.Control
                            type="text"
                            name="estCost"
                            onChange={this.inputChange('estimatedCost').bind(
                              this
                            )}
                            value={this.state.treatmentItem?.estimatedCost}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Actual Cost:</Form.Label>
                          <Form.Control
                            type="text"
                            name="actCost"
                            onChange={this.inputChange('actualCost').bind(this)}
                            value={this.state.treatmentItem?.actualCost}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Time:</Form.Label>
                          <Form.Control
                            type="text"
                            name="time"
                            onChange={this.inputChange('time').bind(this)}
                            value={this.state.treatmentItem?.time}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Others</Form.Label>
                          <Form.Control
                            type="text"
                            name="others"
                            onChange={this.inputChange('others').bind(this)}
                            value={this.state.treatmentItem?.others}
                            style={{
                              height: '35px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label
                            style={{
                              fontSize: '10px',
                            }}
                          >
                            Is BCP/DRC Required?
                          </Form.Label>
                          <select
                            classname="form-control"
                            name="bcpDcr"
                            onChange={this.inputChange('bcpDcr').bind(this)}
                            value={this.state.treatmentItem?.bcpDcr}
                            style={{
                              height: '30px',
                            }}
                          >
                            <option value="BCP">BCP</option>
                            <option value="DRC">DRC</option>
                            <option value="BCP + DRC">BCP + DRC</option>
                            <option value="none">None</option>
                          </select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Review Frequency:</Form.Label>
                          <Form.Control
                            as="select"
                            name="frequencyOfReview"
                            onChange={this.inputChange(
                              'frequencyOfReview'
                            ).bind(this)}
                            value={
                              this.state.treatmentItem?.frequencyOfReview?.id
                            }
                            style={{
                              height: '30px',
                            }}
                          >
                            {this.getReviewFrequency()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Due Date <span className="text-danger"> *</span>
                          </Form.Label>
                          <DatePicker
                            value={this.state.treatmentItem?.dueDate}
                            name="testDate"
                            onChange={(e) => this.handleDateChange(e)}
                            dateFormat="dd/mm/yy"
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        Completed: <span className="text-danger"> *</span>
                        <Form.Group as={Row}>
                          <Col xs="8">
                            <RangeSlider
                              value={this.state.treatmentItem?.completed}
                              onChange={(e) => {
                                let treatmentItem = {
                                  ...this.state.treatmentItem,
                                }
                                treatmentItem.completed = e.target.value
                                this.setState({
                                  treatmentItem,
                                })
                              }}
                            />
                          </Col>
                          <Col xs="3">
                            <Form.Control
                              value={this.state.treatmentItem?.completed}
                              onChange={this.inputChange('completed').bind(
                                this
                              )}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Action Effectiveness:</Form.Label>
                          <Form.Control
                            as="select"
                            name="effectiveness"
                            onChange={this.inputChange('effectiveness').bind(
                              this
                            )}
                            value={this.state.treatmentItem?.effectiveness?.id}
                          >
                            {this.getActionEffectiveness()}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.File label="Upload Evidence" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Notes</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="notes"
                            onChange={this.inputChange('notes').bind(this)}
                            value={this.state.treatmentItem?.notes}
                            style={{
                              height: '120px',
                              width: '100%',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <Button onClick={this.backClickhandler.bind(this)}>Back</Button>
                <Button type={'submit'} onClick={this.saveClickd.bind(this)}>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
}

export default EditActionPlanModal
