import React, { Component } from 'react'
import axios from 'axios'
import LoginFormInput from '../presentational/LoginFormInput.jsx'
import Auth from '../../lib/Auth.js'
import Swal from 'sweetalert2'

class LoginContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: 'Login',
      postData: {
        username: '',
        password: '',
      },
      errors: {},
      loginDataRes: {},
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange({ target: { name, value } }) {
    console.log('VALUE ---- BBA', { name, value })
    const postData = { ...this.state.postData, [name]: value }
    const errors = {}
    this.setState({ postData, errors })
  }

  handleSubmit(e) {
    e.preventDefault()
    axios
      .post(BASE_URL + '/authentication', this.state.postData)
      .then(res => {
        this.setState({ loginDataRes: res.data })
        Auth.setToken(res.data.token)
        // this.props.history.push(`/`)
        window.location.reload()
      })
      .catch(err => {
        if (err.response.status == 401)
          return Swal.fire('', 'Invalid Username/Password', 'info')
        if (err.response.status == 402)
          return Swal.fire({
            html: `Your licence has expired, please contact your system administrator, or click <a href="/licence/0">here</a> to extend your licence`,
            icon: 'info',
          })
        if (err.response.status == 500)
          return Swal.fire(
            '',
            'Something has gone wrong ☹️. Please try again later. If this problem persists, please contact your system administrator',
            'info'
          )
      })
  }

  render() {
    const { title } = this.state
    return (
      <LoginFormInput
        type="text"
        value={this.state}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        title={title}
        errors={this.state.errors}
      />
    )
  }
}

export default LoginContainer
