import Axios from 'axios'
import { notification } from 'antd'
import moment from 'moment'

export const uploadFile = async (fileData, orgId, req) => {
  // debugger
  try {
    if (fileData?.length > 0) {
      fileData?.map(async (fileItem) => {
        await Axios.post(BASE_URL + `/organisations/${orgId}/document/upload`, {
          ...req,
          fileName: fileItem.name,
          uploadDate: moment().format(),
          size: fileItem.size,
        }).then((res) => {
          let { data } = res

          uploadImageToServer(data?.fileUrl, fileItem)
        })
      })
    } else {
      Axios.post(BASE_URL + `/organisations/${orgId}/document/upload`, {
        ...req,
      }).then((res) => {
        let { data } = res

        uploadImageToServer(data?.fileUrl, fileData)
      })
    }
  } catch (e) {
    console.log(e)
  }
}

const uploadImageToServer = async (url, file) => {
  try {
    let myHeaders = new Headers()
    myHeaders.append('Content-Type', file.type)
    myHeaders.append('Access-Control-Allow-Origin', '*')

    await fetch(url, {
      method: 'PUT',
      headers: myHeaders,
      body: file.originFileObj,
    })
      .then((response) => response.text())
      .then((result) => {
        console.log('RESULT', result)
        notification.success({
          message: 'Successful',
          description: 'Uploaded Successfully',
        })
      })
      .catch((error) => {
        notification.success({
          message: 'Failed',
          description: 'Uploaded Failed',
        })
        console.log('error', error)
      })
  } catch (e) {
    console.log(e)
  }
}

export const getDocumentByType = async (orgId, req) => {
  try {
    let reqData = {}
    await Axios.post(BASE_URL + `/organisations/${orgId}/documents/details`, {
      ...req,
    }).then((result) => {
      console.log('DOC RESULT --->', result)
      const { data } = result
      if (data) {
        console.log(data)
        reqData = data
        return data
      } else {
        notification.warning({
          description: 'No File Found',
        })
        reqData = []
        return []
      }
    })
    return reqData
  } catch (e) {
    console.log(e)
    return []
  }
}
export const deleteFile = async (orgId, docId) => {
  try {
    let reqData = {}
    await Axios.delete(
      BASE_URL + `/organisations/${orgId}/document/${docId}/delete`
    ).then((res) => {
      let { data } = res
      reqData = data
      notification.success({
        message: 'Successful',
        description: 'Deleted Successfully',
      })
    })
    return reqData
  } catch (e) {
    notification.warning({
      message: 'Unsuccessful',
      description: 'Can not delete please try again late !',
    })
    console.log(e)
  }
}
