/* eslint-disable prettier/prettier */
import React, { useContext } from 'react'
import userContext from '../../../../context/userContext.js';
import Table from './RiskReport.TableIndividual.jsx'
export default function RiskReport({ data , departmentsMap}) {
    const { sessionDetails } = useContext(userContext)
    const profileImage = sessionDetails?.profileImage?.url;
    const { fullName, jobTitle } = sessionDetails?.user
    

    const getTotals = (risks) => {
        const total = risks.reduce((acc, risk) => {

            if (risk.impactType.name === 'POSITIVE') {
                acc.totalGrossOpp += risk.grossEmv.netValue
                acc.totalNetOpp += risk.netEmv.netValue
                acc.totalTargetOpp += risk.targetEmv.netValue
            } else {
                acc.totalGrossNeg += risk.grossEmv.netValue
                acc.totalNetNeg += risk.netEmv.netValue
                acc.totalTargetNeg += risk.targetEmv.netValue
            }


            return acc;
        }, {
            totalGrossOpp: 0, totalNetOpp: 0, totalTargetOpp: 0,
            totalGrossNeg: 0, totalNetNeg: 0, totalTargetNeg: 0,
        })
        return total


    }
    return (
        <div>
            <div className="w-100">
                {
                    data?.criteria === 'INDIVIDUAL' && <div className="report-profile-section">
                        <img src={profileImage} alt={fullName} className="navProfileImage" />
                        <div className="name-holder">
                            <span>{fullName}</span>
                            <span>{jobTitle}</span>
                        </div>
                    </div>
                }
            </div>
            <Table risks={data.data} totals={getTotals(data.data)} departmentsMap={departmentsMap} />
        </div>
    )
}



