import React, { Component } from 'react'
import { Table, Modal, Spinner } from 'react-bootstrap'
import axios from 'axios'
import Auth from '../../../lib/Auth.js'
import { couldStartTrivia } from 'typescript'
import ButtonActions from '../../common/EditDeleteButtons.jsx'
import ButtonsHeader from '../../common/EditDeleteTH.jsx'
import Swal from 'sweetalert2'
import { InfoToolTip } from '../../common/InfoTooltip.jsx'
import isEmpty from 'lodash/fp/isEmpty'
import { PlusOutlined } from '@ant-design/icons'
import { Upload, Select } from 'antd'
import Axios from 'axios'
import userContext from '../../../context/userContext'
const { Option } = Select
export default class UserSetup extends Component {
  static contextType = userContext

  constructor(props) {
    super(props)
    this.state = {
      // activeItem: `/admin/user-setup/${this.props.match.params.id}`,
      filteredOrganization: [],
      organisationUsers: [],
      userData: {},
      organisationJobRoles: [],
      jobRolesDepartments: [],
      jobRolesTeams: [],
      postOrganisationUsers: {
        firstName: '',
        lastName: '', // status: '',
        emailAddress: '',
        team: 0,
        department: 0,
        jobRole: 0,
        role: 0, // usersPage: 0,
        // totalUserPages: 0,
      },
      roles: props.systemRoles,
      userPermissions: [],
      permissionsObject: {},
      selectedDepartmentPermission: { departments: [] },
      usersPage: 0,
      totalUserPages: 0,
      rowsPerPage: 10,
      showErrorModal: false,
      fileList: [],
      sFileList: [],
      profileImage: {},
      profileVisible: '',
      userProfileList: [],
      loader: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.addUser = this.addUser.bind(this)
    this.viewUser = this.viewUser.bind(this)
    this.updateUser = this.updateUser.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.closeErrorModal = this.closeErrorModal.bind(this)

    this.orgId = Auth.getOrg()
    this.userId = Auth.getUserId()
  }

  handleClick(value) {
    // window.location.href = `${value}`
    // this.setState({ activeItem: value })
  }

  componentWillReceiveProps(props) {
    this.setState({ roles: props.systemRoles })
  }

  handleChange({ target: { name, value } }, messageType) {
    this.setState((prevState) => ({
      ...prevState,
      [messageType]: { ...prevState[messageType], [name]: value },
    }))
  }

  closeErrorModal() {
    this.setState({ showErrorModal: false })
  }

  componentDidMount() {
    const { sessionDetails } = this.context
    let profilePic = {}
    let users = []
    axios
      .get(BASE_URL + `/organisations/${this.props.match.params.id}/jobRoles`)
      .then((res) => {
        this.setState({ organisationJobRoles: res.data })
      })

    axios
      .get(BASE_URL + `/roles`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        this.setState({ roles: res.data })
      })

    axios
      .get(BASE_URL + `/organisations/${this.props.match.params.id}/users`, {
        params: {
          department: 0,
          team: 0,
          jobRole: 0,
          status: 0,
        },
      })
      .then((res) => {
        this.setState({ organisationUsers: res.data }, () =>
          this.setState({
            totalUserPages: this.getTotalPages('organisationUsers'),
          })
        )
        let userDataId = res.data.find(
          (item) => item.emailAddress == Auth.getUserId()
        )
        axios
          .get(BASE_URL + `/userPermissions/` + userDataId?.id)
          .then((res) => {
            if (res?.data) {
              let { selectedDepartmentPermission } = this.state
              let departments = res?.data?.departments
              let userPermissions = Object.keys(departments)
              let setDept = {
                ...selectedDepartmentPermission,
                department: userPermissions,
              }
              /* selectedDepartmentPermission: {
                department: e,
                // permission: perm,
              }*/
              this.setState({
                userPermissions: userPermissions,
                selectedDepartmentPermission: setDept,
              })
            }
          })
        users = res.data
      })
      .then(() => {
        let userIds = []
        users &&
          users.forEach((item) => {
            if (userIds.includes(item.id)) {
            } else {
              userIds.push(item.id)
            }
          })

        const urls = userIds.map((item) => {
          axios
            .get(
              `${BASE_URL}/authentication/passwords/${this.props.match.params.id}/status/${item}`,
              {
                headers: {
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
              }
            )
            .then((res) => {})
        })

        axios
          .get(
            `${BASE_URL}/organisations/${this.props.match.params.id}/users/photo`,
            {
              headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
              },
            }
          )
          .then((res) => {
            this.setState({ userProfileList: res.data })
          })

        axios.all([...urls]).then((responses) => {
          let usersWithStatus = []
          responses.forEach((response) => {
            if (
              response &&
              (response.data !== null || response.data.length !== 0)
            ) {
              usersWithStatus = [...usersWithStatus, ...response.data]
            }
          })

          users.forEach((user) => {
            const status = usersWithStatus.filter(
              (item) => item.id === user.id
            )[0]
          })
        })
      })
    axios
      .get(
        BASE_URL + `/organisations/${this.props.match.params.id}/departments`,
        {
          params: {
            parentDepartment: 0,
            departmentHead: 0,
          },
        }
      )
      .then((res) => {
        let permissionsObject = {}
        res.data.forEach((item) => {
          permissionsObject[item.id] = ''
        })
        this.setState({
          jobRolesDepartments: res.data,
          permissionsObject,
        })
      })

    this.setState({
      fileList: sessionDetails?.profileImage?.image,
    })
  }

  getProfile = (orgId, userID) => {
    try {
      Axios.get(BASE_URL + `/organisations/${orgId}/users/${userID}/photo`)
        .then((res) => {
          let { data } = res

          let { userProfileList } = this.state

          return data?.url
        })
        .then((result) => result)
    } catch (e) {
      console.log(e)
    }
  }

  getPageRows = (type, typePage) => {
    var chunks = []
    let i = 0
    let n = this.state[type].length
    while (i < n) {
      chunks.push(this.state[type].slice(i, (i += this.state.rowsPerPage)))
    }

    return chunks[typePage] || []
  }

  getTotalPages = (type) => {
    var chunks = [],
      i = 0,
      n = this.state[type].length
    while (i < n) {
      chunks.push(this.state[type].slice(i, (i += this.state.rowsPerPage)))
    }
    return chunks.length
  }

  viewUser(e, orgUser) {
    const { userProfileList } = this.state
    this.setState({
      postOrganisationUsers: {
        id: orgUser.id,
        firstName: orgUser.firstName,
        lastName: orgUser.lastName,
        department: orgUser?.department?.id,
        team: orgUser.team,
        jobRole: orgUser?.jobRole?.id,
        organisation: orgUser?.organisation?.id,
        emailAddress: orgUser.emailAddress,
        role: orgUser?.role?.id,
      },
      sFileList: [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: userProfileList[orgUser.id],
        },
      ],
    })
    axios.get(BASE_URL + `/userPermissions/${orgUser.id}`).then((res) => {
      let permissionsObject
      let userPermissions
      if (res.data.departments == null) {
        permissionsObject = {}
      } else {
        permissionsObject = res.data.departments
        userPermissions = permissionsObject && Object.keys(permissionsObject)
      }
      this.setState({
        postOrganisationUsers: {
          id: orgUser.id,
          firstName: orgUser.firstName,
          lastName: orgUser.lastName,
          department: orgUser?.department?.id,
          team: orgUser.team,
          jobRole: orgUser?.jobRole?.id,
          organisation: orgUser?.organisation?.id,
          emailAddress: orgUser.emailAddress,
          role: orgUser?.role?.id,
        },
        permissionsObject: permissionsObject,
        userPermissions,
      })
    })
  }

  addUser(e) {
    e.preventDefault()
    axios
      .post(BASE_URL + `/organisations/${this.props.match.params.id}/users`, {
        ...this.state.postOrganisationUsers, // status: 'ACTIVE',
        isUser: true,
        team: 0,
        role: Number(this.state.postOrganisationUsers.role) || 0,
        department: Number(this.state.postOrganisationUsers.department) || 0,
        jobRole: Number(this.state.postOrganisationUsers.jobRole) || 0,
        profilePhotoFile: 'string',
      })
      .then((res) => {
        if (res?.data) {
          const { selectedDepartmentPermission } = this.state
          axios
            .put(BASE_URL + `/userPermissions/${res?.data}`, {
              departments: {
                [selectedDepartmentPermission.department]:
                  selectedDepartmentPermission.permission,
              },
              teams: null,
            })
            .then()
            .catch((e) => {
              this.setState({
                errors: err.response.data,
                showErrorModal: true,
              })
              return Swal.fire('', err.response.data, 'error')
            })
        }
        window.location.reload()
      })
      .catch((err) => {
        this.setState({
          errors: err.response.data,
          showErrorModal: true,
        })
        return Swal.fire('', 'User with this email already exists', 'error')
      })
  }

  updateUser(e, userId) {
    e.preventDefault()
    this.setState({ loader: true })

    axios
      .put(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/users/${userId}`,
        {
          ...this.state.postOrganisationUsers, // status: 'ACTIVE',
          isUser: true,
          team: 0,
          id: undefined,
          organisation: undefined,
          role: Number(this.state.postOrganisationUsers.role) || 0,
          department: Number(this.state.postOrganisationUsers.department) || 0,
          jobRole: Number(this.state.postOrganisationUsers.jobRole) || 0,
        }
      )
      .then((res) => {
        const { selectedDepartmentPermission, permissionsObject } = this.state
        console.log(
          'STATE dept DDPS -->',
          permissionsObject,
          selectedDepartmentPermission
        )

        console.log('STATE DDPS THIS -->', this.state)
        let permissionsObj = selectedDepartmentPermission?.department?.reduce(
          (final, data) => {
            return { ...final, [data]: 'view' }
          },
          {}
        )

        axios
          .put(BASE_URL + `/userPermissions/${userId}`, {
            departments: {
              ...permissionsObj,
            },
            teams: null,
          })
          .then((res) => {
            window.location.reload()
          })
          .catch((e) => {
            this.setState({
              errors: err.response.data,
              showErrorModal: true,
            })
            return Swal.fire('', err.response.data, 'error')
          })
        
        // this.updateProfile()
        // this.setState({ loader: false })
      })
      .catch((err) => {
        this.setState({ loader: false })

        throw err
      })

    this.setState({ loader: false })
  }

  deleteUser(e, userId) {
    e.preventDefault()
    axios
      .delete(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/users/${userId}`
      )
      .then(() => location.reload())
  }

  handleOtherPermissionsChange(e) {
    // e.preventDefault()
    // let perm = this.state.permissionsObject[e]

    let selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    )
    if (selectedOptions?.length === 1 && selectedOptions[0] === '') {
      this.setState({
        userPermissions: [],
        selectedDepartmentPermission: {
          department: [],
        },
      })
    } else {
      this.setState({
        userPermissions: Array.from(
          e.target.selectedOptions,
          (option) => option.value
        ),
        selectedDepartmentPermission: {
          department: Array.from(
            e.target.selectedOptions,
            (option) => option.value
          ),
        },
      })
    }
  }

  handlePermissions(e) {
    e.preventDefault()
    let permissionsObject = this.state.permissionsObject
    permissionsObject[this.state.selectedDepartmentPermission.department] =
      e.target.value

    this.setState({ permissionsObject, userPermissions: e.target.value })
  }

  handleJobRoleFilter = (value) => {
    //jobRolesDepartments/organisationJobRoles
    let departmentId = value
    let filterJobRole =
      this.state.postOrganisationUsers.department == 0
        ? this.state.organisationJobRoles
        : this.state.organisationJobRoles.filter(
            (item) =>
              item?.department?.id ==
              this.state.postOrganisationUsers.department
          )
    // console.log('JOB ROLE FILETR --->', filterJobRole)
  }

  handleFileChange = ({ fileList }) => {
    let fileData = fileList && fileList.length > 0 && fileList[0]
    if (fileList && fileList.length !== 0) {
      // this.updateProfile(fileData)
    }
    this.setState({ sFileList: fileList, profileImage: fileData })
  }

  handlePreview = () => {}

  handleCancel = () => {
    this.setState({
      profileVisible: false,
    })
  }

  updateProfile = () => {
    try {
      const { profileImage } = this.state

      Axios.post(
        BASE_URL +
          `/organisations/${this.orgId}/users/${this.state.postOrganisationUsers.id}/photo`,
        {
          fileName: profileImage.name,
          size: profileImage.size,
        }
      )
        .then((res) => {
          let { data } = res

          this.uploadImageToServer(data?.url, profileImage)
        })
        .catch((e) => {
          window.location.reload()
        })
    } catch (e) {
      window.location.reload()
      console.log('e', e)
    }
  }

  uploadImageToServer = async (url, file) => {
    try {
      const { setSession } = this.context
      let myHeaders = new Headers()
      myHeaders.append('Content-Type', file.type)
      myHeaders.append('Access-Control-Allow-Origin', '*')

      await fetch(url, {
        method: 'PUT',
        headers: myHeaders,
        body: file.originFileObj,
      })
        .then((response) => response.text())
        .then((result) => {
          // this.getProfile()
          setSession({
            profileImage: {
              image: {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: url,
              },
              url: url,
            },
          })
          window.location.reload()
        })
        .catch((error) => {
          console.log('error', error)
          window.location.reload()
        })
      this.setState({ loader: false })
      window.location.reload()
    } catch (e) {
      console.log(e)
    }
    window.location.reload()
  }

  render() {
    const jobRoles =
      this.state.postOrganisationUsers.department == 0
        ? this.state.organisationJobRoles
        : this.state.organisationJobRoles.filter(
            (item) =>
              item?.department?.id ==
              this.state.postOrganisationUsers.department
          )
    const {
      fileList,
      profileVisible,
      profileImage,
      userProfileList,
      sFileList,
    } = this.state

    return (
      <>
        <Modal
          title="Upload Profile Image"
          visible={profileVisible}
          onCancel={this.handleCancel}
          onOk={this.handleCancel}
        >
          <div className="modalInner"></div>
        </Modal>
        <div className="tab-title">
          <h2>
            <InfoToolTip
              title={'User Setup'}
              text="User Setup Info Text"
            ></InfoToolTip>
          </h2>
          <p>
            Add New{' '}
            <button
              type="button"
              className="icon add"
              data-toggle="modal"
              data-target="#addModal"
            >
              <i className="fa fa-plus" />
            </button>
          </p>

          <div
            className="modal fade region"
            id="addModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <form onSubmit={this.addUser}>
                  <div className="modal-header">
                    <h2 className="modal-title" id="addModalLabel">
                      Add User
                    </h2>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div>
                      <div className=" mr-2">
                        {/*<span className="d-flex justify-content-center align-content-center">
                          
                          </span>*/}

                        <Upload
                          action=""
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={this.handlePreview}
                          onChange={this.handleChange}
                          customRequest={(e) => {
                            e.onSuccess((val) => {})
                            e.onError((err) => {})
                          }}
                        >
                          {/* {thumbNailImage && (
                <div className="thumbNailImage">
                  <img src={profileImage} alt="Profile Image" />
                </div>
              )}*/}
                          {/*{fileList && fileList.length < 1 ? (
                            <div>
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                          ) : null}*/}
                        </Upload>
                        {/*{profileImage && (
                          <img src={profileImage} alt="Profile Image" />
                        )}*/}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>
                          First Name
                          <span className="form-mandatory">*</span>
                        </label>
                        <input
                          onChange={(e) =>
                            this.handleChange(e, 'postOrganisationUsers')
                          }
                          name="firstName"
                          className="input-text"
                        />
                      </div>
                      <div className="form-cell">
                        <label>
                          Last Name
                          <span className="form-mandatory">*</span>
                        </label>
                        <input
                          onChange={(e) =>
                            this.handleChange(e, 'postOrganisationUsers')
                          }
                          name="lastName"
                          className="input-text"
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>
                          Email
                          <span className="form-mandatory">*</span>
                        </label>
                        <input
                          onChange={(e) =>
                            this.handleChange(e, 'postOrganisationUsers')
                          }
                          name="emailAddress"
                          className="input-text"
                        />
                      </div>

                      <div className="form-cell">
                        <label>Department</label>
                        <select
                          className="form-select"
                          defaultValue={'default'}
                          onChange={(e) => {
                            this.handleChange(e, 'postOrganisationUsers')
                          }}
                          name="department"
                        >
                          <option value="default" disabled="disabled"></option>
                          {this.state.jobRolesDepartments.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-cell">
                        <label>Job Role</label>
                        <select
                          className="form-select"
                          defaultValue={-1}
                          onChange={(e) =>
                            this.handleChange(e, 'postOrganisationUsers')
                          }
                          name="jobRole"
                        >
                          <option value={-1} disabled></option>
                          {!isEmpty(jobRoles) &&
                            jobRoles.map((item) => {
                              return (
                                <option value={item.id}>{item.jobTitle}</option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="form-cell">
                        <label>
                          System Role
                          <span className="form-mandatory">*</span>
                        </label>
                        <select
                          className="form-select"
                          defaultValue={-1}
                          onChange={(e) =>
                            this.handleChange(e, 'postOrganisationUsers')
                          }
                          name="role"
                        >
                          <option value="">-</option>
                          {/* {this.state.roles.map(
                                                        (item) => (
                                                            <option
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        )
                                                    )} */}
                          <option value="1">Admin</option>
                          <option value="2">Edit</option>
                          <option value="3">View</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>Other Permissions</label>
                        <div className="other-permissions-container">
                          <select
                            multiple
                            defaultValue={this.state.jobRolesDepartments[0]?.id}
                            onChange={(e) =>
                              this.handleOtherPermissionsChange(e)
                            }
                            style={{ height: 100 }}
                          >
                            <option value="">-</option>
                            {this.state.jobRolesDepartments.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </select>
                        </div>
                        <small id="emailHelp" class="form-text text-muted">
                          The “other permissions” is a view-only role where a
                          user will only view the assigned department’s
                          objectives and risks.
                          <br /> To multi-select departments, please hold the
                          CTRL button while you click on the department
                        </small>
                      </div>

                      {/* <div className="form-cell">
                        <label>System Role (Each Permission)</label>
                        <div className="other-permissions-container">
                          <select
                            defaultValue=""
                            value={
                              this.state.permissionsObject[
                                this.state.selectedDepartmentPermission
                                  .department
                              ]
                                ? this.state.permissionsObject[
                                    this.state.selectedDepartmentPermission
                                      .department
                                  ]
                                : ''
                            }
                            onChange={(e) => this.handlePermissions(e)}
                          >
                            <option value="">-</option>
                            <option value="view">View</option>
                          </select>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary add"
                      aria-label="Close"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Table size="sm">
          <thead>
            <tr>
              <th>No.</th>
              <th>Photo</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Department</th>
              <th>Status</th>
              <ButtonsHeader />
            </tr>
          </thead>
          <tbody>
            {this.getPageRows('organisationUsers', this.state.usersPage).map(
              (orgUser, index) => {
                if (orgUser.organisation.id == this.props.match.params.id) {
                  return (
                    <tr key={orgUser.id}>
                      <td>
                        {this.state.usersPage * this.state.rowsPerPage +
                          index +
                          1}
                      </td>
                      <td className="userSetup">
                        <div className=" mr-2">
                          {/*<span className="d-flex justify-content-center align-content-center">
                          
                          </span>*/}
                          {userProfileList && userProfileList[orgUser.id] && (
                            <img
                              className="userProfileImage"
                              src={userProfileList[orgUser.id]}
                              alt="Profile Image"
                            />
                          )}
                        </div>{' '}
                      </td>
                      <td>{orgUser.firstName}</td>
                      <td>{orgUser.lastName}</td>
                      <td>{orgUser.emailAddress}</td>
                      <td>{orgUser.department?.name}</td>
                      <td>{orgUser?.role?.role}</td>

                      <ButtonActions
                        dataTarget="#viewUserModal"
                        editAction={(e) => this.viewUser(e, orgUser)}
                        deleteAction={(e) => this.deleteUser(e, orgUser.id)}
                        itemId={orgUser.id}
                      />
                    </tr>
                  )
                }
              }
            )}
          </tbody>
        </Table>

        <div className="text-right mt-3">
          <span style={{ fontSize: '14px' }}>
            Showing page {this.state.usersPage + 1} of{' '}
            {this.state.totalUserPages}
          </span>
          <button
            className="previous-page btn btn-primary mx-2"
            disabled={this.state.usersPage == 0}
            onClick={() =>
              this.setState((prevProps) => ({
                usersPage: prevProps.usersPage - 1,
              }))
            }
          >
            Prev
          </button>
          <button
            disabled={this.state.usersPage === this.state.totalUserPages - 1}
            className="next-page btn btn-primary"
            onClick={() =>
              this.setState((prevProps) => ({
                usersPage: prevProps.usersPage + 1,
              }))
            }
          >
            Next
          </button>
        </div>

        {/* Edit Users modal*/}

        <div
          className="modal fade region"
          id="viewUserModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="userModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <form
                onSubmit={(e) =>
                  this.updateUser(e, this.state.postOrganisationUsers.id)
                }
              >
                <div className="modal-header">
                  <h2 className="modal-title" id="viewModalLabel">
                    Edit User
                  </h2>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="d-flex justify-content-center">
                    <div>
                      <Upload
                        action=""
                        listType="picture-card"
                        fileList={sFileList}
                        onPreview={this.handlePreview}
                        onChange={this.handleFileChange}
                      >
                        {sFileList.length < 1 ? (
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                          </div>
                        ) : null}
                      </Upload>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-cell">
                      <label>
                        First Name
                        <span className="form-mandatory">*</span>
                      </label>
                      <input
                        onChange={(e) =>
                          this.handleChange(e, 'postOrganisationUsers')
                        }
                        name="firstName"
                        className="input-text"
                        value={this.state.postOrganisationUsers.firstName}
                      />
                    </div>
                    <div className="form-cell">
                      <label>
                        Last Name
                        <span className="form-mandatory">*</span>
                      </label>
                      <input
                        onChange={(e) =>
                          this.handleChange(e, 'postOrganisationUsers')
                        }
                        name="lastName"
                        className="input-text"
                        value={this.state.postOrganisationUsers.lastName}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-cell">
                      <label>
                        Email Address
                        <span className="form-mandatory">*</span>
                      </label>
                      <input
                        onChange={(e) =>
                          this.handleChange(e, 'postOrganisationUsers')
                        }
                        name="emailAddress"
                        className="input-text"
                        value={this.state.postOrganisationUsers.emailAddress}
                      />
                    </div>
                    <div className="form-cell">
                      <label>Department</label>
                      <select
                        className="form-select"
                        // defaultValue={'default'}
                        onChange={(e) => {
                          this.handleChange(e, 'postOrganisationUsers')
                          this.handleJobRoleFilter(e.target.value)
                        }}
                        value={this.state.postOrganisationUsers.department}
                        name="department"
                      >
                        <option value="">-</option>
                        {this.state.jobRolesDepartments.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-cell">
                      <label>Job Role</label>
                      <select
                        className="form-select"
                        onChange={(e) =>
                          this.handleChange(e, 'postOrganisationUsers')
                        }
                        name="jobRole"
                        value={this.state.postOrganisationUsers.jobRole}
                      >
                        <option value="">-</option>
                        {!isEmpty(jobRoles) &&
                          jobRoles.map((orgJobRole, i) => (
                            <option key={i} value={orgJobRole.id}>
                              {' '}
                              {orgJobRole.title}{' '}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-cell">
                      <label>
                        System Role
                        <span className="form-mandatory">*</span>
                      </label>
                      <select
                        className="form-select"
                        value={this.state.postOrganisationUsers.role}
                        onChange={(e) =>
                          this.handleChange(e, 'postOrganisationUsers')
                        }
                        name="role"
                      >
                        <option value="">-</option>
                        <option value="1">Admin</option>
                        <option value="2">Edit</option>
                        <option value="3">View</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-cell">
                      <label>Other Permissions </label>
                      <div className="other-permissions-container">
                        {/*<Select
                          mode="multiple"
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Please select"
                          defaultValue={
                            this.state.selectedDepartmentPermission.departments
                          }
                          onChange={this.handleOtherPermissionsChange}
                        >
                          {this.state.jobRolesDepartments.map(item => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </Select>*/}

                        <select
                          multiple
                          value={this.state.userPermissions}
                          onChange={(e) => {
                            this.handleOtherPermissionsChange(e)
                          }}
                          style={{ height: 100 }}
                        >
                          <option value="">-</option>
                          {this.state.jobRolesDepartments.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                      <small id="emailHelp" class="form-text text-muted">
                        The “other permissions” is a view-only role where a user
                        will only view the assigned department’s objectives and
                        risks.
                        <br /> To multi-select departments, please hold the CTRL
                        button while you click on the department
                      </small>
                    </div>

                    {/* <div className="form-cell">
                      <label>System Role (Each Permission)</label>
                      <div className="other-permissions-container">
                        <select
                          defaultValue=""
                          value={
                            this.state?.userPermissions?.length > 0
                              ? 'view'
                              : ''
                          }
                          onChange={(e) => this.handlePermissions(e)}
                        >
                          <option value="">-</option>
                          <option value="view">View</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary add"
                    aria-label="Close"
                  >
                    {this.state.loader ? (
                      <Spinner animation="grow" variant="primary" />
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}
