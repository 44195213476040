import React from 'react'
import { Pie } from '@ant-design/plots'

const AuditReport = () => {
  const data = [
    {
      type: 'Risk Module',
      value: 20,
    },
    {
      type: 'Objectives Module',
      value: 30,
    },
    {
      type: 'Report Module',
      value: 60,
    },
  ]
  const config = {
    appendPadding: 10,

    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'inner',
    },
    style: {
      height: '300px',
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  }

  return (
    <div className="risk-setup-container ">
      <div className="auditReport">
        <span className="auditHeader m-0">
          <h2 className="text-center">Monthly Audit Trail</h2>
        </span>
        <div className="row p-4">
          <div className="col-12 col-md-6 pt-3">
            <h4>Activity Log (Risk Module)</h4>
            <div className="riskModule">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Description</th>
                    <th scope="col">Action</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>test@gmail.com</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <td>test@gmail.com</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <td>test@gmail.com</td>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                    <td>@mdo</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 col-md-6 pt-3">
            <h4>Activity Log (Report Module)</h4>
            <div className="reportModule">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Description</th>
                    <th scope="col">Action</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>test@gmail.com</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <td>test@gmail.com</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <td>test@gmail.com</td>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                    <td>@mdo</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 col-md-6 pt-3">
            <h4>Activity Log (Objectives Module)</h4>
            <div className="objectivesModule">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Description</th>
                    <th scope="col">Action</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>test@gmail.com</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <td>test@gmail.com</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <td>test@gmail.com</td>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                    <td>@mdo</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 col-md-6 pt-3 text-center">
            <h4>Monthly Activity Analytics of all Modules</h4>
            <div className="activityAnalytics">
              <Pie {...config} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AuditReport
