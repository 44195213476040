import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Table, Button } from 'react-bootstrap'
import axios from 'axios'
import '../../style/container/risk-tables.scss'
import Swal from 'sweetalert2'
import Auth from '../../lib/Auth.js'
import moment from 'moment'
import { accessLevels } from '../../lib/AccessLevels'
import { InfoToolTip } from '../common/InfoTooltip.jsx'
import Spinner from 'react-bootstrap/Spinner'
import { isEmpty } from 'lodash'
import { uncertaintyType } from '../../common/uncertaintyType'
import { Popconfirm } from 'antd'

export default function MyRisksTable({
  riskFormHandler,
  tableProps = [],
  department,
  description,
  owner,
  orgId,
  riskEditFormHandler,
  tableIsOpen,
  tableType,
}) {
  const [tableData, setTableData] = useState([])
  const [orgID, setOrgID] = useState(0)
  const [users, setUsers] = useState([])
  const [treatmentCount, setTreatmentCount] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRows] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  var outputHighestScore = 0
  // let counter = 0
  // console.log('TABLE TYPE -->', tableType)
  useEffect(() => {
    fetchData()
    getUsers()
    // getRiskIds()
  }, [])
  useEffect(() => {
    fetchData()
    // getRiskIds()
  }, [tableType])

  useEffect(() => {
    if (tableIsOpen == true) {
      fetchData()
    }
  }, [tableIsOpen])
  const [loader, setLoader] = useState(false)

  const getUsers = () => {
    setLoader(true)
    axios
      .get(BASE_URL + `/organisations/${orgId}/users`, {
        params: {
          department: 0,
          team: 0,
          jobRole: 0,
          status: 0,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setUsers(res.data)
          setLoader(false)
        }
        setLoader(false)
      })
      .catch((error) => setLoader(false))
  }

  const deleteButtonClicked = (id) => {
    axios
      .delete(BASE_URL + `/organisations/${orgId}/risks/${id}`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Row deleted successfully',
            icon: 'success',
          })
          fetchData()
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `Cannot delete risk with id ${id}`,
          text: `${err}`,
          icon: 'warning',
          timer: 5000,
        })
      })
  }

  const deleteRisk = (id) => {
    axios
      .put(
        BASE_URL + `/organisations/${orgId}/risks/${id}/delete`,
        {
          deleted: true,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Row deleted successfully',
            icon: 'success',
          })
          fetchData()
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `Cannot delete risk with id ${id}`,
          text: `${err}`,
          icon: 'warning',
          timer: 5000,
        })
      })
  }

  const editRisk = (riskItem) => {
    riskEditFormHandler(riskItem)
  }

  const getRiskScore = (data) => {
    var highestValue = null

    var assessmentArray = []

    data.map((item) => {
      if (item.riskAssessmentType.name == 'NET') {
        assessmentArray = [...assessmentArray, item]
      }
    })

    if (assessmentArray.length !== 0) {
      highestValue = setScoreValues(assessmentArray)
    } else {
      highestValue = { score: 0, colour: '#fff' }
    }

    return highestValue
  }

  const setScoreValues = (array) => {
    var highestValue = { score: 0, colour: '#fff' }
    array.forEach((item) => {
      if (
        item.probabilityImpact.riskImpact.score *
          item.probabilityImpact.riskProbability.multiplier >
        highestValue.score
      ) {
        highestValue = {
          score:
            item.probabilityImpact.riskImpact.score *
            item.probabilityImpact.riskProbability.multiplier,
          colour: `#${item.probabilityImpact.colour}`,
        }
      } else {
        highestValue = highestValue
      }
    })
    return highestValue
  }

  const getTotalPages = () => {
    var chunks = [],
      i = 0,
      n = tableData.length
    while (i < n) {
      chunks.push(tableData.slice(i, (i += rowsPerPage)))
    }
    return chunks.length
  }

  useEffect(() => {
    setTotalPages(getTotalPages())
  }, [tableData])

  const getPageRows = () => {
    var chunks = [],
      i = 0,
      n = tableData.length
    while (i < n) {
      chunks.push(tableData.slice(i, (i += rowsPerPage)))
    }

    return chunks[page] || tableData
  }

  const renderTableData = () => {
    return getPageRows().map((item, index) => {
      // console.log('NN ITEM ---->', item)
      return (
        <tr key={item.id}>
          <td>{item.ref}</td>
          <td>{item.eventDescription}</td>
          <td>{item.eventConsequences}</td>
          <td>
            {!isEmpty(item.impactType.name) &&
              uncertaintyType(item.impactType.name)}{' '}
          </td>
          <td>{item.riskType.name}</td>

          <td
            style={{
              backgroundColor: `${item.tableHighestColorScore.colour}`,
            }}
          >
            {item.tableHighestColorScore.score}
          </td>
          <td>
            {(
              ((item.netEmv.minValue +
                item.netEmv.maxValue +
                item.netEmv.likelyValue) /
                3) *
              (item.netEmv.probability / 100)
            ).toFixed(2)}
          </td>
          <td>{item?.riskTreatments?.length}</td>
          <td>
            {moment(
              `${item.reviewDate.day}/${item.reviewDate.month}/
                        ${item.reviewDate.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td>
            {Auth.getRole() !== 'View' ? (
              <span className="buttons-container">
                {tableType !== 'deletedRisk' && (
                  <button onClick={() => editRisk(item)}>
                    <img src="../../assets/Edit_Icon.svg" alt="edit" />
                  </button>
                )}
                {tableType === 'deletedRisk' ? (
                  <Popconfirm
                    title="Are you sure to delete this task?"
                    onConfirm={() => deleteButtonClicked(item.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button>
                      <img src="../../assets/Delete_Icon.svg" alt="Delete" />
                    </button>
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Are you sure to delete this task?"
                    onConfirm={() => deleteRisk(item.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button>
                      <img src="../../assets/Delete_Icon.svg" alt="Delete" />
                    </button>
                  </Popconfirm>
                )}
              </span>
            ) : (
              ''
            )}
          </td>
        </tr>
      )
    })
  }

  const params = {
    owner: Auth.getLoggedUserId(),
  }
  //const currentUser = Auth.getPayload()

  // if (currentUser.Role !== accessLevels.admin) {
  //     params.owner = currentUser.user
  // }
  // const fetchData = () => {
  //     let thisRiskIds = []

  //     axios
  //         .get(BASE_URL + `/organisations/${orgId}/risks`, { params })
  //         .then((res) => {
  //             if (res.status === 200) {
  //                 const urls = res.data.map((item) =>
  //                     axios.get(
  //                         BASE_URL + `/organisations/${orgId}/riskTreatments`,
  //                         {
  //                             params: {
  //                                 risk: item.id,
  //                             },
  //                         }
  //                     )
  //                 )

  //                 let data = res.data
  //                 thisRiskIds = res.data
  //                 axios
  //                     .all([...urls])
  //                     .then((responses) => {
  //                         responses.forEach(
  //                             (item, index) =>
  //                                 (data[index].riskTreatments = item.data)
  //                         )
  //                     })
  //                     .then(() => {
  //                         const assessmentUrls = thisRiskIds.map((item) =>
  //                             axios.get(
  //                                 BASE_URL +
  //                                     `/organisations/${orgId}/risks/${item.id}/assessments`
  //                             )
  //                         )
  //                         axios.all([...assessmentUrls]).then((responses) => {
  //                             responses.forEach((item, index) => {
  //                                 data[
  //                                     index
  //                                 ].tableHighestColorScore = getRiskScore(
  //                                     item.data
  //                                 )
  //                             })
  //                             setTableData(
  //                                 data.sort((a, b) => {
  //                                     return b.ref - a.ref
  //                                 })
  //                             )
  //                         })
  //                     })
  //             }
  //         })
  // }

  const fetchData = () => {
    setLoader(true)
    let thisRiskIds = []
    axios
      .get(BASE_URL + `/organisations/${orgId}/risks`, {
        params,
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // setTableData(res.data)
          const urls = res.data.map((item) =>
            axios.get(BASE_URL + `/organisations/${orgId}/riskTreatments`, {
              params: {
                risk: item.id,
              },
              headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
              },
            })
          )

          let data = res.data
          thisRiskIds = res.data
          axios
            .all([...urls])
            .then((responses) => {
              responses.forEach(
                (item, index) => (data[index].riskTreatments = item.data)
              )
            })
            .then(() => {
              const assessmentUrls = thisRiskIds.map((item) =>
                axios.get(
                  BASE_URL +
                    `/organisations/${orgId}/risks/${item.id}/assessments`,
                  {
                    headers: {
                      Authorization: `Bearer ${Auth.getToken()}`,
                    },
                  }
                )
              )
              axios.all([...assessmentUrls]).then((responses) => {
                responses.forEach((item, index) => {
                  data[index].tableHighestColorScore = getRiskScore(item.data)
                })
                // console.log('RISK --->', data)
                let dataFilter =
                  data &&
                  data.reduce(
                    (final, data) => {
                      return {
                        ...final,
                        risk:
                          data.completed && !data.deleted
                            ? [...final.risk, data]
                            : final.risk,
                        draft:
                          !data.completed && !data.deleted
                            ? [...final.draft, data]
                            : final.draft,
                        deleted: data.deleted
                          ? [...final.deleted, data]
                          : final.deleted,
                      }
                    },
                    {
                      risk: [],
                      draft: [],
                      deleted: [],
                    }
                  )
                // console.log('dataFilter -->', dataFilter)
                if (tableType === 'risk') {
                  setTableData(
                    dataFilter?.risk &&
                      dataFilter?.risk.sort((a, b) => {
                        return b.ref - a.ref
                      })
                  )
                } else if (tableType === 'draftRisk') {
                  setTableData(
                    dataFilter?.draft &&
                      dataFilter?.draft.sort((a, b) => {
                        return b.ref - a.ref
                      })
                  )
                } else if (tableType === 'deletedRisk') {
                  setTableData(
                    dataFilter?.deleted &&
                      dataFilter?.deleted.sort((a, b) => {
                        return b.ref - a.ref
                      })
                  )
                }
              })
              setLoader(false)
            })
            .catch((error) => setLoader(false))
        }
      })
      .catch((error) => setLoader(false))
  }

  // console.log('Auth.getRole() ===>', Auth.getRole())
  // return (
  if (loader) {
    return (
      <div className="d-flex justify-content-center">
        {loader && <Spinner animation="grow" />}
      </div>
    )
  } else {
    if (!tableData) {
      return (
        <React.Fragment>
          (
          <div className="px-5 my-5 text-center">
            <h1>You do not have any Risks setup</h1>
            {tableType === 'risk' && (
              <button onClick={riskFormHandler} className="addRiskButton">
                Add your first risk
              </button>
            )}
          </div>
          )
        </React.Fragment>
      )
    } else {
      return (
        <div className="px-5 my-5 table-main">
          <h4 style={{ color: '#33466B' }}>
            {tableType === 'risk' && (
              <InfoToolTip title={'My Risk'} text={'My Risk'}></InfoToolTip>
            )}
            {tableType === 'draftRisk' && (
              <InfoToolTip
                title={'Draft Risk'}
                text={'Draft Risk'}
              ></InfoToolTip>
            )}
            {tableType === 'deletedRisk' && (
              <InfoToolTip
                title={'Deleted Risk'}
                text={'Deleted Risk'}
              ></InfoToolTip>
            )}
          </h4>
          {Auth.getRole() !== 'View' ? (
            <React.Fragment>
              {tableType === 'risk' && (
                <p>
                  Add New
                  <i
                    className="fa fa-plus"
                    style={{ color: '#4CAF50' }}
                    onClick={riskFormHandler}
                  />
                </p>
              )}
            </React.Fragment>
          ) : (
            ''
          )}
          <div
            className="table-container"
            style={{ width: '100%', overflowX: 'scroll' }}
          >
            <table className="risks-table strategic-indicators-table">
              <thead>
                <tr>
                  <th
                    style={{
                      width: '5%',
                    }}
                  >
                    Ref
                  </th>
                  <th style={{ width: '15%' }}>Risk Event</th>
                  <th style={{ width: '10%' }}>Risk Impact</th>
                  <th style={{ width: '10%' }}>Uncertainty</th>
                  <th style={{ width: '10%' }}>Risk Type</th>
                  <th style={{ width: '5%' }}>Net Score</th>
                  <th style={{ width: '5%' }}>Net Value</th>
                  <th title="Number of Treatments" style={{ width: '5%' }}>
                    No. of Ts
                  </th>
                  <th style={{ width: '5%' }}>Review Date</th>
                  <th style={{ width: '10%' }}> </th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
              {/* <tbody>{tableProps.map(renderNewRow)}</tbody> */}
            </table>
          </div>
          <div className="text-right mt-3">
            <span style={{ fontSize: '14px' }}>
              Showing page {page + 1} of {totalPages}
            </span>
            <button
              className="previous-page btn btn-primary mx-2"
              disabled={page == 0}
              onClick={() => setPage((page) => page - 1)}
            >
              Prev
            </button>
            <button
              disabled={page === totalPages - 1}
              className="next-page btn btn-primary"
              onClick={() => setPage((page) => page + 1)}
            >
              Next
            </button>
          </div>
        </div>
      )
    }
  }
}
