import React, { Component } from 'react'
import axios from 'axios'
import Auth from '../../lib/Auth.js'

export default class ImpactDescriptionTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      positiveImpacts: [],
      negativeImpacts: [],
      impactDimensions: [],
      positiveTableData: [],
      negativeTableData: [],
      loading: true,
      orgId: 0,
    }
  }

  componentWillMount() {
    let token = Auth.getToken()
    if (!token) {
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    this.setState({
      orgId: JSON.parse(window.atob(base64)).Org,
    })
  }

  // componentWillReceiveProps(props) {
  //     this.setState({ orgId: props.orgId })
  // }

  componentDidMount() {
    this.fetchData()
  }

  renderPositiveImpactHeader() {
    let columns = []
    columns.push(<th>Impact Dimensions </th>)
    this.state.positiveImpacts.map((item, index) => {
      columns.push(
        <th
          style={{
            width: `calc(70% / ${this.state.positiveImpacts.length})`,
          }}
          key={item.id}
        >
          {item.score}
        </th>
      )
    })

    return <tr className="bg-table-header">{columns}</tr>
  }

  renderPositiveImpactTitles() {
    let columns = []
    columns.push(<th className="bg-white"> </th>)
    this.state.positiveImpacts.map((item, index) => {
      columns.push(
        <th
          style={{
            width: `calc(70% / ${this.state.positiveImpacts.length})`,
          }}
          key={item.id}
        >
          {item.name}
        </th>
      )
    })

    return <tr className="bg-table-header">{columns}</tr>
  }

  renderNegativeImpactHeader() {
    let columns = []
    columns.push(<th>Impact Dimensions </th>)
    this.state.negativeImpacts.map((item, index) => {
      columns.push(
        <th
          style={{
            width: `calc(70% / ${this.state.negativeImpacts.length})`,
          }}
          key={item.id}
        >
          {item.score}
        </th>
      )
    })

    return <tr className="bg-table-header">{columns}</tr>
  }

  renderNegativeImpactTitles() {
    let columns = []
    columns.push(<th className="bg-white"> </th>)
    this.state.negativeImpacts.map((item, index) => {
      columns.push(
        <th
          style={{
            width: `calc(70% / ${this.state.negativeImpacts.length})`,
          }}
          key={item.id}
        >
          {item.name}
        </th>
      )
    })

    return <tr className="bg-table-header">{columns}</tr>
  }

  handlePositiveImpactDataChange(dimension_id, risk_id, event) {
    event.persist()
    const description = event.target.value
    let data = [...this.state.positiveTableData]
    let newPositiveTableData = [...data]

    if (data.length == 0) {
      newPositiveTableData.push({
        impactDimension: dimension_id,
        riskImpact: risk_id,
        description: description,
      })
    } else {
      let tableObject = data.filter(
        item =>
          item.impactDimension === dimension_id && item.riskImpact === risk_id
      )[0]
      const exists = tableObject == undefined ? false : true
      if (exists) {
        tableObject.description = description
        newPositiveTableData = data.map(item => {
          if (
            item.riskImpact == risk_id &&
            item.impactDimensions == dimension_id
          ) {
            return tableObject
          } else {
            return item
          }
        })
      } else {
        tableObject = {
          impactDimension: dimension_id,
          riskImpact: risk_id,
          description: description,
        }
        newPositiveTableData.push(tableObject)
      }
    }

    this.setState({ positiveTableData: [...newPositiveTableData] })
  }

  handleNegativeImpactDataChange(dimension_id, risk_id, event) {
    event.persist()
    const description = event.target.value
    let data = [...this.state.negativeTableData]
    let newNegativeTableData = [...data]

    if (data.length == 0) {
      newNegativeTableData.push({
        impactDimension: dimension_id,
        riskImpact: risk_id,
        description: description,
      })
    } else {
      let tableObject = data.filter(
        item =>
          item.impactDimension === dimension_id && item.riskImpact === risk_id
      )[0]
      const exists = tableObject == undefined ? false : true
      if (exists) {
        tableObject.description = description
        newNegativeTableData = data.map(item => {
          if (
            item.riskImpact == risk_id &&
            item.impactDimensions == dimension_id
          ) {
            return tableObject
          } else {
            return item
          }
        })
      } else {
        tableObject = {
          impactDimension: dimension_id,
          riskImpact: risk_id,
          description: description,
        }
        newNegativeTableData.push(tableObject)
      }
    }

    this.setState({ negativeTableData: [...newNegativeTableData] })
  }

  savePositiveDataTable() {
    this.setState({ loading: true }, () => {
      axios
        .put(
          BASE_URL + `/organisations/${this.state.orgId}/impactDescriptions`,
          {
            riskDomainImpactMessages: [...this.state.positiveTableData],
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }

  saveNegativeDataTable() {
    this.setState({ loading: true }, () => {
      axios
        .put(
          BASE_URL + `/organisations/${this.state.orgId}/impactDescriptions`,
          {
            riskDomainImpactMessages: [...this.state.negativeTableData],
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }

  getData(dimension_id, risk_id, type) {
    if (type == 'positive') {
      const object = this.state.positiveTableData.filter(
        item =>
          item.impactDimension === dimension_id && item.riskImpact === risk_id
      )
      if (object[0] == undefined) {
        return ''
      } else {
        return object[0].description
      }
    } else {
      const object = this.state.negativeTableData.filter(
        item =>
          item.impactDimension === dimension_id && item.riskImpact === risk_id
      )
      if (object[0] == undefined) {
        return ''
      } else {
        return object[0].description
      }
    }
  }

  renderPositiveTableData() {
    return this.state.impactDimensions.map((impact, index) => {
      let columns = []
      columns.push(<td>{impact.name}</td>)
      this.state.positiveImpacts.forEach((item, index) =>
        columns.push(
          <td>
            <textarea
              onChange={e =>
                this.handlePositiveImpactDataChange(impact.id, item.id, e)
              }
              value={this.getData(impact.id, item.id, 'positive')}
              placeholder="Type here..."
            />
          </td>
        )
      )
      return <tr>{columns}</tr>
    })
  }

  renderNegativeTableData() {
    return this.state.impactDimensions.map((impact, index) => {
      let columns = []
      columns.push(<td>{impact.name}</td>)
      this.state.negativeImpacts.forEach(item =>
        columns.push(
          <td>
            <textarea
              onChange={e =>
                this.handleNegativeImpactDataChange(impact.id, item.id, e)
              }
              value={this.getData(impact.id, item.id, 'negative')}
              placeholder="Type here..."
            />
          </td>
        )
      )
      return <tr>{columns}</tr>
    })
  }

  async fetchData() {
    try {
      let positiveImpacts = []
      let negativeImpacts = []
      let impactDimensions = []
      let positiveImpactDescriptionData = []
      let negativeImpactDescriptionData = []
      let impactTypes = await axios.get(
        BASE_URL + `/organisations/${this.state.orgId}/impacts`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      if (impactTypes.status === 200) {
        positiveImpacts = impactTypes.data
          .filter(item => item.impactType.name === 'POSITIVE')
          .sort((a, b) => b.score - a.score)
        negativeImpacts = impactTypes.data
          .filter(item => item.impactType.name === 'NEGATIVE')
          .sort((a, b) => b.score - a.score)
      }

      let dimensions = await axios.get(
        BASE_URL + `/organisations/${this.state.orgId}/impactDimensions`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      if (dimensions.status === 200) {
        impactDimensions = dimensions.data
      }

      let descriptionsData = await axios.get(
        BASE_URL + `/organisations/${this.state.orgId}/impactDescriptions`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )

      if (descriptionsData.status === 200) {
        descriptionsData.data.forEach(element => {
          if (element.riskImpact.impactType.name === 'POSITIVE') {
            positiveImpactDescriptionData.push({
              riskImpact: element.riskImpact.id,
              impactDimension: element.riskDomain.id,
              description: element.description,
            })
          } else {
            negativeImpactDescriptionData.push({
              riskImpact: element.riskImpact.id,
              impactDimension: element.riskDomain.id,
              description: element.description,
            })
          }
        })
      }

      this.setState({
        negativeImpacts,
        positiveImpacts,
        impactDimensions,
        positiveTableData: positiveImpactDescriptionData,
        negativeTableData: negativeImpactDescriptionData,
        loading: false,
      })
    } catch (err) {
      throw err
    }
  }

  render() {
    return (
      <div className="impact-description-container">
        <div className="table-container px-5 my-5">
          <h4>Positive Impact Description</h4>
          <table className="mt-4 mb-0" style={{ width: '100%' }}>
            <thead>{this.renderPositiveImpactTitles()}</thead>
          </table>
          <table className="mt-2" style={{ width: '100%' }}>
            <thead>{this.renderPositiveImpactHeader()}</thead>
            <tbody>
              {this.state.loading ? (
                <tr className="text-center">
                  <td colspan={this.state.positiveImpacts.length + 3}>
                    Loading...
                  </td>{' '}
                </tr>
              ) : (
                this.renderPositiveTableData()
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="w-100 mb-4 px-5 text-right">
                    <button onClick={() => this.savePositiveDataTable()}>
                        Save
                    </button>
                </div> */}
        <div className="table-container px-5 my-5">
          <h4>Negative Impact Description</h4>
          <table className="mt-4 mb-0" style={{ width: '100%' }}>
            <thead>{this.renderNegativeImpactTitles()}</thead>
          </table>
          <table className="mt-2" style={{ width: '100%' }}>
            <thead>{this.renderNegativeImpactHeader()}</thead>
            <tbody>
              {this.state.loading ? (
                <tr className="text-center">
                  <td colspan={this.state.negativeImpacts.length + 3}>
                    Loading...
                  </td>{' '}
                </tr>
              ) : (
                this.renderNegativeTableData()
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="w-100 mb-4 px-5 text-right">
                    <button onClick={() => this.saveNegativeDataTable()}>
                        Save
                    </button>
                </div> */}
      </div>
    )
  }
}
