import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import camelCase from 'lodash/fp/camelCase'

export default function EditTableItemModal(props) {
  const { riskCategory } = props
  const [show, setShow] = useState(true)
  const [headings, setHeadings] = useState([])
  const [fields, setFields] = useState({})
  const [tab, setTab] = useState(0)
  const handleClose = () => props.dismissModal()

  useEffect(() => {
    setHeadings(props.heading)
    setTab(props.tabGroup)
    setShow(props.showModal)

    let data = { ...props.item }
    // delete data[id]
    setFields(data)
  }, [props])

  const handleFieldsChange = (value, field_key) => {
    const newFields = { ...fields }
    newFields[field_key] = value
    setFields(newFields)
  }

  const handleSave = () => {
    let isEmpty = false
    for (const [key, value] of Object.entries(fields)) {
      if (
        headings.includes(
          headings.includes(key.charAt(0).toUpperCase() + key.slice(1))
        ) &&
        String(value).trim().length === 0
      )
        isEmpty = true
    }
    if (isEmpty) {
    } else {
      props.editField(fields)
    }
  }

  const renderFields = () => {
    return headings.map(item => {
      /*const field_key = item
        .toLowerCase()
        .split(' ')
        .join('_')*/
      const field_key = camelCase(item)

      return (
        <div className="form-group">
          <label>{item}</label> <br />
          {item.toLowerCase() === 'description' ? (
            <textarea
              value={fields[field_key]}
              onChange={e => handleFieldsChange(e.target.value, field_key)}
            />
          ) : item.toLowerCase() === 'risk category' ? (
            <select
              value={fields[field_key]}
              onChange={e => handleFieldsChange(e.target.value, field_key)}
            >
              <option value=""></option>
              {riskCategory &&
                riskCategory.map((item, key) => (
                  <option key={key} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          ) : (
            <input
              type="text"
              value={fields[field_key]}
              onChange={e => handleFieldsChange(e.target.value, field_key)}
            />
          )}
        </div>
      )
    })
  }

  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderFields()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
