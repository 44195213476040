/* eslint-disable prettier/prettier */
import React, { useContext } from 'react'
import Table from './RiskTreatmentReport.TableIndividual.jsx'
import userContext from '../../../../context/userContext'




export default function RiskReport({ data }) {

    const [totals, setTotals] = React.useState(null)
    const [overall, setOverall] = React.useState()

    const { sessionDetails } = useContext(userContext)

    const currency = sessionDetails?.user?.organisation?.currencyCode
    console.log(data)


    React.useEffect(() => {
        const riskTotals = []
        for (const risk in data.data) {
            const risksArray = Object.values(data.data[`${risk}`]).map(item => item.treatments).flat()
            console.log(risksArray)
            const total = risksArray.reduce((acc, item) => {
                acc.estimatedCost += item.estimatedCost;
                acc.actualCost += item.actualCost;
                return acc;
            }, { estimatedCost: 0, actualCost: 0 })

            riskTotals.push(total)
        }

        const overAll = riskTotals.reduce((acc, item) => {
            acc.overallEstimatedCost += item.estimatedCost;
            acc.overallActualCost += item.actualCost;
            return acc;
        }, { overallEstimatedCost: 0, overallActualCost: 0 })

        setTotals(riskTotals)
        setOverall(overAll)

    }, [data])




    return (
        <>
            <div
                className="w-100">
                {Object.keys(data.data).map((key, index) => {
                    if (Object.values(data.data[`${key}`]).length == 0) {
                        return ''

                    }
                    else {
                        return <>

                            <Table risks={data.data[`${key}`]} totals={totals && totals[index]} />
                        </>
                    }
                })}

            </div>
            <hr />
            <div className="w-100">

                <div className="total-count-row-treatment">
                    <div className="labels-div">
                        <p>Overall Estimated Cost:</p>
                        <p>Overall Actual Cost:</p>

                    </div>
                    <div className="values-div">
                        <p>&nbsp;{currency} {overall?.overallEstimatedCost}</p>
                        <p>&nbsp;{currency} {overall?.overallActualCost} </p>
                    </div>
                </div>
            </div>
        </>
    )
}



