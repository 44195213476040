import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import currencyList from '../../../common/countaryCurrencyList.json'
import isEmpty from 'lodash/fp/isEmpty'
import { Popconfirm } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

const OrganisationSetup = ({
  updateSystemAdmin,
  addressMessage,
  handleChangeRegions,
  handleChangeCountry,
  handleChange,
  handleChangeValues,
  countries,
  organisationValuesData,
  addValues,
  organisationValues,
  values,
  viewValue,
  updateValue,
  deleteValue,
  viewRegion,
  organisationData,
  organisationRegionsMessage,
  viewOrganisation,
  updateOrganisation,
  addRegion,
  postOrganisation,
  regionData,
  region,
  postOrganisationAddress,
  updateRegion,
  deleteRegion,
  industries,
  users,
  getBase64,
  handleCancel,
  handlePreview,
  handleChangeFileImage,
  previewVisible,
  previewImage,
  fileList,
  previewTitle,
}) => {
  console.log(
    'ORGA -->',
    organisationData,
    postOrganisation,
    postOrganisationAddress
  )
  return (
    <div className="organisation-setup layout">
      <div className="body-container">
        <div className="account-form-input">
          <hr className="global-hr" />
          <div className="form-header">
            <h2>Account Maintenance</h2>
            <button
              onClick={viewOrganisation}
              data-toggle="modal"
              data-target="#editOrganisationModal"
              type="button"
              className="btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src="../../assets/Edit_Icon.svg" alt="edit" />
            </button>
          </div>

          {/* Edit Organisation modal */}

          <div
            className="modal fade region"
            id="editOrganisationModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="editOrganisationModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <form onSubmit={updateOrganisation}>
                  <div className="modal-header">
                    <h2 className="modal-title" id="editOrganisationModal">
                      Edit Organisation
                    </h2>
                  </div>

                  <div className="modal-body">
                    <div className="form-row">
                      <div className="form-cell">
                        <label>Name of Organisation</label>
                        <input
                          name="name"
                          className="input-text"
                          onChange={(e) => handleChange(e, 'postOrganisation')}
                          value={postOrganisation.name}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>Address Line 1</label>
                        <input
                          name="addressLine1"
                          className="input-text"
                          onChange={(e) =>
                            handleChange(e, 'postOrganisationAddress')
                          }
                          value={postOrganisationAddress.addressLine1}
                        />
                      </div>
                      <div className="form-cell">
                        <label>Address Line 2</label>
                        <input
                          name="addressLine2"
                          className="input-text"
                          onChange={(e) =>
                            handleChange(e, 'postOrganisationAddress')
                          }
                          value={postOrganisationAddress.addressLine2}
                        />
                      </div>
                      <div className="form-cell">
                        <label>Address Line 3</label>
                        <input
                          name="addressLine3"
                          className="input-text"
                          onChange={(e) =>
                            handleChange(e, 'postOrganisationAddress')
                          }
                          value={postOrganisationAddress.addressLine3}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>Post Code</label>
                        <input
                          name="postCode"
                          className="input-text"
                          onChange={(e) =>
                            handleChange(e, 'postOrganisationAddress')
                          }
                          value={postOrganisationAddress.postCode}
                        />
                      </div>
                      <div className="form-cell">
                        <label>State</label>
                        <input
                          name="state"
                          className="input-text"
                          onChange={(e) =>
                            handleChange(e, 'postOrganisationAddress')
                          }
                          value={postOrganisationAddress.state}
                        />
                      </div>
                      <div className="form-cell">
                        <label>Country</label>
                        <select
                          defaultValue={'default'}
                          value={postOrganisationAddress.country.code}
                          onChange={(e) =>
                            handleChange(e, 'postOrganisationAddress')
                          }
                          name="country"
                        >
                          <option value="default" disabled="disabled"></option>
                          {countries.map((country, i) => (
                            <option key={i} value={country.code}>
                              {' '}
                              {country.longName}{' '}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>Currency</label>
                        <select
                          value={postOrganisation.currencyCode}
                          onChange={(e) => handleChange(e, 'postOrganisation')}
                          name="currencyCode"
                        >
                          <option value="" disabled="disabled"></option>
                          {!isEmpty(currencyList) &&
                            Object.keys(currencyList).map((shotCode, key) => {
                              return (
                                <option
                                  key={key}
                                  value={currencyList[shotCode].currency}
                                >
                                  {`${currencyList[shotCode].currency} (${currencyList[shotCode].symbol}) `}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="form-cell">
                        <label>Industry</label>
                        <select
                          className="form-select"
                          value={postOrganisation.industry.id}
                          name="industry"
                          onChange={(e) => handleChange(e, 'postOrganisation')}
                        >
                          <option value="default" disabled="disabled"></option>
                          {industries.map((industry, i) => (
                            <option key={i} value={industry.id}>
                              {' '}
                              {industry.name}{' '}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>Mission</label>
                        <textarea
                          rows="4"
                          onChange={(e) => handleChange(e, 'postOrganisation')}
                          name="mission"
                          value={postOrganisation.mission}
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>Vision</label>
                        <textarea
                          rows="4"
                          onChange={(e) => handleChange(e, 'postOrganisation')}
                          name="vision"
                          value={postOrganisation.vision}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary add"
                      aria-label="Close"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/*Organisation data*/}

          <div className="form-row">
            <div className="form-cell">
              <label>Name of Organisation</label>
              <input
                name="name"
                className="input-text"
                value={organisationData.name}
                disabled
              />
            </div>
            <div className="form-cell">
              <label>Address Line 1</label>
              <input
                name="addressLine1"
                className="input-text"
                value={organisationData.contactAddress.addressLine1}
                disabled
              />
            </div>
            <div className="form-cell">
              <label>Address Line 2</label>
              <input
                name="addressLine2"
                className="input-text"
                value={organisationData.contactAddress.addressLine2}
                disabled
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-cell">
              <label>Address Line 3</label>
              <input
                name="addressLine3"
                className="input-text"
                value={organisationData.contactAddress.addressLine3}
                disabled
              />
            </div>
            <div className="form-cell">
              <label>Post Code</label>
              <input
                name="postCode"
                className="input-text"
                value={organisationData.contactAddress.postcode}
                disabled
              />
            </div>
            <div className="form-cell">
              <label>State</label>
              <input
                name="state"
                className="input-text"
                value={organisationData.contactAddress.state}
                disabled
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-cell">
              <label>Country</label>
              <select
                className="form-select"
                name="country"
                value={organisationData.contactAddress.country.code}
                disabled
              >
                {countries.map((country, i) => (
                  <option key={i} value={country.code}>
                    {' '}
                    {country.longName}{' '}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-cell">
              <label>Currency</label>
              <select
                className="form-select"
                name="currencyCode"
                defaultValue={organisationData.currencyCode || ''}
                disabled
              >
                <option value="" disabled="disabled"></option>
                {!isEmpty(currencyList) &&
                  Object.keys(currencyList).map((shotCode, key) => {
                    return (
                      <option key={key} value={currencyList[shotCode].currency}>
                        {currencyList[shotCode].currency}
                      </option>
                    )
                  })}
              </select>
            </div>
            <div className="form-cell">
              <label>Industry</label>
              <select
                className="form-select"
                defaultValue={'default'}
                name="industry"
                disabled
              >
                <option>{organisationData?.industry?.name}</option>
              </select>
            </div>
            <div className="form-cell"></div>
          </div>

          {/*Add Mission & Vision */}

          <div className="form-row double">
            <div className="form-column">
              <label>Mission</label>
              <textarea
                disabled
                rows="4"
                value={organisationData.mission}
              ></textarea>
            </div>

            <div className="form-column">
              <label>Vision</label>
              <textarea
                disabled
                rows="4"
                value={organisationData.vision}
              ></textarea>
            </div>
          </div>

          {/*Add Region modal*/}

          <div className="form-row mt-5">
            <div className="form-cell">
              <h2> Regions</h2>
              <button
                type="button"
                className="icon add"
                data-toggle="modal"
                data-target="#regionModal"
              >
                <label className="add-button-text">Add New</label>
                <img src="../../assets/add.svg" alt="add" />
              </button>
              <div
                className="modal fade region"
                id="regionModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="regionModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <form onSubmit={addRegion}>
                      <div className="modal-header">
                        <h2 className="modal-title" id="regionModalLabel">
                          Add Region
                        </h2>
                      </div>

                      <div className="modal-body">
                        <div className="form-column-modal">
                          <div className="form-row">
                            <div className="form-cell">
                              <label>
                                Region Name
                                <span className="form-mandatory">*</span>
                              </label>
                              <input
                                onChange={(e) =>
                                  handleChange(e, 'organisationRegionsMessage')
                                }
                                name="name"
                                className="input-text"
                              />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-cell">
                              <label>
                                Contact
                                <span className="form-mandatory">*</span>
                              </label>
                              <select
                                defaultValue={'default'}
                                onChange={(e) =>
                                  handleChange(e, 'organisationRegionsMessage')
                                }
                                name="contactUser"
                              >
                                <option
                                  value="default"
                                  disabled="disabled"
                                ></option>
                                {users.map((user, i) => (
                                  <option key={i} value={user.id}>
                                    {' '}
                                    {user.firstName}{' '}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-cell">
                              <label>
                                Address Line 1
                                <span className="form-mandatory">*</span>
                              </label>
                              <input
                                onChange={(e) =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="addressLine1"
                                className="input-text"
                              />
                            </div>
                            <div className="form-cell">
                              <label>Address Line 2</label>
                              <input
                                onChange={(e) =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="addressLine2"
                                className="input-text"
                              />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-cell">
                              <label>Address Line 3</label>
                              <input
                                onChange={(e) =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="addressLine3"
                                className="input-text"
                              />
                            </div>
                            <div className="form-cell">
                              <label>State</label>
                              <input
                                onChange={(e) =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="state"
                                className="input-text"
                              />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-cell">
                              <label>Post Code</label>
                              <input
                                onChange={(e) =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="postCode"
                                className="input-text"
                              />
                            </div>
                            <div className="form-cell">
                              <label>
                                Country
                                <span className="form-mandatory">*</span>
                              </label>
                              <select
                                defaultValue={'default'}
                                onChange={(e) =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="country"
                              >
                                <option
                                  value="default"
                                  disabled="disabled"
                                ></option>
                                {countries.map((country, i) => (
                                  <option key={i} value={country.code}>
                                    {' '}
                                    {country.longName}{' '}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary add"
                          aria-label="Close"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Regions table*/}

          <Table size="sm" className="regions-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Region Name</th>
                <th>Contact Name</th>
                <th>Email</th>
                <th>Country</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {regionData.map((region, index) => (
                <tr key={index}>
                  <td></td>
                  <td>{region?.name}</td>
                  <td>
                    {region?.contactUser?.firstName} {region?.contactUser?.lastName}
                  </td>
                  <td>{region?.contactUser?.emailAddress}</td>
                  <td>{region?.regionAddress?.country?.longName}</td>
                  <td className="table-button-container">
                    <button
                      onClick={(e) => viewRegion(e, region.id)}
                      data-toggle="modal"
                      data-target="#editRegionModal"
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <img src="../../assets/Edit_Icon.svg" alt="edit" />
                    </button>
                    <Popconfirm
                      title="Are you sure to delete this task?"
                      onConfirm={(e) => deleteRegion(e, region.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src="../../assets/Delete_Icon.svg" alt="Delete" />
                      </button>
                    </Popconfirm>
                  </td>
                </tr>
              ))}
              {regionData.length < 1 ? (
                <td colSpan={6}>You have 0 Regions</td>
              ) : (
                ''
              )}
            </tbody>
          </Table>

          {/* Edit Regions table*/}

          <div
            className="modal fade region"
            id="editRegionModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="editRegionModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <form onSubmit={(e) => updateRegion(e, region.id)}>
                  <div className="modal-header">
                    <h2 className="modal-title" id="regionModalLabel">
                      Edit Region
                    </h2>
                  </div>
                  <div className="modal-body">
                    <div className="form-column-modal">
                      <div className="form-row">
                        <div className="form-cell">
                          <label>
                            Region Name
                            <span className="form-mandatory">*</span>
                          </label>
                          <input
                            onChange={(e) =>
                              handleChange(e, 'organisationRegionsMessage')
                            }
                            name="name"
                            className="input-text"
                            value={organisationRegionsMessage.name}
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-cell">
                          <label>
                            Contact
                            <span className="form-mandatory">*</span>
                          </label>
                          <select
                            defaultValue={'default'}
                            onChange={(e) =>
                              handleChange(e, 'organisationRegionsMessage')
                            }
                            name="contactUser"
                            value={organisationRegionsMessage.contactUser}
                          >
                            <option
                              value="default"
                              disabled="disabled"
                            ></option>
                            {users.map((user, i) => (
                              <option key={i} value={user.id}>
                                {user.firstName} {user.lastName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-cell">
                          <label>
                            Address Line 1
                            <span className="form-mandatory">*</span>
                          </label>
                          <input
                            onChange={(e) =>
                              handleChangeRegions(e, 'addressMessage')
                            }
                            name="addressLine1"
                            className="input-text"
                            value={
                              organisationRegionsMessage.addressMessage
                                .addressLine1
                            }
                          />
                        </div>
                        <div className="form-cell">
                          <label>Address Line 2</label>
                          <input
                            onChange={(e) =>
                              handleChangeRegions(e, 'addressMessage')
                            }
                            name="addressLine2"
                            className="input-text"
                            value={
                              organisationRegionsMessage.addressMessage
                                .addressLine2
                            }
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-cell">
                          <label>Address Line 3</label>
                          <input
                            onChange={(e) =>
                              handleChangeRegions(e, 'addressMessage')
                            }
                            name="addressLine3"
                            className="input-text"
                            value={
                              organisationRegionsMessage.addressMessage
                                .addressLine3
                            }
                          />
                        </div>
                        <div className="form-cell">
                          <label>State</label>
                          <input
                            onChange={(e) =>
                              handleChangeRegions(e, 'addressMessage')
                            }
                            name="state"
                            className="input-text"
                            value={
                              organisationRegionsMessage.addressMessage.state
                            }
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-cell">
                          <label>Post Code</label>
                          <input
                            onChange={(e) =>
                              handleChangeRegions(e, 'addressMessage')
                            }
                            name="postCode"
                            className="input-text"
                            value={
                              organisationRegionsMessage.addressMessage.postCode
                            }
                          />
                        </div>
                        <div className="form-cell">
                          <label>
                            Country
                            <span className="form-mandatory">*</span>
                          </label>
                          <select
                            defaultValue={'default'}
                            onChange={(e) =>
                              handleChangeRegions(e, 'addressMessage')
                            }
                            name="country"
                            value={
                              organisationRegionsMessage.addressMessage.country
                            }
                          >
                            <option
                              value="default"
                              disabled="disabled"
                            ></option>
                            {countries.map((country, i) => (
                              <option key={i} value={country.code}>
                                {' '}
                                {country.longName}{' '}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary add"
                      aria-label="Close"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/*Add Values*/}

          <div className="form-cell mt-5">
            <h2>Values</h2>
            <button
              type="button"
              className="icon add"
              data-toggle="modal"
              data-target="#valuesModal"
            >
              <label className="add-button-text">Add New</label>
              <img src="../../assets/add.svg" alt="add" />
            </button>
          </div>

          <div
            className="modal fade region"
            id="valuesModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="valuesModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title" id="valuesModalLabel">
                    Add Values
                  </h2>
                </div>

                <div className="modal-body">
                  <div className="form-column-modal">
                    <div className="form-row">
                      <div className="form-cell">
                        <label>
                          Ref
                          <span className="form-mandatory">*</span>
                        </label>
                        <input
                          onChange={(e) =>
                            handleChangeValues(e, 'organisationValues')
                          }
                          name="ref"
                          className="input-text"
                          value={organisationValues.ref}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>
                          Name
                          <span className="form-mandatory">*</span>
                        </label>
                        <input
                          onChange={(e) =>
                            handleChangeValues(e, 'organisationValues')
                          }
                          name="name"
                          className="input-text"
                          value={organisationValues.name}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>
                          Description
                          <span className="form-mandatory">*</span>
                        </label>
                        <textarea
                          rows="4"
                          onChange={(e) =>
                            handleChangeValues(e, 'organisationValues')
                          }
                          name="description"
                          value={organisationValues.description}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={addValues}
                    type="button"
                    disabled={
                      organisationValues.description === '' ||
                      organisationValues.name === ''
                        ? true
                        : false
                    }
                    className="btn btn-primary add"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*Values table*/}

          <Table size="sm">
            <thead>
              {organisationValuesData.length >= 1 ? (
                <tr>
                  <th>Ref</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              ) : (
                <tr></tr>
              )}
            </thead>
            <tbody>
              {organisationValuesData.map((orgValue, index) => (
                <tr key={index}>
                  <td>{orgValue.ref}</td>
                  <td>{orgValue.name}</td>
                  <td>{orgValue.description}</td>
                  <td className="table-button-container">
                    <button
                      onClick={(e) => viewValue(e, orgValue.id)}
                      data-toggle="modal"
                      data-target="#viewValuesModal"
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <img src="../../assets/Edit_Icon.svg" alt="edit" />
                    </button>
                    <Popconfirm
                      title="Are you sure to delete this task?"
                      onConfirm={(e) => deleteValue(e, orgValue.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src="../../assets/Delete_Icon.svg" alt="Delete" />
                      </button>
                    </Popconfirm>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div
            className="modal fade region"
            id="viewValuesModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="viewValuesModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title" id="valuesModalLabel">
                    Edit Values
                  </h2>
                </div>

                <div className="modal-body">
                  <div className="form-column-modal">
                    <div className="form-row">
                      <div className="form-cell">
                        <label>
                          Ref
                          <span className="form-mandatory">*</span>
                        </label>
                        <input
                          onChange={(e) => handleChange(e, 'values')}
                          name="ref"
                          className="input-text"
                          value={values.ref}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>
                          Name
                          <span className="form-mandatory">*</span>
                        </label>
                        <input
                          onChange={(e) => handleChange(e, 'values')}
                          name="name"
                          className="input-text"
                          value={values.name}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-cell">
                        <label>
                          Description
                          <span className="form-mandatory">*</span>
                        </label>
                        <textarea
                          rows="4"
                          onChange={(e) => handleChange(e, 'values')}
                          name="description"
                          value={values.description}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    onClick={(e) => updateValue(e, values.id)}
                    type="button"
                    disabled={
                      values.ref === '' ||
                      values.description === '' ||
                      values.name === ''
                        ? true
                        : false
                    }
                    className="btn btn-primary add"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="form-header mt-5">
            <h2>System Admin</h2>
            <button
              data-toggle="modal"
              data-target="#editSystemAdminDetails"
              type="button"
              className="btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src="../../assets/Edit_Icon.svg" alt="edit" />
            </button>
          </div>

          <div className="form-row">
            <div className="form-cell">
              <label>First Name</label>
              <input
                name="first-name"
                className="input-text"
                disabled
                value={organisationData.systemAdmin.firstName}
              />
            </div>
            <div className="form-cell">
              <label>Last Name </label>
              <input
                name="last-time"
                className="input-text"
                disabled
                value={organisationData.systemAdmin.lastName}
              />
            </div>
            <div className="form-cell">
              <label>Email Address</label>
              <input
                name="email"
                className="input-text"
                disabled
                value={organisationData.systemAdmin.emailAddress}
              />
            </div>
          </div>

          <div
            className="modal fade region"
            id="editSystemAdminDetails"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="editSystemAdminDetailsModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <form onSubmit={updateSystemAdmin}>
                  <div className="modal-header">
                    <h2 className="modal-title" id="valuesModalLabel">
                      Change System Admin
                    </h2>
                  </div>

                  <div className="modal-body">
                    <div className="form-column-modal">
                      <div className="form-row">
                        <div className="form-cell">
                          <label>
                            User
                            <span className="form-mandatory">*</span>
                          </label>
                          <select
                            defaultValue={'default'}
                            onChange={(e) =>
                              handleChange(e, 'postOrganisation')
                            }
                            name="systemAdmin"
                            value={organisationData.systemAdmin.id}
                          >
                            <option
                              value="default"
                              disabled="disabled"
                            ></option>
                            {users.map((user, i) => (
                              <option key={i} value={user.id}>
                                {' '}
                                {user.firstName}{' '}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary add"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="form-row mt-5">
            <div className="form-cell">
              <h2>Subscription</h2>
              <div className="subscription-container">
                <div className="subscription-header">
                  <p></p>
                  <p>30 day trial</p>
                  <p></p>
                </div>
                <div className="subscription-body">
                  <p className="subscription-body-title">Features</p>

                  <div className="subscription-body-container">
                    <div className="subscription-columns">
                      <p> - Org Structure & Internal Common Module </p>
                      <p> - Self Service </p>
                      <p> - Online Onboarding Training </p>
                    </div>
                    <div className="subscription-columns">
                      <p> - Onboarding Training </p>
                      <p> - System Support (with biz hours) </p>
                      <p> - Reports & Analysis </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-column">
            <div className="form-cell subscription-upgrade">
              <button className="btn btn-primary">Upgrade</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrganisationSetup
