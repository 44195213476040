import React, { Component } from 'react'
import axios from 'axios'
import ReactDOM from 'react-dom'
import LicenceFormInput from '../presentational/LicenceFormInput.jsx'
import '@babel/polyfill'
import { loadStripe } from '@stripe/stripe-js'
import Auth from '../../lib/Auth.js'

const stripePromise = loadStripe('pk_test_WnuYQJ6746tleL66Ie1vJb9k')

class LicenceContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      postData: {
        paymentMessage: {
          organisation: parseInt(this.props.match.params.orgId),
          itemCode: 'price_HNuUvzQpPGzaCE',
          quantity: 1,
        },
      },
      licences: [],
    }

    this.handlePaymentClick = this.handlePaymentClick.bind(this)
  }

  componentDidMount() {
    axios
      .get(BASE_URL + `/licenceTypes`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        this.setState({ licences: res.data })
      })
  }

  async handlePaymentClick(e, stripeItemCode) {
    // set itemCode
    this.setState({
      ...this.state,
      postData: {
        ...this.state.postData,
        itemCode: stripeItemCode,
      },
    })
    // Call your backend to create the Checkout session.
    const response = await axios.post(
      BASE_URL + '/payments',
      this.state.postData.paymentMessage,
      {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    )
    const sessionId = await response.data
    //if stripeItemCodeis empty (free licence) redirect to login page
    if (stripeItemCode === '') {
      await this.props.history.push('/')
    } else {
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise
      const error = await stripe.redirectToCheckout({
        sessionId,
      })
    }
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  }

  render() {
    return (
      <LicenceFormInput
        type="text"
        handlePaymentClick={this.handlePaymentClick}
        licences={this.state.licences}
      />
    )
  }
}

export default LicenceContainer
