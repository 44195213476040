import React from 'react'
import { Navbar, Button, Nav } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import Auth from '../../lib/Auth.js'
import userContext from '../../context/userContext'
import Axios from 'axios'
import { Modal, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import isEmpty from 'lodash/fp/isEmpty'
import axios from 'axios'
class NavBar extends React.Component {
  static contextType = userContext

  constructor() {
    super()
    this.state = {
      profileData: {
        name: '',
        jobTitle: '',
        department: '',
      },
      profileVisible: false,
      fileList: [],
      profileImage: '',
      thumbNailImage: true,
    }

    this.logout = this.logout.bind(this)
    this.orgId = Auth.getOrg()
    this.userId = Auth.getUserId()
    this.LoggedIn = Auth.getLoggedUserId()
  }

  componentDidMount() {
    let token = Auth.getToken()
    if (!token) {
    } else {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const parts = token.split('.')
      this.getProfileData()
      this.getProfile()
      /*this.setState({
							// orgId: JSON.parse(window.atob(base64)).Org,
							userEmail: JSON.parse(atob(parts[1])).Email,
					})*/
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!Auth.isAuthenticated()) {
      this.logout()
    }
  }

  // componentWillUnmount() {}

  logout() {
    Auth.removeToken()
    localStorage.getItem('reportingStructure')
    this.setState({
      profileData: {
        name: '',
        jobTitle: '',
        department: '',
      },
    })

    this.props.history.push('/')
    window.location.reload()
  }

  getJobRoles = (user) => {
    const { setSession } = this.context
    axios
      .get(BASE_URL + `/organisations/${this.orgId}/jobRoles`)
      .then((res) => {
        let { data } = res
        let filterRole =
          data &&
          data.reduce(
            (final, data) => {
              return {
                ...final,
                isOrgOwner:
                  isEmpty(data.parentJobRole) &&
                  user?.department?.id === data?.department?.id
                    ? true
                    : final.isOrgOwner,
                orgOwner: isEmpty(data.parentJobRole) ? data : final.orgOwner,
                parentDepartment:
                  user?.jobTitle === data?.jobTitle
                    ? data?.parentJobRole
                    : final.parentDepartment,
              }
            },
            { orgOwner: {}, parentDepartment: {}, isOrgOwner: false }
          )

        setSession({ org: filterRole, user: user })
      })
      .catch((e) => console.log(e))
  }

  getProfileData = () => {
    Axios.get(BASE_URL + `/organisations/${this.orgId}/users`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    }).then((res) => {
      let data = res.data.filter(
        (item) => item.emailAddress == Auth.getUserId()
      )[0]
      this.getJobRoles(data)

      this.setState({
        profileData: {
          name: data.fullName,
          jobTitle: data.jobTitle,
          department: data.department ? data.department.name : '',
        },
      })
    })
  }

  updateProfile = (fileData) => {
    try {
      Axios.post(
        BASE_URL + `/organisations/${this.orgId}/users/${this.LoggedIn}/photo`,
        {
          fileName: fileData.name,
          size: fileData.size,
        }
      ).then((res) => {
        let { data } = res

        this.uploadImageToServer(data?.url, fileData)
      })
    } catch (e) {
      console.log(e)
    }
  }

  deleteProfile = () => {
    const { setSession } = this.context
    try {
      Axios.delete(
        BASE_URL + `/organisations/${this.orgId}/users/${this.LoggedIn}/photo`
      ).then((res) => {
        let { data } = res
        this.getProfile()
        this.setState({ profileImage: '' })
      })
    } catch (e) {
      console.log(e)
    }
  }

  /*  deleteServerProfile = async url => {
		  try {
				  console.log('URL DATA --->', url, file)
				  let myHeaders = new Headers()
				  myHeaders.append('Content-Type', file.type)
				  myHeaders.append('Access-Control-Allow-Origin', '*')
				  await fetch(url, {
						  method: 'PUT',
						  headers: myHeaders,
						  body: file.originFileObj,
				  })
					  .then(response => response.text())
					  .then(result => {
							  console.log('IMAG UPLOAD', result)
							  this.getProfile()
					  })
					  .catch(error => console.log('error', error))
		  } catch (e) {
				  console.log(e)
		  }
  }*/

  uploadImageToServer = async (url, file) => {
    try {
      let myHeaders = new Headers()
      myHeaders.append('Content-Type', file.type)
      myHeaders.append('Access-Control-Allow-Origin', '*')
      //thumbUrl
      //  ?.originFileObj
      //   file.originFileObj
      //   var fileList = this.files;
      /*let file = file.originFileObj

      var r = new FileReader()
      r.onload = function() {
        return r.result
      }
      r.readAsBinaryString(file)*/
      await fetch(url, {
        method: 'PUT',
        headers: myHeaders,
        body: file.originFileObj,
      })
        .then((response) => response.text())
        .then((result) => {
          this.getProfile()
        })
        .catch((error) => console.log('error', error))
    } catch (e) {
      console.log(e)
    }
  }

  getProfile = () => {
    const { setSession } = this.context
    try {
      Axios.get(
        BASE_URL + `/organisations/${this.orgId}/users/${this.LoggedIn}/photo`
      ).then((res) => {
        let { data } = res
        /*{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      }*/

        this.setState(
          {
            fileList: [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: data.url,
              },
            ],
            profileImage: data.url,
          },
          () => {
            setSession({
              profileImage: {
                image: {
                  uid: '-1',
                  name: 'image.png',
                  status: 'done',
                  url: data.url,
                },
                url: data.url,
              },
            })
          }
        )
      })
    } catch (e) {
      console.log(e)
    }
  }

  handleOk = () => {
    this.setState({
      profileVisible: true,
    })
  }

  handleProfileView = () => {
    this.setState({
      profileVisible: true,
    })
  }

  handleCancel = () => {
    this.setState({
      profileVisible: false,
    })
  }

  handleChange = ({ fileList }) => {
    let fileData = fileList.length > 0 && fileList[0]
    if (fileList.length !== 0) {
      this.updateProfile(fileData)
    }
    this.setState({ fileList })
  }

  handlePreview = () => {}

  render() {
    const {
      profileData,
      profileVisible,
      fileList,
      profileImage,
      thumbNailImage,
    } = this.state

    return (
      <div>
        <Modal
          title="Upload Profile Image"
          visible={profileVisible}
          onCancel={this.handleCancel}
          onOk={this.handleCancel}
        >
          <div className="modalInner">
            <Upload
              action=""
              listType="picture-card"
              fileList={fileList}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
              onRemove={() => {
                this.deleteProfile()
              }}
            >
              {/* {thumbNailImage && (
                <div className="thumbNailImage">
                  <img src={profileImage} alt="Profile Image" />
                </div>
              )}*/}
              {fileList.length < 1 ? (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              ) : null}
            </Upload>
          </div>
        </Modal>
        {
          /*this.props.location.pathname != '/' &&*/
          this.props.location.pathname != '/register' &&
            this.props.location.pathname != '/thank-you' &&
            this.props.location.pathname != '/forgot-password' &&
            this.props.location.pathname != '/reset-password/:token' && (
              <Navbar className="navbar">
                <Navbar.Brand href="/">
                  <img
                    src="/assets/ConradRP_Logo_Strapline_Cropped.png"
                    alt="conrad rp logo"
                    height="32"
                  />
                </Navbar.Brand>
                {Auth.isAuthenticated() && (
                  <React.Fragment>
                    <Nav>
                      <div>
                        <div className="d-flex">
                          <div
                            className="navProfileImage mr-2"
                            onClick={this.handleProfileView}
                          >
                            {/*<span className="d-flex justify-content-center align-content-center">
                          
                          </span>*/}
                            {profileImage && (
                              <img src={profileImage} alt="Profile Image" />
                            )}
                          </div>
                          <div className="d-flex flex-column justify-content-center align-content-center navProfileDetails mr-3">
                            <p className="m-0 small">{profileData.name}</p>
                            <p className="m-0 small">
                              {profileData.jobTitle} - {profileData.department}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-content-center">
                        <Button className="my-profile" onClick={this.logout}>
                          Logout
                        </Button>
                      </div>
                    </Nav>
                  </React.Fragment>
                )}
              </Navbar>
            )
        }
      </div>
    )
  }
}

export default withRouter(NavBar)
