import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import '../../../style/container/risk-tables.scss'
import * as moment from 'moment'
import axios from 'axios'
import { InfoToolTip } from '../../common/InfoTooltip.jsx'
import Auth from '../../../lib/Auth.js'

const StrategicGoalsTable = props => {
  const [showMore, setShowMore] = useState(true)
  const [goalsData, setGoalsData] = useState([])

  //org id set to 97 so that some data can be viewed in the table. Change later
  const [orgID, setorgID] = useState(props.orgId)
  const [users, setUsers] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRows] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const StrategicGoalsData = {
    heading: [
      { id: 1, label: 'Year' },
      { id: 2, label: 'Ref' },
      { id: 3, label: 'Category' },
      { id: 4, label: 'Description' },
      { id: 5, label: 'Start Date' },
      { id: 6, label: 'End Date' },
      { id: 7, label: 'Lead' },
    ],
    data: [
      {
        year: '2020',
        ref: '1',
        description: 'Global Pandemic',
        category: 'Rethink things',
        start: '1/1/2020',
        end: '31/12/2020',
        lead: 'PM Modi',
      },
      {
        year: '2020',
        ref: '1',
        description: 'Global Pandemic',
        category: 'Rethink things',
        start: '1/1/2020',
        end: '31/12/2020',
        lead: 'PM Modi',
      },
      {
        year: '2020',
        ref: '1',
        description: 'Global Pandemic',
        category: 'Rethink things',
        start: '1/1/2020',
        end: '31/12/2020',
        lead: 'PM Modi',
      },
      {
        year: '2020',
        ref: '1',
        description: 'Global Pandemic',
        category: 'Rethink things',
        start: '1/1/2020',
        end: '31/12/2020',
        lead: 'PM Modi',
      },
      {
        year: '2020',
        ref: '1',
        description: 'Global Pandemic',
        category: 'Rethink things',
        start: '1/1/2020',
        end: '31/12/2020',
        lead: 'PM Modi',
      },
    ],
  }

  useEffect(() => {
    fetchData()
  }, [])

  const getTotalPages = () => {
    var chunks = [],
      i = 0,
      n = goalsData.length
    while (i < n) {
      chunks.push(goalsData.slice(i, (i += rowsPerPage)))
    }
    return chunks.length
  }

  useEffect(() => {
    setTotalPages(getTotalPages())
  }, [goalsData])

  const getPageRows = () => {
    var chunks = [],
      i = 0,
      n = goalsData.length
    while (i < n) {
      chunks.push(goalsData.slice(i, (i += rowsPerPage)))
    }
    return chunks[page] || goalsData
  }

  const fetchData = () => {
    axios
      .get(BASE_URL + `/goals`, {
        params: {
          organisation: orgID,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          setGoalsData(res.data)
          setPage(0)
        }
      })
    axios
      .get(BASE_URL + `/organisations/${orgID}/users`, {
        params: {
          department: 0,
          team: 0,
          jobRole: 0,
          status: 0,
        },headers: {
				      Authorization: `Bearer ${Auth.getToken()}`,
		      },
      })
      .then(res => {
        if (res.status === 200) {
          setUsers(res.data)
        }
      })
  }

  const renderTableHeading = () => {
    return StrategicGoalsData.heading.map(item => {
      return <th>{item.label}</th>
    })
  }

  //rendering live data
  const renderTableData = () => {
    return getPageRows().map((item, index) => {
      return (
        <tr key={index}>
          {/* <td>{item.year}</td> */}
          <td>{item.ref}</td>
          <td>{item.category.name}</td>
          <td>{item.description}</td>
          <td>
            {moment(
              `${item.startDt.day}/${item.startDt.month}/
                    ${item.startDt.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td>
            {moment(
              `${item.endDt.day}/${item.endDt.month}/
                    ${item.endDt.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td>{users?.filter(user => user.id === item.lead)[0]?.name}</td>
        </tr>
      )
    })
  }

  const renderSlicedTableData = () => {
    // let slicedData = goalsData.slice(0, 2)
    return getPageRows().map((item, index) => {
      return (
        <tr key={index}>
          {/* <td>{item.year}</td> */}
          <td>{item.ref}</td>
          <td>{item.category.name}</td>
          <td>{item.description}</td>
          <td>
            {moment(
              `${item.startDt.day}/${item.startDt.month}/
                    ${item.startDt.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td>
            {moment(
              `${item.endDt.day}/${item.endDt.month}/
                    ${item.endDt.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td>{users.filter(user => user.id === item.lead)[0]?.name}</td>
        </tr>
      )
    })
  }

  const showMoreClickHandler = () => {
    setShowMore(!showMore)
  }
  return (
    <div className="table-main">
      <h4 style={{ color: '#33466B' }}>
        <InfoToolTip
          title={'Strategic goals'}
          text={' Strategic goals Info text'}
        ></InfoToolTip>
      </h4>
      <div class={{ width: '100%', overflowX: 'scroll' }}>
        <table className="risks-table">
          <thead>
            {/* {renderTableHeading()}
                    <ButtonsHeader /> */}
            <tr style={{ height: '40px' }}>
              {/* <th>Year</th> */}
              <th width="10%">Ref</th>
              <th width="20%">Category</th>
              <th width="40%">Description</th>
              <th width="10%">Start Date</th>
              <th width="10%">End Date</th>
              <th width="10%">Lead</th>
            </tr>
          </thead>
          {showMore ? (
            <tbody>{renderSlicedTableData()}</tbody>
          ) : (
            <tbody>{renderTableData()}</tbody>
          )}
        </table>
      </div>
      <div className="text-right mt-3">
        <span style={{ fontSize: '14px' }}>
          Showing page {page + 1} of {totalPages}
        </span>
        <button
          className="previous-page btn btn-primary mx-2"
          disabled={page == 0}
          onClick={() => setPage(page => page - 1)}
        >
          Prev
        </button>
        <button
          disabled={page === totalPages - 1}
          className="next-page btn btn-primary"
          onClick={() => setPage(page => page + 1)}
        >
          Next
        </button>
      </div>
      {/* <p onClick={showMoreClickHandler} className="toggle-button">
                {showMore ? 'Show More' : 'Show Less'}
            </p> */}
    </div>
  )
}

export default StrategicGoalsTable
