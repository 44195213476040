import isEmpty from 'lodash/fp/isEmpty'
import toLower from 'lodash/fp/toLower'
export function uncertaintyType(type) {
  switch (toLower(type)) {
    case 'positive':
      return 'Opportunity'
    case 'negative':
      return 'Threat'
  }
}
