import React from 'react'

export const FinancialGrid = (props) => {
  const {
    summaryDashBoard,
    financialCostValues,
    financialValues,
    currency,
  } = props
  return (
    <div className="financeSummary mr-4">
      <h3 className="mb-2">Financials</h3>
      <div className="d-flex financeSummaryGrid">
        <div>
          <p className="strong m-0">Cost of Exposure of Threats</p>
          <div className="d-flex mr-2">
            <div className="d-flex border border-secondary">
              <div className="financeSummaryBox border-right">
                <span className="financeSummaryCircle">
                  <p className=" m-0">Gross Value@Risk</p>
                  <p className=" m-0" style={{ fontSize: '20px' }}>
                    {' '}
                    <span>
                      {currency} {summaryDashBoard?.financials?.threats?.gross}
                    </span>
                  </p>
                  <p className=" m-0" style={{ fontSize: '18px' }}>
                    {financialValues(
                      summaryDashBoard?.financials?.threats?.gross,
                      summaryDashBoard?.financials?.threats?.recentGross
                    )}
                  </p>
                </span>
              </div>
              <div className="financeSummaryBox">
                <span className="financeSummaryCircle">
                  <p className="small m-0">Net Value@Risk</p>
                  <p className=" m-0" style={{ fontSize: '20px' }}>
                    {' '}
                    {currency} {summaryDashBoard?.financials?.threats?.net}
                  </p>
                  <p className=" m-0" style={{ fontSize: '18px' }}>
                    {' '}
                    {financialValues(
                      summaryDashBoard?.financials?.threats?.net,
                      summaryDashBoard?.financials?.threats?.recentNet
                    )}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="strong m-0">Cost of Exposure of opportunities</p>
          <div className="d-flex">
            <div className="d-flex border border-secondary">
              <div className="financeSummaryBox border-right">
                <span className="financeSummaryCircle">
                  <p className="small m-0">Gross Value@Risk</p>
                  <p className=" m-0" style={{ fontSize: '20px' }}>
                    {' '}
                    <span>
                      {currency}{' '}
                      {summaryDashBoard?.financials?.opportunities?.gross}
                    </span>
                  </p>
                  <p className=" m-0" style={{ fontSize: '18px' }}>
                    {financialValues(
                      summaryDashBoard?.financials?.opportunities?.gross,
                      summaryDashBoard?.financials?.opportunities?.recentGross
                    )}
                  </p>
                </span>
              </div>
              <div className="financeSummaryBox">
                <span className="financeSummaryCircle">
                  <p className="small m-0">Net Value@Risk</p>
                  <p className=" m-0" style={{ fontSize: '20px' }}>
                    {' '}
                    {currency}{' '}
                    {summaryDashBoard?.financials?.opportunities?.net}
                  </p>
                  <p className=" m-0" style={{ fontSize: '18px' }}>
                    {' '}
                    {financialValues(
                      summaryDashBoard?.financials?.opportunities?.net,
                      summaryDashBoard?.financials?.opportunities?.recentNet
                    )}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex financeSummaryGrid mt-3">
        <div>
          <p className="strong m-0">Cost of treating threats</p>
          <div className="d-flex mr-2">
            <div className="d-flex border border-secondary">
              <div className="financeSummaryBox border-right">
                <span className="financeSummaryCircle">
                  <p className="small m-0">Estimated</p>
                  <p className=" m-0" style={{ fontSize: '20px' }}>
                    <span>
                      {currency}
                      {summaryDashBoard?.financials?.costOfThreats?.estimated}
                    </span>
                  </p>
                  <p className=" m-0" style={{ fontSize: '18px' }}>
                    {financialCostValues(
                      summaryDashBoard?.financials?.costOfThreats?.estimated,
                      summaryDashBoard?.financials?.costOfThreats
                        ?.recentEstimated
                    )}
                  </p>
                </span>
              </div>
              <div className="financeSummaryBox">
                <span className="financeSummaryCircle">
                  <p className="small m-0">Actual</p>
                  <p className=" m-0" style={{ fontSize: '20px' }}>
                    <span>
                      {currency}
                      {summaryDashBoard?.financials?.costOfThreats?.actual}
                    </span>
                  </p>
                  <p className=" m-0" style={{ fontSize: '18px' }}>
                    {financialCostValues(
                      summaryDashBoard?.financials?.costOfThreats?.actual,
                      summaryDashBoard?.financials?.costOfThreats?.recentActual
                    )}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="strong m-0">Cost of treating opportunities</p>
          <div className="d-flex">
            <div className="d-flex border border-secondary">
              <div className="financeSummaryBox border-right">
                <span className="financeSummaryCircle">
                  <p className="small m-0">Estimated</p>
                  <p className=" m-0" style={{ fontSize: '20px' }}>
                    {' '}
                    <span>
                      {currency}{' '}
                      {
                        summaryDashBoard?.financials?.costOfOpportunities
                          ?.estimated
                      }
                    </span>
                  </p>
                  <p className=" m-0" style={{ fontSize: '18px' }}>
                    {' '}
                    {financialCostValues(
                      summaryDashBoard?.financials?.costOfOpportunities
                        ?.estimated,
                      summaryDashBoard?.financials?.costOfOpportunities
                        ?.recentEstimated
                    )}
                  </p>
                </span>
              </div>
              <div className="financeSummaryBox">
                <span className="financeSummaryCircle">
                  <p className="small m-0">Actual</p>
                  <p className=" m-0" style={{ fontSize: '20px' }}>
                    <span>
                      {currency}
                      {
                        summaryDashBoard?.financials?.costOfOpportunities
                          ?.actual
                      }
                    </span>
                  </p>
                  <p className=" m-0" style={{ fontSize: '18px' }}>
                    {financialCostValues(
                      summaryDashBoard?.financials?.costOfOpportunities?.actual,
                      summaryDashBoard?.financials?.costOfOpportunities
                        ?.recentActual
                    )}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
