import React, { Component } from 'react'
import axios from 'axios'
import OrganizationChart from '@dabeng/react-orgchart'
import Auth from '../../lib/Auth.js'

class OrgChartContainer extends Component {
  constructor() {
    super()

    this.state = {
      orgChart: {},
      errors: {},
    }
  }

  componentDidMount() {
    axios
      .get(BASE_URL + `/orgchart/1`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        this.setState({ orgChart: res.data })
      })
      .catch(err => this.setState({ errors: err.response }))
  }

  render() {
    return (
      <div>
        Org Chart
        <OrganizationChart
          ref={el => (this.orgChart = el)}
          datasource={this.state.orgChart}
        />
      </div>
    )
  }
}

export default OrgChartContainer
