import React, { Component } from 'react'
import { Modal, Form, Container, Row, Col, Button } from 'react-bootstrap'
import { notification, Upload, Popconfirm } from 'antd'
import Swal from 'sweetalert2'
import {
  DownloadOutlined,
  UploadOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import '../../style/container/objectives.scss'
import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
import RangeSlider from 'react-bootstrap-range-slider'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import axios from 'axios'
import moment from 'moment'
import userContext from '../../context/userContext'
import Auth from '../../lib/Auth'

class EditAfterAddModal extends Component {
  static contextType = userContext
  constructor(props) {
    super(props)
    this.state = {
      step: 1,
      actionDes: '',
      actionOw: 0,
      actionTy: 1,
      actionSta: 0,
      actionPri: 0,
      actionFoc: '',
      estCost: 0,
      actCost: 0,
      time: '',
      others: '',
      bcpReq: '',
      frequencyOfReview: '',
      duedate: '',
      actionEf: '',
      notes: '',
      showFirst: true,
      showNext: false,
      completed: 0,
      riskId: [],
      orgID: 0,
      treatmentItem: [],
      show: false,
      thisRiskId: 0,
    }
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentWillReceiveProps(props) {
    let date = props.treatmentObject.dd

    let treatmentItem = { ...props.treatmentObject }
    treatmentItem.dueDate = date
    treatmentItem.bcpReq = treatmentItem.bcpDcr
    this.setState({
      orgID: props.orgID,
      treatmentItem: treatmentItem,
      show: props.showEditModal,
    })
  }

  inputChange = (input) => (e) => {
    let treatmentItem = { ...this.state.treatmentItem }
    treatmentItem[input] = e.target.value
    this.setState({
      treatmentItem,
    })
  }

  renderActionOwners() {
    return this.props.actionOwners.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }
  nextClickHandler = () => {
    this.setState({
      showNext: true,
    })
  }

  backClickhandler = () => {
    this.setState({
      showNext: false,
    })
  }

  cancelClicked = () => {
    this.props.closeEditModal()
  }

  saveClickd = () => {
    this.setState({
      showNext: false,
    })
    this.props.closeEditModal()
    let editObject = {
      ref: this.state.treatmentItem?.ref,
      description: this.state.treatmentItem.description,
      cost: this.state.treatmentItem.ec,
      actualCost: this.state.treatmentItem.ac,
      dueDate: moment(this.state.treatmentItem.dd, 'DD-MM-YY').format(
        'YYYY-MM-DD'
      ),
      actionPriority: this.state.treatmentItem?.ap,
      riskStatus: this.state.treatmentItem?.as,
      actionType: this.state.treatmentItem?.actionType,
      completed: this.state.treatmentItem?.completed,
      focus: this.state.treatmentItem?.focus,
      owner: this.state.treatmentItem?.owner,
      risk: 0,
      time: this.state.treatmentItem?.time,
      notes: this.state.treatmentItem?.notes,
      comments: 'na',
      frequencyOfReview: this.state.treatmentItem?.frequencyOfReview,
      others: this.state.treatmentItem?.others,
      bcpDcr: this.state.treatmentItem?.bcpReq,
    }

    let updateObject = {
      ref: this.state.treatmentItem.ref,
      description: this.state.treatmentItem.description,
      ec: this.state.treatmentItem.ec,
      ac: this.state.treatmentItem.ac,
      dd: this.state.treatmentItem.dd,
      ap: this.state.treatmentItem.ap,
      as: this.state.treatmentItem.as,
      focus: this.state.treatmentItem?.focus,
      actionType: this.state.treatmentItem.actionType,
      completed: this.state.treatmentItem.completed,
      owner: this.state.treatmentItem.owner,
      risk: this.props.myRiskId,
      id: this.props.treatmentObject.id,
      time: this.state.treatmentItem?.time,
      notes: this.state.treatmentItem?.notes,
      comments: 'na',
      others: this.state.treatmentItem?.others,
      bcpDcr: this.state.treatmentItem?.bcpReq,
      frequencyOfReview: this.state.treatmentItem?.frequencyOfReview,
    }

    axios
      .put(
        BASE_URL +
          `/organisations/${this.state.orgID}/riskTreatments/${this.props.treatmentObject.id}`,
        {
          ...editObject,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          this.props.updateTable({
            ...updateObject,
            riskId: res.data,
          })
          Swal.fire({
            title: 'Data posted successfully',
            text: 'Your form data is now present in the table',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
          })
        } else {
          return Swal.fire(
            '',
            'There was an error adding your treatment. Please try again'
          )
        }
      })
  }

  getReviewFrequency() {
    return this.props.frequencyOfReview.map((item, index) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getActionPriorities() {
    return this.props.actionPriorities.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getActionEffectiveness() {
    return this.props.actionEffectiveness.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getActionStatus() {
    return this.props.actionStatus.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  handleDateChange = (e) => {
    let date = moment(e).format('DD-MM-YY')

    let treatmentItem = { ...this.state.treatmentItem }

    treatmentItem.dueDate = date

    this.setState({
      duedate: date,
      treatmentItem,
    })
  }

  fetchData = () => {}

  render() {
    const {
      actionDes,
      actionOw,
      actionTy,
      actionSta,
      actionPri,
      actionFoc,
      estCost,
      actCost,
      time,
      others,
      bcpReq,
      frequencyOfReview,
      duedate,
      actionEf,
      notes,
      completed,
    } = this.state
    const values = {
      actionDes,
      actionOw,
      actionTy,
      actionSta,
      actionPri,
      actionFoc,
      estCost,
      actCost,
      time,
      others,
      bcpReq,
      frequencyOfReview,
      duedate,
      actionEf,
      notes,
      completed,
    }

    return (
      <div>
        <div>
          <Modal
            show={this.props.showEditModal}
            onHide={this.props.closeEditModal}
            className="modal-main action-plan-modal"
          >
            <Modal.Header className="modal-header">
              Edit Action Plan / Cost of Treatment
            </Modal.Header>
            <Modal.Body className="modal-body">
              <Form>
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Description of Action Plan [SMART]
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="actionDes"
                            onChange={this.inputChange('description').bind(
                              this
                            )}
                            value={this.state.treatmentItem?.description}
                            style={{
                              width: '100%',
                              height: '150px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Owner<span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionOw"
                            onChange={this.inputChange('owner').bind(this)}
                            value={this.state.treatmentItem?.owner}
                          >
                            <option value="">-</option>
                            {this.renderActionOwners()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Type:<span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionType"
                            onChange={this.inputChange('actionType').bind(this)}
                            value={this.state.treatmentItem.actionType}
                          >
                            <option value="">-</option>
                            <option value="1">Proactive</option>
                            <option value="2">Reactive</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Status:
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionSta"
                            onChange={this.inputChange('as').bind(this)}
                            value={
                              this.state.treatmentItem?.as == null
                                ? this.props.actionStatus[0]?.id
                                : this.state.treatmentItem?.as
                            }
                          >
                            <option value="">-</option>
                            {this.getActionStatus()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Priority:
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionPri"
                            onChange={this.inputChange('ap').bind(this)}
                            value={
                              this.state.treatmentItem?.ap == null
                                ? this.props.actionPriorities[0]?.id
                                : this.state.treatmentItem?.ap
                            }
                            style={{
                              width: '100%',
                            }}
                          >
                            <option value="">-</option>
                            {this.getActionPriorities()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Action Focus:</Form.Label>
                          <span className="text-danger"> *</span>
                          <Form.Control
                            as="select"
                            name="actionFoc"
                            onChange={this.inputChange('focus').bind(this)}
                            value={this.state.treatmentItem?.focus}
                            style={{
                              width: '100%',
                            }}
                          >
                            <option value="">-</option>
                            <option value="1">Cause</option>
                            <option value="2">Impact</option>
                            <option value="3">Impact and Cause</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Estimated Cost:</Form.Label>
                          <Form.Control
                            type="text"
                            name="estCost"
                            onChange={this.inputChange('ec').bind(this)}
                            value={this.state.treatmentItem?.ec}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Actual Cost:</Form.Label>
                          <Form.Control
                            type="text"
                            name="actCost"
                            onChange={this.inputChange('actualCost').bind(this)}
                            value={this.state.treatmentItem?.ac}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Time:</Form.Label>
                          <Form.Control
                            type="text"
                            name="time"
                            onChange={this.inputChange('time').bind(this)}
                            value={this.state.treatmentItem?.time}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Others</Form.Label>
                          <Form.Control
                            type="text"
                            name="others"
                            onChange={this.inputChange('others').bind(this)}
                            value={this.state.treatmentItem?.others}
                            style={{
                              height: '35px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label
                            style={{
                              fontSize: '10px',
                            }}
                          >
                            Is BCP/DRC Required?
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="bcpReq"
                            onChange={this.inputChange('bcpReq').bind(this)}
                            value={this.state.treatmentItem?.bcpReq}
                            style={{
                              height: '30px',
                            }}
                          >
                            <option value="BCP">BCP</option>
                            <option value="DRC">DRC</option>
                            <option value="BCP + DRC">BCP + DRC</option>
                            <option value="none">None</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Review Frequency:{' '}
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="frequencyOfReview"
                            onChange={this.inputChange(
                              'frequencyOfReview'
                            ).bind(this)}
                            value={this.state.treatmentItem?.frequencyOfReview}
                            style={{
                              height: '30px',
                            }}
                          >
                            {this.getReviewFrequency()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Due Date<span className="text-danger"> *</span>
                          </Form.Label>
                          <DatePicker
                            value={this.state.treatmentItem?.dueDate}
                            name="testDate"
                            onChange={(e) => this.handleDateChange(e)}
                            dateFormat="dd-mm-yy"
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        Completed: <span className="text-danger"> *</span>
                        <Form.Group as={Row}>
                          <Col xs="8">
                            <RangeSlider
                              value={this.state.treatmentItem?.completed}
                              onChange={(e) => {
                                let treatmentItem = {
                                  ...this.state.treatmentItem,
                                }
                                treatmentItem.completed = e.target.value
                                this.setState({
                                  treatmentItem,
                                })
                              }}
                            />
                          </Col>
                          <Col xs="3">
                            <Form.Control
                              value={this.state.treatmentItem?.completed}
                              onChange={this.inputChange('completed').bind(
                                this
                              )}
                            />
                          </Col>
                        </Form.Group>
                        {/* <Form.Group controlId="formBasicRange">
                                                    <Form.Label>
                                                        %Completed
                                                    </Form.Label>
                                                    <Form.Control type="range" />
                                                </Form.Group> */}
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Action Effectiveness:</Form.Label>
                          <Form.Control
                            as="select"
                            name="actionEf"
                            onChange={this.inputChange('actionEf').bind(this)}
                            value={this.state.treatmentItem?.actionEf}
                          >
                            {this.getActionEffectiveness()}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Upload Evidence:</Form.Label>
                          <Upload
                            multiple={true}
                            customRequest={(e) => {
                              e.onSuccess((val) => {
                                console.log('FILE VAL --->', val)
                              })
                              e.onError((err) => {
                                console.log('FILE err --->', err)
                              })
                            }}
                            // onChange={handleDocUpload}
                          >
                            <Button icon={<UploadOutlined />}>
                              Click to Upload
                            </Button>
                          </Upload>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Notes</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="notes"
                            onChange={this.inputChange('notes').bind(this)}
                            value={this.state.treatmentItem?.notes}
                            style={{
                              height: '120px',
                              width: '350px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
              <div>
                {/* <Button onClick={this.props.closeEditModal}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={this.nextClickHandler.bind(this)}
                                >
                                    Next
                                </Button>*/}
                <Button onClick={this.backClickhandler.bind(this)}>Back</Button>
                <Button type={'submit'} onClick={this.saveClickd.bind(this)}>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            show={this.state.showNext}
            className="modal-main action-plan-modal"
          >
            <Modal.Header>Edit Action Plan - Cost of Treatment</Modal.Header>
            <Modal.Body>
              <Form>
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Estimated Cost:</Form.Label>
                          <Form.Control
                            type="text"
                            name="estCost"
                            onChange={this.inputChange('ec').bind(this)}
                            value={this.state.treatmentItem?.ec}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Actual Cost:</Form.Label>
                          <Form.Control
                            type="text"
                            name="actCost"
                            onChange={this.inputChange('actualCost').bind(this)}
                            value={this.state.treatmentItem?.ac}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Time:</Form.Label>
                          <Form.Control
                            type="text"
                            name="time"
                            onChange={this.inputChange('time').bind(this)}
                            value={this.state.treatmentItem?.time}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Others</Form.Label>
                          <Form.Control
                            type="text"
                            name="others"
                            onChange={this.inputChange('others').bind(this)}
                            value={this.state.treatmentItem?.others}
                            style={{
                              height: '35px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label
                            style={{
                              fontSize: '10px',
                            }}
                          >
                            Is BCP/DRC Required?
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="bcpReq"
                            onChange={this.inputChange('bcpReq').bind(this)}
                            value={this.state.treatmentItem?.bcpReq}
                            style={{
                              height: '30px',
                            }}
                          >
                            <option value="BCP">BCP</option>
                            <option value="DRC">DRC</option>
                            <option value="BCP + DRC">BCP + DRC</option>
                            <option value="none">None</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Review Frequency:</Form.Label>
                          <Form.Control
                            as="select"
                            name="frequencyOfReview"
                            onChange={this.inputChange(
                              'frequencyOfReview'
                            ).bind(this)}
                            value={this.state.treatmentItem?.frequencyOfReview}
                            style={{
                              height: '30px',
                            }}
                          >
                            {this.getReviewFrequency()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Due Date</Form.Label>
                          <DatePicker
                            value={this.state.treatmentItem?.dueDate}
                            name="testDate"
                            onChange={(e) => this.handleDateChange(e)}
                            dateFormat="dd-mm-yy"
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        Completed:
                        <Form.Group as={Row}>
                          <Col xs="8">
                            <RangeSlider
                              value={this.state.treatmentItem?.completed}
                              onChange={(e) => {
                                let treatmentItem = {
                                  ...this.state.treatmentItem,
                                }
                                treatmentItem.completed = e.target.value
                                this.setState({
                                  treatmentItem,
                                })
                              }}
                            />
                          </Col>
                          <Col xs="3">
                            <Form.Control
                              value={this.state.treatmentItem?.completed}
                              onChange={this.inputChange('completed').bind(
                                this
                              )}
                            />
                          </Col>
                        </Form.Group>
                        {/* <Form.Group controlId="formBasicRange">
                                                    <Form.Label>
                                                        %Completed
                                                    </Form.Label>
                                                    <Form.Control type="range" />
                                                </Form.Group> */}
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Action Effectiveness:</Form.Label>
                          <Form.Control
                            as="select"
                            name="actionEf"
                            onChange={this.inputChange('actionEf').bind(this)}
                            value={this.state.treatmentItem?.actionEf}
                          >
                            {this.getActionEffectiveness()}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.File label="Upload Evidence" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Notes</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="notes"
                            onChange={this.inputChange('notes').bind(this)}
                            value={this.state.treatmentItem?.notes}
                            style={{
                              height: '120px',
                              width: '100%',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <Button onClick={this.backClickhandler.bind(this)}>Back</Button>
                <Button type={'submit'} onClick={this.saveClickd.bind(this)}>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
}

export default EditAfterAddModal
