import React, { useContext } from 'react'
import { uncertaintyType } from '../../../../common/uncertaintyType.js'
import userContext from '../../../../context/userContext'
import * as moment from 'moment'

export default function RiskTreatmentReportTable({ risks, totals }) {

    const { sessionDetails } = useContext(userContext)
    const currency = sessionDetails?.user?.organisation?.currencyCode
    const risksArray = Object.values(risks)
    console.log(risksArray)
    const departmentName = risksArray[0].departmentName

    return (
        <>
            {departmentName && <p><b>Department:</b> {departmentName}</p>}

            <div className="mb-3">
                {/* <p className="mb-1">Department {risks[0]?.risk?.department}</p> */}
                <table className="risk-treatment organisational report multi-report-table" id="report-table" data-departmentName={departmentName}>
                    <thead>
                        <tr>
                            <th>Ref</th>
                            <th>Risk Event</th>
                            <th>Treatment (Action Plan)</th>
                            <th>Action Priority</th>
                            {/* <th>Action Status</th> */}
                            {/* <th>Action Focus</th> */}
                            <th>% Completed</th>
                            <th>Estimated Cost</th>
                            <th>Actual Cost</th>
                            <th>Owner</th>
                            <th>Due Date</th>
                            <th>No of Days Overdue</th>

                        </tr>


                    </thead>
                    <tbody>

                        {risksArray?.map((risk) => {
                            return risk.treatments.map((item, index) => {
                                console.log(item)
                                const dueDate = moment(item?.dueDate, 'YYYY-MM-DD')
                                console.log("--",moment().diff(dueDate, 'days').toString())
                                return (
                                    <tr>
                                        <td>{index == 0 && item.risk.ref}</td>
                                        <td>{index == 0 && item.risk.eventDescription}</td>
                                        <td>{item.description}</td>

                                        <td>{item?.actionPriority?.name}</td>
                                        {/* <td>{item?.actionStatus}</td> */}

                                        {/* <td>{item?.focus}</td> */}

                                        <td>{item?.completed}</td>
                                        <td>{currency} {item?.estimatedCost}</td>
                                        <td>{currency} {item?.actualCost}</td>
                                        <td>{item?.ownerName}</td>
                                        <td>{dueDate.format('DD-MM-YYYY')}</td>
                                        <td>{moment().diff(dueDate, 'days').toString()}</td>


                                    </tr>
                                )
                            })
                        })
                        }

                        <tr>
                            <td className="font-weight-bold text-right" colspan="5">Total Estimated Cost:</td>
                            <td className="font-weight-bold" colspan="5">{currency} {totals?.estimatedCost}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold text-right" colspan="5">Total Actual Cost:</td>
                            <td className="font-weight-bold" colspan="5">{currency} {totals?.actualCost}</td>
                        </tr>

                    </tbody>
                </table>

                {/* <div className="total-count-row-treatment">
                    <div className="labels-div">
                        <p>Total Estimated Cost: </p>
                        <p>Total Actual Cost: </p>



                    </div>
                    <div className="values-div">
                        <p>&nbsp;{currency} {totals?.estimatedCost}</p>
                        <p>&nbsp;{currency} {totals?.actualCost} </p>

                    </div>
                </div> */}
            </div>
        </>
    )
}
