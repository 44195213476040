import React, { Component } from 'react'
import axios from 'axios'
import ReactDOM from 'react-dom'
import UserSetup from '../../presentational/admin/_UserSetup.jsx'
import { Navbar } from 'react-bootstrap'
import SideMenu, { Item } from 'react-sidemenu'
import Auth from '../../../lib/Auth.js'

class UserSetupContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeItem: `/admin/user-setup/${this.props.match.params.id}`,
            organisationUsers: [],
            userData: {},
            organisationJobRoles: [],
            jobRolesDepartments: [],
            jobRolesTeams: [],
            postOrganisationUsers: {
                firstName: '',
                lastName: '',
                status: '',
                emailAddress: '',
                team: 0,
                department: 0,
                jobRole: 0,
            },
            roles: [],
            userPermissions: [],
        }
        this.handleChange = this.handleChange.bind(this)
        this.addUser = this.addUser.bind(this)
        this.viewUser = this.viewUser.bind(this)
        this.updateUser = this.updateUser.bind(this)
        this.deleteUser = this.deleteUser.bind(this)
    }

    handleClick(value) {
        window.location.href = `${value}`
        this.setState({ activeItem: value })
    }

    handleChange({ target: { name, value } }, messageType) {
        this.setState({
            ...this.state,
            [messageType]: { ...this.state[messageType], [name]: value },
        })
        if (name === 'jobRole') {
            //very hacky way to get the departments that the job role belongs to for now until jobrole/jobroleid api works
            this.state.organisationJobRoles.forEach(jobRole => {
                if (jobRole.id === parseInt(value, 10)) {
                    if (jobRole.department != null) {
                        this.setState({
                            jobRolesDepartments: jobRole.department,
                        })
                    } else {
                        this.setState({ jobRolesDepartments: [] })
                    }
                    if (jobRole.team != null) {
                        this.setState({ jobRolesTeams: jobRole.team })
                    } else {
                        this.setState({ jobRolesTeams: [] })
                    }
                }
            })
        }
    }

    componentDidMount() {
        axios
            .all([
                axios.get(
                    BASE_URL +
                        `/organisations/${this.props.match.params.id}/jobRoles`
                ),
                axios.get(BASE_URL + `/roles`, {
                    headers: {
                        Authorization: `Bearer ${Auth.getToken()}`,
                        'content-type': 'application/json',
                    },
                }),
            ])
            .then(
                axios.spread((organisationJobRoles, roles) => {
                    console.log(
                        'DDS  ORGANIZATION ->',
                        organisationJobRoles,
                        roles
                    )
                    console.log(
                        'DDS  ROLES ->',

                        roles
                    )
                    this.setState({
                        organisationJobRoles: organisationJobRoles.data,
                    })
                    this.setState({ roles: roles.data })
                })
            )
        axios
            .get(
                BASE_URL + `/organisations/${this.props.match.params.id}/users`,
                {
                    params: {
                        department: 0,
                        team: 0,
                        jobRole: 0,
                        status: 0,
                    },
                }
            )
            .then(res => {
                this.setState({ organisationUsers: res.data })
            })
        axios
            .get(
                BASE_URL +
                    `/organisations/${this.props.match.params.id}/departments`,
                {
                    params: {
                        parentDepartment: 0,
                        departmentHead: 0,
                    },
                }
            )
            .then(res => {
                this.setState({ jobRolesDepartments: res.data })
            })
        axios
            .get(
                BASE_URL + `/organisations/${this.props.match.params.id}/teams`,
                {
                    params: {
                        department: 0,
                        teamLead: 0,
                        parentTeam: 0,
                    },
                }
            )
            .then(res => {
                this.setState({ jobRolesTeams: res.data })
            })
        axios.get(BASE_URL + `/userPermissions/1`, {}).then(res => {
            this.setState({ userPermissions: res.data })
        })
    }

    addUser(e) {
        e.preventDefault()
        axios
            .post(
                BASE_URL + `/organisations/${this.props.match.params.id}/users`,
                this.state.postOrganisationUsers
            )
            .then(() => location.reload())
            .catch(err => {
                this.setState({ errors: err.response.data })
            })
    }

    viewUser(e, userId) {
        e.preventDefault()
        axios
            .get(
                BASE_URL +
                    `/organisations/${this.props.match.params.id}/users/${userId}`
            )
            .then(res => {
                this.setState({ userData: res.data })
            })
            .then(res => {
                const {
                    state: { userData, postOrganisationUsers },
                } = this
                this.setState({
                    postOrganisationUsers: {
                        id: userData.id,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        department: userData.department,
                        team: userData.team,
                        status: userData.status.name,
                        emailAddress: userData.emailAddress,
                    },
                })
            })
    }

    updateUser(e, userId) {
        e.preventDefault()
        axios
            .put(
                BASE_URL +
                    `/organisations/${this.props.match.params.id}/users/${userId}`,
                this.state.postOrganisationUsers
            )
            .then(() =>
                this.props.history.push(
                    `/admin/user-setup/${this.props.match.params.id}`
                )
            )
            .then(() => location.reload())
    }

    deleteUser(e, userId) {
        e.preventDefault()
        axios
            .delete(
                BASE_URL +
                    `/organisations/${this.props.match.params.id}/users/${userId}`
            )
            .then(() =>
                this.props.history.push(
                    `/admin/user-setup/${this.props.match.params.id}`
                )
            )
            .then(() => location.reload())
    }

    render() {
        if (!this.state.postOrganisationUsers) return null
        const {
            organisationUsers,
            organisationJobRoles,
            jobRolesDepartments,
            jobRolesTeams,
            userData,
            postOrganisationUsers,
            roles,
            userPermissions,
        } = this.state
        return (
            <div>
                <SideMenu
                    activeItem={this.state.activeItem}
                    onMenuItemClick={this.handleClick}
                >
                    <Item label="Dashboard" value="/dashboard"></Item>
                    <Item label="Organisation" value="/organisation"></Item>
                    <Item label="Objectives" value="/objectives"></Item>
                    <Item label="Risks" value="/risks"></Item>
                    <Item
                        label="Programme & Projects"
                        value="/programme-and-projects"
                    ></Item>
                    <Item label="Reports" value="/reports"></Item>
                    <Item label="Admin" value="/admin">
                        <Item
                            label="Account Maintenance"
                            value={`/admin/account-maintenance/${this.props.match.params.id}`}
                        />
                        <Item
                            label="Reporting Structure"
                            value={`/admin/reporting-structure/${this.props.match.params.id}`}
                        />
                        <Item
                            label="User Setup"
                            value={`/admin/user-setup/${this.props.match.params.id}`}
                        />
                        <Item
                            label="Org Strategy"
                            value={`/admin/org-strategy/${this.props.match.params.id}`}
                        />
                    </Item>
                </SideMenu>
                <UserSetup
                    handleChange={this.handleChange}
                    addUser={this.addUser}
                    viewUser={this.viewUser}
                    updateUser={this.updateUser}
                    deleteUser={this.deleteUser}
                    userData={userData}
                    organisationUsers={organisationUsers}
                    organisationJobRoles={organisationJobRoles}
                    jobRolesDepartments={jobRolesDepartments}
                    jobRolesTeams={jobRolesTeams}
                    organisationId={this.props.match.params.id}
                    postOrganisationUsers={postOrganisationUsers}
                    roles={roles}
                    userPermissions={userPermissions}
                />
            </div>
        )
    }
}

export default UserSetupContainer
