import React, { useState, useEffect } from 'react'
import { Table, Button } from 'react-bootstrap'
import axios from 'axios'
import Auth from '../../lib/Auth.js'
import Swal from 'sweetalert2'
import { Popconfirm } from 'antd'
// import EditAfterAddModal from '../presentational/EditAfterAddModal.jsx'

const TreatmentPlanTable = ({
  treatmentTableData,
  firstPlanAdded,
  orgID,
  riskId = 0,
  updateData = () => {},
  actionOwner,
  frequencyOfReview,
  actionPriorities,
  actionEffectiveness,
  actionStatus,
  actionOwners,
  updateTreatments,
  editJustTreatmentHandler,
  deleteTreatmentMain,
}) => {
  const [tableData, setTableData] = useState([])
  const [users, setUsers] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [myTreatmentItem, setMyTreatmentItem] = useState([])

  useEffect(() => {
    if (treatmentTableData.length >= 2) {
      firstPlanAdded()
    }
  }, [treatmentTableData])

  useEffect(() => {
    setTableData(treatmentTableData)
  }, [treatmentTableData])
  ///organisations/{orgId}/riskTreatments

  useEffect(() => {
    // fetchTreatments()
    getUsers()
  }, [])

  const getUsers = () => {
    axios
      .get(BASE_URL + `/organisations/${orgID}/users`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        setUsers(res.data)
      })
  }

  const deleteTreatment = (treatmentTableData) => {
    axios
      .delete(
        BASE_URL +
          `/organisations/${orgID}/riskTreatments/${treatmentTableData.id}`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        Swal.fire('', 'Treatment deleted', 'success').then(() =>
          deleteTreatmentMain(treatmentTableData.id)
        )
      })
      .catch((err) =>
        Swal.fire(
          '',
          'Could not delete this treatment, please try again',
          'info'
        )
      )
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const editTreatment = (treatmentTableData, index) => {
    setMyTreatmentItem(treatmentTableData)
    editJustTreatmentHandler(treatmentTableData, index)
  }

  console.log({
    TPD: {
      treatmentTableData,
      firstPlanAdded,
      orgID,
      riskId,
      updateData,
      actionOwner,
      frequencyOfReview,
      actionPriorities,
      actionEffectiveness,
      actionStatus,
      actionOwners,
      updateTreatments,
      editJustTreatmentHandler,
      deleteTreatmentMain,
    },
  })

  const renderNewRow = (treatmentTableData, index) => {
    return (
      <tr key={index}>
        <td style={{ width: '8%' }}>{treatmentTableData.ref}</td>
        <td style={{ width: '20%' }}>{treatmentTableData.description}</td>
        <td style={{ width: '10%' }}>{treatmentTableData.ec}</td>
        <td style={{ width: '8%' }}>{treatmentTableData.dd}</td>
        <td style={{ width: '10%' }}>
          {
            actionPriorities?.filter(
              (item) => item.id == treatmentTableData.ap
            )[0]?.name
          }
        </td>
        <td style={{ width: '10%' }}>
          {' '}
          {actionStatus?.find((item) => item.id == treatmentTableData.as)?.name}
        </td>
        <td style={{ width: '9%' }}>{treatmentTableData.completed}%</td>
        <td style={{ width: '15%' }}>
          {
            users?.filter((item) => item.id == treatmentTableData.owner)[0]
              ?.name
          }
        </td>

        <td style={{ width: '11%' }}>
          <span className="buttons-container">
            <Button
              onClick={() => {
                console.log({ treatmentTableData })
                editTreatment(treatmentTableData, index)
              }}
            >
              <i className="fa fa-pencil"></i>
            </Button>
            <Popconfirm
              Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => deleteTreatment(treatmentTableData)}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <i className="fa fa-trash"></i>
              </Button>
            </Popconfirm>
          </span>
        </td>
      </tr>
    )
    // }
  }

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th style={{ width: '8%' }}>Ref</th>
            <th style={{ width: '20%' }}>Description</th>
            <th style={{ width: '10%' }}>Estimated Cost</th>
            <th style={{ width: '8%' }}>Due Date</th>
            <th style={{ width: '10%' }}>Action Priority</th>
            <th style={{ width: '10%' }}>Action Status</th>
            <th style={{ width: '9%' }}>% Completed</th>
            <th style={{ width: '15%' }}>Owner</th>
            <th style={{ width: '11%' }}></th>
          </tr>
        </thead>
        <tbody>{treatmentTableData.map(renderNewRow)}</tbody>
      </Table>
    </div>
  )
}

export default TreatmentPlanTable
