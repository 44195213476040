import React, { Component } from 'react'
import { Tabs, Tab, Form } from 'react-bootstrap'
import MyRisksForm from '../presentational/MyRisksForm.jsx'
import MyRisksTable from '../presentational/MyRisksTable.jsx'
import MyDeptRisksForm from '../presentational/MyDeptRisksForm.jsx'
import MyDeptRisksTable from '../presentational/MyDeptRisksTable.jsx'
import MyTasksTable from '../presentational/MyTasksTable.jsx'
import '../../style/container/risk-tables.scss'
import '../../style/container/objectives.scss'
import Auth from '../../lib/Auth.js'
import RiskEditForm from '../presentational/RiskEditForm.jsx'
class Risks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showRiskForm: false,
      showRiskTable: true,
      showDeptRiskForm: false,
      showDeptRiskTable: true,
      riskEventFromChild: '',
      riskImpactFromChild: '',
      riskCausesFromChild: '',
      riskTypeFromChild: '',
      netScoreFromChild: '',
      netValueFromChild: '',
      reviewDateFromChild: '',
      treatmentsFromChild: 0,
      deptRiskEventFromChild: '',
      deptRiskImpactFromChild: '',
      deptRiskCausesFromChild: '',
      deptRiskTypeFromChild: '',
      deptNetScoreFromChild: '',
      deptNetValueFromChild: '',
      deptOwnerFromChild: 0,
      tableProps: [
        {
          ref: 'test ref',
          event: 'test event',
          impact: 'test impact',
          causes: 'test causes',
          type: 'test type',
          score: 'test score',
          value: 'test value',
          treatments: 0,
          review: 'test date',
        },
      ],
      deptTableProps: [
        {
          ref: 'test ref',
          event: 'test event',
          impact: 'test impact',
          causes: 'test causes',
          type: 'test type',
          score: 'test score',
          value: 'test value',
          treatments: '',
          status: '',
          owner: 'test owner',
        },
      ],
      tabs: [
        { id: 1, label: 'My Risk', bgColor: '#28359D' },
        { id: 2, label: 'My Departmental Risks', bgColor: '#00838F' },
        { id: 3, label: 'Draft Risk', bgColor: '#FF8F00' },
        { id: 4, label: 'Deleted Risk', bgColor: '#FF4500' },
      ],
      selectedTab: 1,
      myTasksTableData: [
        {
          id: 1,
          ref: '1234',
          task: 'quarantine',
          type: 'emergency',
          status: 'Completed',
          start: '02/2020',
          due: '07/2020',
          completed: '100%',
          priority: 'high',
        },
        {
          id: 2,
          ref: '1234',
          task: 'vaccination',
          type: 'medical',
          status: 'Ongoing',
          start: '07/2020',
          due: 'undecided',
          completed: '15%',
          priority: 'high',
        },
      ],
      orgId: 0,
      showRiskEditForm: false,
      selectedRiskItem: null,
    }
  }

  componentWillMount() {
    let token = Auth.getToken()
    if (!token) {
    }
    const base64Url = token && token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    this.setState({
      orgId: JSON.parse(window.atob(base64)).Org,
    })
  }

  riskEditFormHandler = riskItem => {
    this.setState({
      showRiskTable: false,
      showRiskForm: false,
      showRiskEditForm: true,
      selectedRiskItem: riskItem,
    })
  }

  riskFormHandler = () => {
    this.setState({
      showRiskForm: true,
      showRiskTable: false,
    })
  }

  cancelClickHandler = () => {
    this.setState({
      showRiskForm: false,
      showRiskEditForm: false,
      showRiskTable: true,
    })
  }

  deptRiskFormHandler = () => {
    this.setState({
      showDeptRiskForm: true,
      showDeptRiskTable: false,
    })
  }

  renderTab = () => {
    switch (this.state.selectedTab) {
      case 1:
        return (
          <div>
            {this.state.showRiskTable ? (
              <MyRisksTable
                tableType="risk"
                tableProps={this.state.tableProps}
                tableTitle="My Departmental Risks"
                riskFormHandler={this.riskFormHandler}
                department={this.state.deptForGet}
                description={this.state.descForGet}
                owner={this.state.ownerForGet}
                orgId={this.state.orgId}
                riskEditFormHandler={this.riskEditFormHandler}
                tableIsOpen={this.state.showRiskTable}
              />
            ) : null}
            {this.state.showRiskForm ? (
              <MyRisksForm
                tableType="risk"
                switchMethod={this.formTableStateSwitch.bind(this)}
                cancelClickHandler={this.cancelClickHandler.bind(this)}
              />
            ) : null}
            {this.state.showRiskEditForm ? (
              <RiskEditForm
                tableType="risk"
                riskItem={this.state.selectedRiskItem}
                switchMethod={this.formTableStateSwitch.bind(this)}
                cancelClickHandler={this.cancelClickHandler.bind(this)}
              />
            ) : null}
          </div>
        )
      case 2:
        return (
          <div>
            {this.state.showDeptRiskTable ? (
              <MyDeptRisksTable
                deptTableProps={this.state.deptTableProps}
                tableTitle="My Risk"
                deptRiskFormHandler={this.deptRiskFormHandler}
                orgId={this.state.orgId}
                showDeptRiskTable={this.state.showDeptRiskTable}
              />
            ) : null}
            {this.state.showDeptRiskForm ? (
              <MyDeptRisksForm
                switchMethod={this.deptFormTableStateSwitch.bind(this)}
              />
            ) : null}
          </div>
        )
      case 3:
        return (
          <div>
            {this.state.showRiskTable ? (
              <MyRisksTable
                tableType="draftRisk"
                tableProps={this.state.tableProps}
                tableTitle="My Departmental Risks"
                riskFormHandler={this.riskFormHandler}
                department={this.state.deptForGet}
                description={this.state.descForGet}
                owner={this.state.ownerForGet}
                orgId={this.state.orgId}
                riskEditFormHandler={this.riskEditFormHandler}
                tableIsOpen={this.state.showRiskTable}
              />
            ) : null}
            {this.state.showRiskForm ? (
              <MyRisksForm
                tableType="draftRisk"
                switchMethod={this.formTableStateSwitch.bind(this)}
                cancelClickHandler={this.cancelClickHandler.bind(this)}
              />
            ) : null}
            {this.state.showRiskEditForm ? (
              <RiskEditForm
                tableType="draftRisk"
                riskItem={this.state.selectedRiskItem}
                switchMethod={this.formTableStateSwitch.bind(this)}
                cancelClickHandler={this.cancelClickHandler.bind(this)}
              />
            ) : null}
          </div>
        )
      case 4:
        return (
          <div>
            {this.state.showRiskTable ? (
              <MyRisksTable
                tableType="deletedRisk"
                tableProps={this.state.tableProps}
                tableTitle="My Departmental Risks"
                riskFormHandler={this.riskFormHandler}
                department={this.state.deptForGet}
                description={this.state.descForGet}
                owner={this.state.ownerForGet}
                orgId={this.state.orgId}
                riskEditFormHandler={this.riskEditFormHandler}
                tableIsOpen={this.state.showRiskTable}
              />
            ) : null}
            {this.state.showRiskForm ? (
              <MyRisksForm
                tableType="deletedRisk"
                switchMethod={this.formTableStateSwitch.bind(this)}
                cancelClickHandler={this.cancelClickHandler.bind(this)}
              />
            ) : null}
            {this.state.showRiskEditForm ? (
              <RiskEditForm
                tableType="deletedRisk"
                riskItem={this.state.selectedRiskItem}
                switchMethod={this.formTableStateSwitch.bind(this)}
                cancelClickHandler={this.cancelClickHandler.bind(this)}
              />
            ) : null}
          </div>
        )
      default:
    }
  }

  //Method passed to risks form
  calledFromChild(
    eventdata,
    impactdata,
    causesdata,
    typedata,
    scoredata,
    valuedata,
    treatdata,
    datedata
  ) {
    this.setState({
      riskEventFromChild: eventdata,
      riskImpactFromChild: impactdata,
      riskCausesFromChild: causesdata,
      riskTypeFromChild: typedata,
      netScoreFromChild: scoredata,
      netValueFromChild: valuedata,
      treatmentsFromChild: treatdata,
      reviewDateFromChild: datedata,
      showRiskForm: false,
      showRiskTable: true,
    })
  }

  formTableStateSwitch() {
    this.setState({
      showRiskForm: false,
      showRiskTable: true,
      showRiskEditForm: false,
    })
  }

  deptFormTableStateSwitch() {
    this.setState({
      showDeptRiskForm: false,
      showDeptRiskTable: true,
    })
  }
  //Method passed to dept risks form
  calledFromChildAgain(
    eventdata,
    impactdata,
    causesdata,
    typedata,
    scoredata,
    valuedata,
    ownerdata
  ) {
    this.setState({
      deptRiskEventFromChild: eventdata,
      deptRiskImpactFromChild: impactdata,
      deptRiskCausesFromChild: causesdata,
      deptRiskTypeFromChild: typedata,
      deptNetScoreFromChild: scoredata,
      deptNetValueFromChild: valuedata,
      deptOwnerFromChild: ownerdata,
      showDeptRiskForm: false,
      showDeptRiskTable: true,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.riskEventFromChild !== this.state.riskEventFromChild ||
      prevState.riskImpactFromChild !== this.state.riskImpactFromChild ||
      prevState.riskCausesFromChild != this.state.riskCausesFromChild ||
      prevState.riskTypeFromChild !== this.state.riskTypeFromChild ||
      prevState.netScoreFromChild !== this.state.netScoreFromChild ||
      prevState.netValueFromChild !== this.state.netValueFromChild ||
      prevState.reviewDateFromChild !== this.state.reviewDateFromChild
    ) {
      this.setState({
        tableProps: [
          ...this.state.tableProps,
          {
            ref: '',
            event: this.state.riskEventFromChild,
            impact: this.state.riskImpactFromChild,
            causes: this.state.riskCausesFromChild,
            type: this.state.riskTypeFromChild,
            score: this.state.netScoreFromChild,
            value: this.state.netValueFromChild,
            treatments: this.state.treatmentsFromChild,
            review: this.state.reviewDateFromChild,
          },
        ],
      })
    }
    if (
      prevState.deptRiskEventFromChild !== this.state.deptRiskEventFromChild ||
      prevState.deptRiskImpactFromChild !==
        this.state.deptRiskImpactFromChild ||
      prevState.deptRiskCausesFromChild !==
        this.state.deptRiskCausesFromChild ||
      prevState.deptRiskTypeFromChild !== this.state.deptRiskTypeFromChild ||
      prevState.deptNetScoreFromChild !== this.state.deptNetScoreFromChild ||
      prevState.deptNetValueFromChild !== this.state.deptNetValueFromChild ||
      prevState.deptOwnerFromChild !== this.state.deptOwnerFromChild
    ) {
      this.setState({
        deptTableProps: [
          ...this.state.deptTableProps,
          {
            ref: '',
            event: this.state.deptRiskEventFromChild,
            impact: this.state.deptRiskImpactFromChild,
            causes: this.state.deptRiskCausesFromChild,
            type: this.state.deptRiskTypeFromChild,
            score: this.state.deptNetScoreFromChild,
            value: this.state.deptNetValueFromChild,
            treatments: '',
            status: '',
            owner: this.state.deptOwnerFromChild,
          },
        ],
      })
    }
  }
  render() {
    return (
      <div style={{ ...this.props.style }} className="risk-setup-container">
        <div className="risk-tabs-container">
          {this.state.tabs.map(item => (
            <div
              key={item.id}
              style={{
                background:
                  item.id === this.state.selectedTab ? item.bgColor : '#c5cae9',
              }}
              onClick={() =>
                this.setState({
                  selectedTab: item.id,
                  showRiskForm: false,
                  showDeptRiskForm: false,
                  showRiskTable: true,
                  showDeptRiskTable: true,
                  showRiskEditForm: false,
                })
              }
              className={`tab-label ${
                item.id === this.state.selectedTab ? 'selected-tab' : ''
              }`}
            >
              {item.label}
            </div>
          ))}
        </div>
        <hr />
        {this.renderTab()}
      </div>
    )
  }
}

export default Risks
