import React, { useContext } from 'react'
import { uncertaintyType } from '../../../../common/uncertaintyType'
import userContext from '../../../../context/userContext'
import * as moment from 'moment'
export default function RiskRegisterDetailedTable({ risks }) {
  const { sessionDetails } = useContext(userContext)
  const currency = sessionDetails?.user?.organisation?.currencyCode

  const departmentName = Object.values(risks)[0]?.departmentName
  const risksArray = Object.values(risks)

  const calculateValue = React.useCallback((min, max, likely, probability) => {
    return (
      ((Number(min) + Number(max) + Number(likely)) / 3) *
      (Number(probability) / 100)
    ).toFixed(2)
  }, [])

  return risksArray.map((risk) => {
    return (
      <div className="risk-register-container my-4">
        <div className="row">
          <div className="col-12">
            <div className="title w-100 justify-content-between d-flex">
              <span>
                Department: &nbsp;<b>{departmentName}</b>
              </span>
              <span>
                Risk Owner: &nbsp;<b>{risk.ownerName}</b>
              </span>
              <span>
                Review Date: &nbsp;
                <b>{`${risk.reviewDate?.day}-${risk.reviewDate?.month}-${risk.reviewDate?.year}`}</b>
              </span>
            </div>
          </div>
          <div className="col-12 double-table-report">
            <table
              className="risk-register detailed organisational report"
              data-departmentname={departmentName}
              data-ownername={risk.ownerName}
              data-reviewdate={`${risk.reviewDate?.day}-${risk.reviewDate?.month}-${risk.reviewDate?.year}`}
            >
              <thead>
                <tr>
                  <th>Risk Description</th>
                  <th>Objectives Impacted</th>
                  <th>Risk Classification</th>
                  <th>Review Date</th>
                  <th>Risk Scores</th>

                  <th>Comments</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    {risk.ref}
                    <br />
                    <span>
                      <b>Risk Event:</b>
                      <span className="d-block">{risk?.eventDescription}</span>
                    </span>
                    <br />
                    <span><b>Causes:</b><span className="d-block">{risk?.eventCauses}</span></span>
                    <br />
                    <span><b>Risk Impact:</b><span className="d-block">{risk?.eventConsequences}</span></span>

                  </td>
                  <td>
                    {risk?.objectives.map((item) => (
                      <p>{item?.description}</p>
                    ))}
                  </td>
                  <td>
                    <span>
                      <b>Uncertainty:</b>
                      <span className="d-block">
                        {uncertaintyType(risk?.impactType?.name)}
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>Risk Category:</b>
                      <span className="d-block">{risk?.riskCategory?.name}</span>
                    </span>
                    <br />
                    <span>
                      <b>Risk Type:</b>
                      <span className="d-block">{risk?.riskType?.name}</span>
                    </span>
                  </td>
                  <td>{`${risk.reviewDate?.day}-${risk.reviewDate?.month}-${risk.reviewDate?.year}`}</td>

                  <td>
                    <span class="d-block p-1 border border-dark" style={{backgroundColor:risk?.scoreObject?.gross?.colour}}>
                      <b>Gross score:</b> &nbsp;
                      <span>{risk?.scoreObject?.gross?.score}</span>
                    </span>
                    <br />
                    <span class="d-block p-1 border border-dark" style={{backgroundColor:risk?.scoreObject?.net?.colour}}>
                      <b>Net score:</b> &nbsp;
                      <span> {risk?.scoreObject?.net?.score}</span>
                    </span>
                    <br />
                    <span class="d-block p-1 border border-dark" style={{backgroundColor:risk?.scoreObject?.target?.colour}}>
                      <b>Target score:</b> &nbsp;
                      <span>{risk?.scoreObject?.target?.score}</span>
                    </span>
                    <br />
                    <span>
                      <b>Gross Value@Risk:</b> &nbsp;
                      <span>
                        {calculateValue(
                          risk?.grossEmv.minValue,
                          risk?.grossEmv.maxValue,
                          risk?.grossEmv.likelyValue,
                          risk?.grossEmv.probability
                        )}
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>Net Value@Risk:</b> &nbsp;
                      <span>
                        {calculateValue(
                          risk?.netEmv.minValue,
                          risk?.netEmv.maxValue,
                          risk?.netEmv.likelyValue,
                          risk?.netEmv.probability
                        )}
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>Target Value@Risk:</b> &nbsp;
                      <span>
                        {calculateValue(
                          risk?.targetEmv.minValue,
                          risk?.targetEmv.maxValue,
                          risk?.targetEmv.likelyValue,
                          risk?.targetEmv.probability
                        )}
                      </span>
                    </span>
                  </td>
                  <td>{risk.comments}</td>
                </tr>
              </tbody>
            </table>

            {risk.treatments.length == 0 ? (
              <>
                <br />
                <p>
                  <b>Treatments Under Risk:</b>
                </p>
                <table>
                  <thead>
                    <tr>
                      <th>Treatment (Action Plan)</th>
                      <th>Action Priority</th>
                      <th>% Completed</th>
                      <th>Estimated Cost</th>
                      <th>Actual Cost</th>
                      <th>Owner</th>
                      <th>Due Date</th>
                      <th>No of Days Overdue</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="8" valign="top">
                        <b>There are no treatments for this Risk</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <br />
                <p>
                  <b>Treatments Under Risk:</b>
                </p>
                <table className="risk-treatment detailed organisational report">
                  <thead>
                    <tr>
                      <th>Treatment (Action Plan)</th>
                      <th>Action Priority</th>
                      <th>% Completed</th>
                      <th>Estimated Cost</th>
                      <th>Actual Cost</th>
                      <th>Owner</th>
                      <th>Due Date</th>
                      <th>No of Days Overdue</th>
                    </tr>
                  </thead>
                  <tbody>
                    {risk.treatments.map((item) => {
                      const dueDate = moment(item?.dueDate, 'YYYY-MM-DD')
                      return (
                        <tr>
                          <td>{item.description}</td>

                          <td>{item?.actionPriority?.name}</td>
                          {/* <td>{item?.actionStatus}</td> */}

                          {/* <td>{item?.focus}</td> */}

                          <td>{item?.completed}</td>
                          <td>
                            {currency} {item?.estimatedCost}
                          </td>
                          <td>
                            {currency} {item?.actualCost}
                          </td>
                          <td>{item?.ownerName}</td>
                          <td>{dueDate.format('DD-MM-YYYY')}</td>
                          <td>{moment().diff(dueDate, 'days').toString()}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    )
  })
}
