/* eslint-disable prettier/prettier */
import React, { useContext } from 'react'
import userContext from '../../../../context/userContext.js';
import Table from './RiskTreatmentReport.TableIndividual.jsx'
export default function RiskReport({ data }) {
    const { sessionDetails } = useContext(userContext)
    const profileImage = sessionDetails?.profileImage?.url;
    const { fullName, jobTitle } = sessionDetails?.user
    const [total, setTotal] = React.useState({
        estimatedCost: 0, actualCost: 0
    })
    console.log(data)
  

    React.useEffect(() => {
        console.log(data.data)
        const risksArray = Object.values(data.data).map(item=>item.treatments).flat()
        console.log(risksArray)
        const total = risksArray.reduce((acc,item) => {
            acc.estimatedCost += item.estimatedCost;
            acc.actualCost += item.actualCost;
            return acc;
        },{estimatedCost:0, actualCost:0})

        setTotal(total)
    }, [])
    return (
        <div>
            <div className="w-100">
                {
                    data?.criteria === 'INDIVIDUAL' && <div className="report-profile-section">
                        <img src={profileImage} alt={fullName} className="navProfileImage" />
                        <div className="name-holder">
                            <span>{fullName}</span>
                            <span>{jobTitle}</span>
                        </div>
                    </div>
                }
            </div>
            <Table risks={data.data} totals={total} />


        </div>
    )
}



