import React, { Component } from 'react'
import SideMenu, { Item } from 'react-sidemenu'
import Auth from '../../lib/Auth.js'
export default class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: ``,
      orgId: '',
    }
  }

  componentDidMount() {
    this.getOrgId()
  }

  getOrgId = () => {
    let token = Auth.getToken()
    if (!token) {
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    this.setState({ orgId: JSON.parse(window.atob(base64)).Org })
  }

  handleClick(value) {
    window.location.href = `${value}`
    //this.props.history.push( `${value}`  )
    this.setState({ activeItem: value })
  }

  render() {
    return (
      <div>
        <SideMenu
          activeItem={this.state.activeItem}
          onMenuItemClick={this.handleClick}
        >
          <Item
            label="Dashboard"
            onClick={() => {
              this.props.history.push('/')
            }}
          ></Item>
          <Item
            label="Organisation"
            onClick={() => {
              this.props.history.push('/organisation')
            }}
          ></Item>
          <Item
            label="Objectives"
            onClick={() => {
              this.props.history.push('/objectives')
            }}
          ></Item>
          <Item
            label="Risks"
            onClick={() => {
              this.props.history.push('/risks')
            }}
          ></Item>
          {/*   <Item
            label="Performance Management"
            onClick={() => {
              this.props.history.push('/programme-and-projects')
            }}
          ></Item> */}
          <Item
            label="Reports"
            onClick={() => {
              this.props.history.push('/reports')
            }}
          ></Item>
          {Auth.getRole() === 'Admin' ? (
            <Item label="Admin" value="/admin">
              <Item
                label="Account Maintenance"
                onClick={() => {
                  this.props.history.push(
                    `/admin/account-maintenance/${this.state.orgId}`
                  )
                }}
              />
              <Item
                label="Organisation Reporting Setup"
                value={`/admin/reporting-structure/${this.state.orgId}`}
                // onClick={() => {
                //   this.props.history.push(
                //     `/admin/reporting-structure/${this.state.orgId}`
                //   )
                // }}
              />
              <Item
                label="Organisation Strategy Setup"
                onClick={() => {
                  this.props.history.push(
                    `/admin/org-strategy/${this.state.orgId}`
                  )
                }}
              />
              <Item
                label="Risk Setup"
                onClick={() => {
                  this.props.history.push(
                    `/admin/risk-setup/${this.state.orgId}`
                  )
                }}
              />
              <Item
                label="Audit Report"
                onClick={() => {
                  this.props.history.push(`/admin/audit-report`)
                }}
              />
            </Item>
          ) : (
            <></>
          )}
        </SideMenu>
      </div>
    )
  }
}
