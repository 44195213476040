import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Navbar } from 'react-bootstrap'
//import NavBar from '../common/NavBar'
import SideMenu, { Item } from 'react-sidemenu'
import Risks from '../presentational/Risks.jsx'
import axios from 'axios'
import Sidebar from '../common/Sidebar.jsx'
import Auth from '../../lib/Auth.js'

class RisksContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      causes: 'test Causes',
      riskEvent: 'test Risk Event',
      riskImpact: 'test Risk Impact',
      riskType: 'test Risk Type',
      department: '',
      owner: 'test Owner',
      identifier: '',
      reviewdate: {
        dayOfMonth: '1',
        dayOfWeek: '1',
        dayOfYear: '1',
      },
      probability: 0,
      minValue: 0,
      maxValue: 0,
      targetValue: 0,
      basis: 'If it is showing this, then api not fetching data',
      // grossMonetaryValue: {
      //     probability: 0,
      //     minValue: 0,
      //     likelyValue: 0,
      //     maxValue: 0,
      //     targetValue: 0,
      //     basis: 0,
      // },
      // netMonetaryValue: {
      //     probability: 0,
      //     minValue: 0,
      //     likelyValue: 0,
      //     maxValue: 0,
      //     targetValue: 0,
      //     basis: 0,
      // },
      // targetMonetaryValue: {
      //     probability: 0,
      //     minValue: 0,
      //     likelyValue: 0,
      //     maxValue: 0,
      //     targetValue: 0,
      //     basis: 0,
      // },
      comments: '',
      errorData: '',
    }
  }

  //Initial api call on mount
  componentWillMount() {
    axios
      .get(BASE_URL + `/organisations/103/risks`, {
        params: {
          department: 0,
          eventDescription: '',
          // organisation: 103,
          owner: 112,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        this.setState({
          causes: res.data[0].eventCauses,
          riskEvent: res.data[0].eventDescription,
          riskImpact: res.data[0].eventConsequences,
          riskType: res.data[0].riskType.name,
          department: res.data.department,
          owner: res.data[0].owner,
          identifier: res.data.identifier,
          reviewdate: {
            dayOfMonth: res.data[0].reviewDate.dayOfMonth,
            dayOfWeek: res.data[0].reviewdate.dayOfWeek,
            dayOfYear: res.data[0].reviewDate.dayOfYear,
          },
        })
      })
      .catch(err => {})
  }

  componentDidMount() {}

  // checkGrossMonetaryValue() {
  //     axios
  //         .get(BASE_URL + `/organisations/103/risks`, {
  //             params: {
  //                 name: '',
  //                 objective: '',
  //                 organization: 0,
  //                 owner: 0,
  //             },
  //         })
  //         .then((res) => {
  //             this.setState({
  //                 grossMonetaryValue: res.data,
  //             })
  //             location.reload()
  //         })
  //         .catch((err) => {
  //             this.setState({ errorData: err.response })
  //         })
  // }

  // checkNetMonetaryValue() {
  //     axios
  //         .get(BASE_URL + `/organisations/103/risks`, {
  //             params: {
  //                 name: '',
  //                 objective: '',
  //                 organization: 0,
  //                 owner: 0,
  //             },
  //         })
  //         .then((res) => {
  //             this.setState({
  //                 netMonetaryValue: res.data,
  //             })
  //             location.reload()
  //         })
  //         .catch((err) => {
  //             this.setState({ errorData: err.response })
  //         })
  // }

  // checkTargetMonetaryValue() {
  //     axios
  //         .get(BASE_URL + `/risks`, {
  //             params: {
  //                 name: '',
  //                 objective: '',
  //                 organization: 0,
  //                 owner: 0,
  //             },
  //         })
  //         .then((res) => {
  //             this.setState({
  //                 targetMonetaryValue: res.data,
  //             })
  //             location.reload()
  //         })
  //         .catch((err) => {
  //             this.setState({ errorData: err.response })
  //         })
  // }

  // //post comments and update
  // postComments(e) {
  //     axios
  //         .post(BASE_URL + `/risks`, this.state.comments)
  //         .then(() => location.reload)
  //         .catch((err) => {
  //             this.setState({ errorData: err.response.data })
  //         })
  // }

  render() {
    return (
      <div>
        <div>
          <Sidebar {...this.props} />
        </div>
        <div>
          <Risks />
        </div>
      </div>
    )
  }
}
export default RisksContainer
