import React, { useState } from 'react'
import { Modal, Form, Container, Row, Col, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import '../../../style/container/objectives.scss'

export default function EditMyDeptKPIModal({
    indicators,
    frequency,
    users,
    show,
    submitForm,
    handleClose,
    item,
}) {

    const [formData, setData] = useState({
        year: item.year,
        objectives: item.objectives.map((item) => item.id),
        lead: item.lead,
        startDt: moment(
            `${item.startDt?.day}-${item.startDt?.month}-${item.startDt?.year}`,
            'DD-MM-YYYY'
        ).format('DD-MM-YY'),
        endDt: moment(
            `${item.endDt.day}-${item.endDt.month}-${item.endDt.year}`,
            'DD-MM-YYYY'
        ).format('DD-MM-YY'),
        weight: item.weight,
        frequencyOfReview:
        item.frequencyOfReview == null ? 0 : item.frequencyOfReview,
        parent: item.parentIndicator.id,
        description: item.description,
        ref: item.ref == null ? Math.floor(Math.random() * 1000) : item.ref,
        id: item.id,
    })

    const [startDate, setStartDate] = useState(
        moment(
            `${item.startDt?.day}-${item.startDt?.month}-${item.startDt?.year}`,
            'DD-MM-YYYY'
        ).format('DD-MM-YY')
    )
    const [endDate, setEndDate] = useState(
        moment(
            `${item.endDt.day}-${item.endDt.month}-${item.endDt.year}`,
            'DD-MM-YYYY'
        ).format('DD-MM-YY')
    )

    const handleChange = (e) => {
        let data = { ...formData }
        data[e.target.name] = e.target.value
        setData(data)
    }

    const handleDateChange = (e, name) => {
        let finalDate = moment(e).format('DD-MM-YY')
        if (name == 'startDate') {
            setStartDate(finalDate)
            setData({ ...formData, startDt: finalDate })
        } else {
            setEndDate(finalDate)
            setData({ ...formData, endDt: finalDate })
        }
    }

    const onIndicatorChange = (e) => {
        const indicator = indicators.filter(
            (item) => item.id == e.target.value
        )[0]
        let objectives = indicator.objectives.map((item) => item.id)
        if (objectives.length == 0) {
            objectives = [81]
        }
        setData({ ...formData, parent: e.target.value, objectives })
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                className="modal-main objectives-resize"
            >
                <Modal.Header>Edit My KPI</Modal.Header>
                <Modal.Body>
                    <Form>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Year:</Form.Label>
                                        <input
                                            type="number"
                                            name="year"
                                            onChange={(e) => handleChange(e)}
                                            value={formData.year}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                                <Col></Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            Departmental Indicator:
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={onIndicatorChange}
                                            value={formData.parent}
                                        >
                                            <option value="-"></option>
                                            {indicators.map((item) => (
                                                <option value={item.id}>
                                                    {item.description}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            Personal Indicator:
                                        </Form.Label>
                                        <input
                                            as="text"
                                            defaultValue=""
                                            onChange={(e) => handleChange(e)}
                                            name="description"
                                            value={formData.description}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Lead:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            defaultValue=""
                                            name="lead"
                                            onChange={(e) => handleChange(e)}
                                            value={formData.lead}
                                        >
                                            <option disabled value="">
                                                -
                                            </option>
                                            {users.map((user) => (
                                                <option
                                                    key={user.id}
                                                    value={user.id}
                                                >
                                                    {user.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Start Date:</Form.Label>
                                        <DatePicker
                                            format="dd-mm-yy"
                                            name="startDate"
                                            onChange={(e) =>
                                                handleDateChange(e, 'startDate')
                                            }
                                            value={startDate}
                                            autoComplete="off"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>End Date:</Form.Label>
                                        <DatePicker
                                            format="dd-mm-yy"
                                            name="endDate"
                                            onChange={(e) =>
                                                handleDateChange(e, 'endDate')
                                            }
                                            value={endDate}
                                            autoComplete="off"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            Review Frequency:
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="frequencyOfReview"
                                            onChange={(e) => handleChange(e)}
                                            value={formData.frequencyOfReview}
                                        >
                                            <option disabled value={0}>
                                                -
                                            </option>
                                            {frequency.map((freq) => (
                                                <option
                                                    key={freq.id}
                                                    value={freq.id}
                                                >
                                                    {freq.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                                <Col></Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={(e) => submitForm(e, formData)}>
                            Save
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
