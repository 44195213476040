import React, { Component, useState, useEffect } from 'react'
import axios from 'axios'
import { useFormik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import { Modal, Button } from 'react-bootstrap'
import * as moment from 'moment'
import ButtonsHeader from '../../../common/EditDeleteTH.jsx'
import ButtonActions from '../../../common/EditDeleteButtons.jsx'
import { InfoToolTip } from '../../../common/InfoTooltip.jsx'
import Auth from '../../../../lib/Auth.js'

export default class StrategicGoalsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      users: [],
      goalCategories: [],
      showEdit: false,
      showAdd: false,
      loading: true,
      orgId: props.orgId,
      selectedItem: null,
      page: 0,
      rowsPerPage: 10,
      totalPages: 0,
    }
  }

  componentWillReceiveProps(props) {
    this.setState({ orgId: props.orgId })
    this.setState({ users: props.users })
    this.setState({ goalCategories: props.goalCategories })
  }

  componentDidMount() {
    this.fetchData()
    this.getUsers()
    this.getGoalCategories()
    this.getUsers()
  }

  getPageRows = () => {
    var chunks = [],
      i = 0,
      n = this.state.tableData.length
    while (i < n) {
      chunks.push(this.state.tableData.slice(i, (i += this.state.rowsPerPage)))
    }

    return chunks[this.state.page] || this.state.tableData
  }

  getTotalPages = () => {
    var chunks = [],
      i = 0,
      n = this.state.tableData.length
    while (i < n) {
      chunks.push(this.state.tableData.slice(i, (i += this.state.rowsPerPage)))
    }
    return chunks.length
  }

  fetchData() {
    axios
      .get(BASE_URL + `/goals`, {
        params: {
          organisation: this.props.match.params.id,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({ tableData: res.data, page: 0, loading: false }, () =>
            this.setState({ totalPages: this.getTotalPages() })
          )
        }
      })
  }

  getUsers() {
    axios
      .get(BASE_URL + `/organisations/${this.props.match.params.id}/users`, {
        params: {
          department: 0,
          team: 0,
          jobRole: 0,
          status: 0,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({ users: res.data, loading: false })
        }
      })
  }

  getGoalCategories() {
    axios
      .get(BASE_URL + `/goalCategories`, {
        params: {
          organisation: this.props.match.params.id,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({ goalCategories: res.data, loading: false })
        }
      })
  }

  handleEdit = (e, item) => {
    this.setState({ selectedItem: item }, () =>
      this.setState({ showEdit: true })
    )
  }

  deleteItem = (e, item) => {
    this.setState({ loading: true }, () => {
      axios
        .delete(BASE_URL + `/goals/${item.id}`, {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        })
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }

  renderTableData() {
    return this.getPageRows().map((item, index) => {
      return (
        <tr className="table-data" key={index}>
          {/* <td>{item.year}</td> */}
          <td style={{ width: '9%' }}>{item.ref}</td>
          <td style={{ width: '10%' }}>{item.category.name}</td>
          <td>{item.description}</td>
          <td style={{ width: '9%' }}>
            {moment(
              `${item.startDt.day}/${item.startDt.month}/
                        ${item.startDt.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td style={{ width: '9%' }}>
            {moment(
              `${item.endDt.day}/${item.endDt.month}/
                        ${item.endDt.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td style={{ width: '10%' }}>
            {this.state.users.filter(user => user.id === item.lead)[0]?.name}
          </td>
          <ButtonActions
            itemId=""
            dataTarget=""
            editAction={e => this.handleEdit(e, item)}
            deleteAction={e => this.deleteItem(e, item)}
          />
        </tr>
      )
    })
  }

  addItem = values => {
    let data = values
    data.startDt = moment(data.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.endDt = moment(data.endDt, 'DD-MM-YY').format('YYYY-MM-DD')
    this.setState({ showAdd: false, loading: true }, () => {
      axios
        .post(
          BASE_URL + `/goals`,
          {
            ...data,
            organisation: this.state.orgId,
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }

  editItem = values => {
    let data = { ...values }
    data.startDt = moment(values.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.endDt = moment(values.endDt, 'DD-MM-YY').format('YYYY-MM-DD')

    this.setState({ showEdit: false, loading: true }, () => {
      axios
        .put(
          BASE_URL + `/goals/${data.id}`,
          {
            ...data,
            organisation: this.state.orgId,
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }
  render() {
    return (
      <div className="table-container px-5 my-5">
        <h4>
          <InfoToolTip title={'Goals'} text={' Goals Info text'}></InfoToolTip>
        </h4>
        <p>
          Add New{' '}
          <i
            className="fa fa-plus"
            onClick={() => this.setState({ showAdd: true })}
          />
        </p>

        <table className="goal-categories-table" style={{ width: '100%' }}>
          <thead className="strategic-goals-table">
            <tr className="bg-table-header">
              {/* <th>Year</th> */}
              <th>Ref</th>
              <th>Category</th>
              <th>Strategic Goal</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Lead</th>
              <ButtonsHeader />
            </tr>
          </thead>
          <tbody>
            {this.state.loading ? (
              <tr className="text-center">
                <td colSpan={5}>Loading...</td>{' '}
              </tr>
            ) : (
              this.renderTableData()
            )}
          </tbody>
        </table>

        <div className="text-right mt-3">
          <span style={{ fontSize: '14px' }}>
            Showing page {this.state.page + 1} of {this.state.totalPages}
          </span>
          <button
            className="previous-page btn btn-primary mx-2"
            disabled={this.state.page == 0}
            onClick={() =>
              this.setState(prevState => ({
                page: prevState.page - 1,
              }))
            }
          >
            Prev
          </button>
          <button
            disabled={this.state.page === this.state.totalPages - 1}
            className="next-page btn btn-primary"
            onClick={() =>
              this.setState(prevState => ({
                page: prevState.page + 1,
              }))
            }
          >
            Next
          </button>
        </div>

        {this.state.showAdd && (
          <AddTableItemModal
            showModal={this.state.showAdd}
            addField={this.addItem}
            orgId={this.state.orgId}
            users={this.state.users}
            goalCategories={this.state.goalCategories}
            dismissModal={() => this.setState({ showAdd: false })}
          />
        )}

        {this.state.showEdit && (
          <EditTableItemModal
            showModal={this.state.showEdit}
            editField={this.editItem}
            orgId={this.state.orgId}
            users={this.state.users}
            goalCategories={this.state.goalCategories}
            dismissModal={() => this.setState({ showEdit: false })}
            item={this.state.selectedItem}
          />
        )}
      </div>
    )
  }
}

const AddTableItemModal = props => {
  const [show, setModal] = useState(props.showModal)
  const submitRef = React.useRef()
  const [startDt, setStartDt] = useState(null)
  const [endDt, setEndDt] = useState(null)

  const handleClose = () => {
    props.dismissModal()
  }

  const handleSave = () => {
    submitRef.current.click()
  }

  const handleEndDateChange = e => {
    setEndDt(moment(e).format('DD-MM-YY'))
  }

  const handleStartDateChange = e => {
    setStartDt(moment(e).format('DD-MM-YY'))
  }

  const formik = useFormik({
    initialValues: {
      year: '',
      ref: '',
      category: '',
      description: '',
      lead: '',
      organisation: props.orgId,
    },
    validationSchema: Yup.object({
      year: Yup.string().required('Required'),
      ref: Yup.string().required('Required'),
      category: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
    }),
    onSubmit: values => {
      props.addField({ ...values, startDt, endDt })
    },
  })

  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add New</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label htmlFor="year">Year</label>
            <input
              id="year"
              name="year"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.year}
            />
            {formik.touched.year && formik.errors.year ? (
              <div className="text-center text-red pr-5">
                {formik.errors.year}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="ref">Ref</label>
            <input
              id="ref"
              name="ref"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ref}
            />
            {formik.touched.ref && formik.errors.ref ? (
              <div className="text-center text-red pr-5">
                {formik.errors.ref}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              defaultValue={'default'}
              id="category"
              name="category"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="default" disabled="disabled"></option>
              {props.goalCategories.map((goalCategory, i) => (
                <option key={i} value={goalCategory.id}>
                  {goalCategory.name}
                </option>
              ))}
            </select>
            {formik.touched.category && formik.errors.category ? (
              <div className="text-center text-red pr-5">
                {formik.errors.category}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="lead">Strategic Lead</label>
            <select
              defaultValue={'default'}
              id="lead"
              name="lead"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="">-</option>
              {props.users.map((user, i) => (
                <option key={i} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
            {formik.touched.lead && formik.errors.lead ? (
              <div className="text-center text-red pr-5">
                {formik.errors.lead}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="description">Strategic Goal</label>
            <textarea
              id="description"
              name="description"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="text-center text-red pr-5">
                {formik.errors.description}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="startDt">Start Date</label>
            <DatePicker
              value={startDt}
              name="startDt"
              onChange={e => handleStartDateChange(e)}
              dateFormat="dd-mm-yy"
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <label htmlFor="endDt">End Date</label>
            <DatePicker
              value={endDt}
              name="endDt"
              onChange={e => handleEndDateChange(e)}
              dateFormat="dd-mm-yy"
              autoFill={false}
              autoComplete="off"
            />
          </div>

          <button type="submit" ref={submitRef} className="d-none">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const EditTableItemModal = props => {
  const [show, setModal] = useState(props.showModal)
  const submitRef = React.useRef()
  const item = props.item
  const [startDt, setStartDt] = useState(
    moment(
      `${item.startDt.day}-${item.startDt.month}-${item.startDt.year}`,
      'DD-MM-YYYY'
    ).format('DD-MM-YY')
  )
  const [endDt, setEndDt] = useState(
    moment(
      `${item.endDt.day}-${item.endDt.month}-${item.endDt.year}`,
      'DD-MM-YYYY'
    ).format('DD-MM-YY')
  )

  const handleEndDateChange = e => {
    setEndDt(moment(e).format('DD-MM-YY'))
  }

  const handleStartDateChange = e => {
    setStartDt(moment(e).format('DD-MM-YY'))
  }

  const handleClose = () => {
    props.dismissModal()
  }

  const handleSave = () => {
    submitRef.current.click()
  }

  const formik = useFormik({
    initialValues: {
      year: item.year,
      ref: item.ref,
      category: item.category.id,
      description: item.description,
      lead: item.lead,
      organisation: props.orgId,
    },
    validationSchema: Yup.object({
      year: Yup.string().required('Required'),
      ref: Yup.string().required('Required'),
      category: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
      lead: Yup.string().required('Required'),
    }),
    onSubmit: values => {
      props.editField({
        ...values,
        startDt: startDt,
        endDt: endDt,
        id: item.id,
      })
    },
  })

  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label htmlFor="year">Year</label>
            <input
              id="year"
              name="year"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.year}
            />
            {formik.touched.year && formik.errors.year ? (
              <div className="text-center text-red pr-5">
                {formik.errors.year}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="ref">Ref</label>
            <input
              id="ref"
              name="ref"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ref}
            />
            {formik.touched.ref && formik.errors.ref ? (
              <div className="text-center text-red pr-5">
                {formik.errors.ref}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              defaultValue={'default'}
              id="category"
              name="category"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.category}
            >
              <option value="default" disabled="disabled"></option>
              {props.goalCategories.map((goalCategory, i) => (
                <option key={i} value={goalCategory.id}>
                  {goalCategory.name}
                </option>
              ))}
            </select>
            {formik.touched.category && formik.errors.category ? (
              <div className="text-center text-red pr-5">
                {formik.errors.category}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="lead">Strategic Lead</label>
            <select
              defaultValue={'default'}
              id="lead"
              name="lead"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lead}
            >
              <option value="">-</option>
              {props.users.map((user, i) => (
                <option key={i} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
            {formik.touched.lead && formik.errors.lead ? (
              <div className="text-center text-red pr-5">
                {formik.errors.lead}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="description">Strategic Goal</label>
            <textarea
              id="description"
              name="description"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="text-center text-red pr-5">
                {formik.errors.description}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="startDt">Start Date</label>
            <DatePicker
              value={startDt}
              name="startDt"
              onChange={e => handleStartDateChange(e)}
              dateFormat="dd-mm-yy"
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <label htmlFor="endDt">End Date</label>
            <DatePicker
              value={endDt}
              name="endDt"
              onChange={e => handleEndDateChange(e)}
              dateFormat="dd-mm-yy"
              autoFill={false}
              autoComplete="off"
            />
          </div>

          <button type="submit" ref={submitRef} className="d-none">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
