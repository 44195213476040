import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import {
  Form,
  Container,
  Row,
  Col,
  Button,
  Table,
  CloseButton,
} from 'react-bootstrap'
import axios from 'axios'
import ViewMatrix from '../presentational/ViewMatrix.jsx'
import ViewImpact from '../presentational/ViewImpact.jsx'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import TreatmentPlanTable from '../presentational/TreatmentPlanTable.jsx'
import AddActionPlanModal from '../presentational/AddActionPlanModal.jsx'
import Auth from '../../lib/Auth.js'
import Swal from 'sweetalert2'
import '../../style/presentational/risk-form.scss'
import { isEmpty } from 'lodash'
import sortBy from 'lodash/fp/sortBy'

class MyDeptRisksForm extends Component {
  grossHighestRAGColor
  grossHighestRAGNumber
  netHighestRAGColor
  netHighestRAGNumber
  targetHighestRAGColor
  targetHighestRAGNumber

  constructor(props) {
    super(props)
    this.state = {
      // showModal: false,
      //--------causes, event, impact....remove duplicate
      riskEvent: '',
      riskImpact: '',
      riskCauses: '',
      //-----------states passed in post method---------------------------
      riskType: '',
      riskCategory: '',
      riskstatus: '',
      reviewDate: '',
      freqReview: '',
      selectedUncertainty: 'POSITIVE',
      riskOwner: 0,
      impactDim: 0,
      initialProbabGross: 0,
      initialProbabNet: 0,
      initialProbabTarget: 0,
      initialImpactGross: 0,
      initialImpactNet: 0,
      initialImpactTarget: 0,

      //--------------------------------------------------------
      netScore: '',

      treatments: 0,
      treatmentStatus: '',
      riskStatus: [],
      impactDimentions: [],
      riskTypes: [],
      riskCategories: [],
      reviewFrequency: [],
      impactDescriptions: [],
      probabilities: [],
      selectedProbabilityGross: '',
      selectedProbabilityNet: '',
      selectedProbabilityTarget: '',
      descProbability: '',
      ragColor: '#fff',
      ragNumber: '0',
      riskRating: '',
      grossProbab: 0,
      grossMin: 0,
      grossMax: 0,
      grossLikely: 0,
      grossValue: 0,
      netProbab: 0,
      netMin: 0,
      netMax: 0,
      netLikely: 0,
      netValue: 0,
      targetProbab: 0,
      targetMin: 0,
      targetMax: 0,
      targetLikely: 0,
      targetValue: 0,
      grossAssessmentEvalTable: [],
      netAssessmentEvalTable: [],
      targetAssessmentEvalTable: [],
      grossHighestRAGNumber: 0,
      grossHighestRAGColor: '#fff',
      netHighestRAGNumber: 0,
      netHighestRAGColor: '#fff',
      targetHighestRAGNumber: 0,
      targetHighestRAGColor: '#fff',
      colorMan: true,
      descriptionFromChild: '',
      estimatedCostFromChild: 0,
      dueDateFromChild: '',
      actionPriorityFromChild: '',
      actionStatusFromChild: '',
      ownerFromChild: '',
      completedFromChild: 0,
      treatmentTableData: [
        {
          ref: 'initial ref',
          description: 'initial description',
          ec: 'initial estimated cost',
          dd: 'initial due date',
          ap: 'initial action priority',
          as: 'initial action status',
          completed: '50',
          owner: 'initial owner',
        },
      ],
      showModal: false,
      actionEffectiveness: [],
      actionOwner: '',
      actionPriorities: [],
      noPlanYet: true,
      orgId: 0,
      negativeProbabilityImpacts: [],
      positiveProbabilityImpacts: [],
      positiveImpacts: [],
      negativeImpacts: [],
      usersList: [],
      objectivesData: [],
      departments: [],
      //NEWSTATES....
      selectedIdentifier: '',
    }
    this.sendToParentAgain = this.sendToParentAgain.bind(this)
    this.probabSelectNet = this.probabSelectNet.bind(this)
    this.probabSelectGross = this.probabSelectGross.bind(this)
    this.probabSelectTarget = this.probabSelectTarget.bind(this)
    // this.setMinandMaxProbability = this.setMinandMaxProbability.bind(this)
    // this.getNetvalue = this.getNetvalue.bind(this)
    // this.renderRAG = this.renderRAG.bind(this)
    this.setRiskRating = this.setRiskRating.bind(this)
    // this.viewMatrixClicked = this.viewMatrixClicked.bind(this)
    this.actionPlanClickHandler = this.actionPlanClickHandler.bind(this)
  }

  componentWillMount() {
    let token = Auth.getToken()
    if (!token) {
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    this.setState({
      orgId: JSON.parse(window.atob(base64)).Org,
    })
  }

  saveClickHandler = () => {
    axios
      .post(
        BASE_URL + `/organisations/${this.state.orgId}/risks`,
        {
          eventCauses: this.state.riskCauses,
          // eventDescription: this.state.riskEvent,
          eventDescription: this.state.riskEvent,
          eventConsequences: this.state.riskImpact,
          objectives: [0],
          uncertaintyType: this.state.selectedUncertainty,
          department: 0,
          owner: 1,
          ref: JSON.stringify(Math.floor(Math.random() * 1000 + 1)),
          identifier: this.state.selectedIdentifier.trim(),
          riskType: this.state.riskType,
          riskCategory: this.state.riskCategory,
          riskStatus: this.state.riskstatus,
          reviewFrequency: this.state.freqReview,
          reviewDate: this.state.reviewDate,
          grossEmv: {
            probability: this.state.grossProbab,
            minValue: this.state.grossMin,
            likelyValue: this.state.grossLikely,
            maxValue: this.state.grossMax,
            assumptionBasis: 'Gross Assumption',
          },
          grossRiskAssessment: {
            riskAssessmentMessages: [
              {
                riskDomain: this.state.impactDim,
                impact: this.state.initialImpactGross,
                probability: this.state.initialProbabGross,
              },
            ],
          },
          netEmv: {
            probability: this.state.netProbab,
            minValue: this.state.netMin,
            likelyValue: this.state.netLikely,
            maxValue: this.state.netMax,
            assumptionBasis: 'Net Assumption',
          },
          netRiskAssessment: {
            riskAssessmentMessages: [
              {
                riskDomain: this.state.impactDim,
                impact: this.state.initialImpactNet,
                probability: this.state.initialProbabNet,
              },
            ],
          },
          targetEmv: {
            probability: this.state.targetProbab,
            minValue: this.state.targetMin,
            likelyValue: this.state.targetLikely,
            maxValue: this.state.targetMax,
            assumptionBasis: 'Target Assumption',
          },
          targetRiskAssessment: {
            riskAssessmentMessages: [
              {
                riskDomain: this.state.impactDim,
                impact: this.state.initialImpactTarget,
                probability: this.state.initialProbabTarget,
              },
            ],
          },
          comments: 'string',
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then(res => {
        if (res.status == 200) {
          Swal.fire({
            title: 'Data posted successfully',
            text: 'Your form data is now present in the table',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
          })
        }
      })
      .catch(err => {
        Swal.fire({
          title: 'Data post unsuccessful',
          text: `${err}`,
          icon: 'warning',
          timer: 5000,
        })
      })
    // this.props.data.getParamsOnSubmit(0, this.state.riskEvent, 1)
    this.props.switchMethod()
  }

  // renderUsers() {
  //     return this.state.usersList.map((item) => {
  //         return <option>{item.name}</option>
  //     })
  // }

  getPositiveRating() {
    return this.state.positiveImpacts.map(item => {
      return <option value={item.name}>{item.name}</option>
    })
  }

  getNegativeRating() {
    return this.state.negativeImpacts.map(item => {
      return <option value={item.name}>{item.name}</option>
    })
  }

  firstPlanAdded = () => {
    this.setState({
      noPlanYet: false,
    })
  }

  sendToParentAgain(e) {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  setGrossProbab(e) {
    this.setState({
      grossProbab: e.target.value,
    })
  }

  setGrossMax(e) {
    this.setState({
      grossMax: e.target.value,
    })
  }

  setGrossMin(e) {
    this.setState({
      grossMin: e.target.value,
    })
  }

  setGrossLikely(e) {
    this.setState({
      grossLikely: e.target.value,
    })
  }

  setNetProbab(e) {
    this.setState({
      netProbab: e.target.value,
    })
  }

  setNetMax(e) {
    this.setState({
      netMax: e.target.value,
    })
  }

  setNetMin(e) {
    this.setState({
      netMin: e.target.value,
    })
  }

  setNetLikely(e) {
    this.setState({
      netLikely: e.target.value,
    })
  }

  setTargetProbab(e) {
    this.setState({
      targetProbab: e.target.value,
    })
  }

  setTargetMax(e) {
    this.setState({
      targetMax: e.target.value,
    })
  }

  setTargetMin(e) {
    this.setState({
      targetMin: e.target.value,
    })
  }

  setTargetLikely(e) {
    this.setState({
      targetLikely: e.target.value,
    })
  }

  componentWillReceiveProps() {
    this.setState({
      actionOw: this.props.actionOwner,
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.descriptionFromChild !== this.state.descriptionFromChild ||
      prevState.estimatedCostFromChild !== this.state.estimatedCostFromChild ||
      prevState.dueDateFromChild != this.state.dueDateFromChild ||
      prevState.actionPriorityFromChild !==
        this.state.actionPriorityFromChild ||
      prevState.actionStatusFromChild !== this.state.actionStatusFromChild ||
      prevState.ownerFromChild !== this.state.ownerFromChild ||
      prevState.reviewDateFromChild !== this.state.reviewDateFromChild ||
      prevState.completedFromChild !== this.state.completedFromChild
    ) {
      this.setState({
        treatmentTableData: [
          ...this.state.treatmentTableData,
          {
            ref: 'next ref',
            description: this.state.descriptionFromChild,
            ec: this.state.estimatedCostFromChild,
            dd: this.state.dueDateFromChild,
            ap: this.state.actionPriorityFromChild,
            as: this.state.actionStatusFromChild,
            completed: this.state.completedFromChild,
            owner: this.state.ownerFromChild,
          },
        ],
        treatments: prevState.treatments + 1,
      })
    }
  }

  actionPlanClickHandler = () => {
    this.setState({
      showModal: true,
    })
  }

  getObjectives() {
    return this.state.objectivesData.map(item => {
      return <option>{item.description}</option>
    })
  }

  fetchData() {
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/impactDimensions`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            impactDimentions: res.data,
            impactDim: res.data[0].id,
          })
        }
      }),
      axios
        .get(BASE_URL + `/organisations/${this.state.orgId}/riskStatuses`, {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        })
        .then(res => {
          if (res.status === 200) {
            this.setState({
              riskStatus: res.data,
              riskstatus: res.data[0].id,
            })
          }
        })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/probabilities`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            probabilities: res.data,
            selectedProbabilityGross: res.data[0].id,
            selectedProbabilityNet: res.data[0].id,
            selectedProbabilityTarget: res.data[0].id,
            initialProbabGross: res.data[0].id,
            initialProbabNet: res.data[0].id,
            initialProbabTarget: res.data[0].id,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/riskTypes`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            riskTypes: res.data,
            riskType: res.data[0].id,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/riskCategories`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            riskCategories: res.data,
            riskCategory: res.data[0].id,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/frequencyOfReviews`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            reviewFrequency: res.data,
            freqReview: res.data[0].id,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/impactDescriptions`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            impactDescriptions: res.data,
            initialImpactGross: res.data[0].riskImpact.id,
            initialImpactNet: res.data[0].riskImpact.id,
            initialImpactTarget: res.data[0].riskImpact.id,
          })
        }
      })
    //Call this indise action plan modal
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/priorities`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            actionPriorities: res.data,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/effectiveness`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            actionEffectiveness: res.data,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/probabilityImpacts`, {
        params: {
          impactType: 'NEGATIVE',
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            negativeProbabilityImpacts: res.data,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/probabilityImpacts`, {
        params: {
          impactType: 'POSITIVE',
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            positiveProbabilityImpacts: res.data,
          })
        }
      })

    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/impacts`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          const positiveImpacts = res.data.filter(
            item => item.impactType.name === 'POSITIVE'
          )
          const negativeImpacts = res.data.filter(
            item => item.impactType.name === 'NEGATIVE'
          )
          this.setState({
            negativeImpacts,
            positiveImpacts,
            loading: false,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/users`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            usersList: res.data,
          })
        }
      })
    axios
      .get(BASE_URL + `/objectives`, {
        params: {
          organisation: this.state.orgId,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status == 200) {
          this.setState({
            objectivesData: res.data,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/departments`, {
        params: {
          parentDepartment: 0,
          departmentHead: 0,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            departments: res.data,
          })
        }
      })
  }

  renderUsers() {
    const { usersList } = this.state
    return (
      !isEmpty(sortBy('fullName')(usersList)) &&
      sortBy('fullName')(usersList).map(item => {
        return <option value={item.id}>{item.name}</option>
      })
    )
  }

  renderRiskStatus() {
    return this.state.riskStatus.map(item => {
      return <option value={item.id}>{item.name}</option>
    })
  }
  renderRiskType() {
    return this.state.riskTypes.map(item => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  renderRiskCategory() {
    return this.state.riskCategories.map(item => {
      return <option value={item.id}>{item.name}</option>
    })
  }
  renderFreqofReview() {
    return this.state.reviewFrequency.map(item => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  renderProbabilities() {
    return this.state.probabilities.map(item => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  renderImpactDimensions() {
    return this.state.impactDimentions.map(item => {
      return (
        <li>
          <input
            type="text"
            value={item.name}
            // readOnly
            style={{
              height: '38px',
              backgroundColor: 'white',
              border: '1px solid rgb(48, 70, 106)',
              borderRadius: '5px',
            }}
          />
        </li>
      )
    })
  }

  viewMatrixClickHandler = () => {
    this.setState({
      showViewMatrix: true,
    })
  }

  viewImpactClickHandler = () => {
    this.setState({
      showViewImpact: true,
    })
  }

  //gross rag returns
  renderGrossRAG() {
    let higherNumber = 0
    let higherColor = 0
    const grossRagItems = this.state.impactDimentions.map((item, index) => {
      const data = this.getRAGData(item.id)
      if (data.ragNumber > higherNumber) {
        higherNumber = data.ragNumber
        higherColor = data.ragColor
      }
      return (
        <li
          style={{
            backgroundColor: data?.ragColor,
            width: '60px',
            height: '38px',
            paddingBottom: '5px',
            border: '3px solid white',
            textAlign: 'center',
          }}
        >
          {data?.ragNumber}
        </li>
      )
    })

    this.grossHighestRAGNumber = higherNumber
    this.grossHighestRAGColor = higherColor
    return grossRagItems
  }

  //net RAG returns
  renderNetRAG() {
    let higherNumber = 0
    let higherColor = 0
    const netRagItems = this.state.impactDimentions.map((item, index) => {
      const data = this.getNetRAGData(item.id)
      if (data.ragNumber > higherNumber) {
        higherNumber = data.ragNumber
        higherColor = data.ragColor
      }
      return (
        <li
          style={{
            backgroundColor: data?.ragColor,
            width: '60px',
            height: '38px',
            paddingBottom: '5px',
            border: '3px solid white',
            textAlign: 'center',
          }}
        >
          {data?.ragNumber}
        </li>
      )
    })

    this.netHighestRAGNumber = higherNumber
    this.netHighestRAGColor = higherColor
    return netRagItems
  }

  //target RAG returns
  renderTargetRAG() {
    let higherNumber = 0
    let higherColor = 0
    const targetRagItems = this.state.impactDimentions.map((item, index) => {
      const data = this.getTargetRAGData(item.id)
      if (data.ragNumber > higherNumber) {
        higherNumber = data.ragNumber
        higherColor = data.ragColor
      }
      return (
        <li
          style={{
            backgroundColor: data?.ragColor,
            width: '60px',
            height: '38px',
            paddingBottom: '5px',
            border: '3px solid white',
            textAlign: 'center',
          }}
        >
          {data?.ragNumber}
        </li>
      )
    })

    this.targetHighestRAGNumber = higherNumber
    this.targetHighestRAGColor = higherColor
    return targetRagItems
  }

  //setting the rag number and color states for gross
  getRAGData(impactDimensionId) {
    const impactItem =
      this.state.grossAssessmentEvalTable.filter(
        item => item.impactId === impactDimensionId
      )[0] === undefined
        ? null
        : this.state.grossAssessmentEvalTable.filter(
            item => item.impactId === impactDimensionId
          )[0]
    if (impactItem == null) {
      return { ragNumber: 0, ragColor: 'white' }
    } else if (this.state.colorMan == true) {
      const colorItem = this.state.positiveProbabilityImpacts.filter(
        item =>
          this.state.selectedProbabilityGross == item.riskProbability.id &&
          impactItem.rating == item.riskImpact.name
      )

      if (colorItem[0] == undefined) {
        return { ragNumber: 0, ragColor: 'white' }
      } else {
        return {
          ragNumber:
            colorItem[0].riskImpact.score *
            colorItem[0].riskProbability.multiplier,
          ragColor: `#${colorItem[0].colour}`,
        }
      }
    }

    // else if (this.state.colorMan == true) {
    return { ragNumber: 0, ragColor: 'white' }
  }

  //setting the rag number and color states for net
  getNetRAGData(impactDimensionId) {
    const impactItem =
      this.state.netAssessmentEvalTable.filter(
        item => item.impactId === impactDimensionId
      )[0] === undefined
        ? null
        : this.state.netAssessmentEvalTable.filter(
            item => item.impactId === impactDimensionId
          )[0]
    if (impactItem == null) {
      return { ragNumber: 0, ragColor: 'white' }
    } else if (this.state.colorMan == true) {
      const colorItem = this.state.positiveProbabilityImpacts.filter(
        item =>
          this.state.selectedProbabilityNet == item.riskProbability.id &&
          impactItem.rating == item.riskImpact.name
      )

      if (colorItem[0] == undefined) {
        return { ragNumber: 0, ragColor: 'white' }
      } else {
        return {
          ragNumber:
            colorItem[0].riskImpact.score *
            colorItem[0].riskProbability.multiplier,
          ragColor: `#${colorItem[0].colour}`,
        }
      }
    }

    // else if (this.state.colorMan == true) {
    return { ragNumber: 0, ragColor: 'white' }
  }

  //setting the rag number and color states for target
  getTargetRAGData(impactDimensionId) {
    const impactItem =
      this.state.targetAssessmentEvalTable.filter(
        item => item.impactId === impactDimensionId
      )[0] === undefined
        ? null
        : this.state.targetAssessmentEvalTable.filter(
            item => item.impactId === impactDimensionId
          )[0]
    if (impactItem == null) {
      return { ragNumber: 0, ragColor: 'white' }
    } else if (this.state.colorMan == true) {
      const colorItem = this.state.positiveProbabilityImpacts.filter(
        item =>
          this.state.selectedProbabilityTarget == item.riskProbability.id &&
          impactItem.rating == item.riskImpact.name
      )

      if (colorItem[0] == undefined) {
        return { ragNumber: 0, ragColor: 'white' }
      } else {
        return {
          ragNumber:
            colorItem[0].riskImpact.score *
            colorItem[0].riskProbability.multiplier,
          ragColor: `#${colorItem[0].colour}`,
        }
      }
    }

    // else if (this.state.colorMan == true) {
    return { ragNumber: 0, ragColor: 'white' }
  }

  //Gross risk rating
  setRiskRating(impactDimension, e) {
    e.persist()
    let table = [...this.state.grossAssessmentEvalTable]
    e.preventDefault()
    if (
      table.filter(item => item.impactId === impactDimension.id)[0] ===
      undefined
    ) {
      table.push({ impactId: impactDimension.id, rating: e.target.value })
      this.setState({
        grossAssessmentEvalTable: table,
      })
    } else {
      let newTable = table.map(item => {
        if (item.impactId == impactDimension.id) {
          let newItem = {
            impactId: impactDimension.id,
            rating: e.target.value,
          }
          return newItem
        } else {
          return item
        }
      })

      this.setState({
        grossAssessmentEvalTable: newTable,
      })
    }
  }

  //Net risk rating
  setNetRiskRating(impactDimension, e) {
    e.persist()
    let table = [...this.state.netAssessmentEvalTable]
    e.preventDefault()
    if (
      table.filter(item => item.impactId === impactDimension.id)[0] ===
      undefined
    ) {
      table.push({ impactId: impactDimension.id, rating: e.target.value })
      this.setState({
        netAssessmentEvalTable: table,
      })
    } else {
      let newTable = table.map(item => {
        if (item.impactId == impactDimension.id) {
          let newItem = {
            impactId: impactDimension.id,
            rating: e.target.value,
          }
          return newItem
        } else {
          return item
        }
      })

      this.setState({
        netAssessmentEvalTable: newTable,
      })
    }
  }

  setTargetRiskRating(impactDimension, e) {
    e.persist()
    let table = [...this.state.targetAssessmentEvalTable]
    e.preventDefault()
    if (
      table.filter(item => item.impactId === impactDimension.id)[0] ===
      undefined
    ) {
      table.push({ impactId: impactDimension.id, rating: e.target.value })
      this.setState({
        targetAssessmentEvalTable: table,
      })
    } else {
      let newTable = table.map(item => {
        if (item.impactId == impactDimension.id) {
          let newItem = {
            impactId: impactDimension.id,
            rating: e.target.value,
          }
          return newItem
        } else {
          return item
        }
      })

      this.setState({
        targetAssessmentEvalTable: newTable,
      })
    }
  }

  probabSelectNet(e) {
    this.setState(
      {
        selectedProbabilityNet: e.target.value,
        netHighestRAGNumber: 0,
        netHighestRAGColor: '#fff',
      },
      () => this.setDescription()
    )
  }

  probabSelectGross(e) {
    this.setState(
      {
        selectedProbabilityGross: e.target.value,
        grossHighestRAGNumber: 0,
        grossHighestRAGColor: '#fff',
      },
      () => this.setDescription()
    )
  }

  probabSelectTarget(e) {
    this.setState(
      {
        selectedProbabilityTarget: e.target.value,
        targetHighestRAGNumber: 0,
        targetsHighestRAGColor: '#fff',
      },
      () => this.setDescription()
    )
  }

  setDescription() {
    this.state.probabilities.map(item => {
      if (item.name === this.state.selectedProbability) {
        this.setState({
          descProbability: item.description,
        })
      }
    })
  }

  calculateValue() {
    return (
      ((Number(this.state.grossMin) +
        Number(this.state.grossMax) +
        Number(this.state.grossLikely)) /
        3) *
      (Number(this.state.grossProbab) / 100)
    )
  }

  calculateValueNet() {
    return (
      ((Number(this.state.netMin) +
        Number(this.state.netMax) +
        Number(this.state.netLikely)) /
        3) *
      (Number(this.state.netProbab) / 100)
    )
  }

  calculateValueTarget() {
    return (
      ((Number(this.state.targetMin) +
        Number(this.state.targetMax) +
        Number(this.state.targetLikely)) /
        3) *
      (Number(this.state.targetProbab) / 100)
    )
  }

  handleColorChange = e => {
    e.preventDefault()
    this.setState({
      colorMan: !this.state.colorMan,
    })
  }

  calledFromChild(desc, ec, dd, ap, as, ow, comp) {
    this.setState({
      descriptionFromChild: desc,
      estimatedCostFromChild: ec,
      dueDateFromChild: dd,
      actionPriorityFromChild: ap,
      actionStatusFromChild: as,
      ownerFromChild: ow,
      completedFromChild: comp,
    })
  }

  handleDateChange = e => {
    let date = new Date(e)
    let dateOfMonth = date.getDate()
    let monthOfYear = date.getMonth() + 1
    let year = String(date.getFullYear()).substr(-2)
    this.setState({
      reviewDate: [
        String(dateOfMonth),
        String(monthOfYear).padStart(2, '0'),
        String(year),
      ].join('-'),
    })
  }

  getDepartments() {
    return this.state.departments.map(item => {
      return <option>{item.name}</option>
    })
  }

  render() {
    return (
      <div className="px-5 my-5">
        <button
          className="back-to-risks btn btn-primary"
          onClick={() => this.props.switchMethod()}
        >
          <i className="fa fa-arrow-left"></i> Back
        </button>
        <Form
          // onSubmit={(e) => {
          //     e.preventDefault()
          //     this.props.data.calledFromChildAgain(
          //         this.state.riskEvent,
          //         this.state.riskImpact,
          //         this.state.riskCauses,
          //         this.state.riskType,
          //         this.state.netScore,
          //         this.state.netValue,
          //         this.state.riskOwner
          //     )
          // }}
          onSubmit={e => {
            e.preventDefault()

            this.saveClickHandler()
          }}
        >
          <h6>
            My Departmental Risk
            <span>
              &gt; <u> Add Departmental Risk</u>
            </span>
          </h6>
          <h5>Identification and Description</h5>
          <Container
            style={{
              backgroundColor: 'rgb(230, 230, 230)',
            }}
          >
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Causes:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Describe causes..."
                    name="riskCauses"
                    onChange={this.sendToParentAgain}
                  />
                </Form.Group>
              </Col>
              <i
                class="fa fa-arrows-h"
                aria-hidden="true"
                style={{
                  fontSize: '40px',
                  paddingTop: '55px',
                }}
              ></i>
              <Col>
                <Form.Group>
                  <Form.Label>Risk Event:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Describe risk event..."
                    name="riskEvent"
                    onChange={this.sendToParentAgain}
                  />
                </Form.Group>
              </Col>
              <i
                class="fa fa-arrows-h"
                aria-hidden="true"
                style={{
                  fontSize: '40px',
                  paddingTop: '55px',
                }}
              ></i>
              <Col>
                <Form.Group>
                  <Form.Label>Impact:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Describe impact..."
                    name="riskImpact"
                    onChange={this.sendToParentAgain}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
          <div>
            <Form.Group>
              <Form.Label>Link Objective (KPI)</Form.Label>
              <Form.Control as="select" multiple>
                {this.getObjectives()}
                {/* <option>Objective A</option>
                                <option>Objective B</option>
                                <option>Objective C</option>
                                <option>Objective D</option>
                                <option>Objective E</option>
                                <option>Objective F</option> */}
              </Form.Control>
            </Form.Group>
          </div>
          <div>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Uncertainty Type:</Form.Label>
                    <Form.Control
                      as="select"
                      value={this.state.selectedUncertainty}
                      onChange={e => {
                        this.handleColorChange(e)
                        this.setState({
                          selectedUncertainty: e.target.value,
                        })
                      }}
                    >
                      <option value="Positive">Opportunity</option>
                      <option value="Negative">Threat</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Department/Team:</Form.Label>
                    <Form.Control as="select">
                      {this.getDepartments()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Owner:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.sendToParentAgain}
                      name="riskOwner"
                    >
                      <option value="">Select Identifier</option>
                      {this.renderUsers()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Identifier:</Form.Label>
                    <Form.Control
                      as="select"
                      value={this.state.selectedIdentifier}
                      onChange={e => {
                        this.setState({
                          selectedIdentifier: e.target.value,
                        })
                      }}
                    >
                      {this.renderUsers()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Risk Type:</Form.Label>
                    <Form.Control
                      as="select"
                      name="riskType"
                      onChange={this.sendToParentAgain}
                    >
                      {this.renderRiskType()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Risk Category:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.sendToParentAgain}
                      name="riskCategory"
                    >
                      {this.renderRiskCategory()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Risk Status:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.sendToParentAgain}
                      name="riskstatus"
                    >
                      {this.renderRiskStatus()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Review Frequency:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.sendToParentAgain}
                      name="freqReview"
                    >
                      {this.renderFreqofReview()}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <h6
                    style={{
                      color: '#30466a',
                      fontSize: '14px',
                    }}
                  >
                    Review Date:
                  </h6>
                  <div className="custom-date-picker">
                    <DatePicker
                      value={this.state.reviewDate}
                      name="testDate"
                      onChange={e => this.handleDateChange(e)}
                      dateFormat="dd-mm-yy"
                      autoComplete="off"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* net start */}
          <h5>Gross Assessment and Evaluation</h5>
          <div
            style={{
              backgroundColor: 'rgb(230, 230, 230)',
              paddingBottom: '10px',
            }}
          >
            <div>
              <div style={{ display: 'inline-flex' }}>
                <div>
                  <Container>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <p
                          style={{
                            paddingLeft: '60px',
                            color: '#30466a',
                            paddingTop: '10px',
                            fontSize: '16px',
                          }}
                        >
                          Impact dimensions:
                        </p>
                        <ul
                          style={{
                            listStyle: 'none',
                          }}
                        >
                          {this.renderImpactDimensions()}
                        </ul>
                      </div>
                      <div>
                        <p
                          style={{
                            paddingLeft: '85px',
                            color: '#30466a',
                            paddingTop: '10px',
                            fontSize: '16px',
                          }}
                        >
                          Rating:
                        </p>
                        <ul
                          style={{
                            listStyle: 'none',
                          }}
                        >
                          {this.state.colorMan
                            ? this.state.impactDimentions.map(item => {
                                return (
                                  <li>
                                    <Form.Control
                                      as="select"
                                      onClick={e => this.setRiskRating(item, e)}
                                      onChange={e =>
                                        this.setRiskRating(item, e)
                                      }
                                    >
                                      {this.getPositiveRating()}
                                    </Form.Control>
                                  </li>
                                )
                              })
                            : this.state.impactDimentions.map(item => {
                                return (
                                  <li>
                                    <Form.Control
                                      as="select"
                                      onClick={e => this.setRiskRating(item, e)}
                                      onChange={e =>
                                        this.setRiskRating(item, e)
                                      }
                                    >
                                      {this.getNegativeRating()}
                                    </Form.Control>
                                  </li>
                                )
                              })}
                        </ul>
                      </div>
                      <div>
                        <p
                          style={{
                            paddingLeft: '30px',
                            color: '#30466a',
                            paddingTop: '10px',
                            fontSize: '16px',
                          }}
                        >
                          RAG Status:
                        </p>
                        <ul
                          style={{
                            listStyle: 'none',
                          }}
                        >
                          {this.renderGrossRAG()}
                        </ul>
                      </div>
                    </div>
                  </Container>
                </div>
                <div>
                  <div
                    style={{
                      display: 'block',
                      paddingTop: '10px',
                      paddingLeft: '20px',
                    }}
                  >
                    <Form.Group>
                      <Form.Label style={{ fontSize: '16px' }}>
                        Probability:
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onChange={this.probabSelectGross}
                      >
                        {this.renderProbabilities()}
                      </Form.Control>
                    </Form.Group>
                    <h6>{this.state.descProbability}</h6>
                    <div style={{ display: 'inline-flex' }}>
                      <Form.Group>
                        <Form.Label style={{ fontSize: '16px' }}>
                          Gross Risk Score:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="netScore"
                          onChange={this.sendToParentAgain}
                          value={this.grossHighestRAGNumber}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label style={{ fontSize: '16px' }}>
                          RAG Status:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          readOnly
                          style={{
                            backgroundColor: this.grossHighestRAGColor,
                          }}
                        />
                      </Form.Group>
                    </div>
                    <h6>These scores are automatically calculated</h6>
                    <div
                      style={{
                        display: 'inline-flex',
                        float: 'right',
                      }}
                    >
                      <Button onClick={this.viewMatrixClickHandler.bind(this)}>
                        View Matrix
                      </Button>
                      <Button onClick={this.viewImpactClickHandler.bind(this)}>
                        View Impact
                      </Button>
                    </div>
                  </div>
                  <ViewMatrix
                    showModal={this.state.showViewMatrix}
                    closeModal={() => {
                      this.setState({
                        showViewMatrix: false,
                      })
                    }}
                  />
                  <ViewImpact
                    showModal={this.state.showViewImpact}
                    closeModal={() => {
                      this.setState({
                        showViewImpact: false,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* net end */}
          <br />
          <div>
            <h5>Gross Expected Monetary Value</h5>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Probability:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="%"
                      onChange={this.setGrossProbab.bind(this)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Minimum Value:</Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.minProbability}
                      onChange={this.setGrossMin.bind(this)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Likely Value:</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={this.setGrossLikely.bind(this)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Maximum Value:</Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.maxProbability}
                      onChange={this.setGrossMax.bind(this)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Gross Value@Risk</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E..."
                      value={this.calculateValue().toFixed(2)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>State basis of estimation:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type here..."
                      style={{
                        width: '645px',
                        height: '150px',
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </div>
          {/* Target assessment and evaluation
                Target expected monetary value */}
          <h5>Net Assessment and Evaluation</h5>
          <div
            style={{
              backgroundColor: 'rgb(230, 230, 230)',
              paddingBottom: '10px',
            }}
          >
            <div>
              <div style={{ display: 'inline-flex' }}>
                <div>
                  <Container>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <p
                          style={{
                            paddingLeft: '60px',
                            color: '#30466a',
                            paddingTop: '10px',
                          }}
                        >
                          Impact dimensions:
                        </p>
                        <ul
                          style={{
                            listStyle: 'none',
                          }}
                        >
                          {this.renderImpactDimensions()}
                        </ul>
                      </div>
                      <div>
                        <p
                          style={{
                            paddingLeft: '85px',
                            color: '#30466a',
                            paddingTop: '10px',
                          }}
                        >
                          Rating:
                        </p>
                        <ul
                          style={{
                            listStyle: 'none',
                          }}
                        >
                          {this.state.colorMan
                            ? this.state.impactDimentions.map(item => {
                                return (
                                  <li>
                                    <Form.Control
                                      as="select"
                                      onClick={e => this.setRiskRating(item, e)}
                                      onChange={e =>
                                        this.setNetRiskRating(item, e)
                                      }
                                    >
                                      {this.getPositiveRating()}
                                    </Form.Control>
                                  </li>
                                )
                              })
                            : this.state.impactDimentions.map(item => {
                                return (
                                  <li>
                                    <Form.Control
                                      as="select"
                                      onClick={e => this.setRiskRating(item, e)}
                                      onChange={e =>
                                        this.setNetRiskRating(item, e)
                                      }
                                    >
                                      {this.getNegativeRating()}
                                    </Form.Control>
                                  </li>
                                )
                              })}
                        </ul>
                      </div>
                      <div>
                        <p
                          style={{
                            paddingLeft: '30px',
                            color: '#30466a',
                            paddingTop: '10px',
                          }}
                        >
                          RAG Status:
                        </p>
                        <ul
                          style={{
                            listStyle: 'none',
                          }}
                        >
                          {this.renderNetRAG()}
                        </ul>
                      </div>
                    </div>
                  </Container>
                </div>
                <div>
                  <div
                    style={{
                      display: 'block',
                      paddingTop: '10px',
                      paddingLeft: '20px',
                    }}
                  >
                    <Form.Group>
                      <Form.Label style={{ fontSize: '16px' }}>
                        Probability:
                      </Form.Label>
                      <Form.Control as="select" onChange={this.probabSelectNet}>
                        {this.renderProbabilities()}
                      </Form.Control>
                    </Form.Group>
                    <h6>{this.state.descProbability}</h6>
                    <div style={{ display: 'inline-flex' }}>
                      <Form.Group>
                        <Form.Label style={{ fontSize: '16px' }}>
                          Net Risk Score:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="netScore"
                          // onChange={
                          //     this.sendToParentAgain
                          // }
                          value={this.netHighestRAGNumber}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label style={{ fontSize: '16px' }}>
                          RAG Status:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          readOnly
                          style={{
                            backgroundColor: this.netHighestRAGColor,
                          }}
                        />
                      </Form.Group>
                    </div>
                    <h6>These scores are automatically calculated</h6>
                    <div
                      style={{
                        display: 'inline-flex',
                        float: 'right',
                      }}
                    >
                      <Button onClick={this.viewMatrixClickHandler.bind(this)}>
                        View Matrix
                      </Button>
                      <Button onClick={this.viewImpactClickHandler.bind(this)}>
                        View Impact
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h5>Net Expected Monetary Value</h5>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Probability:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="%"
                      onChange={this.setNetProbab.bind(this)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Minimum Value:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E..."
                      value={this.state.minProbability}
                      onChange={this.setNetMin.bind(this)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Likely Value:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E..."
                      onChange={this.setNetLikely.bind(this)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Maximum Value:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E..."
                      onChange={this.setNetMax.bind(this)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Net Value@Risk</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E..."
                      name="netValue"
                      onChange={this.sendToParentAgain}
                      value={this.calculateValueNet().toFixed(2)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>State basis of estimation:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type here..."
                      style={{
                        width: '645px',
                        height: '150px',
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="tab-title">
            <div style={{ display: 'block' }}>
              <h5>Risk Treatments (Action Plan)</h5>
              {this.state.noPlanYet ? (
                <div>
                  <h4 style={{ color: '#30466a' }}>
                    You do not have any Risk Treatment Plan set up
                  </h4>
                  <Button onClick={this.actionPlanClickHandler.bind(this)}>
                    Add a treatment plan
                  </Button>
                </div>
              ) : (
                <div>
                  <div>
                    <p>
                      Add New
                      <span>
                        <button
                          type="button"
                          className="icon add"
                          onClick={this.actionPlanClickHandler.bind(this)}
                        >
                          <img src="../../assets/add.svg" alt="add" />
                        </button>
                      </span>
                    </p>
                  </div>
                  <div>
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ width: '8%' }}>Ref</th>
                          <th style={{ width: '20%' }}>Description</th>
                          <th style={{ width: '10%' }}>Estimated Cost</th>
                          <th style={{ width: '8%' }}>Due Date</th>
                          <th style={{ width: '10%' }}>Action Priority</th>
                          <th style={{ width: '10%' }}>Action Status</th>
                          <th style={{ width: '9%' }}>% Completed</th>
                          <th style={{ width: '15%' }}>Owner</th>
                          <th style={{ width: '11%' }}></th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                </div>
              )}
              <TreatmentPlanTable
                treatmentTableData={this.state.treatmentTableData}
                index={0}
                firstPlanAdded={this.firstPlanAdded.bind(this)}
              />{' '}
              <AddActionPlanModal
                showModal={this.state.showModal}
                actionOwners={this.state.usersList}
                actionOwner={this.state.actionOwner}
                frequencyOfReview={this.state.reviewFrequency}
                actionPriorities={this.state.actionPriorities}
                actionEffectiveness={this.state.actionEffectiveness}
                actionStatus={this.state.riskStatus}
                closeModal={() => {
                  this.setState({
                    showModal: false,
                  })
                }}
                data={{
                  descriptionFromChild: this.state.descriptionFromChild,
                  estimatedCostFromChild: this.state.estimatedCostFromChild,
                  dueDateFromChild: this.state.dueDateFromChild,
                  actionPriorityFromChild: this.state.actionPriorityFromChild,
                  actionStatusFromChild: this.state.actionStatusFromChild,
                  ownerFromChild: this.state.ownerFromChild,
                  calledFromChild: this.calledFromChild.bind(this),
                }}
              />
            </div>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <h5>Target Assessment and Evaluation</h5>
            <div
              style={{
                backgroundColor: 'rgb(230, 230, 230)',
                paddingBottom: '10px',
              }}
            >
              <div style={{ display: 'inline-flex' }}>
                <div>
                  <Container>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <p
                          style={{
                            paddingLeft: '60px',
                            color: '#30466a',
                            paddingTop: '10px',
                            fontSize: '16px',
                          }}
                        >
                          Impact dimensions:
                        </p>
                        <ul
                          style={{
                            listStyle: 'none',
                          }}
                        >
                          {this.renderImpactDimensions()}
                        </ul>
                      </div>
                      <div>
                        <p
                          style={{
                            paddingLeft: '85px',
                            color: '#30466a',
                            paddingTop: '10px',
                            fontSize: '16px',
                          }}
                        >
                          Rating:
                        </p>
                        <ul
                          style={{
                            listStyle: 'none',
                          }}
                        >
                          {this.state.colorMan
                            ? this.state.impactDimentions.map(item => {
                                return (
                                  <li>
                                    <Form.Control
                                      as="select"
                                      onClick={e => this.setRiskRating(item, e)}
                                      onChange={e =>
                                        this.setTargetRiskRating(item, e)
                                      }
                                    >
                                      {this.getPositiveRating()}
                                    </Form.Control>
                                  </li>
                                )
                              })
                            : this.state.impactDimentions.map(item => {
                                return (
                                  <li>
                                    <Form.Control
                                      as="select"
                                      onClick={e => this.setRiskRating(item, e)}
                                      onChange={e =>
                                        this.setTargetRiskRating(item, e)
                                      }
                                    >
                                      {this.getNegativeRating()}
                                    </Form.Control>
                                  </li>
                                )
                              })}
                        </ul>
                      </div>
                      <div>
                        <p
                          style={{
                            paddingLeft: '30px',
                            color: '#30466a',
                            paddingTop: '10px',
                            fontSize: '16px',
                          }}
                        >
                          RAG Status:
                        </p>
                        <ul
                          style={{
                            listStyle: 'none',
                          }}
                        >
                          {this.renderTargetRAG()}
                        </ul>
                      </div>
                    </div>
                  </Container>
                </div>
                <div>
                  <div
                    style={{
                      display: 'block',
                      paddingTop: '10px',
                      paddingLeft: '20px',
                    }}
                  >
                    <Form.Group>
                      <Form.Label style={{ fontSize: '16px' }}>
                        Probability:
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onChange={this.probabSelectTarget}
                      >
                        {this.renderProbabilities()}
                      </Form.Control>
                    </Form.Group>
                    <h6>{this.state.descProbability}</h6>
                    <div style={{ display: 'inline-flex' }}>
                      <Form.Group>
                        <Form.Label style={{ fontSize: '16px' }}>
                          Target Risk Score:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={this.targetHighestRAGNumber}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label style={{ fontSize: '16px' }}>
                          RAG Status:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          readOnly
                          style={{
                            backgroundColor: this.targetHighestRAGColor,
                          }}
                        />
                      </Form.Group>
                    </div>
                    <h6>These scores are automatically calculated</h6>
                    <div
                      style={{
                        display: 'inline-flex',
                        float: 'right',
                      }}
                    >
                      <Button onClick={this.viewMatrixClickHandler.bind(this)}>
                        View Matrix
                      </Button>
                      <Button onClick={this.viewImpactClickHandler.bind(this)}>
                        View Impact
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div>
            <h5>Target Expected Monetary Value</h5>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Probability:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="%"
                      onChange={this.setTargetProbab.bind(this)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Minimum Value:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E..."
                      onChange={this.setTargetMin.bind(this)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Likely Value:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E..."
                      onChange={this.setTargetLikely.bind(this)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Maximum Value:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E..."
                      onChange={this.setTargetMax.bind(this)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Target Value@Risk</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E..."
                      value={this.calculateValueTarget().toFixed(2)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>State basis of estimation:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type here..."
                      style={{
                        width: '645px',
                        height: '150px',
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </div>
          <br />
          <div>
            <h5>Comments:</h5>
            <Form.Group>
              <Form.Label>Type any comments or notes here:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type here..."
                style={{
                  width: '645px',
                  height: '150px',
                }}
              />
            </Form.Group>
          </div>
          <div>
            {/* Buttons div */}
            <Button>Save Draft</Button>
            <Button type={'sumbit'}>Save</Button>
          </div>
        </Form>
      </div>
    )
  }
}

export default MyDeptRisksForm
