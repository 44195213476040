import React, { Component } from 'react'
import axios from 'axios'
import { notification } from 'antd'
import ReactDOM from 'react-dom'
import ForgotPasswordFormInput from '../presentational/ForgotPasswordFormInput.jsx'
import Auth from '../../lib/Auth.js'

class ForgotPasswordContainer extends Component {
  constructor() {
    super()

    this.state = {
      title: 'Forgot Password',
      postData: {},
      errors: {},
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange({ target: { name, value } }) {
    const postData = { ...this.state.postData, [name]: value }
    const errors = {}
    this.setState({ postData, errors })
  }

  handleSubmit(e) {
    e.preventDefault()
    axios
      .post(
        BASE_URL + '/authentication/passwords/forgotten',
        this.state.postData,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then(() => {
        notification.open({
          message: 'Reset Password',
          description:
            'A link to your email address has been sent to reset your password',
          onClick: () => {
            console.log('Reset Password')
          },
        })
        this.props.history.push('/')
      })
      .catch(err => this.setState({ errors: err.data }))
  }

  render() {
    const { title } = this.state
    return (
      <ForgotPasswordFormInput
        type="text"
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        title={title}
      />
    )
  }
}

export default ForgotPasswordContainer
