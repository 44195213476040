import React from 'react'
// import ShowcaseButton from '../showcase-components/showcase-button';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  LineMarkSeries,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  LabelSeries,
} from 'react-vis'
import Axios from 'axios'
import Auth from '../../../lib/Auth.js'
import * as moment from 'moment'

const initBarData = {
  Jan: 0,
  Feb: 0,
  Mar: 0,
  Apr: 0,
  May: 0,
  Jun: 0,
  Jul: 0,
  Aug: 0,
  Sep: 0,
  Oct: 0,
  Nov: 0,
  Dec: 0,
}

export default class BarChart extends React.Component {
  orgId
  constructor(props) {
    super(props)
    this.state = {
      useCanvas: false,
      totalRisksMonthlyArray: [],
      totalOpportunitiesMonthlyArray: [],
      totalThreatsMonthlyArray:[],
      labelData: [],
      showGraph: false,
    }
    this.orgId = Auth.getOrg()
  }

  componentDidMount() {
    let totalRisksMonthly = { ...initBarData }
    let totalOpportunitiesMonthly = { ...initBarData }
    let totalThreatsMonthly = { ...initBarData }

    Axios.get(
      BASE_URL +
        `/organisations/${this.orgId}/risks?owner=${Auth.getLoggedUserId()}`,
      {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    )
      .then((res) => {
        res.data.forEach((risk) => {
          let date = moment(
            `${risk?.createdAt?.day}/${risk?.createdAt?.month}/${risk?.createdAt?.year}`,
            'DD/MM/YYYY'
          )
          let month = date.format('MMM')
          if (risk.impactType.name.toLowerCase() == 'negative') {
            totalThreatsMonthly[month] += 1
          } else {
            totalOpportunitiesMonthly[month] += 1
          }

          totalRisksMonthly[month] += 1
        })
        return Promise.resolve()
      })
      .then(() => {
        const labelData = [
          { x: 'Jan', y: 0 },
          { x: 'Feb', y: 0 },
          { x: 'Mar', y: 0 },
          { x: 'Apr', y: 0 },
          { x: 'May', y: 0 },
          { x: 'Jun', y: 0 },
          { x: 'Jul', y: 0 },
          { x: 'Aug', y: 0 },
          { x: 'Sep', y: 0 },
          { x: 'Oct', y: 0 },
          { x: 'Nov', y: 0 },
          { x: 'Dec', y: 0 },
        ]

        let totalRisksMonthlyArray = []
        for (let key in totalRisksMonthly) {
          totalRisksMonthlyArray.push({
            x: key,
            y: totalRisksMonthly[key],
          })
        }

        let totalOpportunitiesMonthlyArray = []
        for (let key in totalOpportunitiesMonthly) {
          totalOpportunitiesMonthlyArray.push({
            x: key,
            y: totalOpportunitiesMonthly[key],
          })
        }

        let totalThreatsMonthlyArray = []
        for (let key in totalThreatsMonthly) {
          totalThreatsMonthlyArray.push({
            x: key,
            y: totalThreatsMonthly[key],
          })
        }

        this.setState({
          totalRisksMonthlyArray,
          totalOpportunitiesMonthlyArray,
          totalThreatsMonthlyArray,
          labelData,
          showGraph: true,
        })
      })
  }

  render() {
    if (this.state.showGraph) {
      const { useCanvas } = this.state
      // const content = useCanvas ? 'TOGGLE TO SVG' : 'TOGGLE TO CANVAS'
      const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries
      return (
        <div>
          <p className="risks-chart mt-3">Monthly Number of Risks</p>

          <XYPlot xType="ordinal" width={600} height={300} xDistance={100}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            {/* <BarSeries
                            className="vertical-bar-series-example"
                            color="#15235c"
                            data={this.state.totalOpportunitiesMonthlyArray}
                        />
                        <BarSeries
                            color="orange"
                            data={this.state.totalThreatsMonthlyArray}
                        /> */}
            <LineMarkSeries
              data={this.state.totalRisksMonthlyArray}
              color="yellow"
              stroke="black"
            />

            <LineMarkSeries
              data={this.state.totalOpportunitiesMonthlyArray}
              color="yellow"
              stroke="green"
            />

            <LineMarkSeries
              data={this.state.totalThreatsMonthlyArray}
              color="yellow"
              stroke="orange"
            />
            <LabelSeries data={this.state.labelData} getLabel={(d) => null} />
          </XYPlot>
        </div>
      )
    } else {
      return ''
    }
  }
}
