import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Auth from '../../../lib/Auth.js'
import { Modal, Form, Container, Row, Col, Button } from 'react-bootstrap'
import '../../../style/container/objectives.scss'
import RangeSlider from 'react-bootstrap-range-slider'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import { Upload, Button as ButtonAnt } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
export default function MyTasksModal({
  priorities,
  handleSubmit,
  show,
  handleClose,
  assignee,
  handleDocUpload,
}) {
  const history = useHistory()
  const [sliderValue, setSliderValue] = useState(0)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [reminder, setReminder] = useState(null)
  const [formData, setData] = useState({
    name: '',
    assignee: Auth.getLoggedUserId(),
    actionType: '',
    completed: '',
    priority: '',
    notes: '',
    startDt: '',
    dueDt: '',
    ref: String(Math.floor(Math.random() + 1 * 1000)),
  })

  const [actionPriorities, setPriorities] = useState([])

  const handleChange = (e) => {
    let data = formData
    data[e.target.name] = e.target.value
    setData(data)
  }

  const handleDateChange = (e, name) => {
    let finalDate = moment(e).format('DD-MM-YY')

    if (name == 'startDate') {
      setStartDate(finalDate)
      setData({ ...formData, startDt: finalDate })
    } else if (name == 'endDate') {
      setEndDate(finalDate)
      setData({ ...formData, dueDt: finalDate })
    } else {
      setReminder(finalDate)
      setData({ ...formData, reminder: finalDate })
    }
  }

  const handleSliderChange = (value) => {
    setSliderValue(value)
    setData({ ...formData, completed: value })
  }

  useEffect(() => {
    setPriorities(priorities)
  }, [JSON.stringify(priorities)])

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-main objectives-resize"
      >
        <Modal.Header>Add Task</Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Task:<span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="name"
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter task name..."
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Type:<span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue=""
                      name="actionType"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">-</option>
                      <option value="PROACTIVE">PROACTIVE</option>
                      <option value="REACTIVE">REACTIVE</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Status:<span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue=""
                      name="actionStatus"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">-</option>
                      <option value="1">PROPOSED</option>
                      <option value="2">APPROVED</option>
                      <option value="3">IN PROGRESS</option>
                      <option value="4">WITHDRAWN</option>
                      <option value="5">IMPLEMENTED</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  {' '}
                  <Form.Group>
                    <Form.Label>
                      Start Date:<span className="text-danger"> *</span>
                    </Form.Label>
                    <DatePicker
                      format="dd-mm-yy"
                      name="startDate"
                      onChange={(e) => handleDateChange(e, 'startDate')}
                      value={startDate}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {' '}
                  <Form.Group>
                    <Form.Label>
                      End Date:<span className="text-danger"> *</span>
                    </Form.Label>
                    <DatePicker
                      format="dd-mm-yy"
                      name="endDt"
                      onChange={(e) => handleDateChange(e, 'endDate')}
                      value={endDate}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formBasicRange">
                    <Form.Label>
                      % Completed<span className="text-danger"> *</span>
                    </Form.Label>
                    <RangeSlider
                      value={sliderValue}
                      onChange={(e) => handleSliderChange(e.target.value)}
                    />
                    <Form.Control
                      onChange={(e) => handleSliderChange(e.target.value)}
                      value={sliderValue}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Priority:<span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="priority"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value={-1}>-</option>
                      {actionPriorities.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Reminder:<span className="text-danger"> *</span>
                    </Form.Label>
                    <DatePicker
                      format="dd-mm-yy"
                      name="reminder"
                      onChange={(e) => handleDateChange(e, 'reminder')}
                      value={reminder}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Upload:</Form.Label>
                    <Upload
                      multiple={true}
                      customRequest={(e) => {
                        e.onSuccess((val) => {
                          console.log('FILE VAL --->', val)
                        })
                        e.onError((err) => {
                          console.log('FILE err --->', err)
                        })
                      }}
                      onChange={handleDocUpload}
                    >
                      <ButtonAnt icon={<UploadOutlined />}>
                        Click to Upload
                      </ButtonAnt>
                    </Upload>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Notes:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Type here..."
                      name="notes"
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={(e) => {
                console.log('formData--->', formData)
                handleSubmit(e, formData)
                // localStorage.setItem('tasks', true)
                // history.push('/objectives')
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
