import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import '../../style/container/objectives.scss'
import ObjectivesTables from './ObjectivesTables.jsx'
import Auth from '../../lib/Auth.js'
import axios from 'axios'
import MyTasksTable from '../presentational/MyTasksTable.jsx'
import { Result } from 'antd'

const getOrgId = () => {
  let token = Auth.getToken()
  if (!token) {
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64)).Org
}

export default function UserObjectives(props) {
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem('tasks') ? 3 : 1
  )
  const [tasks, setTasks] = useState([])
  const orgId = getOrgId()

  useEffect(() => {
    axios
      .get(BASE_URL + `/organisations/${orgId}/tasks`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => setTasks(res.data))
    if (localStorage.getItem('tasks')) {
      localStorage.removeItem('tasks')
    }
  }, [])

  const tabs = [
    {
      id: 1,
      label: 'My Department Objectives and KPI',
      bgColor: '#3D51B5',
    },
    { id: 2, label: 'My Objectives and KPI', bgColor: '#512DA8' },

    { id: 3, label: 'My Tasks', bgColor: '#689F38' },
    // { id: 4, label: 'My Appetite', bgColor: '#FF4500' },
  ]

  //Table #1
  const myDeptObjectivesData = {
    heading: [
      { id: 1, label: 'Year' },
      { id: 2, label: 'Ref' },
      { id: 3, label: 'Strategic Objective' },
      { id: 4, label: 'Department Objective' },
      { id: 5, label: 'Weight' },
      { id: 6, label: 'Start Date' },
      { id: 7, label: 'End Date' },
      { id: 8, label: 'Lead' },
    ],
    data: [],
  }

  //Table #2
  const myDepartmentKpiData = {
    heading: [
      { id: 1, label: 'Year' },
      { id: 2, label: 'Ref' },
      { id: 3, label: 'Strategic Objective' },
      { id: 4, label: 'Department Indicator' },
      { id: 5, label: 'Start Date' },
      { id: 6, label: 'End Date' },
      { id: 7, label: 'Lead' },
    ],
    data: [],
  }

  //Table #3
  const myObjectivesData = {
    heading: [
      { id: 1, label: 'Year' },
      { id: 2, label: 'Ref' },
      { id: 3, label: 'Department Objective' },
      { id: 4, label: 'Objective' },
      { id: 5, label: 'Weight' },
      { id: 6, label: 'Start Date' },
      { id: 7, label: 'End Date' },
      { id: 8, label: 'Lead' },
    ],
    data: [],
  }

  //Table #4
  const myKpiData = {
    heading: [
      { id: 1, label: 'Year' },
      { id: 2, label: 'Ref' },
      { id: 3, label: 'Objective' },
      { id: 4, label: 'Indicator' },
      { id: 5, label: 'Start Date' },
      { id: 6, label: 'End Date' },
      { id: 7, label: 'Lead' },
    ],
    data: [],
  }

  //table #5
  const myTasksData = {
    heading: [
      { id: 1, label: 'Ref' },
      { id: 2, label: 'Task' },
      { id: 3, label: 'Type' },
      { id: 4, label: 'Status' },
      { id: 5, label: 'Start Date' },
      { id: 6, label: 'Due Date' },
      { id: 7, label: 'Completed' },
      { id: 8, label: 'Priority' },
    ],
    data: [],
  }

  const renderTab = () => {
    switch (selectedTab) {
      case 1:
        return (
          <div>
            <div className="departmental-objectives">
              <ObjectivesTables
                tableData={myDeptObjectivesData}
                orgId={orgId}
                tableTitle={'My Department Objectives'}
                modalName={'DepartmentObjectives'}
              />
            </div>

            <div className="departmental-indicators">
              <ObjectivesTables
                orgId={orgId}
                tableData={myDepartmentKpiData}
                tableTitle={'My Department KPIs'}
                modalName={'DepartmentKPI'}
              />
            </div>
          </div>
        )
        break
      case 2:
        return (
          <div>
            <div>
              <ObjectivesTables
                orgId={orgId}
                tableData={myObjectivesData}
                tableTitle={'My Objectives'}
                modalName={'Objectives'}
              />
            </div>

            <div>
              <ObjectivesTables
                orgId={orgId}
                tableData={myKpiData}
                tableTitle={'My KPIs'}
                modalName={'KPI'}
              />
            </div>
          </div>
        )

        break
      case 3:
        return (
          <div>
            <div>
              <ObjectivesTables
                orgId={orgId}
                tableTitle="My Tasks"
                modalName="Tasks"
                tableData={myTasksData}
              />
            </div>
          </div>
        )
        break
      case 4:
        return <Result status="500" title="Coming Soon" subTitle="" />
        break
      default:
        break
    }
  }

  return (
    <div style={{ ...props.style }} className="risk-setup-container">
      <div className="risk-tabs-container">
        {tabs.map(item => (
          <div
            key={item.id}
            style={{
              background: item.id === selectedTab ? item.bgColor : '#c5cae9',
            }}
            onClick={() => setSelectedTab(item.id)}
            className={`tab-label ${
              item.id === selectedTab ? 'selected-tab' : ''
            }`}
          >
            {item.label}
          </div>
        ))}
      </div>
      <hr />
      {renderTab()}
    </div>
  )
}
