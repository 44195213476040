import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap'
import RiskSetup from '../../presentational/admin/RiskSetup.jsx'
import '../../../style/container/risk-setup.scss'
import Sidebar from '../../common/Sidebar.jsx'

export default class RiskSetupContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeItem: `/admin/risk-setup/${this.props.match.params.id}`,
        }
    }

    handleClick(value) {
        window.location.href = `${value}`
        this.setState({ activeItem: value })
    }

    render() {
        return (
            <div>
                <Sidebar {...this.props} />

                <RiskSetup {...this.props} />
            </div>
        )
    }
}
