import React, { useState, useEffect } from 'react'
import { Navbar } from 'react-bootstrap'
import Sidebar from '../common/Sidebar.jsx'
import UserObjectives from '../presentational/UserObjectives.jsx'

const UserObjContainer = (props) => {
    return (
        <div>
            <div>
                <Navbar expand="lg" className="navbar">
                    <Navbar.Brand href="/dashboard">
                        <img
                            src="../../assets/ConradRP_Logo_Strapline_Cropped.png"
                            alt="conrad rp logo"
                            height="32"
                        />
                    </Navbar.Brand>
                </Navbar>
{/* 
                <SideMenu>
                    <Item label="Dashboard" value="/dashboard"></Item>
                    <Item label="Organisation" value="/organisation"></Item>
                    <Item label="Objectives" value="/objectives"></Item>
                    <Item label="Risks" value="/risks"></Item>
                    <Item
                        label="Programme & Projects"
                        value="/programme-and-projects"
                    ></Item>
                    <Item label="Reports" value="/reports"></Item>
                    <Item label="Admin" value="/admin">
                        <Item
                            label="Organisation Setup"
                            // value={`/admin/organisation-setup/${this.props.match.params.id}`}
                        />
                        <Item
                            label="Reporting Structure"
                            // value={`/admin/reporting-structure/${this.props.match.params.id}`}
                        />
                        <Item
                            label="User Setup"
                            // value={`/admin/user-setup/${this.props.match.params.id}`}
                        />
                        <Item
                            label="Org Strategy"
                            // value={`/admin/org-strategy/${this.props.match.params.id}`}
                        />
                    </Item>
                </SideMenu> */}

                <Sidebar {...props} /> 
            </div>
            <div>
                <UserObjectives {...props}  />
            </div>
        </div>
    )
}

export default UserObjContainer
