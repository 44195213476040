import React, { Component } from 'react'
import axios from 'axios'
import ReactDOM from 'react-dom'
import RequestDemoFormInput from '../presentational/RequestDemoFormInput.jsx'
import Auth from '../../lib/Auth.js'

class RequestDemoContainer extends Component {
  constructor() {
    super()

    this.state = {
      title: 'Request A Demo',
      postData: {},
      errors: {},
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange({ target: { name, value } }) {
    const postData = { ...this.state.postData, [name]: value }
    const errors = {}
    this.setState({ postData, errors })
  }

  handleSubmit(e) {
    e.preventDefault()
    axios
      .post('/users', this.state.postData, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(() => this.props.history.push('/licences'))
      .catch(err => this.setState({ errors: err.response.data }))
  }

  render() {
    const { title } = this.state
    return (
      <FormInput
        type="text"
        value={this.state}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        title={title}
      />
    )
  }
}

export default RequestDemoContainer
