import React, { Component } from 'react'
import axios from 'axios'
import ReactDOM from 'react-dom'
import Auth from '../../lib/Auth.js'
import Dashboard from '../presentational/Dashboard.jsx'
import Sidebar from '../common/Sidebar.jsx'
import {ReportsPage} from '../presentational/ReportsPage'
class Reports extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeItem: '/dashboard',
            userInformation: {},
        }
        this.parseJwt = this.parseJwt.bind(this)
    }

    handleClick(value) {
        window.location.href = `${value}`
        this.setState({ activeItem: value })
    }

    componentDidMount() {
        this.parseJwt(Auth.getToken())
    }

    parseJwt(token) {
        if (!token) {
            return
        }
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace('-', '+').replace('_', '/')
        JSON.parse(window.atob(base64))
        this.setState({ userInformation: JSON.parse(window.atob(base64)) })
        return this.state.userInformation
    }

    render() {
        return (
            <div>
                <Sidebar {...this.props} />
                <ReportsPage {...this.props} orgId={this.state.userInformation.Org} />
            </div>
        )
    }
}
export default Reports
