/* eslint-disable prettier/prettier */
import React, { useContext } from 'react'
import Table from './RiskRegister.TableIndividual.jsx'
import userContext from '../../../../context/userContext'
import DetailedTable from './RiskRegister.DetailedTable.jsx'




export default function RiskRegisterReport({ data }) {



    const { sessionDetails } = useContext(userContext)

    const currency = sessionDetails?.user?.organisation?.currencyCode
    console.log(data)





    return (
        <>
            <div
                className="w-100">
                {Object.keys(data.data).map((key, index) => {
                    return <>

                        {data?.detailed ?  <DetailedTable risks={data.data[`${key}`]} /> : <Table risks={data.data[`${key}`]} /> }
                    </>
                })}

            </div>

        </>
    )
}



