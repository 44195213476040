import React, { Component, useState, useEffect } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Modal, Button } from 'react-bootstrap'
import { InfoToolTip } from '../../../common/InfoTooltip.jsx'
import Auth from '../../../../lib/Auth.js'

export default class ProbabiltyImpactsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      positiveImpacts: [],
      negativeImpacts: [],
      showEdit: false,
      showPositiveAdd: false,
      showNegativeAdd: false,
      loading: true,
      orgId: props.orgId,
      selectedItem: null,
      positivePage: 0,
      negativePage: 0,
      rowsPerPage: 5,
      totalPositivePages: 0,
      totalNegativePages: 0,
    }
  }

  componentWillReceiveProps(props) {
    this.setState({ orgId: props.orgId })
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/impacts`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          const positiveImpacts = res.data
            .filter(item => item.impactType.name === 'POSITIVE')
            .sort((a, b) => a.score - b.score)
          const negativeImpacts = res.data
            .filter(item => item.impactType.name === 'NEGATIVE')
            .sort((a, b) => a.score - b.score)
          //    const totalPositivePages = this.getTotalPages(
          //         'positive'
          //     ),
          //    const totalNegativePages = this.getTotalPages(
          //         'negative'
          //     )

          //     let positivePage = this.state.positivePage
          this.setState(
            {
              negativeImpacts,
              positiveImpacts,
              loading: false,
            },
            () =>
              this.setState({
                totalPositivePages: this.getTotalPages('positive'),
                totalNegativePages: this.getTotalPages('negative'),
                positivePage: 0,
                negativePage: 0,
              })
          )
        }
      })
  }

  getPageRows = type => {
    var chunks = [],
      i = 0,
      n =
        type == 'positive'
          ? this.state.positiveImpacts.length
          : this.state.negativeImpacts.length
    while (i < n) {
      if (type == 'positive') {
        chunks.push(
          this.state.positiveImpacts.slice(i, (i += this.state.rowsPerPage))
        )
      } else {
        chunks.push(
          this.state.negativeImpacts.slice(i, (i += this.state.rowsPerPage))
        )
      }
    }

    return (
      chunks[
        type == 'positive' ? this.state.positivePage : this.state.negativePage
      ] || []
    )
  }

  getTotalPages = type => {
    var chunks = [],
      i = 0,
      n =
        type == 'positive'
          ? this.state.positiveImpacts.length
          : this.state.negativeImpacts.length
    while (i < n) {
      if (type == 'positive') {
        chunks.push(
          this.state.positiveImpacts.slice(i, (i += this.state.rowsPerPage))
        )
      } else {
        chunks.push(
          this.state.negativeImpacts.slice(i, (i += this.state.rowsPerPage))
        )
      }
    }
    return chunks.length
  }

  handleEdit = item => {
    this.setState({ selectedItem: item }, () =>
      this.setState({ showEdit: true })
    )
  }

  deleteItem = item => {
    this.setState({ loading: true }, () => {
      axios
        .delete(
          BASE_URL + `/organisations/${this.state.orgId}/impacts/${item.id}`,
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }

  renderNegativeImpactsData() {
    return this.getPageRows('negative').map((item, index) => {
      return (
        <tr className="table-data" key={index}>
          <td>{item.score}</td>
          <td>{item.name}</td>
          <td>{item.description}</td>
          <td>
            <span className="buttons-container">
              <button onClick={() => this.handleEdit(item)}>
                <img src="../../assets/Edit_Icon.svg" alt="edit" />
              </button>
              <button onClick={() => this.deleteItem(item)}>
                <img src="../../assets/Delete_Icon.svg" alt="Delete" />
              </button>
            </span>
          </td>
        </tr>
      )
    })
  }

  renderPositiveImpactsData() {
    return this.getPageRows('positive').map((item, index) => {
      return (
        <tr className="table-data" key={index}>
          <td>{item.score}</td>
          <td>{item.name}</td>
          <td>{item.description}</td>
          <td>
            {' '}
            <span className="buttons-container">
              <button onClick={() => this.handleEdit(item)}>
                <img src="../../assets/Edit_Icon.svg" alt="edit" />
              </button>
              <button onClick={() => this.deleteItem(item)}>
                <img src="../../assets/Delete_Icon.svg" alt="Delete" />
              </button>
            </span>
          </td>
        </tr>
      )
    })
  }

  addItem = values => {
    const data = values
    this.setState(
      { showPositiveAdd: false, showNegativeAdd: false, loading: true },
      () => {
        axios
          .post(
            BASE_URL + `/organisations/${this.state.orgId}/impacts`,
            {
              ...values,
            },
            {
              headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
              },
            }
          )
          .then(res => {
            if (res.status === 200) {
              this.fetchData()
            }
          })
      }
    )
  }

  editItem = values => {
    const data = values
    this.setState({ showEdit: false, loading: true }, () => {
      axios
        .put(
          BASE_URL + `/organisations/${this.state.orgId}/impacts/${data.id}`,
          {
            ...values,
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }

  render() {
    return (
      <>
        <div className="table-container px-5 my-5">
          <h4>
            <InfoToolTip
              title={'Positive Impacts'}
              text={' Positive Impacts Info text'}
            ></InfoToolTip>
          </h4>
          <p>
            Add New{' '}
            <i
              className="fa fa-plus"
              onClick={() => this.setState({ showPositiveAdd: true })}
            />
          </p>

          <table style={{ width: '100%' }}>
            <thead>
              <tr className="bg-table-header">
                <th style={{ width: '5%' }}>Rating</th>
                <th style={{ width: '15%' }}>Impact Title</th>
                <th>Description</th>
                <th className="w-10"></th>
              </tr>
            </thead>
            <tbody>
              {this.state.loading ? (
                <tr className="text-center">
                  <td colSpan={3}>Loading...</td>{' '}
                </tr>
              ) : (
                this.renderPositiveImpactsData()
              )}
            </tbody>
          </table>

          <div className="text-right mt-3">
            <span style={{ fontSize: '14px' }}>
              Showing page {this.state.positivePage + 1} of{' '}
              {this.state.totalPositivePages}
            </span>
            <button
              className="previous-page btn btn-primary mx-2"
              disabled={this.state.positivePage == 0}
              onClick={() =>
                this.setState(prevState => ({
                  positivePage: prevState.positivePage - 1,
                }))
              }
            >
              Prev
            </button>
            <button
              disabled={
                this.state.positivePage === this.state.totalPositivePages - 1
              }
              className="next-page btn btn-primary"
              onClick={() =>
                this.setState(prevState => ({
                  positivePage: prevState.positivePage + 1,
                }))
              }
            >
              Next
            </button>
          </div>

          {this.state.showPositiveAdd && (
            <AddTableItemModal
              showModal={this.state.showPositiveAdd}
              addField={this.addItem}
              dismissModal={() => this.setState({ showPositiveAdd: false })}
              type="POSITIVE"
            />
          )}

          {this.state.showEdit && (
            <EditTableItemModal
              showModal={this.state.showEdit}
              editField={this.editItem}
              dismissModal={() => this.setState({ showEdit: false })}
              item={this.state.selectedItem}
            />
          )}
        </div>
        <div className="table-container px-5 my-5">
          <h4>Negative Impacts</h4>
          <p>
            Add New{' '}
            <i
              className="fa fa-plus"
              onClick={() => this.setState({ showNegativeAdd: true })}
            />
          </p>

          <table style={{ width: '100%' }}>
            <thead>
              <tr className="bg-table-header">
                <th style={{ width: '5%' }}>Rating</th>
                <th style={{ width: '15%' }}>Impact Title</th>
                <th>Description</th>
                <th className="w-10"></th>
              </tr>
            </thead>
            <tbody>
              {this.state.loading ? (
                <tr className="text-center">
                  <td colSpan={3}>Loading...</td>{' '}
                </tr>
              ) : (
                this.renderNegativeImpactsData()
              )}
            </tbody>
          </table>
          <div className="text-right mt-3">
            <span style={{ fontSize: '14px' }}>
              Showing page {this.state.negativePage + 1} of{' '}
              {this.state.totalNegativePages}
            </span>
            <button
              className="previous-page btn btn-primary mx-2"
              disabled={this.state.negativePage == 0}
              onClick={() =>
                this.setState(prevState => ({
                  negativePage: prevState.negativePage - 1,
                }))
              }
            >
              Prev
            </button>
            <button
              disabled={
                this.state.negativePage === this.state.totalNegativePages - 1
              }
              className="next-page btn btn-primary"
              onClick={() =>
                this.setState(prevState => ({
                  negativePage: prevState.negativePage + 1,
                }))
              }
            >
              Next
            </button>
          </div>

          {this.state.showNegativeAdd && (
            <AddTableItemModal
              showModal={this.state.showNegativeAdd}
              addField={this.addItem}
              dismissModal={() => {
                console.log('MY MODAL')
                this.setState({ showNegativeAdd: false })
              }}
              type="NEGATIVE"
            />
          )}

          {this.state.showEdit && (
            <EditTableItemModal
              showModal={this.state.showEdit}
              editField={this.editItem}
              dismissModal={() => this.setState({ showEdit: false })}
              item={this.state.selectedItem}
            />
          )}
        </div>
      </>
    )
  }
}

const AddTableItemModal = props => {
  const { showModal } = props
  console.log('MODAL PROPS--->', props)
  const [show, setModal] = useState(props.showModal)
  const submitRef = React.useRef()
  console.log('MODAL--->')

  const handleClose = () => {
    console.log('DISMISS MODAL')
    // setModal(false)
    props.dismissModal()
  }

  const handleSave = () => {
    submitRef.current.click()
  }

  const formik = useFormik({
    initialValues: {
      score: '',
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      score: Yup.number().required('Required'),
      name: Yup.string().required('Required'),
      // description: Yup.string().required('Required'),
    }),
    onSubmit: values => {
      props.addField({ ...values, impactType: props.type })
    },
  })
  return (
    <Modal show={showModal} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add New</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">Rating</label>
            <input
              id="score"
              name="score"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.score}
            />
            {formik.touched.score && formik.errors.score ? (
              <div className="text-center text-red pr-5">
                {formik.errors.score}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="name">Impact Title</label>
            <input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-center text-red pr-5">
                {formik.errors.name}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              type="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="text-center text-red pr-5">
                {formik.errors.description}
              </div>
            ) : null}
          </div>

          <button type="submit" ref={submitRef} className="d-none">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const EditTableItemModal = props => {
  const [show, setModal] = useState(props.showModal)
  const submitRef = React.useRef()
  const item = props.item

  const handleClose = () => {
    props.dismissModal()
  }

  const handleSave = () => {
    submitRef.current.click()
  }

  const formik = useFormik({
    initialValues: {
      score: item.score,
      name: item.name,
      description: item.description,
    },
    validationSchema: Yup.object({
      score: Yup.number().required('Required'),
      name: Yup.string().required('Required'),
      // description: Yup.string().required('Required'),
    }),
    onSubmit: values => {
      props.editField({
        ...values,
        id: item.id,
        impactType: item.impactType.name,
      })
    },
  })

  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">Rating</label>
            <input
              id="score"
              name="score"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.score}
            />
            {formik.touched.score && formik.errors.score ? (
              <div className="text-center text-red pr-5">
                {formik.errors.score}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="name">Impact Title</label>
            <input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-center text-red pr-5">
                {formik.errors.name}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              type="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="text-center text-red pr-5">
                {formik.errors.description}
              </div>
            ) : null}
          </div>

          <button type="submit" ref={submitRef} className="d-none">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
