import React, {useContext} from 'react'
import userContext from '../../../../context/userContext';
import Table from './RiskRegister.TableIndividual.jsx'
import DetailedTable from './RiskRegister.DetailedTable.jsx'
export default function RiskRegisterReportIndividual({data}) {
    const { sessionDetails } = useContext(userContext)
    const profileImage = sessionDetails?.profileImage?.url;
    const { fullName, jobTitle } = sessionDetails?.user
    const [total, setTotal] = React.useState({
        estimatedCost: 0, actualCost: 0
    })
  
    console.log(data.data)

    return (
        <div>
        <div className="w-100">
            {
                data?.criteria === 'INDIVIDUAL' && <div className="report-profile-section">
                    <img src={profileImage} alt={fullName} className="navProfileImage" />
                    <div className="name-holder">
                        <span>{fullName}</span>
                        <span>{jobTitle}</span>
                    </div>
                </div>
            }
        </div>
        
        {data?.detailed ?  <DetailedTable risks={data.data} /> : <Table risks={data.data}  /> }


    </div>
    )
}
