import React from 'react'
import { Navbar } from 'react-bootstrap'
import Sidebar from '../common/Sidebar.jsx'
import Organisation from '../presentational/Organisation.jsx'

const OrganisationContainer = (props) => {
    return (
        <div>
            <div>
            <Sidebar {...props} />
            </div>
            <div>
                <Organisation />
            </div>
        </div>
    )
}

export default OrganisationContainer
