import React, { Component, useState, useEffect } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Modal, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import ButtonsHeader from '../../../common/EditDeleteTH.jsx'
import ButtonActions from '../../../common/EditDeleteButtons.jsx'
import * as moment from 'moment'
import Swal from 'sweetalert2'
import { InfoToolTip } from '../../../common/InfoTooltip.jsx'
import Auth from '../../../../lib/Auth.js'
import { notification, Modal as M1 } from 'antd'

import includes from 'lodash/fp/includes'
import toLower from 'lodash/fp/toLower'
import userContext from '../../../../context/userContext'
export default class StrategicIndicatorsTable extends Component {
  static contextType = userContext

  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      users: [],
      objectives: [],
      showEdit: false,
      frequency: [],
      showAdd: false,
      loading: true,
      orgId: props.orgId,
      selectedItem: null,
      tableHeading: props.tableHeading,
      showHeadings: props.showHeadings,
      indicatorType: props.indicatorType,
      objEditModal: props.objEditModal,
      page: 0,
      rowsPerPage: 10,
      totalPages: 0,
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      orgId: props.orgId,
      // users: props.users,
      objectives: props.objectives,
      indicatorType: props.indicatorType,
      showHeadings: props.showHeadings,
      objEditModal: props.objEditModal,
      tableHeading: props.tableHeading,
      //frequency: [],
    })
  }

  componentDidMount() {
    // this.fetchData()
    this.getUsers()
    this.getobjectives()
    this.getFrequency()
  }

  getPageRows = () => {
    var chunks = [],
      i = 0,
      n = this.state.tableData.length
    while (i < n) {
      chunks.push(this.state.tableData.slice(i, (i += this.state.rowsPerPage)))
    }

    return chunks[this.state.page] || this.state.tableData
  }

  getTotalPages = () => {
    var chunks = [],
      i = 0,
      n = this.state.tableData.length
    while (i < n) {
      chunks.push(this.state.tableData.slice(i, (i += this.state.rowsPerPage)))
    }
    return chunks.length
  }

  getFrequency() {
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/frequencyOfReviews`, {
        params: {
          organisation: this.state.orgId,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ frequency: res.data, loading: false })
        }
      })
  }

  fetchData() {
    let params =
      this.props.indicatorType === 'DEPARTMENT'
        ? {
            organisation: this.state.orgId,
            objectiveType: this.state.indicatorType,
            department: this.state.loggedIndepartment.id,
          }
        : {
            organisation: this.state.orgId,
            objectiveType: this.state.indicatorType,
          }
    axios
      .get(BASE_URL + `/indicators`, {
        params: params,
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ tableData: res.data, page: 0, loading: false }, () =>
            this.setState({ totalPages: this.getTotalPages() })
          )
        }
      })
  }

  getUsers() {
    const { sessionDetails } = this.context
    axios
      .get(BASE_URL + `/organisations/${this.props.orgId}/users`, {
        params: {
          department: 0,
          team: 0,
          jobRole: 0,
          status: 0,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          let filterData = res?.data.find((data) => {
            return data.id === Auth.getLoggedUserId()
          })
          this.setState(
            {
              users: res.data,
              user: res.data,
              loading: false,
              loggedIndepartment: filterData.department,
              sessionDetails,
            },
            () => {
              this.fetchData()
            }
          )
        }
      })
  }

  getobjectives() {
    axios
      .get(BASE_URL + `/objectives`, {
        params: {
          organisation: this.props.orgId,
          objectiveType: 'STRATEGIC',
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ objectives: res.data, loading: false })
        }
      })
  }

  handleEdit = (item) => {
    if (
      this.state.objEditModal == false ||
      this.state.objEditModal == undefined
    ) {
      this.setState({ selectedItem: item }, () =>
        this.setState({ showEdit: true })
      )
    } else {
      this.props.editItem(item)
    }
  }

  deleteItem = (item) => {
    this.setState({ loading: true }, () => {
      axios
        .delete(BASE_URL + `/indicators/${item.id}`, {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
        .catch((err) => {
          if (err.response && err.response?.status == '400') {
            M1.confirm({
              title: 'Confirm',
              content:
                'The Objective is linked with one or more risks. Are you sure you want to delete it ?',
              okText: 'Delete',
              onOk: () => this.confirmDelete(item.id),

              cancelText: 'Cancel',
            })
          }
        })
      // .then((res) => window.location.reload())
    })
  }
  confirmDelete = (id) => {
    axios
      .delete(BASE_URL + `/indicators/${id}?force=true`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        this.setState({ loading: false })

        if (res.status === 200) {
          this.fetchData()
        } else {
          notification.warning({
            message: 'Unsuccessfully',
            description: '',
          })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  renderTableData() {
    return this.getPageRows().map((item, index) => {
      return (
        <tr className="table-data" key={index}>
          {/* <td>{item.year}</td> */}
          <td>{item.ref}</td>
          <td>
            {this.props.tableHeading?.toLowerCase() == 'strategic objective' &&
              item.objectives.map((item) => item.description).join(', ')}

            {this.props.tableHeading?.toLowerCase() == 'strategic indicator' &&
              item.parentIndicator.description}
            {this.props.tableHeading?.toLowerCase() ==
              'departmental indicator' && item.parentIndicator?.description}
          </td>
          <td style={{ width: '30%' }}>{item.description}</td>
          <td>
            {moment(
              `${item.startDt.day}/${item.startDt.month}/
                        ${item.startDt.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td>
            {moment(
              `${item.endDt.day}/${item.endDt.month}/
                        ${item.endDt.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td>
            {
              this.state.frequency.filter(
                (freq) => freq.id === item.frequencyOfReview
              )[0]?.name
            }
          </td>
          <td>
            {console.log('MY LOG INDICATOR --->', this.state)}
            {this?.state?.users &&
              this?.state?.users.filter((user) => user.id === item.lead)[0]
                ?.name}
          </td>
          {Auth.getRole() !== 'View' && (
            <ButtonActions
              editAction={() => this.handleEdit(item)}
              deleteAction={() => this.deleteItem(item)}
            />
          )}
        </tr>
      )
    })
  }

  addItem = (values) => {
    console.log('values DD ', values)
    let data = { ...values }
    data.startDt = moment(values.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.startDate = data.startDt
    data.endDt = moment(values.endDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.endDate = data.endDt

    if (this.state?.loggedIndepartment?.id) {
      this.setState({ showAdd: false, loading: true }, () => {
        axios
          .post(
            BASE_URL + `/indicators`,
            {
              ...data,
              organisation: this.state.orgId,
              objectiveType: this.state.indicatorType,
              department: this.state.loggedIndepartment.id,
            },
            {
              headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              this.fetchData()
              Swal.fire({
                title: 'Data posted successfully',
                text: 'Your form data is now present in the table',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'OK',
              })
            }
          })
          .catch((error) => {
            this.setState({ showAdd: false, loading: false })
            console.log(error)
            Swal.fire({
              title: 'Data posted un-successfully',
              text: 'Unable to save data.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: 'OK',
            })
          })
      })
    } else {
      this.setState({ showAdd: false })
      Swal.fire({
        title: 'Data posted un-successfully',
        text:
          'Your request can not be processed as department is not assigned to the user.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'OK',
      })
    }
  }

  editItem = (values) => {
    let data = { ...values }
    data.startDt = moment(values.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.startDate = data.startDt
    data.endDt = moment(values.endDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.endDate = data.endDt

    this.setState({ showEdit: false, loading: true }, () => {
      axios
        .put(
          BASE_URL + `/indicators/${data.id}`,
          {
            ...data,
            organisation: this.state.orgId,
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }
  render() {
    return this.state.loading == false ? (
      <>
        <div
          className={`table-container table-main${
            this.state.tableHeading?.toLowerCase() == 'strategic objective'
              ? 'px-5 my-5'
              : ''
          }`}
        >
          {this.state.showHeadings && (
            <>
              <h4>
                <InfoToolTip
                  title={'Strategic Indicators'}
                  text={' Strategic Indicators Info text'}
                ></InfoToolTip>
              </h4>
              {Auth.getRole() !== 'View' && (
                <p>
                  Add New{' '}
                  <i
                    className="fa fa-plus"
                    onClick={() => this.setState({ showAdd: true })}
                  />
                </p>
              )}
            </>
          )}
          <div className="table-main">
            <table
              style={{ width: '100%' }}
              className="strategic-indicators-table"
            >
              <thead>
                <tr className="bg-table-header">
                  {/* <th>Year</th> */}
                  <th style={{ width: '5%' }}>Ref</th>
                  <th>
                    {this.props.orgStratigic
                      ? 'Strategic Objective'
                      : 'Indicators (Strategic/Parent)'}
                    {/*{includes(toLower(this.props.tableHeading), 'indicator')
                      ? 'Strategic Indicator'
                      : 'Strategic Objective'}*/}
                  </th>
                  <th style={{ width: '30%' }}>
                    {this.props.orgStratigic
                      ? ' Indicator Description '
                      : 'Department Indicator'}
                    {/*{includes(toLower(this.props.tableHeading), 'indicator')
                      ? 'Department Indicator'
                      : 'Department Objective'}*/}
                  </th>
                  <th style={{ width: '8%' }}>Start Date</th>
                  <th style={{ width: '8%' }}>End Date</th>
                  <th style={{ width: '10%' }}>Review Freq.</th>
                  <th style={{ width: '10%' }}>Lead</th>
                  <ButtonsHeader />
                </tr>
              </thead>
              <tbody>
                {this.state.loading ? (
                  <tr className="text-center">
                    <td colSpan={5}>Loading...</td>{' '}
                  </tr>
                ) : (
                  this.renderTableData()
                )}
              </tbody>
            </table>
          </div>

          {this.state.showAdd && (
            <AddTableItemModal
              showModal={this.state.showAdd}
              addField={this.addItem}
              orgId={this.state.orgId}
              users={this.state.users}
              objectives={this.state.objectives}
              frequency={this.state.frequency}
              dismissModal={() => this.setState({ showAdd: false })}
            />
          )}

          {this.state.showEdit && (
            <EditTableItemModal
              showModal={this.state.showEdit}
              editField={this.editItem}
              orgId={this.state.orgId}
              users={this.state.users}
              objectives={this.state.objectives}
              dismissModal={() => this.setState({ showEdit: false })}
              item={this.state.selectedItem}
              frequency={this.state.frequency}
            />
          )}
        </div>
        <div className="text-right mt-3">
          <span style={{ fontSize: '14px' }}>
            Showing page {this.state.totalPages ? this.state.page + 1 : 0} of{' '}
            {this.state.totalPages}
          </span>
          <button
            className="previous-page btn btn-primary mx-2"
            disabled={this.state.page == 0}
            onClick={() =>
              this.setState((prevState) => ({
                page: prevState.page - 1,
              }))
            }
          >
            Prev
          </button>
          <button
            disabled={
              this.state.page === this.state.totalPages - 1 ||
              this.state.totalPages === 0
            }
            className="next-page btn btn-primary"
            onClick={() =>
              this.setState((prevState) => ({
                page: prevState.page + 1,
              }))
            }
          >
            Next
          </button>
        </div>
      </>
    ) : (
      'Loading'
    )
  }
}

const AddTableItemModal = (props) => {
  const [show, setModal] = useState(props.showModal)
  const submitRef = React.useRef()
  const [startDt, setStartDt] = useState(null)
  const [endDt, setEndDt] = useState(null)

  const handleClose = () => {
    props.dismissModal()
  }

  const handleSave = () => {
    submitRef.current.click()
  }

  const handleEndDateChange = (e) => {
    setEndDt(moment(e).format('DD-MM-YY'))
  }

  const handleStartDateChange = (e) => {
    setStartDt(moment(e).format('DD-MM-YY'))
  }

  const formik = useFormik({
    initialValues: {
      year: 0,
      ref: '',
      objectives: [],
      description: '',
      reviewFrequency: -1,
      lead: -1,
      organisation: props.orgId,
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const formData = {
        ...values,
        endDt,
        startDt,
        endDate: endDt,
        startDate: startDt,
        weight: 20, //needed else api breaks
      }
      props.addField(formData)
    },
  })
  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add New</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="year">Year</label>
              <input
                id="year"
                name="year"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.year}
              />
              {formik.touched.year && formik.errors.year ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.year}
                </div>
              ) : null}
            </div>

            <div className="col-6">
              <label htmlFor="ref">Ref</label>
              <input
                id="ref"
                name="ref"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ref}
              />
              {formik.touched.ref && formik.errors.ref ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.ref}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <div className="col-12">
              <label className="w-100" htmlFor="objectives">
                Strategic objectives
              </label>
              <select
                style={{ height: '70px' }}
                defaultValue={'default'}
                id="objectives"
                name="objectives"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiple
              >
                <option value={-1} disabled="disabled"></option>
                {props.objectives.map((objective, i) => (
                  <option key={i} value={objective.id}>
                    {objective.description}
                  </option>
                ))}
              </select>
              {formik.touched.objectives && formik.errors.objectives ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.objectives}
                </div>
              ) : null}
            </div>

            <div className="col-12 mt-3">
              <label className="w-100" htmlFor="description">
                Indicator description
              </label>
              <textarea
                id="description"
                name="description"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.description}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="startDt">Start Date</label>
              <DatePicker
                value={startDt}
                name="startDt"
                onChange={(e) => handleStartDateChange(e)}
                dateFormat="dd-mm-yy"
                autoComplete="off"
              />
            </div>

            <div className="col-6">
              <label htmlFor="endDt">End Date</label>
              <DatePicker
                value={endDt}
                name="endDt"
                onChange={(e) => handleEndDateChange(e)}
                dateFormat="dd-mm-yy"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="reviewFreq" className="w-100">
                Review Frequency
              </label>
              <select
                defaultValue={-1}
                value={formik.values.reviewFrequency}
                id="reviewFrequency"
                name="reviewFrequency"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value={-1} disabled="disabled"></option>
                {props.frequency.map((frequency, i) => (
                  <option key={i} value={frequency.id}>
                    {frequency.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-6">
              <label htmlFor="lead">Strategic Lead</label>
              <select
                defaultValue={-1}
                id="lead"
                name="lead"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">-</option>

                {props.users.map((user, i) => (
                  <option key={i} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
              {formik.touched.lead && formik.errors.lead ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.lead}
                </div>
              ) : null}
            </div>
          </div>

          <button type="submit" ref={submitRef} className="d-none">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const EditTableItemModal = (props) => {
  const [show, setModal] = useState(props.showModal)
  const submitRef = React.useRef()
  const [startDt, setStartDt] = useState(
    moment(
      `${props.item.startDt.day}-${props.item.startDt.month}-${props.item.startDt.year}`,
      'DD-MM-YYYY'
    ).format('DD-MM-YY')
  )
  const [endDt, setEndDt] = useState(
    moment(
      `${props.item.endDt.day}-${props.item.endDt.month}-${props.item.endDt.year}`,
      'DD-MM-YYYY'
    ).format('DD-MM-YY')
  )
  const item = props.item

  console.log('ITEMS --->', item)

  const handleClose = () => {
    props.dismissModal()
  }

  const handleSave = () => {
    submitRef.current.click()
  }

  const formik = useFormik({
    initialValues: {
      year: item.year,
      ref: item.ref,
      description: item.description,
      reviewFrequency:
        item.frequencyOfReview == null ? 0 : item.frequencyOfReview,
      lead: item.lead,
      organisation: props.orgId,
      objectives: item.objectives.map((item) => item.id),
    },
    validationSchema: Yup.object({
      // year: Yup.string().required('Required'),
      // ref: Yup.string().required('Required'),
      // goal: Yup.string().required('Required'),
      // description: Yup.string().required('Required'),
      // weight: Yup.string().required('Required'),
      // startDt: Yup.string().required('Required'),
      // endDt: Yup.string().required('Required'),
      // lead: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      props.editField({
        ...values,
        id: item.id,
        objectives: values.objectives,
        //objectiveType: 'STRATEGIC',
        startDt: startDt,
        endDt: endDt,
        ref: values.ref == null ? Math.floor(Math.random() * 1000) : values.ref,
      })
    },
  })

  const handleEndDateChange = (e) => {
    setEndDt(moment(e).format('DD-MM-YY'))
  }

  const handleStartDateChange = (e) => {
    setStartDt(moment(e).format('DD-MM-YY'))
  }

  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="year">Year</label>
              <input
                id="year"
                name="year"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.year}
              />
              {formik.touched.year && formik.errors.year ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.year}
                </div>
              ) : null}
            </div>

            <div className="col-6">
              <label htmlFor="ref">Ref</label>
              <input
                id="ref"
                name="ref"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ref}
              />
              {formik.touched.ref && formik.errors.ref ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.ref}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group ">
            <div className="col-12">
              <label className="w-100" htmlFor="objectives">
                Strategic objective
              </label>
              <select
                style={{ height: '70px' }}
                id="objectives"
                name="objectives"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.objectives}
                multiple
              >
                <option value="default" disabled="disabled"></option>
                {props.objectives.map((objective, i) => (
                  <option key={i} value={objective.id.toString()}>
                    {objective.description}
                  </option>
                ))}
              </select>
              {formik.touched.objectives && formik.errors.objectives ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.objectives}
                </div>
              ) : null}
            </div>

            <div className="col-12 mt-3">
              <label className="w-100" htmlFor="description">
                Indicator description
              </label>
              <textarea
                id="description"
                name="description"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.description}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="startDt">Start Date</label>
              <DatePicker
                value={startDt}
                name="startDt"
                onChange={(e) => handleStartDateChange(e)}
                dateFormat="dd-mm-yy"
                autoComplete="off"
              />
            </div>

            <div className="col-6">
              <label htmlFor="endDt">End Date</label>
              <DatePicker
                value={endDt}
                name="startDt"
                onChange={(e) => handleEndDateChange(e)}
                dateFormat="dd-mm-yy"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="reviewFreq" className="w-100">
                Review Frequency
              </label>
              <select
                id="reviewFrequency"
                name="reviewFrequency"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reviewFrequency}
              >
                <option value={0} disabled="disabled"></option>
                {props.frequency.map((frequency, i) => (
                  <option key={i} value={frequency.id}>
                    {frequency.name}
                  </option>
                ))}
              </select>

              {formik.touched.reviewFreq && formik.errors.reviewFreq ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.reviewFreq}
                </div>
              ) : null}
            </div>
            <div className="col-6">
              <label htmlFor="lead">Strategic Lead</label>
              <select
                id="lead"
                name="lead"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lead}
              >
                <option value="">-</option>

                {props.users.map((user, i) => (
                  <option key={i} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
              {formik.touched.lead && formik.errors.lead ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.lead}
                </div>
              ) : null}
            </div>
          </div>

          <button type="submit" ref={submitRef} className="d-none">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
