import React, { useEffect, useState } from 'react'
import { Bar } from '@ant-design/plots'
import upperFirst from 'lodash/fp/upperFirst'
import lowerCase from 'lodash/fp/lowerCase'
import { isEmpty } from 'lodash/fp'
import moment from 'moment'

const ObjectivesKpiModal = (props) => {
  const [orgChartObj, setOrgChartObj] = useState(null)
  const [orgChartKpi, setOrgChartKpi] = useState(null)

  const {
    userCriteria,
    selectedDept,
    reportData,
    frequencyOfReview,
    userDepartments,
    sessionDetails,
    usersData,
  } = props

  const profileImage = sessionDetails?.profileImage?.url
  const { fullName, jobTitle } = sessionDetails?.user

  useEffect(() => {
    if (reportData && userCriteria === 'ORGANISATIONAL') {
      setOrgChartObj(getOrgnizationalChart(reportData?.objectives))
      setOrgChartKpi(getOrgnizationalChart(reportData?.kpis))
    }
  }, [reportData])

  const data = [
    {
      type: 'Audit',
      sales: 38,
    },
    {
      type: 'Risk Team',
      sales: 52,
    },
    {
      type: 'IT',
      sales: 61,
    },
    {
      type: 'Commercial',
      sales: 145,
    },
    {
      type: 'Marketing',
      sales: 48,
    },
  ]

  const getOrgnizationalChart = (reportData) => {
    try {
      let orgChartCount = userDepartments?.reduce((final, item) => {
        let countDept = reportData?.filter((fData) => {
          return fData.department == item.id
        })
        return countDept?.length > 0
          ? [
              ...final,
              {
                type: item.name,
                count: countDept?.length,
              },
            ]
          : final
      }, [])
      // setOrgChart(orgChartCount)
      return orgChartCount
    } catch (e) {
      console.log(e)
      return []
    }
  }
  const configObj = {
    data: orgChartObj,
    xField: 'count',
    yField: 'type',
    seriesField: 'type',
    style: {
      height: '220px',
      width: '400px',
    },
    color: ({ type }) => {
      return type === 'Commercial' ? '#FAAD14' : '#5B8FF9'
    },
    legend: false,
  }

  const configKpi = {
    data: orgChartKpi,
    xField: 'count',
    yField: 'type',
    seriesField: 'type',
    style: {
      height: '220px',
      width: '400px',
    },
    color: ({ type }) => {
      return type === 'Commercial' ? '#FAAD14' : '#5B8FF9'
    },
    legend: false,
  }

  return (
    <div>
      <div className="w-100">
        {userCriteria === 'INDIVIDUAL' && (
          <div className="report-profile-section">
            <img
              src={profileImage}
              alt={fullName}
              className="navProfileImage"
            />
            <div className="name-holder">
              <span>{fullName}</span>
              <span>{jobTitle}</span>
            </div>
          </div>
        )}
      </div>
      {/* <h5 className="text-center">
        {upperFirst(lowerCase(userCriteria))} Objectives and KPIs
      </h5> */}
      {userCriteria === 'DEPARTMENTAL' && (
        <h6 className="mt-3">{selectedDept?.name}</h6>
      )}
      <div className="mt-4 objKpiTable">
        <h6>Objectives</h6>

        <table className="table table-striped multi-report-table" data-title="Objectives" data-departmentName={selectedDept?.name}>
          <thead>
            <tr>
              <th scope="col">Ref</th>
              <th scope="col">
                {userCriteria === 'INDIVIDUAL'
                  ? 'Personal Objectives'
                  : 'Objectives'}
              </th>

              <th scope="col">Start Date</th>
              <th scope="col">End date</th>
              <th scope="col">Review Freq</th>
              {userCriteria !== 'INDIVIDUAL' && <th scope="col">Lead</th>}
            </tr>
          </thead>
          <tbody>
            {!isEmpty(reportData) &&
              reportData?.objectives?.map((item, key) => {
                return (
                  <tr key={key}>
                    <td>{item.ref}</td>
                    <td>{item.description}</td>
                    <td>
                      {moment(
                        `${item?.startDt?.day}/${item?.startDt?.month}/
                        ${item?.startDt?.year}`,
                        'DD/MM/YYYY'
                      ).format('DD/MM/YY')}
                    </td>
                    <td>
                      {moment(
                        `${item?.targetEndDt?.day}/${item?.targetEndDt?.month}/
                        ${item?.targetEndDt?.year}`,
                        'DD/MM/YYYY'
                      ).format('DD/MM/YY')}
                    </td>
                    <td>
                      {
                        frequencyOfReview?.find(
                          (freq) => freq.id == item.frequencyOfReview
                        )?.name
                      }
                    </td>
                    {userCriteria !== 'INDIVIDUAL' && (
                      <td>
                        {
                          usersData?.find((user) => user.id == item.lead)
                            ?.fullName
                        }
                      </td>
                    )}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>

      <div className="mt-4 objKpiTable">
        <h6>Indicators</h6>
        <table className="table table-striped multi-report-table" data-title="Indicators">
          <thead>
            <tr>
              <th scope="col">Ref</th>

              <th scope="col">
                {userCriteria === 'INDIVIDUAL'
                  ? 'Personal Indicator'
                  : 'Indicator'}
              </th>
              <th scope="col">Start Date</th>
              <th scope="col">End date</th>
              <th scope="col">Review Freq</th>
              {userCriteria !== 'INDIVIDUAL' && <th scope="col">Lead</th>}
            </tr>
          </thead>
          <tbody>
            {!isEmpty(reportData) &&
              reportData?.kpis?.map((item, key) => {
                return (
                  <tr key={key}>
                    <td>{item.ref}</td>
                    <td>{item?.description}</td>
                    <td>
                      {moment(
                        `${item?.startDt?.day}/${item?.startDt?.month}/
                        ${item?.startDt?.year}`,
                        'DD/MM/YYYY'
                      ).format('DD/MM/YY')}
                    </td>
                    <td>
                      {moment(
                        `${item?.endDt?.day}/${item?.endDt?.month}/
                        ${item?.endDt?.year}`,
                        'DD/MM/YYYY'
                      ).format('DD/MM/YY')}
                    </td>
                    <td>
                      {
                        frequencyOfReview?.find(
                          (freq) => freq.id == item.frequencyOfReview
                        )?.name
                      }
                    </td>
                    {userCriteria !== 'INDIVIDUAL' && (
                      <td>
                        {
                          usersData?.find((user) => user.id == item.lead)
                            ?.fullName
                        }
                      </td>
                    )}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      {userCriteria === 'ORGANISATIONAL' && (
        <div className="m-3 d-flex justify-content-between">
          <div>
            {!isEmpty(orgChartObj) && (
              <div>
                <h6>Analytics of Department objectives</h6>
                <Bar {...configObj} />
              </div>
            )}
          </div>
          <div>
            {!isEmpty(orgChartKpi) && (
              <div>
                <h6>Analytics of Department indicators</h6>
                <Bar {...configKpi} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default ObjectivesKpiModal
