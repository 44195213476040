import React, { useState, useEffect } from 'react'
import { Dropdown, Table, Button, Card, ListGroup } from 'react-bootstrap'
import axios from 'axios'
import toString from 'lodash/fp/toString'

import '../../style/container/organisation.scss'
import Swal from 'sweetalert2'
import { InfoToolTip } from '../common/InfoTooltip.jsx'
import Auth from '../../lib/Auth.js'
import { notification } from 'antd'
import { isEmpty } from 'lodash'
import { uncertaintyType } from '../../common/uncertaintyType'

const CustomDropdown = props => {
  const [orgDepartments, setOrgDepartments] = useState([])
  const [parentId, setParentId] = useState([])
  const [children, setChildren] = useState([])
  const [orgID, setorgID] = useState(props.orgId)
  const [deptWithChild, setDeptWithChild] = useState([])
  const [deptHasParent, setDeptHasParent] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [objectivesData, setObjectivesData] = useState([])
  const [riskData, setRiskData] = useState([])
  const [users, setUsers] = useState([])
  const [frequency, setFrequency] = useState([])
  const [myDepartments, setMyDepartments] = useState([])
  const [objectivePage, setObjectivePage] = useState(0)
  const [riskPage, setRiskPage] = useState(0)
  const [rowsPerPage, setRows] = useState(5)
  const [totalRiskPages, setTotalRiskPages] = useState(0)
  const [totalObjectivePages, setTotalObjectivePages] = useState(0)
  const [userPermissions, setUserPermissions] = useState({})

  useEffect(() => {
    fetchData()
  }, [])

  const getTotalPages = type => {
    var chunks = [],
      i = 0,
      n = type == 'objective' ? objectivesData.length : riskData.length
    while (i < n) {
      if (type == 'objective') {
        chunks.push(objectivesData.slice(i, (i += rowsPerPage)))
      } else {
        chunks.push(riskData.slice(i, (i += rowsPerPage)))
      }
    }
    return chunks.length
  }

  useEffect(() => {
    setTotalRiskPages(getTotalPages('risk'))
  }, [riskData])

  useEffect(() => {
    setTotalObjectivePages(getTotalPages('objective'))
  }, [objectivesData])

  const getPageRows = type => {
    var chunks = [],
      i = 0,
      n = type == 'objective' ? objectivesData.length : riskData
    while (i < n) {
      if (type == 'objective') {
        chunks.push(objectivesData.slice(i, (i += rowsPerPage)))
      } else {
        chunks.push(riskData.slice(i, (i += rowsPerPage)))
      }
    }
    return chunks[type == 'objective' ? objectivePage : riskPage] || []
  }

  const fetchData = () => {
    let departments = []
    axios
      .get(BASE_URL + `/organisations/${orgID}/departments`, {
        params: {
          parentDepartment: 0,
          departmentHead: 0,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        departments = res.data
      })
      .then(() => {
        //pushing dept ids into deptIds array
        let depIds = []
        departments.forEach(item => {
          if (depIds.includes(item.id)) {
          } else {
            depIds.push(item.id)
          }
        })
        //getting urls for each individual child
        const urls = depIds.map(item =>
          axios.get(
            BASE_URL + `/organisations/${orgID}/departments/${item}/children`,
            {
              headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
              },
            }
          )
        )
        axios.all([...urls]).then(responses => {
          let departmentsWithChildren = []
          responses.forEach(response => {
            if (response.data.length !== 0) {
              departmentsWithChildren = [
                ...departmentsWithChildren,
                ...response.data,
              ]
            }
          })

          departments.forEach(department => {
            let dep = department
            departmentsWithChildren.forEach(child => {
              if (child.parentDepartment.id == department.id) {
                dep.childName = child.name
              }
            })
            if (dep.childName == undefined) {
              dep.childName = ''
            }
          })

          setOrgDepartments(departments)
        })
      })
    axios
      .get(BASE_URL + `/organisations/${orgID}/users`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        // let userDataId = res.data.find(
        //   item => item.emailAddress == Auth.getUserId()
        // )
        console.log('GET AUTH -->', Auth.getLoggedUserId())
        axios
          .get(BASE_URL + `/userPermissions/` + Auth.getLoggedUserId(), {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          })
          .then(res => {
            if (res?.data) {
              let departments = res?.data?.departments
              let userPerm = Object.keys(departments)
              setUserPermissions(userPerm)
            }
          })
        setUsers(res.data)
      })
    axios
      .get(BASE_URL + `/organisations/${orgID}/frequencyOfReviews`, {
        params: {
          organisation: orgID,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        setFrequency(res.data)
      })
    axios
      .get(BASE_URL + `/organisations/${orgID}/departments`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status === 200) {
          setMyDepartments(res.data)
        }
      })
  }

  const getDeptWithParents = () => {
    return orgDepartments.map(item => {
      if (item.parentDepartment !== null) {
        return <li>{item.name}</li>
      }
    })
  }

  const getChildren = () => {
    return orgDepartments.map(item => {
      return <li>{item.childName}</li>
    })
  }

  const getChildrenOfDepartments = dept => {
    return orgDepartments
      .filter(item => item.parentDepartment !== null)
      .map(filtereditem => {
        if (dept == filtereditem.parentDepartment.name) {
          return `${filtereditem.name} `
        }
      })
  }

  const titleClickhandler = () => {}

  const getRiskScore = data => {
    var highestValue = null

    var assessmentArray = []

    data.map(item => {
      if (item.riskAssessmentType.name == 'NET') {
        assessmentArray = [...assessmentArray, item]
      }
    })

    if (assessmentArray.length !== 0) {
      highestValue = setScoreValues(assessmentArray)
    } else {
      highestValue = { score: 0, colour: '#fff' }
    }

    return highestValue
  }

  const setScoreValues = array => {
    var highestValue = { score: 0, colour: '#fff' }
    array.forEach(item => {
      if (
        item.probabilityImpact.riskImpact.score *
          item.probabilityImpact.riskProbability.multiplier >
        highestValue.score
      ) {
        highestValue = {
          score:
            item.probabilityImpact.riskImpact.score *
            item.probabilityImpact.riskProbability.multiplier,
          colour: `#${item.probabilityImpact.colour}`,
        }
      } else {
        highestValue = highestValue
      }
    })
    return highestValue
  }

  const showDeptDetails = id => {
    let thisRiskIds = []
    console.log(
      'USER DEPAT --->',
      userPermissions,
      id,
      userPermissions?.includes(toString(id))
    )
    if (userPermissions?.includes(toString(id))) {
      axios
        .get(BASE_URL + `/objectives`, {
          params: {
            organisation: orgID,
            objectiveType: 'DEPARTMENT',
            department: id,
          },
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        })
        .then(res => {
          if (res.status === 200) {
            setObjectivesData(res.data)
            setObjectivePage(0)
          }
        })
      /*
       .get(BASE_URL + `/organisations/${orgID}/risks`, {
          params: {
            department: `${id}`,
          },
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        })*/

      axios
        .get(BASE_URL + `/organisations/${orgID}/departments/${id}/risks`, {
          params: {
            department: `${id}`,
          },
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        })
        .then(res => {
          if (res.status === 200) {
            let newRes = [
              ...res?.data?.childDepartmentRisks,
              ...res?.data?.departmentRisks,
            ]
            const urls = newRes.map(item =>
              axios.get(BASE_URL + `/organisations/${orgID}/riskTreatments`, {
                params: {
                  risk: item.id,
                },
                headers: {
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
              })
            )

            let data = newRes
            thisRiskIds = newRes

            axios
              .all([...urls])
              .then(responses => {
                responses.forEach(
                  (item, index) => (data[index].riskTreatments = item.data)
                )
              })
              .then(() => {
                const assessmentUrls = thisRiskIds.map(item =>
                  axios.get(
                    BASE_URL +
                      `/organisations/${orgID}/risks/${item.id}/assessments`,
                    {
                      headers: {
                        Authorization: `Bearer ${Auth.getToken()}`,
                      },
                    }
                  )
                )
                axios.all([...assessmentUrls]).then(responses => {
                  responses.forEach((item, index) => {
                    data[index].tableHighestColorScore = getRiskScore(item.data)
                  })
                  let dataFilter =
                    data &&
                    data.reduce(
                      (final, data) => {
                        return {
                          ...final,
                          risk:
                            data.completed && !data.deleted
                              ? [...final.risk, data]
                              : final.risk,
                          draft: !data.completed
                            ? [...final.draft, data]
                            : final.draft,
                          deleted: data.deleted
                            ? [...final.deleted, data]
                            : final.deleted,
                        }
                      },
                      {
                        risk: [],
                        draft: [],
                        deleted: [],
                      }
                    )
                  setRiskData(dataFilter.risk)
                  setRiskPage(0)
                })
              })
          }
        })
    } else {
      notification.error({
        message: 'Permission Error',
        description: 'You do not have permission to view this department.',
      })
    }
  }

  const renderDeptObjectives = () => {
    return objectivesData.map((item, index) => {
      return (
        <tr key={index}>
          {/* <td>{item.year}</td> */}
          <td>{item.ref}</td>
          <td> {item.parentObjective?.description}</td>
          <td>{item.description}</td>
          <td>
            {item.startDt.day}/{item.startDt.month}/{item.startDt.year}
          </td>
          <td>
            {item.targetEndDt.day}/{item.targetEndDt.month}/
            {item.targetEndDt.year}
          </td>
          <td>
            {
              frequency.filter(freq => freq.id == item.frequencyOfReview)[0]
                ?.name
            }
          </td>
          <td>
            {users.filter(user => user.id === item.lead)[0]?.name}
            {/* Users */}
          </td>
        </tr>
      )
    })
  }

  const renderDeptRisks = () => {
    return riskData.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.ref}</td>
          <td>{item.eventDescription}</td>
          <td>{item.eventConsequences}</td>
          <td>
            {!isEmpty(item.impactType.name) &&
              uncertaintyType(item.impactType.name)}{' '}
          </td>
          <td>{item.riskType.name}</td>
          <td
            style={{
              backgroundColor: `${item.tableHighestColorScore.colour}`,
            }}
          >
            {item.tableHighestColorScore.score}
          </td>
          <td>
            {(
              ((item.netEmv.minValue +
                item.netEmv.maxValue +
                item.netEmv.likelyValue) /
                3) *
              (item.netEmv.probability / 100)
            ).toFixed(2)}
          </td>
          <td>{item?.riskTreatments?.length}</td>
          <td>{users.filter(user => user.id === item.owner)[0]?.name}</td>
        </tr>
      )
    })
  }

  const getTopDepartments = () => {
    return orgDepartments.map((orgDepartment, index) => {
      if (orgDepartment.parentDepartment == null) {
        return (
          <div style={{ display: 'inline-flex', cursor: 'pointer' }}>
            <div
              className="card-outer"
              style={{
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer',
              }}
            >
              <Card
                className="card-main"
                border="info"
                style={{
                  flex: 1,
                  cursor: 'pointer',
                  border: '2px dashed #30466a !important',
                }}
                onClick={() => showDeptDetails(orgDepartment.id)}
              >
                <Card.Title>{orgDepartment.name.toUpperCase()}</Card.Title>
                <Card.Body style={{ cursor: 'pointer' }}>
                  <Card.Subtitle style={{ cursor: 'pointer' }}>
                    Parent:
                  </Card.Subtitle>
                  <Card.Text style={{ cursor: 'pointer' }}>
                    <i style={{ cursor: 'pointer' }}>
                      This department has no parent
                    </i>
                  </Card.Text>
                  <Card.Subtitle style={{ cursor: 'pointer' }}>
                    Children:
                  </Card.Subtitle>
                  <ListGroup style={{ cursor: 'pointer' }} variant="flush">
                    <ListGroup.Item>
                      {getChildrenOfDepartments(orgDepartment.name)}
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </div>
          </div>
        )
      }
    })
  }

  const getOtherDepartments = () => {
    return orgDepartments.map((orgDepartment, index) => {
      if (orgDepartment.parentDepartment !== null) {
        return (
          <div style={{ display: 'inline-flex', cursor: 'pointer' }}>
            <div
              className="card-outer"
              style={{
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer',
              }}
            >
              <Card
                className="card-main"
                border="info"
                style={{ flex: 1, cursor: 'pointer' }}
                onClick={() => showDeptDetails(orgDepartment.id)}
              >
                <Card.Title style={{ cursor: 'pointer' }}>
                  {orgDepartment.name.toUpperCase()}
                </Card.Title>
                <Card.Body style={{ cursor: 'pointer' }}>
                  <Card.Subtitle style={{ cursor: 'pointer' }}>
                    Parent:
                  </Card.Subtitle>
                  <Card.Text style={{ cursor: 'pointer' }}>
                    {orgDepartment.parentDepartment.name}
                  </Card.Text>
                  <Card.Subtitle style={{ cursor: 'pointer' }}>
                    Children:
                  </Card.Subtitle>
                  <ListGroup style={{ cursor: 'pointer' }} variant="flush">
                    <ListGroup.Item style={{ cursor: 'pointer' }}>
                      {getChildrenOfDepartments(orgDepartment.name)}
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </div>
          </div>
        )
      }
    })
  }

  const showAllClickHandler = () => {
    setShowAll(!showAll)
  }

  return (
    <div>
      <div className="text-center">
        <h4>
          <b>
            <u>TOP LEVEL DEPARTMENTS OF YOUR ORGANISATION</u>
          </b>
        </h4>
        {getTopDepartments()}
      </div>
      <br />
      {showAll ? (
        <div className="text-center">
          <h4>
            <b>
              <u>OTHER DEPARTMENTS OF YOUR ORGANISATION</u>
            </b>
          </h4>
          <div className="d-flex flex-wrap justify-content-center">
            {getOtherDepartments()}
          </div>
        </div>
      ) : null}
      {showAll ? <br /> : null}
      <div className="text-center">
        <Button onClick={showAllClickHandler}>
          {showAll ? 'SHOW TOP DEPARTMENTS' : 'SHOW ALL DEPARTMENTS'}
        </Button>
      </div>
      <div>
        <h4 style={{ color: '#33466B' }}>
          <InfoToolTip
            title={'Departmental Objectives'}
            text={'Objectives Info Text'}
          />
        </h4>
        {objectivesData.length == 0 ? (
          <div>
            <p>No objectives data to show</p>
          </div>
        ) : (
          <div
            className="table-main"
            style={{ width: '100%', overflowX: 'scroll' }}
          >
            <Table>
              <thead>
                <tr>
                  {/* <th>Year</th> */}
                  <th>Ref</th>
                  <th>Objective (Strategic/Parent)</th>
                  <th>Department objectives</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Freq. Review</th>
                  <th>Strategic Lead</th>
                </tr>
              </thead>
              <tbody>{renderDeptObjectives()}</tbody>
            </Table>
            <div className="text-right mt-3">
              <span style={{ fontSize: '14px' }}>
                Showing page {objectivePage + 1} of {totalObjectivePages}
              </span>
              <button
                className="previous-page btn btn-primary mx-2"
                disabled={objectivePage == 0}
                onClick={() =>
                  setObjectivePage(objectivePage => objectivePage - 1)
                }
              >
                Prev
              </button>
              <button
                disabled={objectivePage === totalObjectivePages - 1}
                className="next-page btn btn-primary"
                onClick={() =>
                  setObjectivePage(objectivePage => objectivePage + 1)
                }
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
      <br />
      <div>
        <h4 style={{ color: '#33466B' }}>
          <InfoToolTip
            title={'Departmental Risk'}
            text={'Risk Info Text'}
          ></InfoToolTip>
        </h4>
        {riskData.length == 0 ? (
          <div>No risk data to show</div>
        ) : (
          <div
            className="table-main"
            style={{ width: '100%', overflowX: 'scroll' }}
          >
            <Table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: '5%',
                    }}
                  >
                    Ref
                  </th>
                  <th style={{ width: '15%' }}>Risk Event</th>
                  <th style={{ width: '10%' }}>Risk Impact</th>
                  <th style={{ width: '10%' }}>Uncertainty</th>
                  <th style={{ width: '5%' }}>Risk Type</th>
                  <th style={{ width: '5%' }}>Net Score</th>
                  <th style={{ width: '5%' }}>Net Value</th>
                  <th style={{ width: '3%' }}>No. of Ts</th>
                  <th style={{ width: '13%' }}>Risk Owner</th>
                </tr>
              </thead>
              <tbody>{renderDeptRisks()}</tbody>
            </Table>
           {/* <div className="text-right mt-3">
              <span style={{ fontSize: '14px' }}>
                Showing page {riskPage + 1} of {totalRiskPages}
              </span>
              <button
                className="previous-page btn btn-primary mx-2"
                disabled={riskPage == 0}
                onClick={() => setRisk(riskPage => riskPage - 1)}
              >
                Prev
              </button>
              <button
                disabled={riskPage === totalRiskPages - 1}
                className="next-page btn btn-primary"
                onClick={() => setRiskPage(riskPage => riskPage + 1)}
              >
                Next
              </button>
            </div>*/}
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomDropdown
