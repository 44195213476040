import React, { useContext } from 'react'
import { uncertaintyType } from '../../../../common/uncertaintyType'
import userContext from '../../../../context/userContext'

export default function RiskReportTable({ risks, totals, departmentsMap }) {

    const { sessionDetails } = useContext(userContext)
    const currency = sessionDetails?.user?.organisation?.currencyCode



    return (
        <div className="mb-3">
            <p className="mb-1">Department: {departmentsMap[risks[0].risk.department]}</p>
            <table className="risk organisational report multi-report-table" data-departmentname={departmentsMap[risks[0].risk.department]}>
                <thead>
                    <th>Ref</th>
                    <th>Risk Event</th>
                    <th>Risk Impact</th>
                    <th>Uncertainty</th>
                    <th>Risk Type</th>
                    <th>Gross Value</th>
                    <th>Net Value</th>
                    <th>Target Value</th>
                </thead>
                <tbody>

                    {risks.map(({ risk }) => {
                        return (
                            <tr>
                                <td>{risk.ref}</td>
                                <td>{risk.eventDescription}</td>
                                <td>{risk.eventConsequences}</td>

                                <td>{uncertaintyType(risk.impactType.name)}</td>
                                <td>{risk.riskType.name}</td>

                                <td>{currency} {risk.grossEmv.netValue}</td>
                                <td>{currency} {risk.netEmv.netValue}</td>
                                <td>{currency} {risk.targetEmv.netValue}</td>



                            </tr>)
                    })}
                    <tr>
                        <td className="font-weight-bold text-right" colspan="5">Dept Total - Threat Value</td>
                        <td className="font-weight-bold">{currency} {totals.totalGrossNeg}</td>
                        <td className="font-weight-bold">{currency} {totals.totalNetNeg}</td>
                        <td className="font-weight-bold">{currency} {totals.totalTargetNeg}</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold text-right" colspan="5">Dept Total - Opportunity Value</td>
                        <td className="font-weight-bold">{currency} {totals.totalGrossOpp}</td>
                        <td className="font-weight-bold">{currency} {totals.totalNetOpp}</td>
                        <td className="font-weight-bold">{currency} {totals.totalTargetOpp}</td>
                    </tr>
                </tbody>
            </table>

            {/* <div className="total-count-row">
                <div className="labels-div">
                    <p>Dept Total - Threat Value</p>
                    <p>Dept Total - Opportunity Value</p>

                </div>
                <div className="values-div">
                    <p><span>{currency} {totals.totalGrossNeg}</span> <span>{currency} {totals.totalNetNeg}</span> <span>{currency} {totals.totalTargetNeg}</span></p>
                    <p><span>{currency} {totals.totalGrossOpp}</span> <span>{currency} {totals.totalNetOpp}</span> <span>{currency} {totals.totalTargetOpp}</span></p>

                </div>
            </div> */}
        </div>
    )
}
