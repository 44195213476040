import React, { Component } from 'react'
import axios from 'axios'
import { ChromePicker } from 'react-color'
const DEFAULT_COLOR = '#ffffff'
import Auth from '../../lib/Auth.js'

export default class RiskMatrixTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      positiveImpacts: [],
      negativeImpacts: [],
      probabilities: [],
      loading: true,
      orgId: 0,
      positiveColorPickerColor: DEFAULT_COLOR,
      negativeColorPickerColor: DEFAULT_COLOR,
      showNegativeColorPicker: false,
      opportunitiesTableColorData: [],
      threatsTableColorData: [],
      selectedOpportunitiesItem: null,
      selectedThreatsItem: null,
    }
  }

  // componentWillReceiveProps(props) {
  //     this.setState({ orgId: 4 })
  // }

  componentWillMount() {
    let token = Auth.getToken()
    if (!token) {
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    this.setState({
      orgId: JSON.parse(window.atob(base64)).Org,
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    try {
      let positiveImpacts = []
      let negativeImpacts = []
      let probabilities = []
      let opportunitiesTableColorData = []
      let threatsTableColorData = []

      let impactTypes = await axios.get(
        BASE_URL + `/organisations/${this.state.orgId}/impacts`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      if (impactTypes.status === 200) {
        positiveImpacts = impactTypes.data
          .filter(item => item.impactType.name === 'POSITIVE')
          .sort((a, b) => b.score - a.score)
        negativeImpacts = impactTypes.data
          .filter(item => item.impactType.name === 'NEGATIVE')
          .sort((a, b) => b.score - a.score)
      }

      let probabilityImpact = await axios.get(
        BASE_URL + `/organisations/${this.state.orgId}/probabilities`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      if (probabilityImpact.status === 200) {
        probabilities = probabilityImpact.data
      }

      let positiveColorData = await axios.get(
        BASE_URL +
          `/organisations/${this.state.orgId}/probabilityImpacts?impactType=positive`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      positiveColorData.data.forEach(item => {
        if (item.colour !== 'ffffff') {
          opportunitiesTableColorData.push({
            color: `#${item.colour}`,
            riskImpact: item.riskImpact.id,
            riskProbability: item.riskProbability.id,
          })
        } else {
        }
      })

      let negativeColorData = await axios.get(
        BASE_URL +
          `/organisations/${this.state.orgId}/probabilityImpacts/?impactType=negative`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      negativeColorData.data.forEach(item => {
        if (item.colour !== 'ffffff') {
          threatsTableColorData.push({
            color: `#${item.colour}`,
            riskImpact: item.riskImpact.id,
            riskProbability: item.riskProbability.id,
          })
        }
      })

      this.setState({
        negativeImpacts,
        positiveImpacts,
        probabilities,
        opportunitiesTableColorData,
        threatsTableColorData,
        positiveColorPickerColor: opportunitiesTableColorData[0].color,
        negativeColorPickerColor: threatsTableColorData[0].color,
        loading: false,
      })
    } catch (err) {
      throw err
    }
  }

  handleOpportunityItemSelect(probability, impact, e) {
    e.persist()
    let data = [...this.state.opportunitiesTableColorData]
    if (
      data.filter(
        item =>
          item.riskImpact === impact && item.riskProbability === probability
      )[0] === undefined
    ) {
      const colorObject = {}
      colorObject.color = this.state.positiveColorPickerColor
      colorObject.riskImpact = impact
      colorObject.riskProbability = probability
      data.push(colorObject)
      this.setState({
        selectedOpportunitiesItem: {
          ...colorObject,
        },
        opportunitiesTableColorData: data,
      })
    } else {
      const color = this.state.positiveColorPickerColor
      let newData = data.map(item => {
        if (
          item.riskImpact === impact &&
          item.riskProbability === probability
        ) {
          return { ...item, color: color }
        } else {
          return item
        }
      })
      this.setState({
        selectedOpportunitiesItem: {
          riskProbability: probability,
          riskImpact: impact,
          color: color,
        },
        opportunitiesTableColorData: newData,
        positiveColorPickerColor: color,
      })
    }
  }

  handleThreatsItemSelect(probability, impact, e) {
    e.persist()
    let data = [...this.state.threatsTableColorData]
    if (
      data.filter(
        item =>
          item.riskImpact === impact && item.riskProbability === probability
      )[0] === undefined
    ) {
      const colorObject = {}
      colorObject.color = this.state.negativeColorPickerColor
      colorObject.riskImpact = impact
      colorObject.riskProbability = probability
      data.push(colorObject)
      this.setState({
        selectedThreatsItem: {
          ...colorObject,
        },
        threatsTableColorData: data,
      })
    } else {
      const color = this.state.negativeColorPickerColor
      let newData = data.map(item => {
        if (
          item.riskImpact === impact &&
          item.riskProbability === probability
        ) {
          return { ...item, color: color }
        } else {
          return item
        }
      })
      this.setState({
        selectedThreatsItem: {
          riskProbability: probability,
          riskImpact: impact,
          color: color,
        },
        threatsTableColorData: newData,
        negativeColorPickerColor: color,
      })
    }
  }
  getBackgroundColor(impact_id, probability_id, type) {
    if (type === 'positive') {
      if (
        this.state.opportunitiesTableColorData.filter(
          item =>
            item.riskImpact === impact_id &&
            item.riskProbability === probability_id
        )[0] === undefined
      ) {
        return DEFAULT_COLOR
      } else {
        return this.state.opportunitiesTableColorData.filter(
          item =>
            item.riskImpact === impact_id &&
            item.riskProbability === probability_id
        )[0].color
      }
    } else {
      if (
        this.state.threatsTableColorData.filter(
          item =>
            item.riskImpact === impact_id &&
            item.riskProbability === probability_id
        )[0] === undefined
      ) {
        return DEFAULT_COLOR
      } else {
        return this.state.threatsTableColorData.filter(
          item =>
            item.riskImpact === impact_id &&
            item.riskProbability === probability_id
        )[0].color
      }
    }
  }
  renderPositiveTableData() {
    return [
      ...this.state.probabilities.sort((a, b) => b.multiplier - a.multiplier),
    ].map(item => {
      let columns = []
      columns.push(<td key={Math.random()}>{item.name}</td>)
      columns.push(
        <td key={item.id}>
          {item.minProbability}% - {item.maxProbability}%
        </td>
      )
      columns.push(<td key={Math.random()}>{item.multiplier}</td>)
      this.state.positiveImpacts.forEach(element =>
        columns.push(
          <td
            className="text-center cursor-pointer"
            // onClick={(e) =>
            //     this.handleOpportunityItemSelect(
            //         item.id,
            //         element.id,
            //         e
            //     )
            // }
            style={{
              backgroundColor: this.getBackgroundColor(
                element.id,
                item.id,
                'positive'
              ),
              cursor: 'default',
              color: 'white',
            }}
          >
            {Number(element.score * item.multiplier)}
          </td>
        )
      )
      return <tr>{columns}</tr>
    })
  }

  renderNegativeTableData() {
    return [
      ...this.state.probabilities.sort((a, b) => b.multiplier - a.multiplier),
    ].map(item => {
      let columns = []
      columns.push(<td key={Math.random()}>{item.name}</td>)
      columns.push(
        <td key={item.id}>
          {item.minProbability}% - {item.maxProbability}%
        </td>
      )
      columns.push(<td key={Math.random()}>{item.multiplier}</td>)
      this.state.negativeImpacts.forEach(element =>
        columns.push(
          <td
            className="text-center cursor-pointer"
            // onClick={(e) =>
            //     this.handleThreatsItemSelect(item.id, element.id, e)
            // }
            style={{
              backgroundColor: this.getBackgroundColor(
                element.id,
                item.id,
                'negative'
              ),
              cursor: 'default',
              color: 'white',
            }}
          >
            {Number(element.score * item.multiplier)}
          </td>
        )
      )
      return <tr>{columns}</tr>
    })
  }

  renderPositiveImpactHeader() {
    let columns = []
    columns.push(<th colSpan={3}>Probability Ratings and Titles </th>)
    this.state.positiveImpacts.map((item, index) => {
      columns.push(
        <th
          style={{
            width: `calc(70% / ${this.state.positiveImpacts.length})`,
          }}
          key={Math.random()}
        >
          {item.score}
        </th>
      )
    })

    return <tr className="bg-table-header">{columns}</tr>
  }

  renderPositiveImpactTitles() {
    let columns = []
    columns.push(<th className="bg-white"> </th>)
    this.state.positiveImpacts.map((item, index) => {
      columns.push(
        <th
          style={{
            width: `calc(70% / ${this.state.positiveImpacts.length})`,
          }}
          key={item.id}
        >
          {item.name}
        </th>
      )
    })

    return <tr className="bg-table-header">{columns}</tr>
  }

  renderNegativeImpactTitles() {
    let columns = []
    columns.push(<th className="bg-white"> </th>)
    this.state.negativeImpacts.map((item, index) => {
      columns.push(
        <th
          style={{
            width: `calc(70% / ${this.state.negativeImpacts.length})`,
          }}
          key={item.id}
        >
          {item.name}
        </th>
      )
    })

    return <tr className="bg-table-header">{columns}</tr>
  }

  renderNegativeImpactHeader() {
    let columns = []
    columns.push(<th colSpan={3}>Probability Ratings and Titles </th>)
    this.state.negativeImpacts.map((item, index) => {
      columns.push(
        <th
          style={{
            width: `calc(70% / ${this.state.negativeImpacts.length})`,
          }}
          key={Math.random()}
        >
          {item.score}
        </th>
      )
    })

    return <tr className="bg-table-header">{columns}</tr>
  }

  handleChangeComplete = (color, type) => {
    if (type === 'positive') {
      let data = [...this.state.opportunitiesTableColorData]
      let newPositiveTableData = [...data]

      if (data.length == 0) {
        newPositiveTableData.push({
          riskProbability: this.state.selectedOpportunitiesItem.riskProbability,
          riskImpact: this.state.selectedOpportunitiesItem.riskImpact,
          color: color.hex,
        })
      } else {
        let tableObject = data.filter(
          item =>
            item.riskProbability ===
              this.state.selectedOpportunitiesItem.riskProbability &&
            item.riskImpact === this.state.selectedOpportunitiesItem.riskImpact
        )[0]
        const exists = tableObject == undefined ? false : true
        if (exists) {
          tableObject.color = color.hex
          newPositiveTableData = data.map(item => {
            if (
              item.riskProbaability ==
                this.state.selectedOpportunitiesItem.riskProbability &&
              item.riskImpact == this.state.selectedOpportunitiesItem.riskImpact
            ) {
              return tableObject
            } else {
              return item
            }
          })
        } else {
          tableObject = {
            riskProbability: this.state.selectedOpportunitiesItem
              .riskProbability,
            riskImpact: this.state.selectedOpportunitiesItem.riskImpact,
            color: color.hex,
          }

          newPositiveTableData.push(tableObject)
        }
      }

      this.setState({
        opportunitiesTableColorData: [...newPositiveTableData],
        positiveColorPickerColor: color.hex,
        selectedOpportunitiesItem: {
          riskProbability: this.state.selectedOpportunitiesItem.riskProbability,
          riskImpact: this.state.selectedOpportunitiesItem.riskImpact,
          color: color.hex,
        },
      })
    } else {
      let data = [...this.state.threatsTableColorData]
      let newNegativeTableData = [...data]
      if (data.length == 0) {
        newNegativeTableData.push({
          riskProbability: this.state.selectedThreatsItem.riskProbability,
          riskImpact: this.state.selectedThreatsItem.riskImpact,
          color: color.hex,
        })
      } else {
        let tableObject = data.filter(
          item =>
            item.riskProbability ===
              this.state.selectedThreatsItem.riskProbability &&
            item.riskImpact === this.state.selectedThreatsItem.riskImpact
        )[0]
        const exists = tableObject == undefined ? false : true
        if (exists) {
          tableObject.color = color.hex
          newNegativeTableData = data.map(item => {
            if (
              item.riskProbaability ==
                this.state.selectedThreatsItem.riskProbability &&
              item.riskImpact == this.state.selectedThreatsItem.riskImpact
            ) {
              return tableObject
            } else {
              return item
            }
          })
        } else {
          tableObject = {
            riskProbability: this.state.selectedThreatsItem.riskProbability,
            riskImpact: this.state.selectedThreatsItem.riskImpact,
            color: color.hex,
          }

          newNegativeTableData.push(tableObject)
        }
      }

      this.setState({
        threatsTableColorData: [...newNegativeTableData],
        negativeColorPickerColor: color.hex,
        selectedThreatsItem: {
          riskProbability: this.state.selectedThreatsItem.riskProbability,
          riskImpact: this.state.selectedThreatsItem.riskImpact,
          color: color.hex,
        },
      })
    }
  }

  savePositiveColorData() {
    const data = this.state.opportunitiesTableColorData.map(item => {
      let color = item.color
      color = color.replace('#', '')
      item.colour = color
      delete item[color]
      return item
    })
    this.setState({ loading: true }, () => {
      axios
        .put(
          BASE_URL + `/organisations/${this.state.orgId}/probabilityImpacts`,
          {
            probabilityImpactMessages: [...data],
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }

  saveNegativeColorData() {
    const data = this.state.threatsTableColorData.map(item => {
      let color = item.color
      color = color.replace('#', '')
      item.colour = color
      delete item[color]
      return item
    })
    this.setState({ loading: true }, () => {
      axios
        .put(
          BASE_URL + `/organisations/${this.state.orgId}/probabilityImpacts`,
          {
            probabilityImpactMessages: [...data],
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.fetchData()
          }
        })
    })
  }
  render() {
    return this.state.loading ? (
      <p className="my-5 px-5 test-center"> Loading...</p>
    ) : (
      <div className="matrix-container">
        <div className="positive-matrix-container">
          <div className="table-container px-5 my-5">
            <h4>Opportunities</h4>
            <table
              className="mt-4 heading-table mb-1"
              style={{ width: '100%' }}
            >
              <thead>
                <tr>
                  <th></th>

                  <th
                    colSpan={this.state.positiveImpacts.length}
                    className="text-center"
                  >
                    Positive Impact Ratings and Titles
                  </th>
                </tr>
              </thead>
            </table>
            <table className="titles-table mb-1" style={{ width: '100%' }}>
              <thead>{this.renderPositiveImpactTitles()}</thead>
            </table>
            <table className="headers-table mt-2" style={{ width: '100%' }}>
              <thead>{this.renderPositiveImpactHeader()}</thead>
              <tbody>
                {this.state.loading ? (
                  <tr className="text-center">
                    <td colSpan={3}>Loading...</td>{' '}
                  </tr>
                ) : (
                  this.renderPositiveTableData()
                )}
              </tbody>
            </table>
            {/* <p className="mt-3 select-range-text">
                            Select ranges and colors for Opportunities:
                        </p> */}

            {this.state.selectedOpportunitiesItem !== null ? (
              <div className="w-100">
                <button
                  className="btn btn-md mb-2 color-picker-button"
                  style={{
                    background: this.state.selectedOpportunitiesItem.color,
                  }}
                  onClick={() =>
                    this.setState(prevProps => ({
                      showPositiveColorPicker: !prevProps.showPositiveColorPicker,
                    }))
                  }
                >
                  {this.state.selectedOpportunitiesItem.color}
                </button>
                <button
                  onClick={() => this.savePositiveColorData()}
                  className="save-matrix-button float-right"
                >
                  Save
                </button>
              </div>
            ) : null}
            {this.state.showPositiveColorPicker && (
              <ChromePicker
                color={this.state.positiveColorPickerColor}
                onChange={e => this.handleChangeComplete(e, 'positive')}
              />
            )}
          </div>
        </div>

        <div className="negative-matrix-container">
          <div className="table-container px-5 my-5">
            <h4>Threats</h4>

            <table
              className="mt-4 mb-1 heading-table"
              style={{ width: '100%' }}
            >
              <thead>
                <tr>
                  <th></th>

                  <th
                    colSpan={this.state.positiveImpacts.length}
                    className="text-center"
                  >
                    Negative Impact Ratings and Titles
                  </th>
                </tr>
              </thead>
            </table>
            <table className="titles-table mb-1" style={{ width: '100%' }}>
              <thead>{this.renderNegativeImpactTitles()}</thead>
            </table>
            <table className="headers-table mt-2" style={{ width: '100%' }}>
              <thead>{this.renderNegativeImpactHeader()}</thead>
              <tbody>
                {this.state.loading ? (
                  <tr className="text-center">
                    <td colSpan={3}>Loading...</td>{' '}
                  </tr>
                ) : (
                  this.renderNegativeTableData()
                )}
              </tbody>
            </table>
            {/* <p className="mt-3 select-range-text">
                            Select ranges and colors for Threats:
                        </p> */}

            {this.state.selectedThreatsItem !== null ? (
              <div className="w-100">
                <button
                  className="btn btn-md mb-2 color-picker-button"
                  style={{
                    background: this.state.selectedThreatsItem.color,
                  }}
                  onClick={() =>
                    this.setState(prevProps => ({
                      showNegativeColorPicker: !prevProps.showNegativeColorPicker,
                    }))
                  }
                >
                  {this.state.selectedThreatsItem.color}
                </button>

                <button
                  onClick={() => this.saveNegativeColorData()}
                  className="save-matrix-button float-right"
                >
                  Save
                </button>
              </div>
            ) : null}

            {this.state.showNegativeColorPicker && (
              <ChromePicker
                color={this.state.negativeColorPickerColor}
                onChange={e => this.handleChangeComplete(e, 'negative')}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
