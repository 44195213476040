import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'

import Auth from '../../lib/Auth.js'
import { InfoToolTip } from '../common/InfoTooltip.jsx'
import Spinner from 'react-bootstrap/Spinner'
import { isEmpty } from 'lodash'
import { uncertaintyType } from '../../common/uncertaintyType'
import userContext from '../../context/userContext'

const MyDeptRisksTable = ({
  deptRiskFormHandler,
  deptTableProps = [],
  orgId,
  showDeptRiskTable,
}) => {
  const { sessionDetails } = useContext(userContext)
  //ref, event, impact, causes, type, score, value, treatments, status, owner
  const [tableData, setTableData] = useState([])
  const [noTableData, setNoTableData] = useState(false)
  const [users, setUsers] = useState([])
  const [userDept, setUserDept] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRows] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    let token = Auth.getToken()
    if (!token) {
    }
    const parts = token.split('.')

    setUserEmail(JSON.parse(atob(parts[1])).Email)
  })

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    getUserDepartment()
  }, [users])

  useEffect(() => {
    // if (userDept && showDeptRiskTable) {
    // }
    getUserDepartment()
    fetchDeptData()
  }, [userDept, showDeptRiskTable])

  const getUserDepartment = () => {
    users.forEach(item => {
      if (item.emailAddress == userEmail && item.department) {
        setUserDept(item.department.id)
      }
    })
  }

  const getUsers = () => {
    setLoader(true)
    axios
      .get(BASE_URL + `/organisations/${orgId}/users`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(res => {
        if (res.status == 200) {
          setUsers(res.data)
        }
      })
      .catch(error => setLoader(false))
  }

  const renderTableData = () => {
    return getPageRows().map((item, index) => {
      console.log('MY RENDER TABLE -->', item)
      return (
        <tr key={index}>
          <td>{item.ref}</td>
          <td>{item.eventDescription}</td>
          <td>{item.eventConsequences}</td>

          <td>
            {!isEmpty(item.impactType.name) &&
              uncertaintyType(item.impactType.name)}{' '}
          </td>
          <td>{item.riskType.name}</td>

          <td
            style={{
              backgroundColor: `${item.tableHighestColorScore.colour}`,
            }}
          >
            {item.tableHighestColorScore.score}
          </td>
          <td>
            {(
              ((item.netEmv.minValue +
                item.netEmv.maxValue +
                item.netEmv.likelyValue) /
                3) *
              (item.netEmv.probability / 100)
            ).toFixed(2)}
          </td>
          <td>{item?.riskTreatments?.length}</td>
          <td>{users.filter(user => user.id === item.owner)[0]?.name}</td>
        </tr>
      )
    })
  }

  const getRiskScore = data => {
    var highestValue = null

    var assessmentArray = []

    data.map(item => {
      if (item.riskAssessmentType.name == 'NET') {
        assessmentArray = [...assessmentArray, item]
      }
    })

    if (assessmentArray.length !== 0) {
      highestValue = setScoreValues(assessmentArray)
    } else {
      highestValue = { score: 0, colour: '#fff' }
    }

    return highestValue
  }

  const setScoreValues = array => {
    var highestValue = { score: 0, colour: '#fff' }
    array.forEach(item => {
      if (
        item.probabilityImpact.riskImpact.score *
          item.probabilityImpact.riskProbability.multiplier >
        highestValue.score
      ) {
        highestValue = {
          score:
            item.probabilityImpact.riskImpact.score *
            item.probabilityImpact.riskProbability.multiplier,
          colour: `#${item.probabilityImpact.colour}`,
        }
      } else {
        highestValue = highestValue
      }
    })
    return highestValue
  }

  const fetchDeptData = () => {
    setLoader(true)
    let thisRiskIds = []
    const params = {}
    if (userDept) {
      params.department = userDept
      // /organisations/166/departments/245/risks
      let pDept = sessionDetails?.org?.parentDepartment?.department?.id
      axios
        .get(
          BASE_URL + `/organisations/${orgId}/departments/${userDept}/risks`,
          {
            params,
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then(res => {
          let newRes = [
            ...res?.data?.childDepartmentRisks,
            ...res?.data?.departmentRisks,
          ]
          if (res.status === 200) {
            console.log('RES DDT -->', newRes)
            const urls = newRes.map(item =>
              axios.get(BASE_URL + `/organisations/${orgId}/riskTreatments`, {
                params: {
                  risk: item.id,
                },
                headers: {
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
              })
            )
            /*childDepartmentRisks

departmentRisks*/

            let data = newRes
            thisRiskIds = newRes

            axios
              .all([...urls])
              .then(responses => {
                responses.forEach(
                  (item, index) => (data[index].riskTreatments = item.data)
                )
              })
              .then(() => {
                const assessmentUrls = thisRiskIds.map(item =>
                  axios.get(
                    BASE_URL +
                      `/organisations/${orgId}/risks/${item.id}/assessments`,
                    {
                      headers: {
                        Authorization: `Bearer ${Auth.getToken()}`,
                      },
                    }
                  )
                )
                axios.all([...assessmentUrls]).then(responses => {
                  responses.forEach((item, index) => {
                    data[index].tableHighestColorScore = getRiskScore(item.data)
                  })
                  let dataFilter =
                    data &&
                    data.reduce(
                      (final, data) => {
                        return {
                          ...final,
                          risk:
                            data.completed && !data.deleted
                              ? [...final.risk, data]
                              : final.risk,
                          draft: !data.completed
                            ? [...final.draft, data]
                            : final.draft,
                          deleted: data.deleted
                            ? [...final.deleted, data]
                            : final.deleted,
                        }
                      },
                      {
                        risk: [],
                        draft: [],
                        deleted: [],
                      }
                    )
                  setTableData(dataFilter.risk)
                  if (data && data.length > 0) {
                    setNoTableData(true)
                  }
                })
                setLoader(false)
              })
              .catch(error => setLoader(false))
          }
        })
        .catch(error => setLoader(false))
    }
  }

  const getPageRows = () => {
    var chunks = [],
      i = 0,
      n = tableData.length
    while (i < n) {
      chunks.push(tableData.slice(i, (i += rowsPerPage)))
    }

    return chunks[page] || tableData
  }

  useEffect(() => {
    setTotalPages(getTotalPages())
  }, [tableData])

  const getTotalPages = () => {
    var chunks = [],
      i = 0,
      n = tableData.length
    while (i < n) {
      chunks.push(tableData.slice(i, (i += rowsPerPage)))
    }
    return chunks.length
  }
  if (loader) {
    return (
      <div className="d-flex justify-content-center">
        {loader && <Spinner animation="grow" />}
      </div>
    )
  } else {
    if (!tableData) {
      return (
        <React.Fragment>
          <div className="px-5 my-5 text-center">
            <h1>You do not have any Departmental Risks setup</h1>
            <button onClick={deptRiskFormHandler} className="addRiskButton">
              Add your first dept. risk
            </button>
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <div className="px-5 my-5 table-main">
          <h4 style={{ color: '#33466B' }}>
            <InfoToolTip
              title={'My Departmental Risks'}
              text={'My Departmental Risks Info Text'}
            ></InfoToolTip>
          </h4>
          <div
            className="table-container"
            style={{ width: '100%', overflowX: 'scroll' }}
          >
            <table className="risks-table strategic-indicators-table">
              <thead>
                <tr>
                  <th
                    style={{
                      width: '5%',
                    }}
                  >
                    Ref
                  </th>
                  <th style={{ width: '15%' }}>Risk Event</th>
                  <th style={{ width: '10%' }}>Risk Impact</th>
                  <th style={{ width: '10%' }}>Uncertainty</th>
                  <th style={{ width: '5%' }}>Risk Type</th>
                  <th style={{ width: '5%' }}>Net Score</th>
                  <th style={{ width: '5%' }}>Net Value</th>
                  <th style={{ width: '3%' }}>No. of Ts</th>
                  <th style={{ width: '13%' }}>Risk Owner</th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
          </div>

          <div className="text-right mt-3">
            <span style={{ fontSize: '14px' }}>
              Showing page {page + 1} of {totalPages}
            </span>
            <button
              className="previous-page btn btn-primary mx-2"
              disabled={page == 0}
              onClick={() => setPage(page => page - 1)}
            >
              Prev
            </button>
            <button
              disabled={page === totalPages - 1}
              className="next-page btn btn-primary"
              onClick={() => setPage(page => page + 1)}
            >
              Next
            </button>
          </div>
        </div>
      )
    }
  }
}

export default MyDeptRisksTable
