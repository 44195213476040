import React, { Component } from 'react'
import axios from 'axios'
import OrganisationSetup from '../../presentational/admin/OrganisationSetup.jsx'
import { Navbar } from 'react-bootstrap'
import Sidebar from '../../common/Sidebar.jsx'

class OrganisationSetupContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: `/admin/account-maintenance/${this.props.match.params.id}`,
      countries: [],
      organisationValuesMessage: {
        organisationValues: [
          {
            name: '',
            description: '',
          },
        ],
      },
      values: {
        name: '',
        description: '',
      },

      regionMessage: [],
      region: {
        name: '',
      },
      organisationData: [],
      regionData: [],
      organisation: {},
      postOrganisation: {
        name: '',
        industry: 0,
        mission: '',
        vision: '',
        organisationStatus: '',
        systemAdmin: '',
        currencyCode: '',
      },
      postOrganisationAddress: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        state: '',
        country: '',
        postCode: '',
      },
      organisationValuesDataMessage: {
        organisationValuesData: [],
      },
      organisationRegionsMessage: {
        name: '',
        contactUser: '',
        organisation: this.props.match.params.id,
        addressMessage: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          state: '',
          country: '',
          postCode: '',
        },
      },
      regionsMessage: {},
      industries: [],
      users: [],
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
    }
    this.viewOrganisation = this.viewOrganisation.bind(this)
    this.updateSystemAdmin = this.updateSystemAdmin.bind(this)
    this.addValues = this.addValues.bind(this)
    this.addRegion = this.addRegion.bind(this)
    this.viewRegion = this.viewRegion.bind(this)
    this.updateRegion = this.updateRegion.bind(this)
    this.viewValue = this.viewValue.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this.deleteValue = this.deleteValue.bind(this)
    this.deleteRegion = this.deleteRegion.bind(this)
    this.updateOrganisation = this.updateOrganisation.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeValues = this.handleChangeValues.bind(this)
    this.handleChangeCountry = this.handleChangeCountry.bind(this)
    this.handleChangeRegions = this.handleChangeRegions.bind(this)
  }

  componentDidMount() {
    axios
      .all([
        axios.get(BASE_URL + '/industries'),
        axios.get(BASE_URL + '/countries'),
        axios.get(BASE_URL + `/organisations/${this.props.match.params.id}`),
        axios.get(BASE_URL + `/organisations/${this.props.match.params.id}`),
        axios.get(
          BASE_URL + `/organisations/${this.props.match.params.id}/values`
        ),
        axios.get(
          BASE_URL + `/organisations/${this.props.match.params.id}/regions`
        ),
        axios.get(
          BASE_URL + `/organisations/${this.props.match.params.id}/users`
        ),
      ])
      .then(
        axios.spread(
          (industries, countries, orgData, org, values, regions, users) => {
            this.setState({ industries: industries.data })
            this.setState({ countries: countries.data })
            this.setState({ organisationData: orgData.data })
            this.setState({ organisation: org.data })
            this.setState({
              organisationValuesDataMessage: {
                organisationValuesData: values.data,
              },
            })
            this.setState({ regionData: regions.data })
            this.setState({ users: users.data })
          }
        )
      )
      .catch(err => this.setState({ errors: err.response }))
  }

  handleClick(value) {
    window.location.href = `${value}`
    this.setState({ activeItem: value })
  }

  handleChangeRegions({ target: { name, value } }, messageType) {
    this.setState({
      organisationRegionsMessage: {
        ...this.state.organisationRegionsMessage,
        [messageType]: {
          ...this.state.organisationRegionsMessage[messageType],
          [name]: value,
        },
      },
    })
  }

  handleChangeValues({ target: { name, value } }, valueType) {
    this.setState({
      organisationValuesMessage: {
        ...this.state.organisationValuesMessage,
        [valueType]: [
          {
            ...this.state.organisationValuesMessage[valueType][0],
            [name]: value,
          },
        ],
      },
    })
  }

  handleChange({ target: { name, value } }, messageType) {
    this.setState({
      ...this.state,
      [messageType]: { ...this.state[messageType], [name]: value },
    })
  }

  handleChangeCountry({ target: { name, value } }, messageType) {
    this.setState({
      ...this.state,
      organisation: {
        ...this.state.organisation,
        country: {
          ...this.state.organisation.country,
          [messageType]: {
            ...this.state.organisation.country[messageType],
            [name]: value,
          },
        },
      },
    })
  }

  viewOrganisation(e) {
    e.preventDefault()
    const {
      organisation,
      postOrganisation,
      postOrganisationAddress,
    } = this.state
    console.log('ORG AN --->', organisation)
    axios
      .get(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/addresses/${this.state.organisation.contactAddress.id}`
      )
      .then(res => {
        this.setState({ postOrganisationAddress: res.data })
      })
      .then(res => {
        this.setState({
          postOrganisationAddress: {
            addressLine1: organisation.contactAddress.addressLine1,
            addressLine2: organisation.contactAddress.addressLine2,
            addressLine3: organisation.contactAddress.addressLine3,
            state: organisation.contactAddress.state,
            country: organisation.contactAddress.country.code,
            postCode: organisation.contactAddress.postcode,
          },
          postOrganisation: {
            name: organisation.name,
            industry: organisation?.industry?.id
              ? organisation?.industry?.id
              : '',
            mission: organisation.mission,
            vision: organisation.vision,
            organisationStatus: organisation.organisationStatus.name,
            systemAdmin: organisation.systemAdmin.id,
            currencyCode: organisation.currencyCode,
          },
        })
      })
    // this.setState({})
  }

  updateOrganisation(e) {
    
    e.preventDefault()
    console.log('this.state.postOrganisation -->', this.state.postOrganisation)
    
    axios
      .all([
        axios.put(
          BASE_URL + `/organisations/${this.props.match.params.id}`,
          this.state.postOrganisation
        ),
        axios.put(
          BASE_URL +
            `/organisations/${this.props.match.params.id}/addresses/${this.state.organisation.contactAddress.id}`,
          this.state.postOrganisationAddress
        ),
      ])
      .then(() =>
        this.props.history.push(
          `/admin/account-maintenance/${this.props.match.params.id}`
        )
      )
      .then(() => location.reload())
      .catch(err => this.setState({ errors: err.response }))
  }

  updateValue(e, valueId) {
    e.preventDefault()
    axios
      .put(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/values/${valueId}`,
        this.state.values
      )

      .then(() =>
        this.props.history.push(
          `/admin/account-maintenance/${this.props.match.params.id}`
        )
      )
      .then(() => location.reload())
  }

  addValues(e, valueId) {
    e.preventDefault()
    axios
      .post(
        BASE_URL + `/organisations/${this.props.match.params.id}/values`,
        this.state.organisationValuesMessage
      )
      .then(() =>
        this.props.history.push(
          `/admin/account-maintenance/${this.props.match.params.id}`
        )
      )
      .then(() => location.reload())
  }

  viewValue(e, valueId) {
    e.preventDefault()
    axios
      .get(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/values/${valueId}`
      )
      .then(res => {
        this.setState({ values: res.data })
      })
  }

  deleteValue(e, valueId) {
    e.preventDefault()
    axios
      .delete(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/values/${valueId}`
      )
      .then(() =>
        this.props.history.push(
          `/admin/account-maintenance/${this.props.match.params.id}`
        )
      )
      .then(() => location.reload())
  }

  viewRegion(e, regionId) {
    e.preventDefault()
    axios
      .get(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/regions/${regionId}`
      )
      .then(res => {
        this.setState({ region: res.data })
      })
      .then(res => {
        const {
          state: { region, organisationRegionsMessage },
        } = this
        this.setState({
          organisationRegionsMessage: {
            name: region.name,
            organisation: this.props.match.params.id,
            contactUser: region.contactUser.id,
            addressMessage: {
              addressLine1: region.regionAddress.addressLine1,
              addressLine2: region.regionAddress.addressLine2,
              addressLine3: region.regionAddress.addressLine3,
              country: region.regionAddress.country.code,
              postCode: region.regionAddress.postcode,
              state: region.regionAddress.state,
            },
          },
        })
      })
  }

  addRegion(e) {
    e.preventDefault()
    axios
      .post(
        BASE_URL + `/organisations/${this.props.match.params.id}/regions/`,
        this.state.organisationRegionsMessage
      )
      .then(() => location.reload())
      .catch(err => {
        this.setState({ errors: err.response.data })
      })
  }

  updateRegion(e, regionId) {
    e.preventDefault()
    axios
      .put(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/regions/${regionId}`,
        this.state.organisationRegionsMessage
      )
      .then(() =>
        this.props.history.push(
          `/admin/account-maintenance/${this.props.match.params.id}`
        )
      )
      .then(() => location.reload())
  }

  deleteRegion(e, regionId) {
    e.preventDefault()
    axios
      .delete(
        BASE_URL +
          `/organisations/${this.props.match.params.id}/regions/${regionId}`
      )
      .then(() =>
        this.props.history.push(
          `/admin/account-maintenance/${this.props.match.params.id}`
        )
      )
      .then(() => location.reload())
  }

  updateSystemAdmin(e) {
    e.preventDefault()
    
    const {
      state: { organisationData, postOrganisation },
    } = this
    axios
      .put(
        BASE_URL + `/organisations/${this.props.match.params.id}`,
        this.setState({
          postOrganisation: {
            name: organisationData.name,
            industry: organisationData.industry.id,
            mission: organisationData.mission,
            vision: organisationData.vision,
            organisationStatus: organisationData.organisationStatus.name,
            systemAdmin: postOrganisation.systemAdmin.id,
          },
        })
      )
      .then(() =>
        this.props.history.push(
          `/admin/account-maintenance/${this.props.match.params.id}`
        )
      )
    // .then(() => location.reload())
  }

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  handleChangeFileImage = ({ fileList }) => this.setState({ fileList })

  render() {
    if (
      !this.state.organisationData ||
      !this.state.organisationData.contactAddress ||
      !this.state.organisation ||
      !this.state.organisation.contactAddress
    )
      return null
    const {
      regionsMessage,
      title,
      industries,
      countries,
      values,
      organisationData,
      organisationRegionsMessage,
      postOrganisation,
      regionData,
      region,
      postOrganisationAddress,
      users,
      previewVisible,
      previewImage,
      fileList,
      previewTitle,
    } = this.state
    const { organisationValues } = this.state.organisationValuesMessage
    const { addressMessage } = this.state.organisationRegionsMessage
    console.log('postOrganisationAddress --->', organisationData)
    return (
      <div>
        <Sidebar {...this.props} />
        <OrganisationSetup
          handleChangeRegions={this.handleChangeRegions}
          handleChange={this.handleChange}
          handleChangeValues={this.handleChangeValues}
          countries={countries}
          values={values}
          addValues={this.addValues}
          organisationData={organisationData}
          organisationValuesData={
            this.state.organisationValuesDataMessage.organisationValuesData
          }
          organisationValues={organisationValues}
          viewValue={this.viewValue}
          updateValue={this.updateValue}
          deleteValue={this.deleteValue}
          organisationRegionsMessage={organisationRegionsMessage}
          addRegion={this.addRegion}
          viewRegion={this.viewRegion}
          updateRegion={this.updateRegion}
          deleteRegion={this.deleteRegion}
          regionData={regionData}
          region={region}
          viewOrganisation={this.viewOrganisation}
          updateOrganisation={this.updateOrganisation}
          postOrganisation={postOrganisation}
          handleChangeCountry={this.handleChangeCountry}
          postOrganisationAddress={postOrganisationAddress}
          regionsMessage={regionsMessage}
          addressMessage={addressMessage}
          industries={industries}
          users={users}
          updateSystemAdmin={this.updateSystemAdmin}
          getBase64={this.getBase64}
          handleCancel={this.handleCancel}
          handlePreview={this.handlePreview}
          handleChangeFileImage={this.handleChangeFileImage}
          previewVisible={previewVisible}
          previewImage={previewImage}
          fileList={fileList}
          previewTitle={previewTitle}
        />
      </div>
    )
  }
}

export default OrganisationSetupContainer
