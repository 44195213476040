import React, { useState, useEffect } from 'react'
import { Table, Dropdown, Tab } from 'react-bootstrap'
import StrategicGoalsTable from './Organisation-sectionTables/StrategicGoalsTable.jsx'
import StrategicObjectivesTable from './Organisation-sectionTables/StrategicObjectivesTable.jsx'
import StrategicIndicatorsTable from './Organisation-sectionTables/StrategicIndicatorsTable.jsx'
import CorporateDocumentsTable from './Organisation-sectionTables/CorporateDocumentsTable.jsx'
import CustomDropdown from '../presentational/CustomDropdown.jsx'
import OrganisationObjectivesTable from './Organisation-sectionTables/OrganisationObjectivesTable.jsx'
import OrganisationRiskTable from './Organisation-sectionTables/OrganisationRiskTable.jsx'
import axios from 'axios'
import Auth from '../../lib/Auth.js'
import '../../style/container/risk-tables.scss'
import OrganizationChart from '@dabeng/react-orgchart'

const Organisation = (props) => {
  const [selectedTab, setSelectedTab] = useState(1)
  const [mission, setMission] = useState('')
  const [vision, setVision] = useState('')
  const [name, setName] = useState('')
  const [ref, setRef] = useState(0)
  const [description, setDescription] = useState('')
  const [orgId, setOrgId] = useState(0)
  const [values, setValues] = useState([])
  const [orgChart, setOrgStructure] = useState([])

  const tabs = [
    { id: 1, label: 'Missions Visions and Values', bgColor: '#3C148C' },
    { id: 2, label: 'Reporting Structure', bgColor: '#0097A7' },
    { id: 3, label: 'Strategic Goals and KPI', bgColor: '#AD1552' },
    { id: 4, label: 'Departments and Regions', bgColor: '#1B8494' },
    { id: 5, label: 'Corporate Documents', bgColor: '#3F51B5' },
  ]

  useEffect(() => {
    let token = Auth.getToken()
    if (!token) {
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    setOrgId(JSON.parse(window.atob(base64)).Org)
  }, [orgId])

  //Works for whichever id logged in with
  useEffect(() => {
    fetchData()
  }, [orgId])

  const fetchData = () => {
    axios
      .get(BASE_URL + `/organisations/${orgId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        setMission(res.data.mission)
        setVision(res.data.vision)
        setName(res.data.name)
      })
    axios
      .get(BASE_URL + `/organisations/${orgId}/values`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status == '200') {
          setValues(res.data)
        }
      })
    axios
      .get(BASE_URL + `/orgchart/${orgId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        setOrgStructure(res.data)
      })
  }

  const renderValuesData = () => {
    return values.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.ref}</td>
          <td>{item.name}</td>
          <td>{item.description}</td>
        </tr>
      )
    })
  }

  const renderTab = () => {
    switch (selectedTab) {
      case 1:
        return (
          <div className="my-4">
            <div
              style={{ marginBottom: '1.5rem' }}
              className="missionAndVision"
            >
              <h2 style={{ marginBottom: '.6rem' }}>Our Mission</h2>
              <p style={{ fontSize: '15px', lineHeight: '1.3rem' }}>
                {mission}
              </p>
            </div>

            <div
              style={{ marginBottom: '1.5rem' }}
              className="missionAndVision"
            >
              <h2 style={{ marginBottom: '.6rem' }}>Our Vision</h2>
              <p style={{ fontSize: '15px', lineHeight: '1.3rem' }}>{vision}</p>
            </div>

            <div
              style={{ marginBottom: '1.5rem' }}
              className="missionAndVision"
            >
              <div className="table-main">
                <h2 style={{ marginBottom: '.6rem' }}>Our values</h2>
                <table className="risks-table">
                  <thead>
                    <tr style={{ height: '40px' }}>
                      <th width="10%">Ref</th>
                      <th width="15%">Name</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>{renderValuesData()}</tbody>
                </table>
              </div>
            </div>
          </div>
        )
      case 2:
        return (
          <div className="my-4">
            {/*<img
              src="../../assets/reportingStructuretemp.png"
              alt="rep structure img"
              style={{ maxHeight: '90%', maxWidth: '90%' }}
            />*/}
            {orgChart && <OrganizationChart datasource={orgChart} />}
          </div>
        )
      case 3:
        return (
          <div className="my-2">
            <div>
              <StrategicGoalsTable orgId={orgId} />
            </div>
            <div>
              <StrategicObjectivesTable orgId={orgId} />
            </div>
            <div>
              <StrategicIndicatorsTable orgId={orgId} />
            </div>
          </div>
        )
      case 4:
        return (
          <div className="my-2">
            <div>
              <CustomDropdown orgId={orgId} />
            </div>
            {/* <br />
                        <div>
                            <OrganisationObjectivesTable orgId={orgId} />
                        </div>
                        <div>
                            <OrganisationRiskTable orgId={orgId} />
                        </div> */}
          </div>
        )
      case 5:
        return (
          <div className="my-2">
            <CorporateDocumentsTable orgId={orgId} />
          </div>
        )
      default:
        break
    }
  }
  return (
    <div style={{ ...props.style }} className="risk-setup-container">
      <div className="risk-tabs-container">
        {tabs.map((item) => (
          <div
            key={item.id}
            style={{
              background: item.id === selectedTab ? item.bgColor : '#c5cae9',
            }}
            onClick={() => setSelectedTab(item.id)}
            className={`tab-label ${
              item.id === selectedTab ? 'selected-tab' : ''
            }`}
          >
            {item.label}
          </div>
        ))}
      </div>
      <hr />
      {renderTab()}
    </div>
  )
}

export default Organisation
