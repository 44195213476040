import React, { Component } from 'react'
import { Modal, Form, Container, Row, Col, Button } from 'react-bootstrap'
import { notification, Upload, Popconfirm, Button as BTN } from 'antd'
import {
  DownloadOutlined,
  UploadOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

import '../../style/container/objectives.scss'
import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
import RangeSlider from 'react-bootstrap-range-slider'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import axios from 'axios'
import Auth from '../../lib/Auth.js'
import Swal from 'sweetalert2'
import moment from 'moment'
import { isEmpty } from 'lodash'
import userContext from '../../context/userContext'
import { uploadFile } from '../../common/uploadFile'
class AddActionPlanModal extends Component {
  static contextType = userContext
  constructor(props) {
    super(props)
    this.state = {
      step: 1,
      actionDes: '',
      actionOw: 0,
      actionType: 1,
      riskStatus: 0,
      actionPri: 0,
      focus: 0,
      estimatedCost: '',
      actCost: '',
      time: '',
      others: '',
      bcpReq: '',
      frequencyOfReview: '',
      duedate: '',
      effectiveness: '',
      notes: '',
      showFirst: true,
      showNext: false,
      completed: 0,
      riskId: [],
      docUpload: [],
    }
    this.orgId = Auth.getOrg()
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentWillReceiveProps(props) {
    this.setState({
      myRiskId: props.myRiskId,
      actionOw: '',
      actionType: 1,
      riskStatus: '',
      actionPri: '',
      estimatedCost: '',
      actCost: '',
      focus: '',
      actionDes: '',
    })
    // this.setState({
    //     actionOw: this.props.actionOwner,
    // })
  }

  renderActionOwners() {
    return this.props.actionOwners.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }
  inputChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    })
  }

  nextClickHandler = () => {
    this.setState({
      showNext: true,
    })
  }

  backClickhandler = () => {
    this.setState({
      showNext: false,
    })
  }

  saveClickd = async (event) => {
    const { sessionDetails } = userContext

    const form = event.currentTarget
    if (form.checkValidity() === false && isEmpty(this.state.duedate)) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      this.setState({
        showNext: false,
      })

      // this.props.data.calledFromChild(
      //     this.state.actionDes,
      //     this.state.estimatedCost,
      //     this.state.duedate,
      //     this.state.actionPri,
      //     this.state.riskStatus,
      //     this.state.actionOw,
      //     this.state.completed
      // )
      let description = this.state.actionDes
      const estimatedCost = this.state.estimatedCost
      const actCost = this.state.actCost
      let ref = JSON.stringify(Math.floor(Math.random() * 1000 + 1))

      this.props.closeModal()

      const reqData = {
        ref: ref,
        description: description,
        cost: estimatedCost,
        actCost: actCost,
        dueDate: this.state.duedate,
        actionPriority: this.state.actionPri,
        riskStatus: this.state.riskStatus,
        actionType: 1,
        completed: this.state.completed,
        focus: this.state.focus,
        owner: this.props.actionOwner,
        risk: this.props.myRiskId,
        time: this.state.time,
        notes: this.state.notes,
        comments: 'na',
        bcpDcr: this.state.bcpReq,
        frequencyOfReview: this.state.frequencyOfReview,
        effectiveness: this.state.effectiveness,
      }
      axios
        .post(
          BASE_URL + `/organisations/${this.orgId}/riskTreatments`,
          {
            ref: ref,
            description: this.state.actionDes,
            actualCost: this.state.actCost,
            cost: this.state.estimatedCost,
            dueDate: moment(this.state.duedate, 'DD/MM/YY').format(
              'YYYY-MM-DD'
            ),
            actionPriority: this.state.actionPri,
            riskStatus: this.state.riskStatus,
            actionType: this.state.actionType,
            completed: this.state.completed,
            owner: this.state.actionOw,
            risk: this.state.myRiskId,
            time: this.state.time,
            notes: this.state.notes,
            comments: 'na',
            others: this.state.others,
            bcpDcr: this.state.bcpReq,
            frequencyOfReview: this.state.frequencyOfReview,
            effectiveness: this.state.effectiveness,
            focus: this.state.focus,
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            let uploadReq = {
              description: '',
              user: sessionDetails?.user?.id,
              department: sessionDetails?.user?.department?.id,
              organisation: this.orgId,
              fileType: 'RISK_TREATMENT',
              taskId: 0,
              treatmentId: res.data,
              validFileType: true,
            }
            uploadFile(this.state.docUpload, this.orgId, uploadReq)
            this.setState({
              docUpload: [],
            })

            this.props.updateTable({ ...reqData, riskId: res.data })
            this.setState({
              step: 1,
              actionDes: '',
              actionOw: this.props.actionOwner,
              actionType: 1,
              riskStatus: 0,
              actionPri: 0,
              focus: 0,
              estimatedCost: 0,
              actCost: 0,
              time: '',
              others: '',
              bcpReq: '',
              frequencyOfReview: '',
              duedate: '',
              effectiveness: '',
              notes: '',
              showFirst: true,
              showNext: false,
              completed: 0,
              riskId: [],
            })
            Swal.fire({
              title: 'Data posted successfully',
              text: 'Your form data is now present in the table',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'OK',
            })
          } else {
            return Swal.fire(
              '',
              'There was an error adding your treatment. Please try again'
            )
          }
        })
    }
  }

  getReviewFrequency() {
    return this.props.frequencyOfReview.map((item, index) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getActionPriorities() {
    return this.props.actionPriorities.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getActionEffectiveness() {
    return this.props.actionEffectiveness.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getActionStatus() {
    return this.props.actionStatus.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  handleDateChange = (e) => {
    let finalDate = moment(e).format('DD/MM/YY')

    this.setState({
      duedate: finalDate,
    })
  }

  handleDocUpload = ({ fileList }) => {
    console.log('FILE LIST', fileList)

    if (fileList.length > 0) {
      this.setState({
        docUpload: fileList,
      })

      // updateProfile(fileData)
    }
    // uploadFileList({ fileList })
  }

  fetchData = () => {}

  render() {
    const {
      actionDes,
      actionOw,
      actionType,
      riskStatus,
      actionPri,
      focus,
      estimatedCost,
      actCost,
      time,
      others,
      bcpReq,
      frequencyOfReview,
      duedate,
      effectiveness,
      notes,
      completed,
    } = this.state
    const values = {
      actionDes,
      actionOw,
      actionType,
      riskStatus,
      actionPri,
      focus,
      estimatedCost,
      actCost,
      time,
      others,
      bcpReq,
      frequencyOfReview,
      duedate,
      effectiveness,
      notes,
      completed,
    }

    return (
      <div>
        <div>
          <Modal
            show={this.props.showModal}
            onHide={this.props.closeModal}
            className="modal-main action-plan-modal"
          >
            <Modal.Header className="modal-header">
              Add Action Plan / Cost of Treatment
            </Modal.Header>
            <Modal.Body className="modal-body">
              <Form>
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Description of Action Plan [SMART]
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            rows={3}
                            name="actionDes"
                            onChange={this.inputChange('actionDes').bind(this)}
                            value={values.actionDes}
                            style={{
                              width: '100%',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Owner<span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionOw"
                            onChange={this.inputChange('actionOw').bind(this)}
                            value={values.actionOw}
                          >
                            <option value="">-</option>
                            {this.renderActionOwners()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Type:<span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionType"
                            onChange={this.inputChange('actionType').bind(this)}
                            value={values.actionty}
                          >
                            <option value="">-</option>
                            <option value="1">Proactive</option>
                            <option value="2">Reactive</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Status:
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="riskStatus"
                            onChange={this.inputChange('riskStatus').bind(this)}
                            value={values.riskStatus}
                          >
                            <option value="">-</option>
                            {this.getActionStatus()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Priority:
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="actionPri"
                            onChange={this.inputChange('actionPri').bind(this)}
                            value={values.actionPri}
                            style={{
                              width: '100%',
                            }}
                          >
                            <option value="">-</option>
                            {this.getActionPriorities()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Action Focus:
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="focus"
                            onChange={this.inputChange('focus').bind(this)}
                            value={values.focus}
                            style={{
                              width: '100%',
                            }}
                          >
                            <option value="">-</option>
                            <option value="1">Cause</option>
                            <option value="2">Impact</option>
                            <option value="3">Impact and Cause</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Estimated Cost:</Form.Label>
                          <Form.Control
                            type="text"
                            name="estimatedCost"
                            onChange={this.inputChange('estimatedCost').bind(
                              this
                            )}
                            value={values.estimatedCost}
                            defaultValue=""
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Actual Cost:</Form.Label>
                          <Form.Control
                            type="text"
                            name="actCost"
                            onChange={this.inputChange('actCost').bind(this)}
                            value={values.actCost}
                            defaultValue=""
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Time:</Form.Label>
                          <Form.Control
                            type="text"
                            name="time"
                            onChange={this.inputChange('time').bind(this)}
                            value={values.time}
                            style={{
                              height: '30px',
                              width: '180px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Others</Form.Label>
                          <Form.Control
                            type="text"
                            name="others"
                            onChange={this.inputChange('others').bind(this)}
                            value={values.others}
                            style={{
                              height: '35px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label
                            style={{
                              fontSize: '10px',
                            }}
                          >
                            Is BCP/DRC Required?
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="bcpReq"
                            onChange={this.inputChange('bcpReq').bind(this)}
                            value={values.bcpReq}
                            style={{
                              height: '30px',
                            }}
                          >
                            <option value="">-</option>
                            <option value="BCP">BCP</option>
                            <option value="DRC">DRC</option>
                            <option value="BCP + DRC">BCP + DRC</option>
                            <option value="none">None</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Review Frequency:{' '}
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="frequencyOfReview"
                            onChange={this.inputChange(
                              'frequencyOfReview'
                            ).bind(this)}
                            value={values.frequencyOfReview}
                            style={{
                              height: '30px',
                            }}
                          >
                            <option value="">-</option>
                            {this.getReviewFrequency()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Due Date<span className="text-danger"> *</span>
                          </Form.Label>
                          <DatePicker
                            value={this.state.duedate}
                            name="testDate"
                            onChange={(e) => this.handleDateChange(e)}
                            format="dd/mm/yy"
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        Completed:<span className="text-danger"> *</span>
                        <Form.Group as={Row}>
                          <Col xs="8">
                            <RangeSlider
                              value={this.state.completed}
                              onChange={(e) =>
                                this.setState({
                                  completed: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col xs="3">
                            <Form.Control
                              value={values.completed}
                              onChange={this.inputChange('completed').bind(
                                this
                              )}
                            />
                          </Col>
                        </Form.Group>
                        {/* <Form.Group controlId="formBasicRange">
		                                                <Form.Label>
		                                                    %Completed
		                                                </Form.Label>
		                                                <Form.Control type="range" />
		                                            </Form.Group> */}
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Action Effectiveness:</Form.Label>
                          <Form.Control
                            as="select"
                            name="effectiveness"
                            onChange={this.inputChange('effectiveness').bind(
                              this
                            )}
                            value={values.effectiveness}
                          >
                            <option value="">-</option>
                            {this.getActionEffectiveness()}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Upload Evidence:</Form.Label>
                          <br />
                          <Upload
                            multiple={true}
                            customRequest={(e) => {
                              e.onSuccess((val) => {
                                console.log('FILE VAL --->', val)
                              })
                              e.onError((err) => {
                                console.log('FILE err --->', err)
                              })
                            }}
                            onChange={this.handleDocUpload}
                          >
                            <BTN icon={<UploadOutlined />}>Click to Upload</BTN>
                          </Upload>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Notes</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="notes"
                            onChange={this.inputChange('notes').bind(this)}
                            value={values.notes}
                            style={{
                              height: '120px',
                              width: '100%',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
              <div>
                <Button onClick={this.props.closeModal}>Cancel</Button>
                <Button type={'submit'} onClick={this.saveClickd.bind(this)}>
                  Save
                </Button>
                {/*<Button onClick={this.props.closeModal}>Cancel</Button>
                <Button onClick={this.nextClickHandler.bind(this)}>Next</Button>*/}
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
}

export default AddActionPlanModal
