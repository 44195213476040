import React from 'react'
import PropTypes from 'prop-types'
import { Space, Input } from 'antd'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
const LoginFormInput = ({ title, handleChange, handleSubmit, errors }) => (
  <div className="form-input">
    <div className="form-input-container left">
      <div className="form-input-header">
        <img
          src="assets/ConradRP_Logo_Strapline.png"
          alt="ConradRP Logo"
          height="170"
        />
        <h1>{title}</h1>
      </div>
      <form onSubmit={handleSubmit}>
        {/*<input
          onChange={handleChange}
          name="username"
          className="input-text"
          placeholder="Email Address"
        />

        <input
          onChange={handleChange}
          name="password"
          type="password"
          className="input-text"
          placeholder="Password"
        />*/}
        <Space direction="vertical" style={{ width: '100%' }}>
          <Input
            onChange={handleChange}
            name="username"
            size="small"
            placeholder="Email Address"
          />
          <Input.Password
            onChange={handleChange}
            name="password"
            placeholder="Password"
            size="small"
            iconRender={visible =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Space>

        <div className="button-container">
          <button className="btn primary">Sign in</button>
        </div>
        <p className="d-flex justify-content-center">
          <a href="/forgot-password">Forgot password</a>
        </p>
        {errors.length > 0 && <p className="error">{errors}</p>}
        {/*<p>
          Don't have an account? <a href="/register">Sign up</a>
        </p>*/}
      </form>
    </div>
    <div className="form-input-container right">
      <div className="text-box-container mt-5">
        <h1 className="mt-5">Welcome to ConradRP</h1>
        <div className="text-box" style={{ fontSize: '14px' }}>
          <p>Connecting people, performance outcomes, and risk exposures!</p>
          <p>
            Conrad RP is born out of a passion to support the growing use of
            risk management skill as a decision making and performance
            improvement tool. The main aim of this solution is to give none-risk
            professionals, experts, or users an extremely user friendly,
            engaging but effective tool to be able to set objectives, align
            objectives with organizational strategies, work collaboratively with
            colleagues to identify, describe, assess, evaluate, and manage all
            threats and opportunities to the achievement of these goals,
            deliverables or set objectives.
          </p>
          <p>
            The solution is simple enough to set up, its looks and feels great
            to use with the capacity to collate large data from different
            sources for multiple uses and analysis. Conrad RP is also designed
            to be an intelligent source of managing staff tasks, key performance
            indicators, and risk information.
          </p>
          <p className="" style={{ textAlign: 'left' }}>
            It enables a structured and systematic approach to managing
            organizational Risk, Performance. Our desire is to inspire you to
            deliver your goals and success at whatever you set out to do by
            handling risk better.
          </p>
          <p>Enjoy...</p>
          <p className="m-0">
            <span>Joachim Adebayo Adenusi</span>
            (Oga Risk)
          </p>
          <p>Co-Founder Conrad RP ERP</p>
        </div>
        {/* <button className="btn learn-more">Learn more</button> */}
      </div>
      <div className="large-triangle-container">
        <div className="large-triangle"></div>
      </div>
    </div>
  </div>
)
LoginFormInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
}

export default LoginFormInput
