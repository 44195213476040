import React, { useState, useEffect, useContext } from 'react'
import { Modal, Form, Container, Row, Col, Button } from 'react-bootstrap'
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons'

import '../../../style/container/objectives.scss'
import RangeSlider from 'react-bootstrap-range-slider'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import { Button as ButtonAnt, Upload, Popconfirm } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { deleteFile, getDocumentByType } from '../../../common/uploadFile'

import userContext from '../../../context/userContext'

export default function EditMyTasksModal({
  priorities,
  submitForm,
  show,
  handleClose,
  assignee,
  item,
  handleDocUpload,
}) {
  const { sessionDetails } = useContext(userContext)
  const [docData, setDocData] = useState([])
  const [sliderValue, setSliderValue] = useState(0)
  const [startDate, setStartDate] = useState(
    `${item?.startDate?.day}-${item?.startDate?.month}-${item?.startDate?.year}`
  )
  const [endDate, setEndDate] = useState(
    `${item?.dueDate?.day}-${item?.dueDate?.month}-${item?.dueDate?.year}`
  )
  const [reminder, setReminder] = useState(
    `${item?.reminder?.day}-${item?.reminder?.month}-${item?.reminder?.year}`
  )
  const [formData, setData] = useState({
    name: item.name,
    assignee: item.assignee,
    actionType: item.actionType.name,
    actionStatus: item.actionStatus.id,
    completed: item.completed,
    priority: item.priority.id,
    notes: item.notes,
    reminder: moment(
      `${item.startDate.year}-${item.startDate.month}-${item.startDate.day}`,
      'YYYY-MM-DD'
    ).format('DD-MM-YY'),
    startDt: moment(
      `${item.startDate.year}-${item.startDate.month}-${item.startDate.day}`,
      'YYYY-MM-DD'
    ).format('DD-MM-YY'),
    dueDt: moment(
      `${item.dueDate.year}-${item.dueDate.month}-${item.dueDate.day}`,
      'YYYY-MM-DD'
    ).format('DD-MM-YY'),
    startDate: moment(
      `${item.startDate.year}-${item.startDate.month}-${item.startDate.day}`,
      'DD-MM-YYYY'
    ).format('DD-MM-YY'),
    dueDate: moment(
      `${item.dueDate.year}-${item.dueDate.month}-${item.dueDate.day}`,
      'DD-MM-YYYY'
    ).format('DD-MM-YY'),
    id: item.id,
    ref: item.ref ? item.ref : String(Math.floor(Math.random() + 1 * 1000)),
  })

  const [actionPriorities, setPriorities] = useState([])

  useEffect(() => {
    getFileList()
  }, [])

  const getFileList = async () => {
    const docData = await getDocumentByType(item.organisation, {
      user: sessionDetails?.user?.id,
      department: sessionDetails?.user?.department?.id,
      organisation: item.organisation,
      fileType: 'TASK',
      taskId: item.id,
    })
    console.log('DOCC', docData)
    setDocData(docData)
  }

  const deleteUploadFile = async (docId) => {
    try {
      let orgId = item.organisation
      const data = await deleteFile(orgId, docId)

      getFileList()
    } catch (e) {
      console.log(e)
    }
  }

  const handleChange = (e) => {
    let data = { ...formData }
    data[e.target.name] = e.target.value
    setData(data)
  }

  const handleDateChange = (e, name) => {
    let finalDate = moment(e).format('DD-MM-YY')

    if (name == 'startDate') {
      setStartDate(finalDate)
      setData({ ...formData, startDt: finalDate })
    } else if (name == 'endDate') {
      setEndDate(finalDate)
      setData({ ...formData, dueDt: finalDate })
    } else {
      setReminder(finalDate)
      setData({ ...formData, reminder: finalDate })
    }
  }

  const handleSliderChange = (value) => {
    setSliderValue(value)
    setData({ ...formData, completed: value })
  }

  useEffect(() => {
    setPriorities(priorities)
  }, [JSON.stringify(priorities)])

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-main objectives-resize"
      >
        <Modal.Header>Edit Task</Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Task:<span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="name"
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter task name..."
                      value={formData.name}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Type:<span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={formData?.actionType}
                      name="actionType"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">-</option>
                      <option value="PROACTIVE">PROACTIVE</option>
                      <option value="REACTIVE">REACTIVE</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Status:<span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={formData?.actionStatus}
                      name="actionStatus"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">-</option>
                      <option value="1">PROPOSED</option>
                      <option value="2">APPROVED</option>
                      <option value="3">IN PROGRESS</option>
                      <option value="4">WITHDRAWN</option>
                      <option value="5">IMPLEMENTED</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Start Date:<span className="text-danger"> *</span>
                    </Form.Label>
                    <DatePicker
                      format="DD-MM-YY"
                      name="startDate"
                      onChange={(e) => handleDateChange(e, 'startDate')}
                      value={startDate}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      End Date:<span className="text-danger"> *</span>
                    </Form.Label>
                    <DatePicker
                      format="DD-MM-YY"
                      name="endDt"
                      onChange={(e) => handleDateChange(e, 'endDate')}
                      value={endDate}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formBasicRange">
                    <Form.Label>
                      % Completed<span className="text-danger"> *</span>
                    </Form.Label>
                    <RangeSlider
                      value={formData.completed}
                      onChange={(e) => handleSliderChange(e.target.value)}
                    />
                    <Form.Control
                      value={formData.completed}
                      onChange={(e) => handleSliderChange(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Priority:<span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="priority"
                      value={formData.priority}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value={-1}>-</option>
                      {actionPriorities.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Reminder:<span className="text-danger"> *</span>
                    </Form.Label>
                    <DatePicker
                      format="DD-MM-YY"
                      name="reminder"
                      onChange={(e) => handleDateChange(e, 'reminder')}
                      value={reminder}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Upload:</Form.Label>
                    <br />
                    <Upload
                      multiple={true}
                      customRequest={(e) => {
                        e.onSuccess((val) => {
                          console.log('FILE VAL --->', val)
                        })
                        e.onError((err) => {
                          console.log('FILE err --->', err)
                        })
                      }}
                      onChange={handleDocUpload}
                    >
                      <ButtonAnt icon={<UploadOutlined />}>
                        Click to Upload
                      </ButtonAnt>
                    </Upload>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Notes:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Type here..."
                      name="notes"
                      value={formData.notes}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>File List </Form.Label>
                  <div
                    style={{
                      width: '100%',
                      height: '150px',
                      overflow: 'auto',
                    }}
                  >
                    {docData?.map((data, key) => {
                      return (
                        <div
                          key={key}
                          className="d-flex justify-content-between border mb-1 p-1 bg-white"
                        >
                          <p className="m-0">{data.fileName}</p>
                          <span>
                            <a
                              className="cursor-pointer"
                              href={data.fileUrl}
                              download
                              target="_blank"
                            >
                              <DownloadOutlined />
                            </a>
                            <span className="cursor-pointer">
                              <Popconfirm
                                title="Are you sure to delete this File?"
                                onConfirm={() => {
                                  deleteUploadFile(data.id)
                                }}
                                okText="Yes"
                                cancelText="No"
                              >
                                <DeleteOutlined />
                              </Popconfirm>
                            </span>
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={(e) => submitForm(e, formData)}>Save</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
