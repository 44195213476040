/* eslint-disable prettier/prettier */
import React, { useContext } from 'react'
import Table from './RiskReport.TableOrganisational.jsx'
import userContext from '../../../../context/userContext'


const initObject = {
    totalGrossOpp: 0, totalNetOpp: 0, totalTargetOpp: 0,
    totalGrossNeg: 0, totalNetNeg: 0, totalTargetNeg: 0,
}

export default function RiskReport({ data, departmentsMap }) {

    const [totals, setTotals] = React.useState(null)
    const [overall, setOverall] = React.useState({
        overAllGrossOpp: 0, overAllNetOpp: 0, overAllTargetOpp: 0,
        overAllGrossNeg: 0, overAllNetNeg: 0, overAllTargetNeg: 0,
    })


  

    const { sessionDetails } = useContext(userContext)

    const currency = sessionDetails?.user?.organisation?.currencyCode

    const getTotals = (risks) => {
       return risks.reduce((acc, item) => {
            const risk = item.risk;
            if (risk.impactType.name === 'POSITIVE') {
                acc.totalGrossOpp += risk.grossEmv.netValue
                acc.totalNetOpp += risk.netEmv.netValue
                acc.totalTargetOpp += risk.targetEmv.netValue
            } else {
                acc.totalGrossNeg += risk.grossEmv.netValue
                acc.totalNetNeg += risk.netEmv.netValue
                acc.totalTargetNeg += risk.targetEmv.netValue
            }


            return acc;
        }, {
            totalGrossOpp: 0, totalNetOpp: 0, totalTargetOpp: 0,
            totalGrossNeg: 0, totalNetNeg: 0, totalTargetNeg: 0,
        })

    }


    React.useEffect(() => {
        const totals = [];
        Object.keys(data.data).map((key) => {
            totals.push(getTotals(data.data[`${key}`]))
        })

        const overAllTotals = totals.reduce((acc, item) => {
            acc.overAllGrossOpp += item.totalGrossOpp;
            acc.overAllNetOpp += item.totalNetOpp;
            acc.overAllTargetOpp += item.totalTargetOpp;
            acc.overAllGrossNeg += item.totalGrossNeg;
            acc.overAllNetNeg += item.totalNetNeg;
            acc.overAllTargetNeg += item.totalTargetNeg;

            return acc
        }, {
            overAllGrossOpp: 0, overAllNetOpp: 0, overAllTargetOpp: 0,
            overAllGrossNeg: 0, overAllNetNeg: 0, overAllTargetNeg: 0,
        })

        console.log(overAllTotals)
        setTotals(totals)
        setOverall(overAllTotals)


    }, [data])




    return (
        <>
            <div
                className="w-100">
                {Object.keys(data.data).map((key,index) => {
                    return <Table risks={data.data[`${key}`]} totals={totals ? totals[index] : initObject} departmentsMap={departmentsMap} />
                })}

            </div>
            <hr />
            <div className="w-100">

                <div className="total-count-row">
                    <div className="labels-div">
                        <p>Overall Total - Threat Value</p>
                        <p>Overall Total - Opportunity Value</p>

                    </div>
                    <div className="values-div">
                        <p><span>{currency} {overall?.overAllGrossNeg}</span> <span>{currency} {overall?.overAllNetNeg}</span> <span>{currency} {overall?.overAllTargetNeg}</span></p>
                        <p><span>{currency} {overall?.overAllGrossOpp}</span> <span>{currency} {overall?.overAllNetOpp}</span> <span>{currency} {overall?.overAllTargetOpp}</span></p>

                    </div>
                </div>
            </div>
        </>
    )
}



