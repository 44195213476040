import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Table } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import OrganizationChart from '@dabeng/react-orgchart'
import UserSetup from './UserSetup.jsx'
import ButtonActions from '../../common/EditDeleteButtons.jsx'
import ButtonsHeader from '../../common/EditDeleteTH.jsx'
import { InfoToolTip } from '../../common/InfoTooltip.jsx'
const ReportingStructure = props => {
  const {
    handleChange,
    viewDepartment,
    viewTeam,
    viewJobRole,
    addDepartment,
    addJobRole,
    updateDepartment,
    updateTeam,
    updateJobRole,
    deleteDepartment,
    deleteTeam,
    deleteJobRole,
    postOrganisationDepartments,
    organisationDepartments,
    postOrganisationTeams,
    organisationData,
    organisationTeams,
    organisationJobRoles,
    addTeam,
    orgChart,
    organisationDepartmentData,
    organisationJobRoleData,
    postOrganisationJobRoles,
    teams,
    roles,
    organisationSystemRoles,
    viewSystemRole,
    addSystemRole,
    updateSystemRole,
    deleteSystemRole,
    postOrganisationSystemRoles,
    organisationSystemRolsData,
    systemRoles,
    sysRoles,
    departmentPage,
    totalDepartmentPages,
    setDepartmentPage,
    jobRolesPage,
    totalJobRolePages,
    setJobRolePage,
    getTotalPages,
    getPageRows,
    rowsPerPage,
  } = props

  const [key, setKey] = useState(
    localStorage.getItem('reportingStructure') || 'Departments'
  )

  console.log('RS - orgChart --->', orgChart)

  return (
    <div className="reporting-structure layout table-main">
      <div className="body-container">
        <Tabs
          activeKey={key}
          onSelect={k => {
            localStorage.setItem('reportingStructure', k)
            setKey(k)
          }}
          id="uncontrolled-tab-example"
        >
          <Tab eventKey="Departments" title="Departments + Teams + Units">
            <hr className="global-hr mt-4" />
            <div className="tab-title">
              <h2>
                <InfoToolTip
                  title={'Departments'}
                  text="Departments Info Text"
                ></InfoToolTip>
              </h2>
              <p>
                Add New{' '}
                <button
                  type="button"
                  className="icon add"
                  data-toggle="modal"
                  data-target="#departmentModal"
                >
                  {/* <img src="../../assets/add.svg" alt="add" /> */}
                  <i className="fa fa-plus" />
                </button>
              </p>

              {/* Add department modal */}

              <div
                className="modal fade region"
                id="departmentModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="departmentModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <form onSubmit={addDepartment}>
                      <div className="modal-header">
                        <h2
                          className="modal-title mt-0"
                          id="departmentModalLabel"
                        >
                          Add Department
                        </h2>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body">
                        <div className="form-column-modal">
                          <label>
                            Name
                            <span className="form-mandatory">*</span>
                          </label>
                          <input
                            onChange={e =>
                              handleChange(e, 'postOrganisationDepartments')
                            }
                            name="name"
                            className="input-text"
                          />

                          <label>
                            Parent Department
                            <span className="form-mandatory">*</span>
                          </label>
                          <select
                            className="form-select"
                            defaultValue={'default'}
                            onChange={e =>
                              handleChange(e, 'postOrganisationDepartments')
                            }
                            name="parent"
                          >
                            <option value="0">-</option>
                            {organisationDepartments.map((orgDepartment, i) => (
                              <option key={i} value={orgDepartment.id}>
                                {' '}
                                {orgDepartment.name}{' '}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary add"
                          aria-label="Close"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/*Edit Department*/}

            <Table size="sm" className="departments-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Parent</th>
                  <th>Child</th>
                  <ButtonsHeader />
                </tr>
              </thead>
              {getPageRows('organisationDepartments', departmentPage).map(
                (orgDepartment, index) => {
                  return (
                    <tbody>
                      <tr key={index}>
                        <td>{departmentPage * 10 + index + 1}</td>
                        <td>{orgDepartment.name}</td>
                        {!orgDepartment.parentDepartment ? (
                          <td></td>
                        ) : (
                          <td>{orgDepartment.parentDepartment.name}</td>
                        )}

                        <td>
                          {typeof orgDepartment.childName == undefined
                            ? 'no'
                            : orgDepartment.childName}
                        </td>

                        <ButtonActions
                          dataTarget={'#viewDepartmentModal'}
                          editAction={e => viewDepartment(e, orgDepartment.id)}
                          deleteAction={e =>
                            deleteDepartment(e, orgDepartment.id)
                          }
                          itemId={orgDepartment.id}
                        />
                      </tr>
                    </tbody>
                  )
                }
              )}
              <div
                className="modal fade region"
                id="viewDepartmentModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="departmentModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <form
                      onSubmit={e =>
                        updateDepartment(e, postOrganisationDepartments.id)
                      }
                    >
                      <div className="modal-header">
                        <h2 className="modal-title" id="departmentModalLabel">
                          Edit Department
                        </h2>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body">
                        <div className="form-column-modal">
                          <label>
                            Name
                            <span className="form-mandatory">*</span>
                          </label>
                          <input
                            onChange={e =>
                              handleChange(e, 'postOrganisationDepartments')
                            }
                            name="name"
                            className="input-text"
                            value={postOrganisationDepartments.name}
                          />

                          <label>Parent Department</label>
                          <select
                            className="form-select"
                            defaultValue={'default'}
                            value={postOrganisationDepartments.parent}
                            onChange={e =>
                              handleChange(e, 'postOrganisationDepartments')
                            }
                            name="parent"
                          >
                            <option
                              value="default"
                              disabled="disabled"
                            ></option>
                            {organisationDepartments.map((orgDepartment, i) => (
                              <option key={i} value={orgDepartment.id}>
                                {' '}
                                {orgDepartment.name}{' '}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary add"
                          aria-label="Close"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Table>
            <div className="text-right mt-3">
              <span style={{ fontSize: '14px' }}>
                Showing page {departmentPage + 1} of {totalDepartmentPages}
              </span>

              <button
                className="previous-page btn btn-primary mx-2"
                disabled={departmentPage == 0}
                onClick={() => setDepartmentPage(departmentPage - 1)}
              >
                Prev
              </button>
              <button
                disabled={departmentPage === totalDepartmentPages - 1}
                className="next-page btn btn-primary"
                onClick={() => setDepartmentPage(departmentPage + 1)}
              >
                Next
              </button>
            </div>
          </Tab>

          {/* Add Team modal */}

          <Tab eventKey="Job" title="Job Roles">
            <hr className="global-hr mt-4" />

            <div className="tab-title">
              <h2>
                <InfoToolTip
                  title={'Job Roles'}
                  text="Job Roles Info Text"
                ></InfoToolTip>
              </h2>
              <p>
                Add New{' '}
                <button
                  type="button"
                  className="icon add"
                  data-toggle="modal"
                  data-target="#jobRoleModal"
                >
                  <i className="fa fa-plus" />
                </button>
              </p>

              {/* Add Job Roles modal */}

              <div
                className="modal fade region"
                id="jobRoleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="jobRoleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <form onSubmit={addJobRole}>
                      <div className="modal-header">
                        <h2 className="modal-title" id="jobRoleModalLabel">
                          Add Job Role
                        </h2>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body">
                        <div className="form-column-modal">
                          <label>
                            Name
                            <span className="form-mandatory">*</span>
                          </label>
                          <input
                            onChange={e =>
                              handleChange(e, 'postOrganisationJobRoles')
                            }
                            name="title"
                            className="input-text"
                            value={postOrganisationJobRoles.title}
                          />
                        </div>
                        <div className="form-column-modal">
                          <label>Department</label>
                          <select
                            className="form-select"
                            defaultValue={'default'}
                            onChange={e =>
                              handleChange(e, 'postOrganisationJobRoles')
                            }
                            name="department"
                          >
                            <option
                              value="default"
                              disabled="disabled"
                            ></option>
                            {organisationDepartments.map((orgDepartment, i) => (
                              <option key={i} value={orgDepartment.id}>
                                {' '}
                                {orgDepartment.name}{' '}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-column-modal">
                          <label>
                            Mandatory
                            <span className="form-mandatory">*</span>
                          </label>
                          <select
                            className="form-select"
                            defaultValue={'default'}
                            onChange={e =>
                              handleChange(e, 'postOrganisationJobRoles')
                            }
                            name="mandatory"
                          >
                            <option
                              value="default"
                              disabled="disabled"
                            ></option>
                            <option value={true}> Yes </option>
                            <option value={false}> No </option>
                          </select>
                        </div>
                        <div className="form-column-modal">
                          <label>Reporting To</label>
                          <select
                            onChange={e =>
                              handleChange(e, 'postOrganisationJobRoles')
                            }
                            name="parentJobRole"
                            value={postOrganisationJobRoles.parentJobRole}
                            className="form-select"
                          >
                            <option value={'default'}>-</option>
                            {organisationJobRoles.map(item => {
                              return (
                                <option value={item.id}>
                                  {' '}
                                  {item.jobTitle}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary add"
                          aria-label="Close"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/*Edit Job Roles modal*/}

            <div
              className="modal fade region"
              id="viewjobRoleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="viewjobRoleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <form
                    onSubmit={e =>
                      updateJobRole(e, postOrganisationJobRoles.id)
                    }
                  >
                    <div className="modal-header">
                      <h2 className="modal-title" id="viewjobRoleModalLabel">
                        Edit Job Role
                      </h2>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body">
                      <div className="form-column-modal">
                        <label>
                          Name
                          <span className="form-mandatory">*</span>
                        </label>
                        <input
                          onChange={e =>
                            handleChange(e, 'postOrganisationJobRoles')
                          }
                          name="title"
                          className="input-text"
                          value={postOrganisationJobRoles.title}
                        />
                      </div>
                      <div className="form-column-modal">
                        <label>Department</label>
                        <select
                          className="form-select"
                          defaultValue={'default'}
                          onChange={e =>
                            handleChange(e, 'postOrganisationJobRoles')
                          }
                          name="department"
                          value={postOrganisationJobRoles.department}
                        >
                          <option value="default" disabled="disabled"></option>
                          {organisationDepartments.map((orgDepartment, i) => (
                            <option key={i} value={orgDepartment.id}>
                              {' '}
                              {orgDepartment.name}{' '}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-column-modal">
                        <label>
                          Mandatory
                          <span className="form-mandatory">*</span>
                        </label>
                        <select
                          className="form-select"
                          defaultValue={'default'}
                          onChange={e =>
                            handleChange(e, 'postOrganisationJobRoles')
                          }
                          name="mandatory"
                          value={postOrganisationJobRoles.mandatory}
                        >
                          <option value="default" disabled="disabled"></option>
                          <option value={true}> Yes </option>
                          <option value={false}> No </option>
                        </select>
                      </div>
                      <div className="form-column-modal">
                        <label>Reporting To</label>

                        <select
                          className="form-select"
                          defaultValue={'default'}
                          onChange={e =>
                            handleChange(e, 'postOrganisationJobRoles')
                          }
                          name="parentJobRole"
                          value={postOrganisationJobRoles.parentJobRole}
                        >
                          {organisationJobRoles.map(item => {
                            return (
                              <option value={item.id}>{item.jobTitle}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary add"
                        aria-label="Close"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Job Roles table */}

            <Table size="sm">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Department</th>
                  <th>Mandatory</th>
                  <th>Reporting To</th>
                  <ButtonsHeader />
                </tr>
              </thead>
              {getPageRows('organisationJobRoles', jobRolesPage).map(
                (orgJobRole, index) => {
                  return (
                    <tbody>
                      <tr key={index}>
                        <td>{jobRolesPage * 10 + index + 1}</td>
                        <td>{orgJobRole.title}</td>

                        <td>{orgJobRole?.department?.name}</td>

                        <td>{orgJobRole.isMandatory ? 'Yes' : 'No'}</td>

                        <td>{orgJobRole?.parentJobRole?.title}</td>

                        <ButtonActions
                          dataTarget="#viewjobRoleModal"
                          editAction={e => viewJobRole(e, orgJobRole.id)}
                          deleteAction={e => deleteJobRole(e, orgJobRole.id)}
                          itemId={orgJobRole.id}
                        />
                      </tr>
                    </tbody>
                  )
                }
              )}
            </Table>
            <div className="text-right mt-3">
              <span style={{ fontSize: '14px' }}>
                Showing page {jobRolesPage + 1} of {totalJobRolePages}
              </span>
              <button
                className="previous-page btn btn-primary mx-2"
                disabled={jobRolesPage == 0}
                onClick={() => setJobRolePage(jobRolesPage - 1)}
              >
                Prev
              </button>
              <button
                disabled={jobRolesPage === totalJobRolePages - 1}
                className="next-page btn btn-primary"
                onClick={() => setJobRolePage(jobRolesPage + 1)}
              >
                Next
              </button>
            </div>
          </Tab>

          <Tab eventKey="Org Structure" title="Org Structure">
            <hr className="global-hr mt-4" />
            <OrganizationChart datasource={orgChart} />
          </Tab>

          <Tab eventKey="systemRoles" title="System Roles">
            <hr className="global-hr mt-4" />

            <div className="tab-title">
              <h2>
                <InfoToolTip
                  title={'System Roles'}
                  text="System Roles Info Text"
                ></InfoToolTip>
              </h2>
              {/* <p>
                                Add New
                                <button
                                    type="button"
                                    className="icon add"
                                    data-toggle="modal"
                                    data-target="#systemRoleModal"
                                >
                                    <img src="../../assets/add.svg" alt="add" />
                                </button>
                            </p> */}
              {/* <div
                                className="modal fade region"
                                id="systemRoleModal"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="jobRoleModalLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <form onSubmit={addJobRole}>
                                            <div className="modal-header">
                                                <h2
                                                    className="modal-title"
                                                    id="jobRoleModalLabel"
                                                >
                                                    Add System Role
                                                </h2>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">
                                                        &times;
                                                    </span>
                                                </button>
                                            </div>

                                            <div className="modal-body">
                                                <div className="form-column-modal">
                                                    <label>
                                                        Role Title
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationSystemRoles'
                                                            )
                                                        }
                                                        name="role"
                                                        className="input-text"
                                                    />
                                                </div>

                                                <div className="form-column-modal">
                                                    <label>
                                                        Role Description
                                                        <span className="form-mandatory">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                'postOrganisationSystemRoles'
                                                            )
                                                        }
                                                        name="description"
                                                        className="input-text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary add"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> */}
            </div>

            {/*System roles table*/}

            <Table size="sm">
              <thead>
                <tr>
                  <th className="w-10">No.</th>
                  <th>Role</th>
                  {/* <th className="w-50">Role Description</th> */}
                  <th className="w-10">No. of Users</th>
                </tr>
              </thead>
              {/* {systemRoles.map((role, index) => {
                                return (
                                    <tbody>
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{role.name}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                )
                            })} */}
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Edits</td>
                  <td>{sysRoles.edits}</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Views</td>
                  <td>{sysRoles.views}</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Admins</td>
                  <td>{sysRoles.admins}</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Total No of Users</td>
                  <td>{sysRoles.users}</td>
                </tr>
              </tbody>
            </Table>
          </Tab>

          <Tab eventKey="userSetup" title="User Setup">
            <hr className="global-hr mt-4" />

            <UserSetup systemRoles={systemRoles} {...props} />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default ReportingStructure
