import React, { Component } from 'react'
import axios from 'axios'
import ReactDOM from 'react-dom'
import ResetPasswordFormInput from '../presentational/ResetPasswordFormInput.jsx'
import Auth from '../../lib/Auth.js'

class ResetPasswordContainer extends Component {
  constructor() {
    super()

    this.state = {
      title: 'Reset Password',
      postData: {},
      errors: {},
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange({ target: { name, value } }) {
    const postData = { ...this.state.postData, [name]: value }
    const errors = {}
    this.setState({ postData, errors })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.state.postData = {
      ...this.state.postData,
      token: this.props.match.params.token,
    }
    axios
      .post(BASE_URL + '/authentication/passwords/reset', this.state.postData, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then(() => this.props.history.push('/'))
      .catch(err => this.setState({ errors: err.data }))
  }

  render() {
    const { title } = this.state
    return (
      <ResetPasswordFormInput
        type="text"
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        title={title}
      />
    )
  }
}

export default ResetPasswordContainer
