import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import ViewMatrixTable from './ViewMatrixTable.jsx'
import '../../style/container/objectives.scss'

const ViewMatrix = ({ showModal, closeModal }) => {
    return (
        <div className="">
            <Modal
                show={showModal}
                onHide={closeModal}
                className="modal-main view-matrix-modal"
            >
                <Modal.Header className="modal-header">
                    Risk Matrix Data
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="modal-inner">
                        <ViewMatrixTable />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ViewMatrix
