import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import isEmpty from 'lodash/fp/isEmpty'
import { Modal } from 'antd'
import TermsConditionModal from '../common/TermsContitionModal.jsx'

const AccountCreationFormInput = ({
  title,
  handleChangeMessage,
  handleCheckboxChange,
  handleChangeAddressMessage,
  handleChangeUserMessage,
  handleChangeRegions,
  handleSubmit,
  handleChange,
  industries,
  countries,
  name,
  description,
  addValues,
  orgValues,
  values,
  viewValue,
  selectedValue,
  updateValue,
  deleteValue,
  errors,
  regions,
  regionsAddressMessage,
  addRegions,
  orgRegions,
  viewRegion,
  selectedRegion,
  updateRegion,
  deleteRegion,
  handleChangeSelectedRegions,
  currencyList,
  defaultCountrySelect,
  handleCountrySelect,
  handleCurrencySelect,
}) => {
  const [termsModal, setTermsModal] = useState(false)

  const handleOk = () => {
    setTermsModal(false)
  }

  const handleCancel = () => {
    setTermsModal(false)
  }
  const handleOpen = () => {
    setTermsModal(true)
  }
  return (
    <React.Fragment>
      <TermsConditionModal
        visible={termsModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      {/*<Modal
        title="Conrad RP - Terms of Use "
        visible={termsModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
      
      </Modal>*/}

      <div className="form-input-container">
        <div className="form-input-header">
          <img
            src="assets/ConradRP_Logo_Strapline.png"
            alt="ConradRP Logo"
            height="170"
          />
          <h1>{title}</h1>
        </div>

        <form
          className="body-container account-creation"
          onSubmit={handleSubmit}
        >
          <div className="account-form-input">
            <h2>Organisation Details</h2>

            <div className="form-row">
              <div className="form-cell">
                <label>
                  Name of Organisation
                  <span className="form-mandatory">*</span>
                </label>
                <input
                  onChange={e => handleChangeMessage(e, 'organisationMessage')}
                  name="name"
                  className="input-text"
                />
                {errors.orgName && errors.orgName.length > 0 && (
                  <span className="error">{errors.orgName}</span>
                )}
              </div>

              <div className="form-cell">
                <label>
                  Address Line 1<span className="form-mandatory">*</span>
                </label>
                <input
                  onChange={e => handleChangeMessage(e, 'addressMessage')}
                  name="addressLine1"
                  className="input-text"
                />
                {errors?.address1 && errors.address1.length > 0 && (
                  <span className="error">{errors.address1}</span>
                )}
              </div>

              <div className="form-cell">
                <label>Address Line 2</label>
                <input
                  onChange={e => handleChangeMessage(e, 'addressMessage')}
                  name="addressLine2"
                  className="input-text"
                />
                {errors?.address2 && errors.address2.length > 0 && (
                  <span className="error">{errors.address2}</span>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-cell">
                <label>Address Line 3</label>
                <input
                  onChange={e => handleChangeMessage(e, 'addressMessage')}
                  name="addressLine3"
                  className="input-text"
                />
                {errors?.address3 && errors.address3.length > 0 && (
                  <span className="error">{errors.address3}</span>
                )}
              </div>

              <div className="form-cell">
                <label>Post Code</label>
                <input
                  onChange={e => handleChangeMessage(e, 'addressMessage')}
                  name="postCode"
                  className="input-text"
                />
                {errors?.postCode && errors.postCode.length > 0 && (
                  <span className="error">{errors.postCode}</span>
                )}
              </div>

              <div className="form-cell">
                <label>State</label>
                <input
                  onChange={e => handleChangeMessage(e, 'addressMessage')}
                  name="state"
                  className="input-text"
                />
                {errors?.state && errors.state.length > 0 && (
                  <span className="error">{errors.state}</span>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-cell">
                <label>
                  Country
                  <span className="form-mandatory">*</span>
                </label>
                <select
                  className="form-select"
                  onChange={e => {
                    handleChangeMessage(e, 'addressMessage')
                    handleCountrySelect(e.target.value)
                  }}
                  name="country"
                >
                  <option value="">Select Country</option>
                  {countries.map((country, i) => (
                    <option key={i} value={country.code}>
                      {country.longName}
                    </option>
                  ))}
                </select>
                {errors?.country && errors.country.length > 0 && (
                  <span className="error">{errors.country}</span>
                )}
              </div>

              <div className="form-cell">
                <label>
                  Currency
                  <span className="form-mandatory">*</span>
                </label>
                <select
                  className="form-select"
                  value={
                    !isEmpty(defaultCountrySelect)
                      ? defaultCountrySelect.currency
                      : ''
                  }
                  name="currencyCode"
                  onChange={e => {
                    // let target = e.target
                    // let value = e.target.value
                    // let eventValue = {
                    //     ...e,
                    //     target: {
                    //         ...target,
                    //         value:
                    //             e.target.value &&
                    //             e.target.value.split('-')[0],
                    //     },
                    // }
                    handleChangeMessage(e, 'addressMessage')
                    handleCurrencySelect(e.target.value)
                  }}
                >
                  <option value="">Select Currency</option>
                  {!isEmpty(currencyList) &&
                    Object.keys(currencyList).map((shotCode, key) => {
                      return (
                        <option
                          key={key}
                          value={`${currencyList[shotCode].currency}`}
                        >
                          {currencyList[shotCode].currency}
                        </option>
                      )
                    })}
                </select>
                {errors.currencyCode && errors.currencyCode.length > 0 && (
                  <span className="error">{errors.currencyCode}</span>
                )}
              </div>

              <div className="form-cell">
                <label>Industry</label>
                <select
                  className="form-select"
                  defaultValue={'default'}
                  onChange={e => handleChangeMessage(e, 'organisationMessage')}
                  name="industry"
                >
                  <option value="default" disabled="disabled"></option>
                  {industries.map((industry, i) => (
                    <option key={i} value={industry.id}>
                      {' '}
                      {industry.name}{' '}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/*Add Regions modal*/}

            <div className="form-row">
              <div className="form-cell">
                <h2>Region</h2>
                <button
                  type="button"
                  className="icon add"
                  data-toggle="modal"
                  data-target="#regionModal"
                >
                  <label className="add-button-text">Add New</label>
                  <img src="../../assets/add.svg" alt="add" />
                </button>

                <div
                  className="modal fade region"
                  id="regionModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="regionModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h2 className="modal-title" id="regionModalLabel">
                          Add Region
                        </h2>
                      </div>

                      <div className="modal-body">
                        <div className="form-column-modal">
                          <div className="form-row">
                            <div className="form-cell">
                              <label>
                                Region Name
                                <span className="form-mandatory">*</span>
                              </label>
                              <input
                                onChange={e => handleChange(e, 'regions')}
                                name="name"
                                value={regions.name}
                                className="input-text"
                              />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-cell">
                              <label>
                                Address Line 1
                                <span className="form-mandatory">*</span>
                              </label>
                              <input
                                onChange={e =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="addressLine1"
                                value={regions.addressMessage.addressLine1}
                                className="input-text"
                              />
                            </div>
                            <div className="form-cell">
                              <label>Address Line 2</label>
                              <input
                                onChange={e =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="addressLine2"
                                value={regions.addressMessage.addressLine2}
                                className="input-text"
                              />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-cell">
                              <label>Address Line 3</label>
                              <input
                                onChange={e =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="addressLine3"
                                value={regions.addressMessage.addressLine3}
                                className="input-text"
                              />
                            </div>
                            <div className="form-cell">
                              <label>State</label>
                              <input
                                onChange={e =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="state"
                                value={regions.addressMessage.state}
                                className="input-text"
                              />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-cell">
                              <label>Post Code</label>
                              <input
                                onChange={e =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="postCode"
                                value={regions.addressMessage.postCode}
                                className="input-text"
                              />
                            </div>
                            <div className="form-cell">
                              <label>
                                Country
                                <span className="form-mandatory">*</span>
                              </label>
                              <select
                                defaultValue={'default'}
                                onChange={e =>
                                  handleChangeRegions(e, 'addressMessage')
                                }
                                name="country"
                              >
                                <option
                                  value="default"
                                  disabled="disabled"
                                ></option>
                                {countries.map((country, i) => (
                                  <option key={i} value={country.code}>
                                    {' '}
                                    {country.longName}{' '}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          onClick={addRegions}
                          type="button"
                          disabled={
                            regions.name === '' ||
                            regions.addressMessage.addressLine1 === '' ||
                            regions.addressMessage.country === ''
                              ? true
                              : false
                          }
                          className="btn btn-primary add"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Table size="sm" className="regions-table">
              <thead>
                {orgRegions && orgRegions.length >= 1 ? (
                  <tr>
                    <th>No.</th>
                    <th>Region Name</th>
                    <th>Country</th>
                    <th></th>
                  </tr>
                ) : (
                  <tr></tr>
                )}
              </thead>
              {orgRegions.map((orgRegion, index) => (
                <tbody>
                  <tr key={index}>
                    <td></td>
                    <td>{orgRegion.name}</td>
                    <td>{orgRegion.addressMessage.country}</td>
                    <td className="table-button-container">
                      <button
                        onClick={() => viewRegion(orgRegion, index)}
                        data-toggle="modal"
                        data-target="#viewRegionModal"
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src="../../assets/Edit_Icon.svg" alt="edit" />
                      </button>
                      <button
                        onClick={() => deleteRegion(index)}
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src="../../assets/Delete_Icon.svg" alt="Delete" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>

            {/* Edit organisation regions */}

            <div className="form-row">
              <div
                className="modal fade region"
                id="viewRegionModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="viewRegionModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h2 className="modal-title" id="valuesModalLabel">
                        Edit Organisation Regions
                      </h2>
                    </div>

                    <div className="modal-body">
                      <div className="form-column-modal">
                        <div className="form-row">
                          <div className="form-cell">
                            <label>
                              Number
                              <span className="form-mandatory">*</span>
                            </label>
                            <input
                              onChange={e => handleChange(e, 'selectedRegion')}
                              name="number"
                              className="input-text"
                            />
                          </div>
                          <div className="form-cell">
                            <label>
                              Region Name
                              <span className="form-mandatory">*</span>
                            </label>
                            <input
                              onChange={e => handleChange(e, 'selectedRegion')}
                              name="name"
                              value={selectedRegion.name}
                              className="input-text"
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-cell">
                            <label>
                              Address Line 1
                              <span className="form-mandatory">*</span>
                            </label>
                            <input
                              onChange={e =>
                                handleChangeSelectedRegions(e, 'addressMessage')
                              }
                              name="addressLine1"
                              value={selectedRegion.addressMessage.addressLine1}
                              className="input-text"
                            />
                          </div>
                          <div className="form-cell">
                            <label>Address Line 2</label>
                            <input
                              onChange={e =>
                                handleChangeSelectedRegions(e, 'addressMessage')
                              }
                              name="addressLine2"
                              value={selectedRegion.addressMessage.addressLine2}
                              className="input-text"
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-cell">
                            <label>Address Line 3</label>
                            <input
                              onChange={e =>
                                handleChangeSelectedRegions(e, 'addressMessage')
                              }
                              name="addressLine3"
                              value={selectedRegion.addressMessage.addressLine3}
                              className="input-text"
                            />
                          </div>
                          <div className="form-cell">
                            <label>State</label>
                            <input
                              onChange={e =>
                                handleChangeSelectedRegions(e, 'addressMessage')
                              }
                              name="state"
                              value={selectedRegion.addressMessage.state}
                              className="input-text"
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-cell">
                            <label>Post Code</label>
                            <input
                              onChange={e =>
                                handleChangeSelectedRegions(e, 'addressMessage')
                              }
                              name="postCode"
                              value={selectedRegion.addressMessage.postCode}
                              className="input-text"
                            />
                          </div>
                          <div className="form-cell">
                            <label>
                              Country
                              <span className="form-mandatory">*</span>
                            </label>
                            <select
                              onChange={e =>
                                handleChangeSelectedRegions(e, 'addressMessage')
                              }
                              name="country"
                              value={selectedRegion.addressMessage.country}
                            >
                              <option
                                value="default"
                                disabled="disabled"
                              ></option>
                              {countries.map((country, i) => (
                                <option key={i} value={country.code}>
                                  {' '}
                                  {country.longName}{' '}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        onClick={e => updateRegion(e, selectedRegion)}
                        type="button"
                        disabled={selectedRegion.name === '' ? true : false}
                        className="btn btn-primary add"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h2>Mission and Vision</h2>
            <div className="form-row double">
              <div className="form-column">
                <label>Mission</label>
                <textarea
                  rows="4"
                  onChange={e => handleChangeMessage(e, 'organisationMessage')}
                  name="mission"
                ></textarea>
              </div>
              <div className="form-column">
                <label>Vision</label>
                <textarea
                  rows="4"
                  onChange={e => handleChangeMessage(e, 'organisationMessage')}
                  name="vision"
                ></textarea>
              </div>
            </div>

            <div className="form-row">
              <div className="form-cell">
                <h2>Values</h2>
                <button
                  type="button"
                  className="icon add"
                  data-toggle="modal"
                  data-target="#valuesModal"
                >
                  <label className="add-button-text">Add New</label>
                  <img src="../../assets/add.svg" alt="add" />
                </button>
              </div>

              <div className="form-row">
                <div
                  className="modal fade region"
                  id="valuesModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="valuesModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h2 className="modal-title" id="valuesModalLabel">
                          Add Values
                        </h2>
                      </div>

                      <div className="modal-body">
                        <div className="form-column-modal">
                          <div className="form-row">
                            <div className="form-cell">
                              <label>
                                Ref
                                <span className="form-mandatory">*</span>
                              </label>
                              <input
                                onChange={e => handleChange(e, 'values')}
                                name="ref"
                                className="input-text"
                                value={values.ref}
                              />
                            </div>
                            <div className="form-cell">
                              <label>
                                Value Name
                                <span className="form-mandatory">*</span>
                              </label>
                              <input
                                onChange={e => handleChange(e, 'values')}
                                name="name"
                                className="input-text"
                                value={values.name}
                              />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-cell">
                              <label>
                                Description
                                <span className="form-mandatory">*</span>
                              </label>
                              <textarea
                                rows="4"
                                onChange={e => handleChange(e, 'values')}
                                name="description"
                                value={values.description}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          onClick={addValues}
                          type="button"
                          disabled={
                            values.description === '' || values.name === ''
                              ? true
                              : false
                          }
                          className="btn btn-primary add"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Table size="sm">
              <thead>
                {orgValues && orgValues.length >= 1 ? (
                  <tr>
                    <th>Ref</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th></th>
                  </tr>
                ) : (
                  <tr></tr>
                )}
              </thead>
              {orgValues.map((orgValue, index) => (
                <tbody>
                  <tr key={index}>
                    <td>{orgValue.ref}</td>
                    <td>{orgValue.name}</td>
                    <td>{orgValue.description}</td>
                    <td className="table-button-container">
                      <button
                        onClick={() => viewValue(orgValue, index)}
                        data-toggle="modal"
                        data-target="#viewValuesModal"
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src="../../assets/Edit_Icon.svg" alt="edit" />
                      </button>
                      <button
                        onClick={() => deleteValue(index)}
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src="../../assets/Delete_Icon.svg" alt="Delete" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>

            <div className="form-row">
              <div
                className="modal fade region"
                id="viewValuesModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="viewValuesModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h2 className="modal-title" id="valuesModalLabel">
                        Edit Values
                      </h2>
                    </div>

                    <div className="modal-body">
                      <div className="form-column-modal">
                        <div className="form-row">
                          <div className="form-cell">
                            <label>
                              Ref
                              <span className="form-mandatory">*</span>
                            </label>
                            <input
                              onChange={e => handleChange(e, 'selectedValue')}
                              name="ref"
                              className="input-text"
                              value={selectedValue.ref}
                            />
                          </div>
                          <div className="form-cell">
                            <label>
                              Name
                              <span className="form-mandatory">*</span>
                            </label>
                            <input
                              onChange={e => handleChange(e, 'selectedValue')}
                              name="name"
                              value={selectedValue.name}
                              className="input-text"
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-cell">
                            <label>
                              Description
                              <span className="form-mandatory">*</span>
                            </label>
                            <textarea
                              rows="4"
                              onChange={e => handleChange(e, 'selectedValue')}
                              name="description"
                              value={selectedValue.description}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        onClick={e => updateValue(e, selectedValue)}
                        type="button"
                        disabled={
                          selectedValue.description === '' ||
                          selectedValue.name === ''
                            ? true
                            : false
                        }
                        className="btn btn-primary add"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h2>System Admin Details</h2>
            <div className="form-row">
              <div className="form-column">
                <label>
                  First Name<span className="form-mandatory">*</span>
                </label>
                <input
                  onChange={e => handleChangeMessage(e, 'userMessage')}
                  name="firstName"
                  className="input-text"
                ></input>
                {errors?.firstName && errors.firstName.length > 0 && (
                  <span className="error">{errors.firstName}</span>
                )}
              </div>
              <div className="form-column">
                <label>
                  Last Name<span className="form-mandatory">*</span>
                </label>
                <input
                  onChange={e => handleChangeMessage(e, 'userMessage')}
                  name="lastName"
                  className="input-text"
                ></input>
                {errors.lastName && errors.lastName.length > 0 && (
                  <span className="error">{errors.lastName}</span>
                )}
              </div>
              <div className="form-column">
                <label>
                  Email Address
                  <span className="form-mandatory">*</span>
                </label>
                <input
                  onChange={e => handleChangeMessage(e, 'userMessage')}
                  name="emailAddress"
                  className="input-text"
                />
                {errors.emailAddress && errors.emailAddress.length > 0 && (
                  <span className="error">{errors.emailAddress}</span>
                )}
              </div>
            </div>
          </div>

          {/*<LicenceFormInput />*/}

          <div className="submit-container">
            <div className="terms-and-conditions-container">
              <div
                style={{ alignItems: 'center' }}
                className="checkbox-container"
              >
                <input
                  className="checkbox"
                  onChange={handleCheckboxChange}
                  name="agreeTandC"
                  type="checkbox"
                />
                <div className="checkbox-terms-and-conditions">
                  I agree to Conrad RP{' '}
                  <span className="terms-and-conditions" onClick={handleOpen}>
                    Terms and Conditions
                  </span>{' '}
                  and{' '}
                  <span className="terms-and-conditions" onClick={handleOpen}>
                    Privacy Policy
                  </span>
                </div>
              </div>
              {errors.termsAndConditions &&
                errors.termsAndConditions.length > 0 && (
                  <span className="error">{errors.termsAndConditions}</span>
                )}
            </div>
            <button className="btn primary">Submit</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default AccountCreationFormInput
