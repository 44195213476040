import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import {
  Form,
  Container,
  Row,
  Col,
  Button,
  Table,
  CloseButton,
} from 'react-bootstrap'
import { Rectangle } from 'draw-shape-reactjs'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Steps, Select } from 'antd'
import AddActionPlanModal from '../presentational/AddActionPlanModal.jsx'
import axios from 'axios'
import TreatmentPlanTable from '../presentational/TreatmentPlanTable.jsx'
import ViewMatrix from '../presentational/ViewMatrix.jsx'
import ViewImpact from '../presentational/ViewImpact.jsx'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Auth from '../../lib/Auth.js'
import * as moment from 'moment'
import Swal from 'sweetalert2'
import sortBy from 'lodash/fp/sortBy'

// import { isThisSecond } from 'date-fns/esm'
import EditAfterAddModal from '../presentational/EditAfterAddModal.jsx'
import '../../style/main/date-picker.scss'
import { isEmpty } from 'lodash'
import toInteger from 'lodash/fp/toInteger'
import filter from 'lodash/fp/filter'

const steps = [
  {
    title: 'Identification',
    content: 'Identification-Description',
  },
  {
    title: 'Gross',
    content: 'Gross',
  },
  {
    title: 'Risk Treatments',
    content: 'Risk_Treatments',
  },
  {
    title: 'Net Assessment',
    content: 'Risk-Net-Assessment',
  },
  {
    title: 'Target & Comments',
    content: 'Target-Comments',
  },
]
//Risk Treatments

class MyRisksForm extends Component {
  grossHighestRAGColor
  grossHighestRAGNumber
  netHighestRAGColor
  netHighestRAGNumber
  targetHighestRAGColor
  targetHighestRAGNumber

  constructor(props) {
    super(props)
    this.actionPlanClickHandler = this.actionPlanClickHandler.bind(this)
    // this.viewMatrixClickHandler = this.viewMatrixClickHandler.bind(this)
    // this.viewImpactClickHandler = this.viewImpactClickHandler.bind(this)
    this.grossHighestRAGNumber = null
    this.grossHighestRAGColor = null
    this.state = {
      current: 0,
      showModal: false,
      //--------causes, event, impact....remove duplicate
      riskEvent: '',
      riskImpact: '',
      riskCauses: '',
      //-----------states passed in post method---------------------------
      riskType: '',
      riskCategory: '',
      riskstatus: '',
      reviewDate: moment().format('DD/MM/YY'),
      freqReview: '',
      selectedUncertainty: 'POSITIVE',
      impactDim: 0,
      initialProbabGross: 0,
      initialProbabNet: 0,
      initialProbabTarget: 0,
      initialImpactGross: 0,
      initialImpactNet: 0,
      initialImpactTarget: 0,

      //--------------------------------------------------------
      netScore: '',

      treatments: 0,
      treatmentStatus: '',

      riskStatus: [],
      impactDimentions: [],
      riskTypes: [],
      riskCategories: [],
      reviewFrequency: [],
      impactDescriptions: [],
      probabilities: [],
      testImpact: '',
      uncertaintyType: '',
      descriptionFromChild: '',
      estimatedCostFromChild: 0,
      dueDateFromChild: '',
      actionPriorityFromChild: '',
      actionStatusFromChild: '',
      ownerFromChild: '',
      completedFromChild: 0,
      treatmentTableData: [],
      showViewMatrix: false,
      showViewImpact: false,
      selectedProbabilityGross: '',
      selectedProbabilityNet: '',
      selectedProbabilityTarget: '',
      descProbability: '',
      ragColor: '#fff',
      ragNumber: '0',
      riskRating: '',
      grossProbab: 0,
      grossMin: 0,
      grossMax: 0,
      grossLikely: 0,
      grossValue: 0,
      grossBasis: '',
      netProbab: 0,
      netMin: 0,
      netMax: 0,
      netLikely: 0,
      netValue: 0,
      netBasis: '',
      targetProbab: 0,
      targetMin: 0,
      targetMax: 0,
      targetLikely: 0,
      targetValue: 0,
      targetBasis: '',
      grossAssessmentEvalTable: [],
      netAssessmentEvalTable: [],
      targetAssessmentEvalTable: [],
      grossNegAssessmentEvalTable: [],
      netNegAssessmentEvalTable: [],
      targetNegAssessmentEvalTable: [],
      grossHighestRAGNumber: 0,
      grossHighestRAGColor: '#fff',
      netHighestRAGNumber: 0,
      netHighestRAGColor: '#fff',
      targetHighestRAGNumber: 0,
      targetHighestRAGColor: '#fff',
      colorMan: true,
      actionEffectiveness: [],
      actionOwner: '',
      actionPriorities: [],
      noPlanYet: true,
      orgId: 0,
      negativeProbabilityImpacts: [],
      positiveProbabilityImpacts: [],
      positiveImpacts: [],
      negativeImpacts: [],
      usersList: [],
      objectivesData: [],
      selectedObjectives: [],
      departments: [],
      //NEW STATES START HERE
      myriskCauses: '',
      myriskDescription: '',
      myriskImpact: '',
      selectedIdentifier: 0,
      selectedComment: '',
      // selectedRiskStatus: 0,
      // selectedRiskcategory: '',
      riskIdsArray: [],
      riskFormId: 0,
      selectedDepartment: 0,
      // treatmentTableData: [],
      showEditModal: false,
      treatmentSentToEdit: [],
      riskId: 0,
      riskOwner: 0,
      selectedTreatment: {},
      currentDimension: '',
    }
    this.sendToParent = this.sendToParent.bind(this)
    this.probabSelectNet = this.probabSelectNet.bind(this)
    this.probabSelectGross = this.probabSelectGross.bind(this)
    this.probabSelectTarget = this.probabSelectTarget.bind(this)
    this.setRiskRating = this.setRiskRating.bind(this)
    this.saveClickHandler = this.saveClickHandler.bind(this)
  }

  componentWillMount() {
    let token = Auth.getToken()
    if (!token) {
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    this.setState({
      orgId: JSON.parse(window.atob(base64)).Org,
    })
  }

  firstPlanAdded = () => {
    this.setState({
      noPlanYet: false,
    })
  }

  actionPlanClickHandler = () => {
    this.setState({
      showModal: true,
    })
  }

  viewMatrixClickHandler = () => {
    this.setState({
      showViewMatrix: true,
    })
  }

  viewImpactClickHandler = () => {
    this.setState({
      showViewImpact: true,
    })
  }

  sendToParent(e) {
    let stateData = {}
    e.preventDefault()
    if (e.target.name === 'riskCategory') {
      let riskTypes = this.filterRiskTask(e.target.value)
      stateData = {
        ...stateData,
        riskTypes: riskTypes,
      }
    }
    stateData = {
      ...stateData,
      [e.target.name]: e.target.value,
    }

    this.setState({
      ...stateData,
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  setGrossProbab(e) {
    this.setState({
      grossProbab: e.target.value,
    })
  }

  setGrossMax(e) {
    this.setState({
      grossMax: e.target.value,
    })
  }

  setGrossMin(e) {
    this.setState({
      grossMin: e.target.value,
    })
  }

  setGrossLikely(e) {
    let grossLikely = e.target.value
    if (toInteger(this.state.grossMax) >= toInteger(grossLikely)) {
      this.setState({
        grossLikely: e.target.value,
      })
    } else {
      this.setState({
        grossLikely: this.state.grossMax,
      })
    }
  }

  setNetProbab(e) {
    this.setState({
      netProbab: e.target.value,
    })
  }

  setNetMax(e) {
    this.setState({
      netMax: e.target.value,
    })
  }

  setNetMin(e) {
    this.setState({
      netMin: e.target.value,
    })
  }

  setNetLikely(e) {
    let netLikely = e.target.value
    if (toInteger(this.state.netMax) >= toInteger(netLikely)) {
      this.setState({
        netLikely: e.target.value,
      })
    } else {
      this.setState({
        netLikely: this.state.netMax,
      })
    }
  }

  setTargetProbab(e) {
    this.setState({
      targetProbab: e.target.value,
    })
  }

  setTargetMax(e) {
    this.setState({
      targetMax: e.target.value,
    })
  }

  setTargetMin(e) {
    this.setState({
      targetMin: e.target.value,
    })
  }

  setTargetLikely(e) {
    let targetLikely = e.target.value
    if (toInteger(this.state.targetMax) >= toInteger(targetLikely)) {
      this.setState({
        targetLikely: e.target.value,
      })
    } else {
      this.setState({
        targetLikely: this.state.targetMax,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.descriptionFromChild !== this.state.descriptionFromChild ||
      prevState.estimatedCostFromChild !== this.state.estimatedCostFromChild ||
      prevState.dueDateFromChild != this.state.dueDateFromChild ||
      prevState.actionPriorityFromChild !==
        this.state.actionPriorityFromChild ||
      prevState.actionStatusFromChild !== this.state.actionStatusFromChild ||
      prevState.ownerFromChild !== this.state.ownerFromChild ||
      prevState.reviewDateFromChild !== this.state.reviewDateFromChild ||
      prevState.completedFromChild !== this.state.completedFromChild
    ) {
      this.setState({
        treatmentTableData: [
          ...this.state.treatmentTableData,
          {
            ref: 'next ref',
            description: this.state.descriptionFromChild,
            ec: this.state.estimatedCostFromChild,
            dd: this.state.dueDateFromChild,
            ap: this.state.actionPriorityFromChild,
            as: this.state.actionStatusFromChild,
            completed: this.state.completedFromChild,
            owner: this.state.ownerFromChild,
          },
        ],
        treatments: prevState.treatments + 1,
      })
    }
  }

  fetchData() {
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/impactDimensions`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            impactDimentions: res.data,
            // impactDim: res.data[0].id,
          })
        }
      }),
      axios
        .get(BASE_URL + `/organisations/${this.state.orgId}/riskStatuses`, {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              riskStatus: res.data,
              riskstatus: res.data[0].id,
            })
          }
        })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/probabilities`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            probabilities: res.data,
            selectedProbabilityGross: res.data[0].id,
            selectedProbabilityNet: res.data[0].id,
            selectedProbabilityTarget: res.data[0].id,
            // initialProbabGross: res.data[0].id,
            // initialProbabNet: res.data[0].id,
            // initialProbabTarget: res.data[0].id,
          })
          // this.renderGrossRAG()
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/riskTypes`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            riskTypeList: res.data,
          })
        }
      })
    //  TODO
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/riskCategories`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            riskCategories: res.data,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/frequencyOfReviews`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            reviewFrequency: res.data,
            freqReview: res.data[0].id,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/impactDescriptions`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            impactDescriptions: res.data,
            initialImpactGross: res.data[0].riskImpact.id,
            initialImpactNet: res.data[0].riskImpact.id,
            initialImpactTarget: res.data[0].riskImpact.id,
          })
        }
      })
    //Call this indise action plan modal
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/priorities`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            actionPriorities: res.data,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/effectiveness`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            actionEffectiveness: res.data,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/probabilityImpacts`, {
        params: {
          impactType: 'NEGATIVE',
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            negativeProbabilityImpacts: res.data,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/probabilityImpacts`, {
        params: {
          impactType: 'POSITIVE',
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            positiveProbabilityImpacts: res.data,
          })
        }
      })

    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/impacts`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const positiveImpacts = res.data.filter(
            (item) => item.impactType.name === 'POSITIVE'
          )
          const negativeImpacts = res.data.filter(
            (item) => item.impactType.name === 'NEGATIVE'
          )
          this.setState({
            negativeImpacts,
            positiveImpacts,
            loading: false,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/users`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            usersList: res.data,
            actionOwner: res.data[0].id,
            riskOwner: res.data[0].id,
          })
        }
      })
    axios
      .get(BASE_URL + `/objectives`, {
        params: {
          organisation: this.state.orgId,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            objectivesData: res.data,
          })
        }
      })
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/departments`, {
        params: {
          parentDepartment: 0,
          departmentHead: 0,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            departments: res.data,
            selectedDepartment: res.data[0].id,
          })
        }
      })
  }

  getriskIdsFromChild(id) {
    this.setState({
      riskIdsArray: id,
    })
  }

  getDepartments() {
    const { departments } = this.state

    return (
      !isEmpty(sortBy('name')(departments)) &&
      sortBy('name')(departments).map((item) => {
        return <option value={item.id}>{item.name}</option>
      })
    )
  }

  setDepartment = (e) => {
    this.setState({
      selectedDepartment: e.target.value,
      riskOwner: this.state.usersList.filter(
        (user) => user.department?.id == e.target.value
      )?.[0]?.id,
    })
  }

  renderUsers() {
    const { usersList } = this.state
    return (
      !isEmpty(sortBy('fullName')(usersList)) &&
      sortBy('fullName')(usersList).map((item) => {
        return <option value={item.id}>{item.name}</option>
      })
    )
  }

  renderOwners() {
    const { usersList } = this.state
    return (
      !isEmpty(sortBy('name')(usersList)) &&
      sortBy('name')(usersList)
        .filter((user) => user.department?.id == this.state.selectedDepartment)
        .map((item) => {
          return <option value={item.id}>{item.name}</option>
        })
    )
  }

  renderRiskStatus() {
    const { riskStatus } = this.state
    return (
      !isEmpty(sortBy('name')(riskStatus)) &&
      sortBy('name')(riskStatus).map((item) => {
        return <option value={item.id}>{item.name}</option>
      })
    )
  }

  renderRiskType() {
    const { riskTypes } = this.state
    return (
      !isEmpty(sortBy('name')(riskTypes)) &&
      sortBy('name')(riskTypes).map((item) => {
        return <option value={item.id}>{item.name}</option>
      })
    )
  }

  renderRiskCategory() {
    const { riskCategories } = this.state
    return (
      !isEmpty(sortBy('name')(riskCategories)) &&
      sortBy('name')(riskCategories).map((item) => {
        return <option value={item.id}>{item.name}</option>
      })
    )
  }

  renderFreqofReview() {
    return this.state.reviewFrequency.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  renderProbabilities() {
    return this.state.probabilities.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getUnique(a) {
    return a.sort().filter(function (item, pos, ary) {
      return !pos || item != ary[pos - 1]
    })
  }

  getPositiveRating() {
    return this.state.positiveImpacts.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  getNegativeRating() {
    return this.state.negativeImpacts.map((item) => {
      return <option value={item.id}>{item.name}</option>
    })
  }

  renderImpactDimensions() {
    return this.state.impactDimentions.map((item) => {
      return (
        <li>
          <input
            type="text"
            value={item.name}
            readOnly
            style={{
              height: '38px',
              backgroundColor: 'white',
              border: '1px solid rgb(48, 70, 106)',
              borderRadius: '5px',
            }}
          />
        </li>
      )
    })
  }

  //gross rag returns
  renderGrossRAG() {
    let higherNumber = 0
    let higherColor = 0
    const grossRagItems = this.state.impactDimentions.map((item, index) => {
      const data = this.getRAGData(item.id)
      if (data.ragNumber > higherNumber) {
        higherNumber = data.ragNumber
        higherColor = data.ragColor
      }
      return (
        <li
          style={{
            backgroundColor: data?.ragColor,
            width: '60px',
            height: '38px',
            paddingBottom: '5px',
            border: '3px solid white',
            textAlign: 'center',
          }}
        >
          {data?.ragNumber}
        </li>
      )
    })

    this.grossHighestRAGNumber = higherNumber
    this.grossHighestRAGColor = higherColor
    return grossRagItems
  }

  //net RAG returns
  renderNetRAG() {
    let higherNumber = 0
    let higherColor = 0
    const netRagItems = this.state.impactDimentions.map((item, index) => {
      const data = this.getNetRAGData(item.id)
      if (data.ragNumber > higherNumber) {
        higherNumber = data.ragNumber
        higherColor = data.ragColor
      }
      return (
        <li
          style={{
            backgroundColor: data?.ragColor,
            width: '60px',
            height: '38px',
            paddingBottom: '5px',
            border: '3px solid white',
            textAlign: 'center',
          }}
        >
          {data?.ragNumber}
        </li>
      )
    })

    this.netHighestRAGNumber = higherNumber
    this.netHighestRAGColor = higherColor
    return netRagItems
  }

  //target RAG returns
  renderTargetRAG() {
    let higherNumber = 0
    let higherColor = 0
    const targetRagItems = this.state.impactDimentions.map((item, index) => {
      const data = this.getTargetRAGData(item.id)
      if (data.ragNumber > higherNumber) {
        higherNumber = data.ragNumber
        higherColor = data.ragColor
      }
      return (
        <li
          style={{
            backgroundColor: data?.ragColor,
            width: '60px',
            height: '38px',
            paddingBottom: '5px',
            border: '3px solid white',
            textAlign: 'center',
          }}
        >
          {data?.ragNumber}
        </li>
      )
    })

    this.targetHighestRAGNumber = higherNumber
    this.targetHighestRAGColor = higherColor
    return targetRagItems
  }

  //setting the rag number and color states for gross
  getRAGData(impactDimensionId) {
    if (this.state.colorMan == true) {
      const impactItem =
        this.state.grossAssessmentEvalTable.filter(
          (item) => item.impactId === impactDimensionId
        )[0] === undefined
          ? null
          : this.state.grossAssessmentEvalTable.filter(
              (item) => item.impactId === impactDimensionId
            )[0]
      if (impactItem == null) {
        return { ragNumber: 0, ragColor: 'white' }
      } else {
        const colorItem = this.state.positiveProbabilityImpacts.filter(
          (item) =>
            this.state.selectedProbabilityGross == item.riskProbability.id &&
            impactItem.rating == item.riskImpact.id
        )

        if (colorItem[0] == undefined) {
          return { ragNumber: 0, ragColor: 'white' }
        } else {
          return {
            ragNumber:
              colorItem[0].riskImpact.score *
              colorItem[0].riskProbability.multiplier,
            ragColor: `#${colorItem[0].colour}`,
          }
        }
      }
    }

    if (this.state.colorMan == false) {
      const impactItem =
        this.state.grossNegAssessmentEvalTable.filter(
          (item) => item.impactId === impactDimensionId
        )[0] === undefined
          ? null
          : this.state.grossNegAssessmentEvalTable.filter(
              (item) => item.impactId === impactDimensionId
            )[0]
      if (impactItem == null) {
        return { ragNumber: 0, ragColor: 'white' }
      } else {
        const colorItem = this.state.negativeProbabilityImpacts.filter(
          (item) =>
            this.state.selectedProbabilityGross == item.riskProbability.id &&
            impactItem.rating == item.riskImpact.id
        )

        if (colorItem[0] == undefined) {
          return { ragNumber: 0, ragColor: 'white' }
        } else {
          return {
            ragNumber:
              colorItem[0].riskImpact.score *
              colorItem[0].riskProbability.multiplier,
            ragColor: `#${colorItem[0].colour}`,
          }
        }
      }
    }
  }

  //setting the rag number and color states for net
  getNetRAGData(impactDimensionId) {
    if (this.state.colorMan == true) {
      const impactItem =
        this.state.netAssessmentEvalTable.filter(
          (item) => item.impactId === impactDimensionId
        )[0] === undefined
          ? null
          : this.state.netAssessmentEvalTable.filter(
              (item) => item.impactId === impactDimensionId
            )[0]
      if (impactItem == null) {
        return { ragNumber: 0, ragColor: 'white' }
      } else {
        const colorItem = this.state.positiveProbabilityImpacts.filter(
          (item) =>
            this.state.selectedProbabilityNet == item.riskProbability.id &&
            impactItem.rating == item.riskImpact.id
        )

        if (colorItem[0] == undefined) {
          return { ragNumber: 0, ragColor: 'white' }
        } else {
          return {
            ragNumber:
              colorItem[0].riskImpact.score *
              colorItem[0].riskProbability.multiplier,
            ragColor: `#${colorItem[0].colour}`,
          }
        }
      }
    }

    if (this.state.colorMan == false) {
      const impactItem =
        this.state.netNegAssessmentEvalTable.filter(
          (item) => item.impactId === impactDimensionId
        )[0] === undefined
          ? null
          : this.state.netNegAssessmentEvalTable.filter(
              (item) => item.impactId === impactDimensionId
            )[0]
      if (impactItem == null) {
        return { ragNumber: 0, ragColor: 'white' }
      } else {
        const colorItem = this.state.negativeProbabilityImpacts.filter(
          (item) =>
            this.state.selectedProbabilityNet == item.riskProbability.id &&
            impactItem.rating == item.riskImpact.id
        )

        if (colorItem[0] == undefined) {
          return { ragNumber: 0, ragColor: 'white' }
        } else {
          return {
            ragNumber:
              colorItem[0].riskImpact.score *
              colorItem[0].riskProbability.multiplier,
            ragColor: `#${colorItem[0].colour}`,
          }
        }
      }
    }
  }

  //setting the rag number and color states for target
  getTargetRAGData(impactDimensionId) {
    if (this.state.colorMan == true) {
      const impactItem =
        this.state.targetAssessmentEvalTable.filter(
          (item) => item.impactId === impactDimensionId
        )[0] === undefined
          ? null
          : this.state.targetAssessmentEvalTable.filter(
              (item) => item.impactId === impactDimensionId
            )[0]
      if (impactItem == null) {
        return { ragNumber: 0, ragColor: 'white' }
      } else {
        const colorItem = this.state.positiveProbabilityImpacts.filter(
          (item) =>
            this.state.selectedProbabilityTarget == item.riskProbability.id &&
            impactItem.rating == item.riskImpact.id
        )

        if (colorItem[0] == undefined) {
          return { ragNumber: 0, ragColor: 'white' }
        } else {
          return {
            ragNumber:
              colorItem[0].riskImpact.score *
              colorItem[0].riskProbability.multiplier,
            ragColor: `#${colorItem[0].colour}`,
          }
        }
      }
    }

    if (this.state.colorMan == false) {
      const impactItem =
        this.state.targetNegAssessmentEvalTable.filter(
          (item) => item.impactId === impactDimensionId
        )[0] === undefined
          ? null
          : this.state.targetNegAssessmentEvalTable.filter(
              (item) => item.impactId === impactDimensionId
            )[0]
      if (impactItem == null) {
        return { ragNumber: 0, ragColor: 'white' }
      } else {
        const colorItem = this.state.negativeProbabilityImpacts.filter(
          (item) =>
            this.state.selectedProbabilityTarget == item.riskProbability.id &&
            impactItem.rating == item.riskImpact.id
        )

        if (colorItem[0] == undefined) {
          return { ragNumber: 0, ragColor: 'white' }
        } else {
          return {
            ragNumber:
              colorItem[0].riskImpact.score *
              colorItem[0].riskProbability.multiplier,
            ragColor: `#${colorItem[0].colour}`,
          }
        }
      }
    }
  }

  //Gross risk rating
  setRiskRating(impactDimension, e) {
    e.persist()
    let table = [...this.state.grossAssessmentEvalTable]
    e.preventDefault()
    if (
      table.filter((item) => item.impactId === impactDimension.id)[0] ===
      undefined
    ) {
      table.push({ impactId: impactDimension.id, rating: e.target.value })
      this.setState({
        grossAssessmentEvalTable: table,
      })
    } else {
      let newTable = table.map((item) => {
        if (item.impactId == impactDimension.id) {
          let newItem = {
            impactId: impactDimension.id,
            rating: e.target.value,
          }
          return newItem
        } else {
          return item
        }
      })
      this.setState({
        grossAssessmentEvalTable: newTable,
      })
    }
  }

  //negative gross risk rating
  setNegRiskRating(impactDimension, e) {
    e.persist()
    let table = [...this.state.grossNegAssessmentEvalTable]
    e.preventDefault()
    if (
      table.filter((item) => item.impactId === impactDimension.id)[0] ===
      undefined
    ) {
      table.push({ impactId: impactDimension.id, rating: e.target.value })
      this.setState({
        grossNegAssessmentEvalTable: table,
      })
    } else {
      let newTable = table.map((item) => {
        if (item.impactId == impactDimension.id) {
          let newItem = {
            impactId: impactDimension.id,
            rating: e.target.value,
          }
          return newItem
        } else {
          return item
        }
      })
      this.setState({
        grossNegAssessmentEvalTable: newTable,
      })
    }
  }

  //Net risk rating
  setNetRiskRating(impactDimension, e) {
    e.persist()
    let table = [...this.state.netAssessmentEvalTable]
    e.preventDefault()
    if (
      table.filter((item) => item.impactId === impactDimension.id)[0] ===
      undefined
    ) {
      table.push({ impactId: impactDimension.id, rating: e.target.value })
      this.setState({
        netAssessmentEvalTable: table,
      })
    } else {
      let newTable = table.map((item) => {
        if (item.impactId == impactDimension.id) {
          let newItem = {
            impactId: impactDimension.id,
            rating: e.target.value,
          }
          return newItem
        } else {
          return item
        }
      })

      this.setState({
        netAssessmentEvalTable: newTable,
      })
    }
  }

  setNegNetRiskRating(impactDimension, e) {
    e.persist()
    let table = [...this.state.netNegAssessmentEvalTable]
    e.preventDefault()
    if (
      table.filter((item) => item.impactId === impactDimension.id)[0] ===
      undefined
    ) {
      table.push({ impactId: impactDimension.id, rating: e.target.value })
      this.setState({
        netNegAssessmentEvalTable: table,
      })
    } else {
      let newTable = table.map((item) => {
        if (item.impactId == impactDimension.id) {
          let newItem = {
            impactId: impactDimension.id,
            rating: e.target.value,
          }
          return newItem
        } else {
          return item
        }
      })
      this.setState({
        netNegAssessmentEvalTable: newTable,
      })
    }
  }

  setTargetRiskRating(impactDimension, e) {
    e.persist()
    let table = [...this.state.targetAssessmentEvalTable]
    e.preventDefault()
    if (
      table.filter((item) => item.impactId === impactDimension.id)[0] ===
      undefined
    ) {
      table.push({ impactId: impactDimension.id, rating: e.target.value })
      this.setState({
        targetAssessmentEvalTable: table,
      })
    } else {
      let newTable = table.map((item) => {
        if (item.impactId == impactDimension.id) {
          let newItem = {
            impactId: impactDimension.id,
            rating: e.target.value,
          }
          return newItem
        } else {
          return item
        }
      })

      this.setState({
        targetAssessmentEvalTable: newTable,
      })
    }
  }

  setNegTargetRiskRating(impactDimension, e) {
    e.persist()
    let table = [...this.state.targetNegAssessmentEvalTable]
    e.preventDefault()
    if (
      table.filter((item) => item.impactId === impactDimension.id)[0] ===
      undefined
    ) {
      table.push({ impactId: impactDimension.id, rating: e.target.value })
      this.setState({
        targetNegAssessmentEvalTable: table,
      })
    } else {
      let newTable = table.map((item) => {
        if (item.impactId == impactDimension.id) {
          let newItem = {
            impactId: impactDimension.id,
            rating: e.target.value,
          }
          return newItem
        } else {
          return item
        }
      })
      this.setState({
        targetNegAssessmentEvalTable: newTable,
      })
    }
  }

  probabSelectNet(e) {
    this.setState(
      {
        selectedProbabilityNet: e.target.value,
        netHighestRAGNumber: 0,
        netHighestRAGColor: '#fff',
      },
      () => this.setDescription()
    )
  }

  probabSelectGross(e) {
    this.setState(
      {
        selectedProbabilityGross: e.target.value,
        grossHighestRAGNumber: 0,
        grossHighestRAGColor: '#fff',
      },
      () => this.setDescription()
    )
  }

  probabSelectTarget(e) {
    this.setState(
      {
        selectedProbabilityTarget: e.target.value,
        targetHighestRAGNumber: 0,
        targetHighestRAGColor: '#fff',
      },
      () => this.setDescription()
    )
  }

  setDescription() {
    this.state.probabilities.map((item) => {
      if (item.name === this.state.selectedProbability) {
        this.setState({
          descProbability: item.description,
        })
      }
    })
  }

  calledFromChild(desc, ec, dd, ap, as, ow, comp) {
    this.setState({
      descriptionFromChild: desc,
      estimatedCostFromChild: ec,
      dueDateFromChild: dd,
      actionPriorityFromChild: ap,
      actionStatusFromChild: as,
      ownerFromChild: ow,
      completedFromChild: comp,
    })
  }

  calculateValue() {
    return (
      ((Number(this.state.grossMin) +
        Number(this.state.grossMax) +
        Number(this.state.grossLikely)) /
        3) *
      (Number(this.state.grossProbab) / 100)
    )
  }

  calculateValueNet() {
    return (
      ((Number(this.state.netMin) +
        Number(this.state.netMax) +
        Number(this.state.netLikely)) /
        3) *
      (Number(this.state.netProbab) / 100)
    )
  }

  calculateValueTarget() {
    return (
      ((Number(this.state.targetMin) +
        Number(this.state.targetMax) +
        Number(this.state.targetLikely)) /
        3) *
      (Number(this.state.targetProbab) / 100)
    )
  }

  handleColorChange = () => {
    this.setState({
      colorMan: !this.state.colorMan,
      // grossHighestRAGColor: '#fff',
      // grossHighestRAGNumber: 0,
      // netHighestRAGColor: '#fff',
      // netHighestRAGNumber: 0,
      // targetHighestRAGColor: '#fff',
      // targetHighestRAGNumber: 0,
    })
  }

  handleDateChange = (e) => {
    this.setState({
      reviewDate: moment(e).format('DD/MM/YY'),
    })
  }

  getObjectives() {
    const { Option } = Select
    const { objectivesData } = this.state
    // this.state.departments
    let filterObjective =
      !isEmpty(objectivesData) &&
      objectivesData.filter((data) => {
        return (
          toInteger(this.state.selectedDepartment) ===
          toInteger(data.department)
        )
      })
    return !isEmpty(filterObjective)
      ? sortBy('description')(filterObjective).map((item) => {
          return <Option value={item.id}>{item.description}</Option>
        })
      : ''
  }

  onSaveClick() {}

  //get gross message body
  getRiskAssessmentMessages = () => {
    let grossMessageObject = []

    if (this.state.colorMan) {
      this.state.grossAssessmentEvalTable.forEach((elem) => {
        grossMessageObject.push({
          domains: elem.impactId,
          impacts: elem.rating,
        })
      })
    } else {
      this.state.grossNegAssessmentEvalTable.forEach((elem) => {
        grossMessageObject.push({
          domains: elem.impactId,
          impacts: elem.rating,
        })
      })
    }
    return grossMessageObject
  }

  //get net message body
  getNetRiskAssessmentMessages = () => {
    let netMessageObject = []

    if (this.state.colorMan) {
      this.state.netAssessmentEvalTable.forEach((elem) => {
        netMessageObject.push({
          domains: elem.impactId,
          impacts: elem.rating,
        })
      })
    } else {
      this.state.netNegAssessmentEvalTable.forEach((elem) => {
        netMessageObject.push({
          domains: elem.impactId,
          impacts: elem.rating,
        })
      })
    }
    return netMessageObject
  }

  getTargetRiskAssessmentMessages = () => {
    let targetMessageObject = []

    if (this.state.colorMan) {
      this.state.targetAssessmentEvalTable.forEach((elem) => {
        targetMessageObject.push({
          domains: elem.impactId,
          impacts: elem.rating,
        })
      })
    } else {
      this.state.targetNegAssessmentEvalTable.forEach((elem) => {
        targetMessageObject.push({
          domains: elem.impactId,
          impacts: elem.rating,
        })
      })
    }

    return targetMessageObject
  }

  updateRiskTreatments = () => {
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/riskTreatments`, {
        params: {
          risk: this.state.riskId,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        this.setState({ treatmentTableData: res.data })
      })
  }

  saveClickHandler = (type) => {
    let grossMessageArray = this.getRiskAssessmentMessages()
    let netMessageArray = this.getNetRiskAssessmentMessages()
    let targetMessageArray = this.getTargetRiskAssessmentMessages()
    axios
      .post(
        BASE_URL + `/organisations/${this.state.orgId}/risks`,
        {
          completed: type === 'draft' ? false : true,
          // deleted: type === 'deleted' ? true : false,
          eventCauses: this.state.riskCauses,
          eventDescription: this.state.riskEvent,
          eventConsequences: this.state.riskImpact,
          objectives: this.state.selectedObjectives,
          uncertaintyType: this.state.selectedUncertainty,
          department: this.state.selectedDepartment,
          owner: this.state.riskOwner,
          organisation: this.state.orgId,
          ref: JSON.stringify(Math.floor(Math.random() * 1000 + 1)),
          identifier: this.state.selectedIdentifier,
          riskType: this.state.riskType,
          riskCategory: this.state.riskCategory,
          riskStatus: this.state.riskstatus,
          reviewFrequency: this.state.freqReview,
          reviewDate: moment(this.state.reviewDate, 'DD/MM/YY').format(
            'YYYY-MM-DD'
          ),
          grossEmv: {
            probability: this.state.grossProbab,
            minValue: this.state.grossMin,
            likelyValue: this.state.grossLikely,
            maxValue: this.state.grossMax,
            assumptionBasis: this.state.grossBasis,
          },
          grossRiskAssessment: {
            riskAssessmentMessages: grossMessageArray.map((item, index) => {
              return {
                riskDomain: item.domains,
                impact: item.impacts,
                probability: this.state.selectedProbabilityGross,
              }
            }),
          },
          netEmv: {
            probability: this.state.netProbab,
            minValue: this.state.netMin,
            likelyValue: this.state.netLikely,
            maxValue: this.state.netMax,
            assumptionBasis: this.state.netBasis,
          },
          netRiskAssessment: {
            riskAssessmentMessages: netMessageArray.map((item, index) => {
              return {
                riskDomain: item.domains,
                impact: item.impacts,
                probability: this.state.selectedProbabilityNet,
              }
            }),
          },
          targetEmv: {
            probability: this.state.targetProbab,
            minValue: this.state.targetMin,
            likelyValue: this.state.targetLikely,
            maxValue: this.state.targetMax,
            assumptionBasis: this.state.targetBasis,
          },
          targetRiskAssessment: {
            riskAssessmentMessages: targetMessageArray.map((item, index) => {
              return {
                riskDomain: item.domains,
                impact: item.impacts,
                probability: this.state.selectedProbabilityTarget,
              }
            }),
          },
          comments: this.state.selectedComment,
          treatments: this.state.riskIdsArray.length,
          riskTreatments: this.state.treatmentTableData.map((item) => {
            return item.id
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          Swal.fire({
            title: 'Data posted successfully',
            text: 'Your form data is now present in the table',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
          })
          this.setState({
            riskFormId: res.data,
          })
        }
      })
      .then((res) => window.location.reload())
      .catch((err) => {
        Swal.fire({
          title: 'Data post unsuccessful',
          text: `${err}`,
          icon: 'warning',
          timer: 5000,
        })
      })
    this.props.switchMethod()
  }

  editTreatmentHandler = (treatment) => {
    this.setState({
      showEditModal: true,
      treatmentSentToEdit: treatment,
    })
  }

  next = () => {
    const { current } = this.state
    this.setState({ current: current + 1 })
  }

  prev = () => {
    const { current } = this.state
    this.setState({ current: current - 1 })
  }

  filterRiskTask = (categoryType) => {
    const { riskTypeList, riskCategories } = this.state
    let category =
      riskCategories &&
      riskCategories?.find((item) => {
        return categoryType == item.id
      })
    return (
      riskTypeList &&
      riskTypeList?.filter((data) => {
        return category.name === data.riskCategory
      })
    )
  }

  render() {
    const { current } = this.state
    const { Step } = Steps
    return (
      <div className="mb-4">
        <ViewMatrix
          showModal={this.state.showViewMatrix}
          closeModal={() => {
            this.setState({
              showViewMatrix: false,
            })
          }}
        />
        <ViewImpact
          showModal={this.state.showViewImpact}
          closeModal={() => {
            this.setState({
              showViewImpact: false,
            })
          }}
        />
        <div className="px-5 my-5">
          <button
            className="back-to-risks btn btn-primary mb-4"
            onClick={() => this.props.switchMethod()}
          >
            <i className="fa fa-arrow-left"></i> Back
          </button>
          <Steps current={current}>
            {steps.map((item) => (
              <Step
                class="btn btn-primary"
                key={item.title}
                title={item.title}
              />
            ))}
          </Steps>

          <Form
            onSubmit={(e) => {
              e.preventDefault()

              this.saveClickHandler()
            }}
          >
            {current === 0 && (
              <div
                className="my-3 p-2"
                style={{
                  backgroundColor: 'rgb(230, 230, 230)',
                }}
              >
                <h5>Identification and Description</h5>
                <Container>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Causes:<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          required
                          as="textarea"
                          rows={4}
                          name="riskCauses"
                          value={this.state.riskCauses}
                          onChange={(e) => {
                            this.setState({
                              riskCauses: e.target.value,
                            })
                          }}
                          placeholder="Describe causes..."
                        />
                      </Form.Group>
                    </Col>
                    <i
                      className="fa fa-arrows-h"
                      aria-hidden="true"
                      style={{
                        fontSize: '40px',
                        paddingTop: '55px',
                      }}
                    ></i>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Risk Event:<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          name="riskEvent"
                          value={this.state.riskEvent}
                          onChange={(e) => {
                            this.setState({
                              riskEvent: e.target.value,
                            })
                          }}
                          placeholder="Describe event..."
                        />
                      </Form.Group>
                    </Col>
                    <i
                      className="fa fa-arrows-h"
                      aria-hidden="true"
                      style={{
                        fontSize: '40px',
                        paddingTop: '55px',
                      }}
                    ></i>
                    <Col>
                      <Form.Label>
                        Risk Impact:<span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        name="riskImpact"
                        value={this.state.riskImpact}
                        onChange={(e) => {
                          this.setState({
                            riskImpact: e.target.value,
                          })
                        }}
                        placeholder="Describe impact..."
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Uncertainty Type:
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.selectedUncertainty}
                          onChange={(e) => {
                            this.setState({
                              selectedUncertainty: e.target.value,
                            })
                            this.handleColorChange()
                          }}
                        >
                          <option value="Positive">Opportunity</option>
                          <option value="Negative">Threat</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Department/Team:
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.selectedDepartment}
                          onChange={this.setDepartment}
                        >
                          <option value="">-</option>
                          {this.getDepartments()}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Owner:<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.riskOwner}
                          onChange={(e) => {
                            this.setState({
                              riskOwner: e.target.value,
                            })
                          }}
                        >
                          <option value="">-</option>
                          {this.renderOwners()}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Identifier:</Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.selectedIdentifier}
                          onChange={(e) =>
                            this.setState({
                              selectedIdentifier: e.target.value,
                            })
                          }
                        >
                          <option value="">-</option>
                          {this.renderUsers()}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <div>
                          <Form.Group>
                            <Form.Label>Link Objective (KPI)</Form.Label>
                            {/*<Select
                              style={{
                                height: '100px',
                              }}
                              multiple
                              value={this.state.selectedObjectives}
                              onChange={e => {
                                this.setState({
                                  selectedObjectives: [e.target.value],
                                })
                              }}
                            >
                              {this.getObjectives()}
                            </Select>*/}
                            <Select
                              mode="multiple"
                              as
                              const
                              style={{ width: '100%' }}
                              value={this.state.selectedObjectives}
                              onChange={(value) => {
                                this.setState({
                                  selectedObjectives: value,
                                })
                              }}
                            >
                              <option value="">-</option>
                              {this.getObjectives()}
                            </Select>
                          </Form.Group>
                        </div>
                        <div></div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Risk Category:<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="riskCategory"
                          onChange={this.sendToParent}
                        >
                          {/*  TODO */}
                          <option value="-">-</option>
                          {this.renderRiskCategory()}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Risk Type:<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.sendToParent}
                          name="riskType"
                        >
                          {/*  TODO */}
                          <option value="-">-</option>
                          {this.renderRiskType()}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Risk Status:<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.sendToParent}
                          name="riskstatus"
                        >
                          <option value="">-</option>
                          {this.renderRiskStatus()}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Review Frequency:
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.sendToParent}
                          name="freqReview"
                        >
                          {this.renderFreqofReview()}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <h6
                        style={{
                          color: '#30466a',
                          fontSize: '14px',
                        }}
                      >
                        Review Date:<span className="text-danger"> *</span>
                      </h6>
                      <div className="custom-date-picker">
                        <DatePicker
                          value={this.state.reviewDate}
                          name="testDate"
                          onChange={(e) => this.handleDateChange(e)}
                          dateFormat="dd/mm/yy"
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
            {current === 1 && (
              <div
                className="my-3 p-2"
                style={{
                  backgroundColor: 'rgb(230, 230, 230)',
                  paddingBottom: '10px',
                }}
              >
                <h5>Gross Assessment and Evaluation</h5>
                <div
                  style={{
                    paddingBottom: '10px',
                  }}
                >
                  <div style={{ display: 'inline-flex' }}>
                    <div>
                      <Container>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <p
                              style={{
                                paddingLeft: '60px',
                                color: '#30466a',
                                paddingTop: '10px',
                                fontSize: '16px',
                              }}
                            >
                              Impact dimensions:
                              <span className="text-danger"> *</span>
                            </p>
                            <ul
                              style={{
                                listStyle: 'none',
                              }}
                            >
                              {this.renderImpactDimensions()}
                            </ul>
                          </div>
                          <div>
                            <p
                              style={{
                                paddingLeft: '85px',
                                color: '#30466a',
                                paddingTop: '10px',
                                fontSize: '16px',
                              }}
                            >
                              Rating:<span className="text-danger"> *</span>
                            </p>
                            <ul
                              style={{
                                listStyle: 'none',
                              }}
                            >
                              {this.state.colorMan
                                ? this.state.impactDimentions.map((item) => {
                                    return (
                                      <li>
                                        <Form.Control
                                          as="select"
                                          onClick={(e) =>
                                            this.setRiskRating(item, e)
                                          }
                                          onChange={(e) =>
                                            this.setRiskRating(item, e)
                                          }
                                        >
                                          <option value="">-</option>
                                          {this.getPositiveRating()}
                                        </Form.Control>
                                      </li>
                                    )
                                  })
                                : this.state.impactDimentions.map((item) => {
                                    return (
                                      <li>
                                        <Form.Control
                                          as="select"
                                          onClick={(e) =>
                                            this.setNegRiskRating(item, e)
                                          }
                                          onChange={(e) =>
                                            this.setNegRiskRating(item, e)
                                          }
                                        >
                                          <option value="">-</option>
                                          {this.getNegativeRating()}
                                        </Form.Control>
                                      </li>
                                    )
                                  })}
                            </ul>
                          </div>
                          <div>
                            <p
                              style={{
                                paddingLeft: '30px',
                                color: '#30466a',
                                paddingTop: '10px',
                                fontSize: '16px',
                              }}
                            >
                              RAG Status:
                            </p>
                            <ul
                              style={{
                                listStyle: 'none',
                              }}
                            >
                              {this.renderGrossRAG()}
                            </ul>
                          </div>
                        </div>
                      </Container>
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'block',
                          paddingTop: '10px',
                          paddingLeft: '20px',
                        }}
                      >
                        <Form.Group>
                          <Form.Label
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            Probability:<span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            onChange={this.probabSelectGross}
                          >
                            <option value="">-</option>
                            {this.renderProbabilities()}
                          </Form.Control>
                        </Form.Group>
                        <h6>{this.state.descProbability}</h6>
                        <div
                          style={{
                            display: 'inline-flex',
                          }}
                        >
                          <Form.Group>
                            <Form.Label
                              style={{
                                fontSize: '16px',
                              }}
                            >
                              Gross Risk Score:
                              <span className="text-danger"> *</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              readOnly
                              value={this.grossHighestRAGNumber}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label
                              style={{
                                fontSize: '16px',
                              }}
                            >
                              RAG Status:<span className="text-danger"> *</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              readOnly
                              style={{
                                backgroundColor: this.grossHighestRAGColor,
                              }}
                            />
                          </Form.Group>
                        </div>
                        <h6>These scores are automatically calculated</h6>
                        <div
                          style={{
                            display: 'inline-flex',
                            float: 'right',
                          }}
                        >
                          <Button onClick={this.viewMatrixClickHandler}>
                            View Matrix
                          </Button>
                          <Button onClick={this.viewImpactClickHandler}>
                            View Impact
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div
                  style={{
                    paddingBottom: '10px',
                  }}
                >
                  <h5>Gross Expected Monetary Value</h5>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Probability:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="%"
                            onChange={this.setGrossProbab.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Minimum Value:</Form.Label>
                          <Form.Control
                            step="0.001"
                            value={this.state.grossMin}
                            type="text"
                            placeholder="E..."
                            onChange={this.setGrossMin.bind(this)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid zip.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Likely Value:</Form.Label>
                          <Form.Control
                            step="0.001"
                            value={this.state.grossLikely}
                            type="number"
                            placeholder="E..."
                            onChange={this.setGrossLikely.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Maximum Value:</Form.Label>
                          <Form.Control
                            step="0.001"
                            value={this.state.grossMax}
                            type="number"
                            placeholder="E... "
                            onChange={this.setGrossMax.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Gross Value@Risk</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="E..."
                            value={this.calculateValue()
                              .toFixed(2)
                              ?.toLocaleString()}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>State basis of estimation:</Form.Label>
                          <Form.Control
                            as="textarea"
                            type="text"
                            placeholder="Type here..."
                            value={this.state.grossBasis}
                            onChange={(e) =>
                              this.setState({
                                grossBasis: e.target.value,
                              })
                            }
                            style={{
                              width: '645px',
                              height: '150px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            )}

            {current === 2 && (
              <div
                className="my-3 p-2"
                style={{
                  backgroundColor: 'rgb(230, 230, 230)',
                  paddingBottom: '10px',
                }}
              >
                <div className="tab-title">
                  <div style={{ display: 'block' }}>
                    <h5>Risk Treatments (Action Plan)</h5>
                    {this.state.treatmentTableData.length == 0 ? (
                      <div>
                        <h4 style={{ color: '#30466a' }}>
                          You do not have any Risk Treatment Plan set up
                        </h4>
                        <Button
                          onClick={this.actionPlanClickHandler.bind(this)}
                        >
                          Add a treatment plan
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <p>
                            Add New
                            <span>
                              <button
                                type="button"
                                className="icon add"
                                onClick={this.actionPlanClickHandler.bind(this)}
                              >
                                <img src="../../assets/add.svg" alt="add" />
                              </button>
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                    {this.state.treatmentTableData.length >= 1 && (
                      <TreatmentPlanTable
                        treatmentTableData={this.state.treatmentTableData}
                        deleteTreatmentMain={(id) =>
                          this.setState({
                            treatmentTableData: this.state.treatmentTableData.filter(
                              (item) => item.id != id
                            ),
                          })
                        }
                        actionStatus={this.state.riskStatus}
                        actionPriorities={this.state.actionPriorities}
                        index={0}
                        firstPlanAdded={this.firstPlanAdded.bind(this)}
                        actionOwners={this.state.usersList}
                        orgID={this.state.orgId}
                        riskId={this.state.riskId}
                        editJustTreatmentHandler={this.editTreatmentHandler}
                        updateTreatments={this.updateRiskTreatments}
                      />
                    )}
                    <AddActionPlanModal
                      showModal={this.state.showModal}
                      actionOwner={this.state.actionOwner}
                      frequencyOfReview={this.state.reviewFrequency}
                      actionPriorities={this.state.actionPriorities}
                      actionEffectiveness={this.state.actionEffectiveness}
                      actionStatus={this.state.riskStatus}
                      actionOwners={this.state.usersList}
                      closeModal={() => {
                        this.setState({
                          showModal: false,
                        })
                      }}
                      data={{
                        descriptionFromChild: this.state.descriptionFromChild,
                        estimatedCostFromChild: this.state
                          .estimatedCostFromhild,
                        dueDateFromChild: this.state.dueDateFromChild,
                        actionPriorityFromChild: this.state
                          .actionPriorityFromChild,
                        actionStatusFromChild: this.state.actionStatusFromChild,
                        ownerFromChild: this.state.ownerFromChild,
                        calledFromChild: this.calledFromChild.bind(this),
                      }}
                      arrayData={{
                        riskIdsArray: this.state.riskIdsArray,
                        getriskIdsFromChild: this.getriskIdsFromChild.bind(
                          this
                        ),
                      }}
                      // myRiskId={this.state.riskItem.id}
                      updateTable={(data) => {
                        console.log({ data })
                        this.setState({
                          treatmentTableData: [
                            ...this.state.treatmentTableData,
                            {
                              ref: data.ref,
                              description: data.description,
                              ec: data.cost,
                              ac: data.actCost,
                              dd: data.dueDate,
                              ap: data.actionPriority,
                              as: data?.riskStatus,
                              focus: data?.focus,
                              actionType: data?.actionType,
                              completed: data.completed,
                              frequencyOfReview: data?.frequencyOfReview,
                              owner: data.owner,
                              id: data.riskId,
                              time: data.time,
                              notes: data.notes,
                              comments: 'na',
                              bcpDcr: data.bcpReq,
                              others: data.others,
                            },
                          ],
                        })
                      }}
                    />

                    <EditAfterAddModal
                      showEditModal={this.state.showEditModal}
                      actionOwner={this.state.actionOwner}
                      frequencyOfReview={this.state.reviewFrequency}
                      actionPriorities={this.state.actionPriorities}
                      actionEffectiveness={this.state.actionEffectiveness}
                      actionStatus={this.state.riskStatus}
                      actionOwners={this.state.usersList}
                      closeEditModal={() => {
                        this.setState({
                          showEditModal: false,
                        })
                      }}
                      updateTable={(data) => {
                        let treatmentTableData = this.state.treatmentTableData.filter(
                          (item) =>
                            item.id !== this.state.treatmentSentToEdit.id
                        )
                        treatmentTableData.push(data)
                        this.setState({
                          treatmentTableData,
                        })
                      }}
                      treatmentItem={{
                        ...this.state.selectedTreatment,
                      }}
                      orgID={this.state.orgId}
                      updateRiskTreatments={this.updateRiskTreatments}
                      treatmentObject={this.state.treatmentSentToEdit}
                    />
                  </div>
                </div>
                <br />
              </div>
            )}

            {current === 3 && (
              <div
                className="my-3 p-2"
                style={{
                  backgroundColor: 'rgb(230, 230, 230)',
                  paddingBottom: '10px',
                }}
              >
                <div style={{ width: '100%' }}>
                  <h5>Net Assessment and Evaluation</h5>
                  <div
                    style={{
                      backgroundColor: 'rgb(230, 230, 230)',
                      paddingBottom: '10px',
                    }}
                  >
                    <div style={{ display: 'inline-flex' }}>
                      <div>
                        <Container>
                          <div
                            style={{
                              display: 'flex',
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  paddingLeft: '60px',
                                  color: '#30466a',
                                  paddingTop: '10px',
                                  fontSize: '16px',
                                }}
                              >
                                Impact dimensions:
                                <span className="text-danger"> *</span>
                              </p>
                              <ul
                                style={{
                                  listStyle: 'none',
                                }}
                              >
                                {this.renderImpactDimensions()}
                              </ul>
                            </div>
                            <div>
                              <p
                                style={{
                                  paddingLeft: '85px',
                                  color: '#30466a',
                                  paddingTop: '10px',
                                  fontSize: '16px',
                                }}
                              >
                                Rating:<span className="text-danger"> *</span>
                              </p>
                              <ul
                                style={{
                                  listStyle: 'none',
                                }}
                              >
                                {this.state.colorMan
                                  ? this.state.impactDimentions.map((item) => {
                                      return (
                                        <li>
                                          <Form.Control
                                            as="select"
                                            onClick={(e) =>
                                              this.setNetRiskRating(item, e)
                                            }
                                            onChange={(e) =>
                                              this.setNetRiskRating(item, e)
                                            }
                                          >
                                            <option value="">-</option>
                                            {this.getPositiveRating()}
                                          </Form.Control>
                                        </li>
                                      )
                                    })
                                  : this.state.impactDimentions.map((item) => {
                                      return (
                                        <li>
                                          <Form.Control
                                            as="select"
                                            onClick={(e) =>
                                              this.setNegNetRiskRating(item, e)
                                            }
                                            onChange={(e) =>
                                              this.setNegNetRiskRating(item, e)
                                            }
                                          >
                                            <option value="">-</option>
                                            {this.getNegativeRating()}
                                          </Form.Control>
                                        </li>
                                      )
                                    })}
                              </ul>
                            </div>
                            <div>
                              <p
                                style={{
                                  paddingLeft: '30px',
                                  color: '#30466a',
                                  paddingTop: '10px',
                                  fontSize: '16px',
                                }}
                              >
                                RAG Status:
                              </p>
                              <ul
                                style={{
                                  listStyle: 'none',
                                }}
                              >
                                {this.renderNetRAG()}
                              </ul>
                            </div>
                          </div>
                        </Container>
                      </div>
                      <div>
                        <div
                          style={{
                            display: 'block',
                            paddingTop: '10px',
                            paddingLeft: '20px',
                          }}
                        >
                          <Form.Group>
                            <Form.Label
                              style={{
                                fontSize: '16px',
                              }}
                            >
                              Probability:
                              <span className="text-danger"> *</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              onChange={this.probabSelectNet}
                            >
                              <option value="">-</option>
                              {this.renderProbabilities()}
                            </Form.Control>
                          </Form.Group>
                          <h6>{this.state.descProbability}</h6>
                          <div
                            style={{
                              display: 'inline-flex',
                            }}
                          >
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: '16px',
                                }}
                              >
                                Net Risk Score:
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="netScore"
                                placeholder="Input Risk Score"
                                // onChange={this.sendToParent}
                                value={this.netHighestRAGNumber}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: '16px',
                                }}
                              >
                                RAG Status:
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                readOnly
                                style={{
                                  backgroundColor: this.netHighestRAGColor,
                                }}
                              />
                            </Form.Group>
                          </div>
                          <h6>These scores are automatically calculated</h6>
                          <div
                            style={{
                              display: 'inline-flex',
                              float: 'right',
                            }}
                          >
                            <Button onClick={this.viewMatrixClickHandler}>
                              View Matrix
                            </Button>
                            <Button onClick={this.viewImpactClickHandler}>
                              View Impact
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <h5>Net Expected Monetary Value</h5>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Probability:<span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="%"
                            value={this.state.netProbab}
                            onChange={this.setNetProbab.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Minimum Value:
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.netMin}
                            onChange={this.setNetMin.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Likely Value:<span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            value={this.state.netLikely}
                            onChange={this.setNetLikely.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Maximum Value:
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            value={this.state.netMax}
                            onChange={this.setNetMax.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Net Value@Risk
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="netValue"
                            onChange={this.sendToParent}
                            value={this.calculateValueNet().toFixed(2)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            State basis of estimation:
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Type here..."
                            value={this.state.netBasis}
                            onChange={(e) =>
                              this.setState({
                                netBasis: e.target.value,
                              })
                            }
                            style={{
                              width: '645px',
                              height: '150px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            )}

            {current === 4 && (
              <div
                className="my-3 p-2"
                style={{
                  backgroundColor: 'rgb(230, 230, 230)',
                  paddingBottom: '10px',
                }}
              >
                <div style={{ width: '100%' }}>
                  <h5>Target Assessment and Evaluation</h5>
                  <div
                    style={{
                      backgroundColor: 'rgb(230, 230, 230)',
                      paddingBottom: '10px',
                    }}
                  >
                    <div style={{ display: 'inline-flex' }}>
                      <div>
                        <Container>
                          <div
                            style={{
                              display: 'flex',
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  paddingLeft: '60px',
                                  color: '#30466a',
                                  paddingTop: '10px',
                                  fontSize: '16px',
                                }}
                              >
                                Impact dimensions:
                              </p>
                              <ul
                                style={{
                                  listStyle: 'none',
                                }}
                              >
                                {this.renderImpactDimensions()}
                              </ul>
                            </div>
                            <div>
                              <p
                                style={{
                                  paddingLeft: '85px',
                                  color: '#30466a',
                                  paddingTop: '10px',
                                  fontSize: '16px',
                                }}
                              >
                                Rating:
                              </p>
                              <ul
                                style={{
                                  listStyle: 'none',
                                }}
                              >
                                {this.state.colorMan
                                  ? this.state.impactDimentions.map((item) => {
                                      return (
                                        <li>
                                          <Form.Control
                                            as="select"
                                            onClick={(e) =>
                                              this.setTargetRiskRating(item, e)
                                            }
                                            onChange={(e) =>
                                              this.setTargetRiskRating(item, e)
                                            }
                                          >
                                            <option value="">-</option>
                                            {this.getPositiveRating()}
                                          </Form.Control>
                                        </li>
                                      )
                                    })
                                  : this.state.impactDimentions.map((item) => {
                                      return (
                                        <li>
                                          <Form.Control
                                            as="select"
                                            onClick={(e) =>
                                              this.setNegTargetRiskRating(
                                                item,
                                                e
                                              )
                                            }
                                            onChange={(e) =>
                                              this.setNegTargetRiskRating(
                                                item,
                                                e
                                              )
                                            }
                                          >
                                            <option value="">-</option>
                                            {this.getNegativeRating()}
                                          </Form.Control>
                                        </li>
                                      )
                                    })}
                              </ul>
                            </div>
                            <div>
                              <p
                                style={{
                                  paddingLeft: '30px',
                                  color: '#30466a',
                                  paddingTop: '10px',
                                }}
                              >
                                RAG Status:
                              </p>
                              <ul
                                style={{
                                  listStyle: 'none',
                                }}
                              >
                                {this.renderTargetRAG()}
                              </ul>
                            </div>
                          </div>
                        </Container>
                      </div>
                      <div>
                        <div
                          style={{
                            display: 'block',
                            paddingTop: '10px',
                            paddingLeft: '20px',
                          }}
                        >
                          <Form.Group>
                            <Form.Label
                              style={{
                                fontSize: '16px',
                              }}
                            >
                              Probability:
                            </Form.Label>
                            <Form.Control
                              as="select"
                              onChange={this.probabSelectTarget}
                            >
                              <option value="">-</option>
                              {this.renderProbabilities()}
                            </Form.Control>
                          </Form.Group>
                          <h6>{this.state.descProbability}</h6>
                          <div
                            style={{
                              display: 'inline-flex',
                            }}
                          >
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: '16px',
                                }}
                              >
                                Target Risk Score:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                readOnly
                                value={this.targetHighestRAGNumber}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: '16px',
                                }}
                              >
                                RAG Status:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                readOnly
                                style={{
                                  backgroundColor: this.targetHighestRAGColor,
                                }}
                              />
                            </Form.Group>
                          </div>
                          <h6>These scores are automatically calculated</h6>
                          <div
                            style={{
                              display: 'inline-flex',
                              float: 'right',
                            }}
                          >
                            <Button
                              onClick={this.viewMatrixClickHandler.bind(this)}
                            >
                              View Matrix
                            </Button>
                            <Button
                              onClick={this.viewImpactClickHandler.bind(this)}
                            >
                              View Impact
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <h5>Target Expected Monetary Value</h5>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Probability:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="%"
                            onChange={this.setTargetProbab.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Minimum Value:</Form.Label>
                          <Form.Control
                            step="0.001"
                            type="text"
                            placeholder="E..."
                            onChange={this.setTargetMin.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Likely Value:</Form.Label>
                          <Form.Control
                            step="0.001"
                            value={this.state.targetLikely}
                            type="number"
                            placeholder="E..."
                            onChange={this.setTargetLikely.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Maximum Value:</Form.Label>
                          <Form.Control
                            step="0.001"
                            type="number"
                            placeholder="E..."
                            onChange={this.setTargetMax.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Target Value@Risk</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="E..."
                            value={this.calculateValueTarget().toFixed(2)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>State basis of estimation:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type here..."
                            value={this.state.targetBasis}
                            onChange={(e) =>
                              this.setState({
                                targetBasis: e.target.value,
                              })
                            }
                            style={{
                              width: '645px',
                              height: '150px',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <br />
                <div>
                  <div>
                    <h5>Comments:</h5>
                    <Form.Group>
                      <Form.Label>Type any comments or notes here:</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Type here..."
                        style={{
                          width: '645px',
                          height: '150px',
                        }}
                        value={this.state.selectedComment}
                        onChange={(e) => {
                          this.setState({
                            selectedComment: e.target.value,
                          })
                        }}
                      />
                    </Form.Group>
                  </div>
                  <Button onClick={this.props.cancelClickHandler}>
                    Cancel
                  </Button>
                  <Button type={'submit'}>Save</Button>
                </div>
              </div>
            )}
          </Form>
        </div>

        <Button
          style={{ margin: '0 8px' }}
          onClick={() => this.prev()}
          disabled={current > 0 ? false : true}
        >
          Previous
        </Button>

        <Button
          type="primary"
          onClick={() => this.next()}
          disabled={current < steps.length - 1 ? false : true}
        >
          Next
        </Button>

        {this.props.tableType !== 'deletedRisk' && (
          <Button type="primary" onClick={() => this.saveClickHandler('draft')}>
            Save Draft
          </Button>
        )}
      </div>
    )
  }
}

export default MyRisksForm
