import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import camelCase from 'lodash/fp/camelCase'
export default function AddTableItemModal(props) {
  const { riskCategory } = props
  const [show, setShow] = useState(true)
  const [headings, setHeadings] = useState([])
  const [fields, setFields] = useState({})
  const [tab, setTab] = useState(0)
  const handleClose = () => props.dismissModal()
  // const handleShow = () => setShow(true)

  useEffect(() => {
    let defaultFields = {}
    props.heading.forEach(item => {
      /*let field_key = item
        .toLowerCase()
        .split(' ')
        .join('_')*/
      let field_key = camelCase(item)
      defaultFields[field_key] = ''
    })
    setFields(defaultFields)
  }, [])

  useEffect(() => {
    setHeadings(props.heading)
    setTab(props.tabGroup)
    setShow(props.showModal)
  }, [props])

  const handleFieldsChange = (value, field_key) => {
    console.log('handleFieldsChange -->', value, field_key)
    const newFields = { ...fields }
    newFields[field_key] = value
    setFields(newFields)
  }

  const handleSave = () => {
    let isEmpty = false
    for (const [key, value] of Object.entries(fields)) {
      if (value.trim().length === 0) isEmpty = true
    }
    console.log('IS EPTY ON SAVE -->', isEmpty, fields)
    if (isEmpty) {
    } else {
      props.addField(fields)
    }
  }

  const renderFields = () => {
    return headings.map(item => {
      const field_key = camelCase(item)
      /*   .toLowerCase()
        .split(' ')
        .join('_')*/

      return (
        <div className="form-group">
          <label>{item}</label> <br />
          {console.log('MAIN ITEM --->', item)}
          {item.toLowerCase() === 'description' ? (
            <textarea
              value={fields[field_key]}
              onChange={e => handleFieldsChange(e.target.value, field_key)}
            />
          ) : item.toLowerCase() === 'risk category' ? (
            <select
              value={fields[field_key]}
              onChange={e => handleFieldsChange(e.target.value, field_key)}
            >
              <option value=""></option>
              {riskCategory &&
                riskCategory.map((item, key) => (
                  <option key={key} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          ) : (
            <input
              type="text"
              value={fields[field_key]}
              onChange={e => handleFieldsChange(e.target.value, field_key)}
            />
          )}
        </div>
      )
    })
  }

  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add New</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderFields()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
