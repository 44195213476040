import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap'
import Sidebar from '../../common/Sidebar.jsx'
import OrganisationStrategySetup from '../../presentational/admin/OrganisationStrategySetup.jsx'
import '../../../style/container/risk-setup.scss'

export default class OrganisationStrategySetupContainerTest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: `/admin/org-strategy/${this.props.match.params.id}`,
    }
  }

  handleClick(value) {
    window.location.href = `${value}`
    this.setState({ activeItem: value })
  }

  render() {
    return (
      <div>
        {/* <SideMenu
                    activeItem={this.state.activeItem}
                    onMenuItemClick={this.handleClick}
                >
                    <Item label="Dashboard" value="/dashboard"></Item>
                    <Item label="Organisation" value="/organisation"></Item>
                    <Item label="Objectives" value="/objectives"></Item>
                    <Item label="Risks" value="/risks"></Item>
                    <Item
                        label="Programme & Projects"
                        value="/programme-and-projects"
                    ></Item>
                    <Item label="Reports" value="/reports"></Item>
                    <Item label="Admin" value="/admin">
                        <Item
                            label="Account Maintenance"
                            value={`/admin/account-maintenance/${this.props.match.params.id}`}
                        />
                        <Item
                            label="Organisation Reporting Setup"
                            value={`/admin/reporting-structure/${this.props.match.params.id}`}
                        />
                        <Item
                            label="Organisation Strategy Setup"
                            value={`/admin/org-strategy/${this.props.match.params.id}`}
                        />
                        <Item
                            label="Risk Setup"
                            value={`/admin/risk-setup/${this.props.match.params.id}`}
                        />
                    </Item>
                </SideMenu> */}
        <Sidebar {...this.props} />

        <OrganisationStrategySetup {...this.props} />
      </div>
    )
  }
}
