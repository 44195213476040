import { object } from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Modal, Form, Container, Row, Col, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import '../../../style/container/objectives.scss'

export default function MyDeptObjModal({
    frequency,
    users,
    objectives,
    show,
    handleClose,
    submitForm,
}) {
    const [formData, setData] = useState({
        year: '',
        goals: [],
        description: '',
        lead: '',
        startDt: '', 
        endDt: '',
        weight: '',
        reviewFrequency: '',
        parent: '',
    })

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const handleChange = (e) => {
        let data = formData
        data[e.target.name] = e.target.value
        setData(data)
    }

    const handleDateChange = (e, name) => {
        let finalDate = moment(e).format('DD/MM/YY')

        if (name == 'startDate') {
            setStartDate(finalDate)
            setData({ ...formData, startDt: finalDate })
        } else {
            setEndDate(finalDate)
            setData({ ...formData, endDt: finalDate })
        }
    }

    const handleObjectiveChange = (e) => {
        const objective = objectives.filter(
            (item) => item.id == e.target.value
        )[0]
        setData({
            ...formData,
            goals: objective.goals.map((item) => item.id),
            parent: objective.id,
        })
    }

    return (
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          className="modal-main objectives-resize"
        >
          <Modal.Header className="modal-header mt-0">
            Add Department Objective
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Form>
              <Container>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Year:<span className="text-danger"> *</span></Form.Label>
                      <input
                        type="number"
                        name="year"
                        onChange={e => handleChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Objectives (Strategic/Parent):<span className="text-danger"> *</span></Form.Label>
                      <Form.Control
                        as="select"
                        onChange={e => handleObjectiveChange(e)}
                      >
                        <option value="-"></option>
                        {objectives.map(item => (
                          <option value={item.id}>{item.description}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Department Objective [SMART]:<span className="text-danger"> *</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter objective"
                        name="description"
                        onChange={e => handleChange(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Lead:<span className="text-danger"> *</span></Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue=""
                        name="lead"
                        onChange={e => handleChange(e)}
                      >
                        <option disabled value="">
                          -
                        </option>
                        {users.map(user => (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Start Date:<span className="text-danger"> *</span></Form.Label>
                      <DatePicker
                        format="dd/mm/yy"
                        name="startDate"
                        onChange={e => handleDateChange(e, 'startDate')}
                        value={startDate}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>End Date:<span className="text-danger"> *</span></Form.Label>
                      <DatePicker
                        format="dd/mm/yy"
                        name="endDate"
                        onChange={e => handleDateChange(e, 'endDate')}
                        value={endDate}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Weight:</Form.Label>
                      <Form.Control
                        type="number"
                        name="weight"
                        placeholder="Enter weight"
                        onChange={e => handleChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Review Frequency:<span className="text-danger"> *</span></Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue=""
                        name="reviewFrequency"
                        onChange={e => handleChange(e)}
                      >
                        <option disabled value="">
                          -
                        </option>
                        {frequency.map(freq => (
                          <option key={freq.id} value={freq.id}>
                            {freq.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={e => submitForm(e, formData)}>Save</Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    )
}
