import React from 'react'

const LicenceFormInput = ({ handlePaymentClick, licences }) => (
    <div className="licence-form-input">
        <h2>Pick a plan</h2>
        <div className="licence-container">
            {licences.map((licence, index) => {
              return (
                <div key={index} className="licence-holder">
                    <div className={`licence-header ${licence.name}`}>{licence.name}</div>
                    <div className="licence-body">
                        <div className="licence-price">
                            <p>£{licence.costPerUser}</p>
                        </div>
                        <div className="licence-button-container">
                            <button className="btn btn-secondary" onClick={(e) => handlePaymentClick(e, licence.stripeItemCode)} >Try for free</button>
                        </div>
                        <div className="licence-features">
                            <p>{licence.description}</p>
                        </div>
                    </div>
                    <div className="licence-footer">
                        <p>See all features</p>
                    </div>
                </div>
              )
            })}
        </div>
    </div>
)

export default LicenceFormInput
