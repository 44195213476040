import React from 'react'

const PaymentSuccessForm = ({paymentDetails}) => (
  <div>
      <p>State: {paymentDetails.status} </p>
      <p>Payment Reference: {paymentDetails.checkoutSessionId} </p>
      <br/>
      <p>Date Completed: {paymentDetails.dateCompleted.dayOfWeek}/{paymentDetails.dateCompleted.dayOfMonth}/{paymentDetails.dateCompleted.year}</p>
  </div>
)

export default PaymentSuccessForm
