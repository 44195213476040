import React, { Component } from 'react'
import axios from 'axios'
import AccountCreationFormInput from '../presentational/AccountCreationFormInput.jsx'
import currencyList from '../../common/countaryCurrencyList.json'
import isEmpty from 'lodash/fp/isEmpty'
import { Modal, notification } from 'antd'
import Auth from '../../lib/Auth.js'

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
)

class AccountCreationContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      requiredItem: 0,
      title: 'Account Creation',
      postData: {
        organisationMessage: {
          name: '',
          status: 'REGISTERING',
        },
        addressMessage: {
          addressLine1: '',
          country: '',
        },
        userMessage: {
          firstName: '',
          lastName: '',
          emailAddress: '',
          status: 'PENDING',
          profilePictureFile: '',
          isUser: true,
          role: 1,
        },
        organisationValuesMessage: {
          organisationValues: [],
        },
        regionMessages: [],
      },
      regions: {
        name: '',
        organisation: this.props.match.params.id,
        addressMessage: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          state: '',
          country: '',
          currencyCode: '',
          postCode: '',
        },
      },
      selectedRegion: {
        name: '',
        organisation: this.props.match.params.id,
        addressMessage: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          state: '',
          country: '',
          currencyCode: '',
          postCode: '',
        },
      },
      values: {
        name: '',
        description: '',
      },
      selectedValue: {
        name: '',
        description: '',
      },
      countries: [],
      industries: [],
      errors: {
        orgName: '',
        address1: '',
        address2: '',
        address3: '',
        postCode: '',
        state: '',
        country: '',
        currencyCode: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        termsAndConditions: '',
      },
      postOrganisationregionMessages: [],
      agreeTandC: false,
      defaultCountrySelect: {},
    }
    this.handleChangeMessage = this.handleChangeMessage.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleChangeRegions = this.handleChangeRegions.bind(this)
    this.handleChangeSelectedRegions = this.handleChangeSelectedRegions.bind(
      this
    )
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.addValues = this.addValues.bind(this)
    this.viewValue = this.viewValue.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this.deleteValue = this.deleteValue.bind(this)
    this.addRegions = this.addRegions.bind(this)
    this.viewRegion = this.viewRegion.bind(this)
    this.deleteRegion = this.deleteRegion.bind(this)
    this.updateRegion = this.updateRegion.bind(this)
    this.validateCurrency = this.validateCurrency.bind(this)
  }

  componentDidMount() {
    axios
      .all([
        axios.get(BASE_URL + '/industries'),
        axios.get(BASE_URL + '/countries'),
      ])
      .then(
        axios.spread((industries, countries) => {
          this.setState({ industries: industries.data })
          this.setState({ countries: countries.data })
        })
      )
      .catch(err => this.setState({ errors: err.response }))
  }

  handleChange({ target: { name, value } }, valueType) {
    this.setState({
      [valueType]: {
        ...this.state[valueType],
        [name]: value,
      },
    })
  }

  handleChangeRegions({ target: { name, value } }, messageType) {
    this.setState({
      regions: {
        ...this.state.regions,
        [messageType]: {
          ...this.state.regions[messageType],
          [name]: value,
        },
      },
    })
  }

  handleChangeSelectedRegions({ target: { name, value } }, messageType) {
    this.setState({
      selectedRegion: {
        ...this.state.selectedRegion,
        [messageType]: {
          ...this.state.selectedRegion[messageType],
          [name]: value,
        },
      },
    })
  }

  handleCheckboxChange({ target: { name, checked } }) {
    let errors = this.state.errors

    switch (name) {
      case 'agreeTandC':
        errors.termsAndConditions = this.validateTermsAndConditions(checked)
        break
      default:
        break
    }

    this.setState({
      [name]: checked,
    })
  }

  handleChangeMessage({ target: { name, value } }, messageType) {
    let errors = this.state.errors
    switch (name) {
      case 'name':
        errors.orgName = this.validateOrgName(value)
        break
      case 'addressLine1':
        errors.address1 = this.validateAddress1(value)
        break
      case 'addressLine2':
        break
      case 'addressLine3':
        break
      case 'postCode':
        break
      case 'state':
        break
      case 'country':
        errors.country = this.validateCountry(value)
        break
      case 'currencyCode':
        errors.currencyCode = this.validateCurrency(value)
        break
      case 'firstName':
        errors.firstName = this.validateFirstName(value)
        break
      case 'lastName':
        errors.lastName = this.validateFirstName(value)
        break
      case 'emailAddress':
        errors.emailAddress = this.validateEmailAddress(value)
        break
      default:
        break
    }

    this.setState({
      postData: {
        ...this.state.postData,
        [messageType]: {
          ...this.state.postData[messageType],
          [name]: value,
        },
      },
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    if (this.validateForm()) {
      axios
        .post(BASE_URL + '/organisations', this.state.postData, {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        })
        //.then(res => {
        //  var path = `/licence/${res.data}`;
        //  this.props.history.push(path);
        //})
        .then(res => {
          console.log('res ---->>', res)

          var path = '/'
          notification.success({
            message: 'Successfully Registered',
            description: 'You have been successfully registered.',
          })
          // this.props.history.push(path)
        })
        .catch(e => {
          console.log('e ---->>', e)
          Modal.error({
            title: 'Unsuccessful',
            content: 'An organisation by this name already exists',
          })
          this.props.history.push(path)
        })
    }
  }

  validateForm() {
    let errors = this.state.errors

    errors.termsAndConditions = this.validateTermsAndConditions(
      this.state.agreeTandC
    )
    errors.orgName = this.validateOrgName(
      this.state.postData.organisationMessage.name
    )
    errors.address1 = this.validateAddress1(
      this.state.postData.addressMessage.addressLine1
    )
    errors.country = this.validateCountry(
      this.state.postData.addressMessage.country
    )
    errors.currencyCode = this.validateCurrency(
      this.state.postData.addressMessage.currencyCode
    )
    errors.firstName = this.validateFirstName(
      this.state.postData.userMessage.firstName
    )
    errors.lastName = this.validateLastName(
      this.state.postData.userMessage.lastName
    )
    errors.emailAddress = this.validateEmailAddress(
      this.state.postData.userMessage.emailAddress
    )
    return this.validateErrors(errors)
  }

  validateErrors(errors) {
    let valid = true
    Object.values(errors).forEach(val => val.length > 0 && (valid = false))
    return valid
  }

  validateLength(value, length) {
    return !isEmpty(value) && value.length >= length
  }

  validateOrgName(value) {
    return this.validateLength(value, 5)
      ? ''
      : 'Organisation name must be at least 5 characters long'
  }

  validateAddress1(value) {
    return this.validateLength(value, 1) ? '' : 'Address 1 can not be blank'
  }

  validateCountry(value) {
    return this.validateLength(value, 1) ? '' : 'Please select a Country'
  }

  validateCurrency(value) {
    return this.validateLength(value, 1) ? '' : 'Please select a Currency'
  }

  validateFirstName(value) {
    return this.validateLength(value, 1) ? '' : 'First name cannot be blank'
  }

  validateLastName(value) {
    return this.validateLength(value, 1) ? '' : 'Last name cannot be blank'
  }

  validateEmailAddress(value) {
    return validEmailRegex.test(value) ? '' : 'Email Address is not valid'
  }

  validateTermsAndConditions(checked) {
    return checked ? '' : 'Please accept Terms & Conditions to continue'
  }

  addValues() {
    if (this.state.values.name || this.state.values.description !== '') {
      this.setState({
        postData: {
          ...this.state.postData,
          organisationValuesMessage: {
            ...this.state.postData.organisationValuesMessage,
            organisationValues: [
              ...this.state.postData.organisationValuesMessage
                .organisationValues,
              {
                name: this.state.values.name,
                description: this.state.values.description,
                ref: this.state.values.ref,
              },
            ],
          },
        },
      })
    }
    this.setState({
      values: {
        ref: '',
        name: '',
        description: '',
      },
    })
  }

  viewValue(value, index) {
    this.setState({
      requiredItem: index,
    })
    const { organisationValues } = this.state.postData.organisationValuesMessage
    organisationValues.map(orgValue => {
      if (orgValue === value) {
        this.setState({
          selectedValue: orgValue,
        })
      }
    })
  }

  updateValue(e, value) {
    const requiredItem = this.state.requiredItem
    let letOrganisationValues = this.state.postData.organisationValuesMessage
      .organisationValues

    letOrganisationValues[requiredItem] = value
    this.setState({
      postData: {
        ...this.state.postData,
        organisationValuesMessage: {
          ...this.state.postData.organisationValuesMessage,
          organisationValues: letOrganisationValues,
        },
      },
    })
  }

  deleteValue(index) {
    const { organisationValues } = this.state.postData.organisationValuesMessage
    this.setState({
      postData: {
        ...this.state.postData,
        organisationValuesMessage: {
          ...this.state.postData.organisationValuesMessage,
          organisationValues: organisationValues.filter((_, i) => i !== index),
        },
      },
    })
  }

  addRegions() {
    this.setState({
      postData: {
        ...this.state.postData,
        regionMessages: [
          ...this.state.postData.regionMessages,
          {
            name: this.state.regions.name,
            addressMessage: {
              addressLine1: this.state.regions.addressMessage.addressLine1,
              addressLine2: this.state.regions.addressMessage.addressLine2,
              addressLine3: this.state.regions.addressMessage.addressLine3,
              state: this.state.regions.addressMessage.state,
              country: this.state.regions.addressMessage.country,
              currencyCode: this.state.regions.addressMessage.currencyCode,
              postCode: this.state.regions.addressMessage.postCode,
            },
          },
        ],
      },
    })
    this.setState({
      regions: {
        name: '',
        addressMessage: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          state: '',
          country: '',
          postCode: '',
        },
      },
    })
  }

  viewRegion(region, index) {
    this.setState({
      requiredItem: index,
    })
    const { regionMessages } = this.state.postData
    regionMessages.map(orgRegion => {
      if (orgRegion === region) {
        this.setState({
          selectedRegion: orgRegion,
        })
      }
    })
  }

  updateRegion(e, region) {
    const requiredItem = this.state.requiredItem
    const letRegionMessage = this.state.postData.regionMessages

    letRegionMessage[requiredItem] = region
    this.setState({
      postData: {
        ...this.state.postData,
        regionMessages: letRegionMessage,
      },
    })
  }

  deleteRegion(index) {
    const { regionMessages } = this.state.postData
    this.setState({
      postData: {
        ...this.state.postData,
        regionMessages: regionMessages.filter((_, i) => i !== index),
      },
    })
  }

  handleCountrySelect = countryName => {
    let currency = {}
    /* !isEmpty(currencyList) &&
            currencyList.find(data => data.countryName === countryName)*/
    let findCountry =
      !isEmpty(this.state.countries) &&
      this.state.countries.find(data => data.code === countryName)

    Object.keys(currencyList).map((shotCode, key) => {
      currency =
        currencyList[shotCode].countryName === findCountry.longName
          ? { ...currency, ...currencyList[shotCode] }
          : currency
    })
    let postData = this.state.postData
    let addressMessage = this.state.postData.addressMessage
    let organisationMessage = this.state.postData.organisationMessage
    postData = {
      ...postData,
      addressMessage: {
        ...addressMessage,
        currencyCode: currency.currency,
        country: countryName,
      },
      organisationMessage: {
        ...organisationMessage,
        currencyCode: currency.currency,
      },
    }

    this.setState(
      {
        defaultCountrySelect: currency,
        postData,
      },
      () => {
        console.log('postData BBD --->', postData)
      }
    )
  }

  handleCurrencySelect = currency => {
    let postData = this.state.postData
    let addressMessage = this.state.postData.addressMessage
    let organisationMessage = this.state.postData.organisationMessage
    postData = {
      ...postData,
      addressMessage: {
        ...addressMessage,
        currencyCode: currency,
      },
      organisationMessage: {
        ...organisationMessage,
        currencyCode: currency,
      },
    }
    this.setState({
      defaultCountrySelect: currency,
      postData,
    })
  }

  render() {
    console.log('BBD', this.state.postData)
    if (!this.state.industries || !this.state.countries) return null
    const {
      title,
      industries,
      countries,
      values,
      selectedValue,
      regions,
      selectedRegion,
      defaultCountrySelect,
    } = this.state
    const { organisationValues } = this.state.postData.organisationValuesMessage
    const { regionMessages } = this.state.postData
    const { regionsAddressMessage } = this.state.regions.addressMessage

    return (
      <div>
        <AccountCreationFormInput
          type="text"
          handleChangeMessage={this.handleChangeMessage}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          handleCheckboxChange={this.handleCheckboxChange}
          addValues={this.addValues}
          viewValue={this.viewValue}
          updateValue={this.updateValue}
          deleteValue={this.deleteValue}
          countries={countries}
          title={title}
          industries={industries}
          orgValues={organisationValues}
          values={values}
          selectedValue={selectedValue}
          errors={this.state.errors}
          regions={regions}
          regionsAddressMessage={regionsAddressMessage}
          handleChangeRegions={this.handleChangeRegions}
          addRegions={this.addRegions}
          orgRegions={regionMessages}
          viewRegion={this.viewRegion}
          selectedRegion={selectedRegion}
          updateRegion={this.updateRegion}
          deleteRegion={this.deleteRegion}
          handleChangeSelectedRegions={this.handleChangeSelectedRegions}
          currencyList={currencyList}
          defaultCountrySelect={defaultCountrySelect}
          handleCountrySelect={this.handleCountrySelect}
          handleCurrencySelect={this.handleCurrencySelect}
        />
      </div>
    )
  }
}

export default AccountCreationContainer
