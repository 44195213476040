import React from 'react'
import PropTypes from 'prop-types'
const VerifyEmail = () => (
  <div className="verify-email">
    <div className="verify-email-container">
      <div className="form-input-header">
        <h1>Thank you</h1>
      </div>
      <p>An link has been sent to your inbox to verify your email and to continue with the registration process.</p>
      <a href="/login" className="btn primary">Login</a>
    </div>
  </div>
)

export default VerifyEmail
