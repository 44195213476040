import React, { useState, useEffect } from 'react'
import { Navbar } from 'react-bootstrap'
import Sidebar from '../common/Sidebar.jsx'
import UserObjectives from '../presentational/UserObjectives.jsx'

const UserObjectivesContainer = (props) => {
    return (
        <div>
            <div>
                <Sidebar {...props} /> 
            </div>
            <div>
                <UserObjectives {...props}  />
            </div>
        </div>
    )
}

export default UserObjectivesContainer
